import { Collapse, Row, Col, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ButtonItem,
    ModalItem,
    dineroDisabledMask,
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Panel } = Collapse;

class VerFacturasReposicion extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        facturasHtml: <p>No se encontro ninguna factura</p>,
    };
    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible)
            this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "cajaefectivo/facturasReposicion",
                { pagoId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success !== 2) return;
                const html = (
                    <Collapse style={{ marginBottom: 10 }}>
                        {res.data.facturas.map((valueFactura) => {
                            return (
                                <Panel
                                    extra={
                                        valueFactura.estado !== 0 ? (
                                            valueFactura.moneda + ' - ' + dineroDisabledMask(valueFactura.monto)
                                        ) : (
                                            <Tag color="red">
                                                Cancelada: {valueFactura.moneda + ' - ' + dineroDisabledMask(valueFactura.monto)}
                                            </Tag>
                                        )
                                    }
                                    header={valueFactura.fechaFactura}
                                    key={valueFactura._id}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <p>
                                                Codigo:{" "}
                                                {valueFactura.codigo}
                                            </p>
                                            <p>
                                                Fecha Factura:{" "}
                                                {valueFactura.fechaFactura}
                                            </p>
                                        </Col>
                                        <Col span={12}>
                                            <p>Moneda: {valueFactura.moneda}</p>
                                            <p>
                                                Monto:{" "}
                                                {dineroDisabledMask(valueFactura.monto)}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <ButtonItem size="small" type="primary" onClick={() => {
                                            if(valueFactura.saldo){
                                                this.props.history.push("/panel/verFacturaSinOrdenDeCompra", { id: valueFactura._id })
                                            }else{
                                                this.props.history.push("/panel/verFacturaCajaChica", { id: valueFactura._id })
                                            }
                                        }}>
                                            Ir a la factura
                                        </ButtonItem>
                                    </Row>
                                </Panel>
                            );
                        })}
                    </Collapse>
                );
                this.setState({ facturasHtml: html });
            });
        this.setState({ loading: false });
    }
    cerrarVerFacturasReposicion() {
        this.props.cerrarVerFacturasReposicion();
    }
    render() {
        return (
            <>
                <ModalItem
                    title="Facturas Reposicion"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarVerFacturasReposicion.bind(this)}
                    paddingTop
                >
                    {this.state.facturasHtml}
                </ModalItem>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(VerFacturasReposicion);
