import React, { Component } from "react";
import { Spin, Divider, Space } from "antd";
import {ButtonItem} from "./Items";
import { TreeSelect } from "antd5";

class TreeSelectItem extends Component {
  render() {
    let propsNuevas = {};
    if (!this.props.noBuscar) {
      propsNuevas.filterOption = (input, option) =>
        option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0 || option?.padre?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
      propsNuevas.showSearch = true;
    }
    if(this.props.mode === 'multiple'){
      propsNuevas.maxTagCount = 'responsive'
    }
    if(this.props.selectAll){
      propsNuevas.dropdownRender = (menu) => {
        return (
          <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
              <ButtonItem type="text" onClick={this.props.onSelectAll}>
                Seleccionar Todos
              </ButtonItem>
          </Space>
          </>
        )
      }
    }
    if (this.props.mostrarCarga){
      propsNuevas.dropdownRender=(menu)=>{
        return(
          <Spin spinning = {this.props.loading}>
          {
            menu
          }
          </Spin>
        )
      }
    }
    let transformedProperties = {};
    for (var key in this.props) {
      if (
        !(
          key.toString() === "noBuscar"
        )
      ) {
        transformedProperties = {
          ...transformedProperties,
          [key]: this.props[key],
        };
      }
    }

    const style = {
      color: this.props.disabled ? 'black' : null,
      backgroundColor: this.props.disabled ? this.props.fondoBlanco ? 'white' : '#F5F5F5' : null,
      
      borderBottom: this.props.sinBorde ? 'none' : '1px solid #E0FFFFFF',
      borderRadius: '4px', // Pequeño redondeo para bordes
      transition: 'border-color 0.3s ease', // Transición suave para el borde
      boxShadow: this.props.sinBorde ? 'none' : '0 1px 3px rgba(0, 0, 0, 0.1)', // Sombra suave,
      width: this.props.width ? this.props.width : undefined,
      ...this.props.style
    }

    return (
      <TreeSelect
        dropdownMatchSelectWidth={this.props.placeholder === 'Insumo' || this.props.placeholder === 'Proveedor' ? 600 : 400}
        {...propsNuevas}
        {...transformedProperties}
        placeholder={this.props.placeholder}
        style={style}
        bordered={false}
      >
        {this.props.children}
      </TreeSelect>
    );
  }
}

export default TreeSelectItem;
