import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const matrizPdf = (data) => {
  return {
    footer: { image: "logoBiwo", alignment: "center", width: 50 },
    content: [
      {
        style: "tableExample",
        table: {
          widths: ["25%", "50%", "25%"],
          body: [
            [
              { image: "logo", fit: [80, 80], border: [false, false, false, false] },
              {
                table: {
                  body: [
                    [{ text: `${data.empresa.nombre}`, style: "tableHeader" }],
                    [{ text: `R.F.C: ${data.empresa.rfc}`, style: "textoChico", alignment: "center" }],
                    [{ text: `${data.empresa.calle} ${data.empresa.numeroCalle} ${data.empresa.numeroInterior || ''}`, style: "textoChico", alignment: "center" }],
                    [{ text: `${data.empresa.colonia.tipoAsentamiento} ${data.empresa.colonia.nombre} C.P. ${data.empresa.colonia.cp} ${data.empresa.municipio.nombre} ${data.empresa.estado.abreviatura} ${data.empresa.pais.abreviatura}`, style: "textoChico", alignment: "center" }],
                    [{ text: "MATRIZ", style: "tableHeader" }],
                  ],
                },
                layout: "noBorders",
                border: [false, false, false, false]
              },
              {
                table: {
                  body: [
                    [{ text: "Clave Insumo:", style: "subheader", alignment: "center" }],
                    [{ text: data.matriz.codigo, style: "textoChico", alignment: "center" }],
                    [{ text: "Fecha:", style: "subheader", alignment: "center" }],
                    [{ text: moment(data.fecha).locale('es').format('LL'), style: "textoChico", alignment: "center" }],
                  ],
                },
                layout: "noBorders",
                border: [false, false, false, false]
              }
            ]
          ]
        },
      },
      { text: `Elaboro: ${data.usuario.nombre} ${data.usuario.apellidoPaterno} ${data.usuario.apellidoMaterno}` , margin: [0, 10, 0, 0] },
      {
        style: "tableExample",
        table: {
          widths: ["60%", "40%"],
          body: [
            [
              {
                table: {
                  widths: ["28%", "72%"],
                  body: [
                    [{ text: "Descripcion:", style: "subheader" }, { text: data.matriz.descripcion, style: "textoChico" }],
                    [{ text: "Descripcion larga:", style: "subheader" }, { text: data.matriz.descripcionLarga, style: "textoChico" }],
                  ],
                },
                layout: "noBorders",
              },
              {
                table: {
                  widths: ["40%", "60%"],
                  body: [
                    [{ text: "Unidad:", style: "subheader" }, { text: data.unidadMatriz.nombre, style: "textoChico" }],
                    [{ text: "Costo:", style: "subheader" }, { text: data.matriz.costo, style: "textoChico" }],
                  ],
                },
                layout: "noBorders",
              }
            ]
          ],
        },
      },
      {
        style: "tableExample",
        table: {
          widths: ["15%", "45%", "10%", "10%", "10%", "10%"],
          body: [
            [
              { text: "Código", style: "tituloTabla" },
              { text: "Insumo", style: "tituloTabla" },
              { text: "Unidad", style: "tituloTabla" },
              { text: "Cantidad", style: "tituloTabla" },
              { text: "Costo Unitario", style: "tituloTabla" },
              { text: "Costo Total", style: "tituloTabla" },
            ],
            ...data.insumosUtilizar.map((insumo) => [
              { text: insumo.clave, style: "textoTabla", alignment: "left" },
              { text: insumo.descripcion, style: "textoTabla", alignment: "center" },
              { text: insumo.unidad, style: "textoTabla", alignment: "center" },
              { text: insumo.cantidad, style: "textoTabla", alignment: "right" },
              { text: insumo.costo, style: "textoTabla", alignment: "right" },
              { text: insumo.costoTotal, style: "textoTabla", alignment: "right" },
            ]),
            [
              { text: "", colSpan: 4, border: [false, false, false, false] }, {}, {}, {},
              { text: "Total", style: "tituloTabla", alignment: "right" },
              { text: data.matriz.costo, style: "textoTabla", alignment: "right" },
            ],
          ],
        },
        layout: {
          defaultBorder: true,
        },
      },
      {
        style: "tableExample",
        margin: [0, 50, 0, 0],
        table: {
          widths: ["15%", "30%", "10%", "30%", "15%"],
          body: [
            [
              { text: "", style: "textoTabla", alignment: "center" },
              { text: `Elaboro: ${data.usuario.nombre} ${data.usuario.apellidoPaterno} ${data.usuario.apellidoMaterno}`, style: "textoTabla", alignment: "center", border: [false, true, false, false] },
              { text: "", style: "textoTabla", alignment: "center" },
              { text: `Autorizo`, style: "textoTabla", alignment: "center", border: [false, true, false, false] },
              { text: "", style: "textoTabla", alignment: "center" },
            ],
          ],
        },
        layout: { defaultBorder: false },
      }
    ],
    images: {
        logo: data.empresa.logo.base64Completa,
        logoBiwo: data.logoBiwo.base64Completa,
      },
    styles: {
      header: { fontSize: 9, bold: true },
      subheader: { fontSize: 8, bold: true },
      tableExample: { margin: [0, 5, 0, 5] },
      tablaSinMargen: { margin: [0, 0, 0, 0] },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: "black",
        alignment: "center",
      },
      textoChico: { fontSize: 8 },
      tituloTabla: {
        fontSize: 8,
        bold: true,
        fillColor: "#cccccc",
        alignment: "center",
      },
      textoTabla: { fontSize: 8 },
    },
    pageSize: "LETTER",
    pageMargins: [20, 20, 20, 20],
  };
};

export default matrizPdf;
