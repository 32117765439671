import { CloudServerOutlined, FileExcelOutlined, EyeOutlined, FilePdfOutlined } from "@ant-design/icons";
import { PageHeader, Space, Tag, Empty, Tooltip, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonItem, TableItem, dineroDisabledMask,SegmentedItem } from "../../../Components/Items";
import moment from "moment";
import VistaPrevia from "./VistaPrevia/VistaPrevia";
import facturaXmlPdf from "../../../Pdf/facturaXmlPdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import complementoXmlPdf from "../../../Pdf/complementoXmlPdf";
import RelacionarPago from "./RelacionarPago/RelacionarPago";
import AgregarAOrdenDeCompra from "./AgregarAOrdenDeCompra/AgregarAOrdenDeCompra";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class ArchivosXml extends Component {
  state = {
    todasArchivosXml: [],
    loading: false,
    emitido: 1,
    modalVistaPrevia: false,
    modalVistaPreviaId: null,
    modalRelacionarPago: false,
    modalRelacionarPagoId: null,
    modalOrdenDeCompra: false,
    modalOrdenDeCompraId: null,
    valor: 1
  };
  componentDidMount() {
    this.consultarArchivosXml(this.state.valor);
  }
  consultarArchivosXml(val) {
    this.setState({ loading: true })
    axios
      .post(
        "descargaMasiva/selectTodosXml",
        { empresaId: this.props.empresaId, valor: val },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false })
        if (res.data.status === 404) {
          this.setState({ todasArchivosXml: [] });
        } else {
          this.setState({
            todasArchivosXml: res.data.data.map(value => {
              return { ...value, accion: { tipoDeComprobante: value.tipoDeComprobante, _id: value._id, contabilizada: value.contabilizada, nombre: value.nombre, estado: value.estado } }
            }),
          });
        }
      });
  }

  descargarXml(id) {
    axios
      .post(
        "descargaMasiva/descargarXml",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          var xmltext = res.data.xml;

          var filename = res.data.name;
          var pom = document.createElement("a");
          var bb = new Blob([xmltext], { type: "text/plain" });

          pom.setAttribute("href", window.URL.createObjectURL(bb));
          pom.setAttribute("download", filename);

          pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
            ":"
          );
          pom.draggable = true;
          pom.classList.add("dragout");

          pom.click();
        } else {
          message.error("Hubo un error y no se pudo descargar");
        }
      });
  }

  agregarFacturaSinOrdenDeCompra(id) {
    this.setState({ loading: true });
    axios
      .post(
        "descargaMasiva/datosXml",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.props.history.push('/panel/agregarFacturaSinOrdenDeCompra', { fileName: res.data.uuid, fileData: JSON.stringify(res.data.dataXml) })
        } else {
          message.error('Hubo un error y no se pudo redigir')
        }
      });
    this.setState({ loading: false });
  }

  agregarFacturaCajaChica(id) {
    this.setState({ loading: true });
    axios
      .post(
        "descargaMasiva/datosXml",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.props.history.push('/panel/agregarFacturaCajaChica', { fileName: res.data.uuid, fileData: JSON.stringify(res.data.dataXml) })
        } else {
          message.error('Hubo un error y no se pudo redigir')
        }
      });
    this.setState({ loading: false });
  }

  abrirVistaPrevia(id) {
    this.setState({
      modalVistaPrevia: true,
      modalVistaPreviaId: id,
    });
  }

  cerrarVistaPrevia() {
    this.setState({
      modalVistaPrevia: false,
      modalVistaPreviaId: null,
    });
  }

  descargarPdf(id) {
    this.setState({ loading: true });
    axios
      .post(
        "descargaMasiva/datosXml",
        { xmlId: id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          if (res.data.tipoDeComprobante === 'I' || res.data.tipoDeComprobante === 'E') {
            pdfMake.createPdf(facturaXmlPdf(res.data)).download(res.data.uuid);
          } else if (res.data.tipoDeComprobante === 'P') {
            pdfMake.createPdf(complementoXmlPdf(res.data)).download(res.data.uuid);
          }
        }
      });
    this.setState({ loading: false });
  }

  abrirRelacionarPago(id) {
    this.setState({
      modalRelacionarPago: true,
      modalRelacionarPagoId: id,
    });
  }

  cerrarRelacionarPago() {
    this.setState({
      modalRelacionarPago: false,
      modalRelacionarPagoId: null,
    });
    this.consultarArchivosXml(this.state.valor)
  }

  abrirModalOrdenDeCompra(id) {
    this.setState({
      modalOrdenDeCompra: true,
      modalOrdenDeCompraId: id,
    });
  }

  cerrarModalOrdenDeCompra() {
    this.setState({
      modalOrdenDeCompra: false,
      modalOrdenDeCompraId: null,
    });
    this.consultarArchivosXml(this.state.valor)
  }

  render() {
    let columns = []
    columns =
      this.state.emitido === 2
        ? [
          {
            title: "Receptor",
            dataIndex: "receptorNombre",
            key: "receptorNombre",
            sorter: (a, b) => {
              if (a.receptorNombre < b.receptorNombre) {
                return -1;
              }
              if (a.receptorNombre > b.receptorNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "receptorNombre",
          },
          {
            title: "Tipo",
            dataIndex: "tipoDeComprobante",
            key: "tipoDeComprobante",
            render: (value) => {
              if (value === "I") {
                return "I - Ingreso";
              } else if (value === "P") {
                return "P - Pago";
              } else if (value === "E") {
                return "E - Egreso";
              } else if (value === "N") {
                return "N - Nomina";
              } else if (value === "T") {
                return "T - Traslado";
              }
            },
          },
          {
            title: "Tipo Pago",
            dataIndex: "formaDePago",
            key: "formaDePago",
            sorter: (a, b) => {
              if (a.formaDePago.c_FormaPago < b.formaDePago.c_FormaPago) {
                return -1;
              }
              if (a.formaDePago.c_FormaPago > b.formaDePago.c_FormaPago) {
                return 1;
              }
              return 0;
            },
            render: (value) => value.c_FormaPago + ' - ' + value.descripcion
          },
          {
            title: "Dia",
            dataIndex: "fecha",
            key: "fecha",
            sorter: (a, b) => {
              if (a.fecha < b.fecha) {
                return -1;
              }
              if (a.fecha > b.fecha) {
                return 1;
              }
              return 0;
            },
            render: (value) => {
              const fecha = moment(value).format("L");
              const arr = fecha.split(" ");

              //loop through each element of the array and capitalize the first letter.

              for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
              }

              //Join all the elements of the array back into a string
              //using a blankspace as a separator
              const str2 = arr.join(" ");
              return str2;
            },
            buscar: "fecha",
          },
          {
            title: "Subtotal",
            dataIndex: "subtotal",
            key: "subtotal",
            render: (value) => dineroDisabledMask(value),
            align: "right",
          },
          {
            title: "Total",
            dataIndex: "total",
            key: "total",
            render: (value) => dineroDisabledMask(value),
            align: "right",
          },
          {
            title: "Accion",
            dataIndex: "accion",
            key: "accion",
            render: (value) => {
              return (
                <Space>
                  {value.estado === 0 &&
                    <Tag color="red" >Cancelada</Tag>
                  }
                </Space>
              );
            },
          },
        ]
        : [
          {
            title: "Emisor",
            dataIndex: "emisorNombre",
            key: "emisorNombre",
            sorter: (a, b) => {
              if (a.emisorNombre < b.emisorNombre) {
                return -1;
              }
              if (a.emisorNombre > b.emisorNombre) {
                return 1;
              }
              return 0;
            },
            buscar: "emisorNombre",
            width: 200,
          },
          {
            title: "Tipo",
            dataIndex: "tipoDeComprobante",
            key: "tipoDeComprobante",
            render: (value) => {
              if (value === "I") {
                return "I - Ingreso";
              } else if (value === "P") {
                return "P - Pago";
              } else if (value === "E") {
                return "E - Egreso";
              } else if (value === "N") {
                return "N - Nomina";
              } else if (value === "T") {
                return "T - Traslado";
              }
            },
            width: "12%",
          },
          {
            title: "Tipo Pago",
            dataIndex: "formaDePago",
            key: "formaDePago",
            sorter: (a, b) => {
              if (a.formaDePago.c_FormaPago < b.formaDePago.c_FormaPago) {
                return -1;
              }
              if (a.formaDePago.c_FormaPago > b.formaDePago.c_FormaPago) {
                return 1;
              }
              return 0;
            },
            render: (value) => value.c_FormaPago + ' - ' + value.descripcion
          },
          {
            title: "Dia",
            dataIndex: "fecha",
            key: "fecha",
            sorter: (a, b) => {
              if (a.fecha < b.fecha) {
                return -1;
              }
              if (a.fecha > b.fecha) {
                return 1;
              }
              return 0;
            },
            render: (value) => {
              const fecha = moment(value).format("L");
              const arr = fecha.split(" ");

              //loop through each element of the array and capitalize the first letter.

              for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
              }

              //Join all the elements of the array back into a string
              //using a blankspace as a separator
              const str2 = arr.join(" ");
              return str2;
            },
            buscar: "fecha",
            width: "12%",
          },
          {
            title: "Subtotal",
            dataIndex: "subtotal",
            key: "subtotal",
            render: (value) => dineroDisabledMask(value),
            align: "right",
            width: "12%",
          },
          {
            title: "Total",
            dataIndex: "total",
            key: "total",
            render: (value) => dineroDisabledMask(value),
            align: "right",
            width: "12%",
          },
          {
            title: "Accion",
            dataIndex: "accion",
            key: "accion",
            render: (value, record, index) => {
              if (value.contabilizada === 1 && value.tipoDeComprobante === 'I') {
                return (
                  <Space>
                    <Tooltip title="Agregar factura sin orden de compra con este xml">
                      <ButtonItem onClick={() => this.agregarFacturaSinOrdenDeCompra(value._id)} size='small'>Factura S/O</ButtonItem>
                    </Tooltip>
                    <Tooltip title="Agregar factura de caja chica con este xml">
                      <ButtonItem onClick={() => this.agregarFacturaCajaChica(value._id)} blanco size='small'>Factura Caja Chica</ButtonItem>
                    </Tooltip>
                    <Tooltip title="Agregar factura a orden de compra con este xml">
                      <ButtonItem onClick={() => this.abrirModalOrdenDeCompra(value._id)} size='small'>Orden de Compra</ButtonItem>
                    </Tooltip>
                    <Tooltip title="Vista Previa">
                      <EyeOutlined
                        size="small"
                        style={{ color: '#00b5e2' }}
                        key={index.toString()}
                        onClick={() => this.abrirVistaPrevia(value._id)}
                      />
                    </Tooltip>
                    <Tooltip title="Descargar Pdf">
                      <FilePdfOutlined style={{ color: 'red' }} key={index.toString()} onClick={() => this.descargarPdf(value._id)} />
                    </Tooltip>
                    <Tooltip title="Descargar Xml">
                      <FileExcelOutlined key={index.toString()} onClick={() => this.descargarXml(value._id)} />
                    </Tooltip>
                  </Space>
                );
              } else if (value.contabilizada === 1 && value.tipoDeComprobante === 'P'){
                return (
                  <Space>
                    <Tooltip title="Relacionar a un pago existente">
                      <ButtonItem onClick={() => this.abrirRelacionarPago(value._id)} blanco size='small'>Relacionar Pago</ButtonItem>
                    </Tooltip>
                    <Tooltip title="Vista Previa">
                      <EyeOutlined
                        size="small"
                        style={{ color: '#00b5e2' }}
                        key={index.toString()}
                        onClick={() => this.abrirVistaPrevia(value._id)}
                      />
                    </Tooltip>
                    <Tooltip title="Descargar Pdf">
                      <FilePdfOutlined style={{ color: 'red' }} key={index.toString()} onClick={() => this.descargarPdf(value._id)} />
                    </Tooltip>
                    <Tooltip title="Descargar Xml">
                      <FileExcelOutlined key={index.toString()} onClick={() => this.descargarXml(value._id)} />
                    </Tooltip>
                  </Space>
                );
              } else if (value.contabilizada === 2) {
                return (
                  <Space>
                    <Tag color="green">Ya Contabilizada</Tag>
                    <Tooltip title="Vista Previa">
                      <EyeOutlined
                        size="small"
                        style={{ color: '#00b5e2' }}
                        key={index.toString()}
                        onClick={() => this.abrirVistaPrevia(value._id)}
                      />
                    </Tooltip>
                    <Tooltip title="Descargar Pdf">
                      <FilePdfOutlined style={{ color: 'red' }} key={index.toString()} onClick={() => this.descargarPdf(value._id)} />
                    </Tooltip>
                    <Tooltip title="Descargar Xml">
                      <FileExcelOutlined key={index.toString()} onClick={() => this.descargarXml(value._id)} />
                    </Tooltip>
                  </Space>
                )
              } else if(value.tipoDeComprobante === 'P' || value.tipoDeComprobante === 'I' || value.tipoDeComprobante === 'E'){
                return (
                  <Space>
                    <Tag color="red">No Contabilizada</Tag>
                    <Tooltip title="Vista Previa">
                      <EyeOutlined
                        size="small"
                        style={{ color: '#00b5e2' }}
                        key={index.toString()}
                        onClick={() => this.abrirVistaPrevia(value._id)}
                      />
                    </Tooltip>
                    <Tooltip title="Descargar Pdf">
                      <FilePdfOutlined style={{ color: 'red' }} key={index.toString()} onClick={() => this.descargarPdf(value._id)} />
                    </Tooltip>
                    <Tooltip title="Descargar Xml">
                      <FileExcelOutlined key={index.toString()} onClick={() => this.descargarXml(value._id)} />
                    </Tooltip>
                  </Space>
                )
              }else {
                return (
                  <Space>
                    <Tag color="red">No Contabilizada</Tag>
                    <Tooltip title="Descargar Xml">
                      <FileExcelOutlined key={index.toString()} onClick={() => this.descargarXml(value._id)} />
                    </Tooltip>
                  </Space>
                )
              }
            },
          },
        ];
    return (
      <>
        <PageHeader
          title={(
            <span>
              Archivos Xml
              &nbsp;
              <CloudServerOutlined />
            </span>
          )}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            window.innerWidth > 1000 &&
            <SegmentedItem
              options={[{label: 'No Contabilizado', value: 1}, {label: 'Contabilizado', value: 2}, {label: 'Todas', value: 3}]}
              onChange={(value) => {
                this.consultarArchivosXml(value)
                this.setState({valor: value})
              }}
              value={this.state.valor}
            />
          }
        />
        <TableItem locale={{
          emptyText: (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={(<>
            <p>Todavía no se obtiene ningun xml. Recuerde que la descarga se actualiza cada dia por la mañana.</p>
          </>)} />)
        }} size='small' loading={this.state.loading} dataSource={this.state.todasArchivosXml} columns={columns} />
        <VistaPrevia
          visible={this.state.modalVistaPrevia}
          id={this.state.modalVistaPreviaId}
          cerrarVistaPrevia={this.cerrarVistaPrevia.bind(this)}
        />
        <RelacionarPago
          visible={this.state.modalRelacionarPago}
          id={this.state.modalRelacionarPagoId}
          cerrarRelacionarPago={this.cerrarRelacionarPago.bind(this)}
        />
        <AgregarAOrdenDeCompra
          visible={this.state.modalOrdenDeCompra}
          id={this.state.modalOrdenDeCompraId}
          history={this.props.history}
          cerrarModalOrdenDeCompra={this.cerrarModalOrdenDeCompra.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ArchivosXml);
