import React, { Component } from "react";
import {
  Card,
  Layout,
  Spin,
  Input,
  Button,
  Row,
  Menu,
  notification,
  message,
  Col,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import logoGrande from "../../assets/Biwo logo grande.png";
import bienvenidaLogin from "../../assets/Bienvenida.png";
import logoHorizontal from "../../assets/Biwo_Horizontal.png";
import { NavLink } from "react-router-dom";
// import Text from "antd/lib/typography/Text";
import { login, hasSigned } from "../../store/actions/user";
import { connect } from "react-redux";
import { Form, FormItem, ButtonItem, ErrorItem } from "../../Components/Items";

const { Header, Footer, Content } = Layout;

class LogIn extends Component {
  state = {
    login: false,
  };

  componentDidMount() {
    //Viejo sitema de autenticacion
    // if (this.props.location?.state?.redirect) {
    //   notification.error({
    //     message: "Adventencia",
    //     description: "No se puede acceder a la pagina sin iniciar sesión",
    //   });
    // }
  }

  onFinish(values) {
    this.setState({ loading: true });
    this.props
      .login(values)
      .then((data) => {
        this.setState({ loading: false });
        if (data?.success === 1) {
          message.info("Bienvenido " + data.nombre);
          this.props.history.push("/panelPrincipal");
        } else {
          message.warning(data.err);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        notification.error({
          message: "Error inesperado",
          description: err.toString(),
        });
      });
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  render() {
    const center = {
      style: {
        margin: "auto",
        display: "block",
        alignSelf: "center",
      },
    };
    const current = this.props.history.location.pathname;
    return (
      <Layout>
        <Header id="header-fixed">
          <Menu
            mode="horizontal"
            selectedKeys={current}
            style={{ height: "100%" }}
            id="menu-centered"
          >
            <Menu.Item key={"/login"} id='menuitem-izquierda'>
              <NavLink to={"/login"}>
                <img src={logoHorizontal} alt='Logo' id="img-logo" />
              </NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ marginTop: 64 }}>
          <Spin size="large" spinning={this.state.login}>
            <Row style={{padding: '1rem'}}>
              <Col xs={0} sm={0} lg={12} xl={15}>
                <img src={bienvenidaLogin} id="img-center" alt="Biwo" style={{width: '100%', height: '100%'}} />
              </Col>
              <Col xs={24} sm={24} lg={12} xl={9} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Card id="login-card" bodyStyle={{ paddingTop: 0 }}>
                  <img src={logoGrande} id="img-center" alt="Biwo" />
                  <Form
                    defaultBehavior
                    name="login"
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed}
                  >
                    <FormItem name="usuario" pattern required max={100} min={5} >
                      <Input
                        placeholder="Correo o usuario"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        maxLength={100}
                      />
                    </FormItem>
                    <FormItem name="password" pattern required max={50} min={5}>
                      <Input.Password
                        placeholder="Contraseña"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        maxLength={50}
                      />
                    </FormItem>
                    <FormItem>
                      <ButtonItem htmlType="submit" type="primary" {...center}>
                        Entrar
                      </ButtonItem>
                    </FormItem>
                    <FormItem>
                      <Button type="link" {...center}>
                        <NavLink to="/recuperarContrasena">
                          Olvidaste tu contraseña
                        </NavLink>
                      </Button>
                    </FormItem>
                    {/*
                      <FormItem>
                        <Row
                          type="flex"
                          justify="center"
                          align="middle"
                          style={{ width: "100%" }}
                        >
                          <Text>
                            ¿No tienes cuenta?
                            <Button type="link">
                              <NavLink to="/registro">Registrate</NavLink>
                            </Button>
                          </Text>
                        </Row>
                      </FormItem>
                    
                    */}
                  </Form>
                </Card>
              </Col>
            </Row>
          </Spin>
        </Content>
        <Footer id="content-background" style={{ textAlign: "center" }}>
          Biwo ©2020
        </Footer>
      </Layout>
    );
  }
}

export default connect(null, { login, hasSigned })(LogIn);
