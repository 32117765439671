import React, { useState } from 'react';

const IconFriends = ({ size = 16, stroke = 2, color = "currentColor", hoverColor = "#1890ff", marginRight = 8 }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={isHovered ? hoverColor : color}
            strokeWidth={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
                transition: "stroke 0.5s ease",
                marginRight: `${marginRight}px`, // Espacio a la derecha
                marginBottom: "-3px" // Ajuste de alineación vertical
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <path d="M7 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
            <path d="M17 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
        </svg>
    );
};

export default IconFriends;
