import { Col, message, Row, Space, Form as FormInitial } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
  ButtonItem,
  Form,
  FormItem,
  InputItem,
  ErrorItem,
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import cobroPdf from "../../../../Pdf/cobroPdf";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class MandarCorreoPago extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pdf: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "pagos/datosPago",
        { pagoId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data?.correosEnvio?.length > 0) {
          this.formRef.current.setFieldsValue({ correosEnvio: res.data.correosEnvio })
        }
        const pdf = pdfMake.createPdf(cobroPdf(res.data));
        this.setState(
          {
            loading: false,
            pdf: pdf,
          },
          () => {
            pdf.open({}, window.frames["complementoPdf"]);
          }
        );
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    if (values?.correosEnvio?.length > 0) {
      this.setState({ loading: true });
      axios
        .post(
          "pagos/enviarPago",
          { ...values, pagoId: this.props.id, empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Pago enviado");
            this.formRef.current.resetFields();
            this.cerrarMandarCorreoPago();
          } else if (res.data.success === 1) {
            message.error("No se pudo enviar el pago a ese correo");
          } else {
            message.error("Sucedio un error y no se pudo enviar");
          }
        });
    } else {
      message.info('Proporcione al menos un correo de envio')
    }
  }
  descargarComplemento() {
    axios
      .post(
        "pagos/datosPago",
        { pagoId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {

        const dataSat = res.data.respuestaComplemento.dataSat
        const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'F') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Nombre + ' )'

        const pdf = cobroPdf(res.data)

        pdfMake.createPdf(pdf).download(nombreArchivo + ".pdf")
      });
  }
  cerrarMandarCorreoPago() {
    this.props.cerrarMandarCorreoPago();
    this.formRef.current.resetFields()
  }
  render() {
    return (
      <ModalItem
        title="Vista previa"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarMandarCorreoPago.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="mandarCorreoComplemento"
          layout
          width={700}
          onCancel={this.cerrarMandarCorreoPago.bind(this)}
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormInitial.List
            name="correosEnvio"
            initialValue={[{ correo: '' }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }, arrayKey) => (
                  <Row>
                    <Col span={22}>
                      <FormItem
                        name={[name, "correo"]}
                        fieldKey={[arrayKey, "correo"]}
                        label='Correo'
                        required
                        margin
                        email
                        pattern
                      >
                        <InputItem style={{ width: '100%' }} placeholder="Correo" />
                      </FormItem>
                    </Col>
                    <Col span={2}>
                      <div style={{ textAlign: 'center' }}>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
                {fields.length < 5 ?
                  <div style={{ textAlign: 'center', marginTop: '5px' }}>
                    <ButtonItem
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Agregar
                    </ButtonItem>
                  </div>
                  : null}
              </>
            )}
          </FormInitial.List>
          <div style={{ display: "flex", justifyContent: "right", marginTop: 20, marginBottom: 10 }}>
            <Space>
              <ButtonItem
                type="primary"
                blanco={true}
                onClick={this.descargarComplemento.bind(this)}
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Descargar
              </ButtonItem>
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Mandar Correo
              </ButtonItem>
              <ButtonItem
                type="primary"
                danger
                onClick={this.cerrarMandarCorreoPago.bind(this)}
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Cancelar
              </ButtonItem>
            </Space>
          </div>
        </Form>
        <iframe
          title={"complemento"}
          style={{ width: "100%", height: "100vh" }}
          id="complementoPdf"
          name="complementoPdf"
        />
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(MandarCorreoPago);
