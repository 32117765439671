import { message, Space, notification, Select, Popconfirm } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
  ButtonItem,
  Form,
  FormItem,
  InputItem,
  SelectItem,
  ErrorItem,
} from "../../../../Components/Items";

const { Option } = Select;

class CancelarPago extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    complemento: false,
    ventas: 0,
  };
  onFinishFailed(e) {
    ErrorItem(e);
  }
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "pagos/consultarPago",
        { pagoId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.satatus === 404) return;
        let ventas = 0;
        if (res.data.ventaId) {
          ventas = 1;
        } else {
          ventas = res.data.ventasId.length;
        }
        this.setState({ ventas, complemento: res.data.complemento });
      });
    this.setState({ loading: false });
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "pagos/cancelarPago",
        { ...values, pagoId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Pago cancelado");
          this.formRef.current.resetFields();
          this.cerrarCancelarPago(true);
        } else if (res.data.success === 1) {
          notification.error({
            message: "Error",
            description: res.data.message,
            duration: null,
          });
        } else {
          message.error("Sucedio un eror y no se pudo cancelar");
        }
        this.setState({ loading: false });
      });
  }
  cerrarCancelarPago(abrirPdf) {
    this.props.cerrarCancelarPago(abrirPdf);
  }
  render() {
    return (
      <>
      <ModalItem
        title="Cancelar Pago"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarCancelarPago.bind(this)}
        paddingTop
        zIndex={2}
      >
        <Form
          ref={this.formRef}
          name="cancelarPago"
          layout
          width={700}
          onCancel={this.cerrarCancelarPago.bind(this)}
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem
            label="Motivo"
            name="motivo"
            required
            pattern
            margin
            max={200}
          >
            <SelectItem placeholder="Motivo">
              <Option value="01">
                01 - Comprobante emitido con errores con relación.
              </Option>
              <Option value="02">
                02 - Comprobante emitido con errores sin relación.
              </Option>
              <Option value="03">03 - No se llevó a cabo la operación.</Option>
              <Option value="04">
                04 - Operación nominativa relacionada en una factura global.
              </Option>
            </SelectItem>
          </FormItem>
          <FormItem
            label="Folio Sustitucion"
            name="folioSustitucion"
            pattern
            margin
            max={200}
          >
            <InputItem placeholder="Folio de Sustitucion" />
          </FormItem>
          <p style={{textAlign: 'center', marginBottom: 10, paddingBottom: 10, marginTop: 10}}>
            {this.state.ventas === 1
              ? "Se cancelara el pago a 1 venta"
              : "Precaucion se cancelara el pago a " +
                this.state.ventas +
                " ventas"}
          </p>
          <p style={{textAlign: 'center', marginBottom: 0, paddingBottom: 0}}>
            {this.state.complemento
              ? "Se cancelara un complemento de pago."
              : ""}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            <Space>
              <Popconfirm
                title={
                  (this.state.ventas === 1
                    ? "Se cancelara el pago a 1 venta"
                    : "Precaucion se cancelara el pago a " +
                      this.state.ventas +
                      " ventas") +
                  (this.state.complemento
                    ? " y se cancelara un complemento de pago."
                    : "")
                }
                onConfirm={() => this.formRef.current.formRef.current.submit()}
                okText="Ok"
                cancelText="Cancelar"
              >
                <ButtonItem
                  type="primary"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Cancelar Pago
                </ButtonItem>
              </Popconfirm>
              <ButtonItem
                type="primary"
                danger
                onClick={this.cerrarCancelarPago.bind(this)}
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Regresar
              </ButtonItem>
            </Space>
          </div>
        </Form>
      </ModalItem>
     </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CancelarPago);
