import React from "react";
import { Segmented } from "antd5";

const SegmentedItem = ({ options, onChange, style, className, defaultValue, value }) => {
  return (
    <Segmented
      options={options}
      onChange={onChange}
      style={{
        ...style,
        backgroundColor: "#7cc9dd", 
        borderColor: "#1890ff",     
        color: "#000000",           
      }}
      className={className}
      defaultValue={defaultValue}
      value={value}
    />
  );
};

export default SegmentedItem;
