import { message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
} from "../../../../Components/Items";

class EditarDoctor extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
        this.consultarTodo();
    }
  }

  onFinishFailed(e) {
    ErrorItem(e);
  };

  consultarTodo() {
    this.setState({ loading: true });
    axios
      .post(
        "doctores/datosDoctor",
        { empresaId: this.props.empresaId, doctorId: this.props.doctorId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
            this.formRef.current.setFieldsValue(res.data.doctor)
        } else {
            message.error("No se encontro informacion");
        }
      });
  }

  onFinish(values) {
    if (values?.nombre) {
      this.setState({ loading: true });
      axios
        .post(
          "doctores/editarDoctor",
          {
            ...values,
            empresaId: this.props.empresaId,
            doctorId: this.props.doctorId 
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Doctor agregado exitosamente");
            this.cerrarEditarDoctor();
          } else if (res.data.success === 1) {
            message.error("No se pudo agregar el doctor");
          } else {
            message.error("Sucedió un error y no se pudo agregar el doctor");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          message.error("Error al comunicarse con el servidor");
        });
    } else {
      message.info("Proporcione al menos el nombre del doctor");
    }
  }
  

  cerrarEditarDoctor = () => {
    this.props.cerrarEditarDoctor();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Editar Doctor"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarEditarDoctor.bind(this)}
      >
        <Form
          ref={this.formRef}
          name="modalEditarDoctor"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
          
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem
            name="codigo"
            label="Número de Doctor"
            required
            margin
          >
            <InputItem disabled placeholder="Ingresa el número de doctor" />
          </FormItem>

          <FormItem
            name="nombre"
            label="Nombre del Doctor"
            required
            margin
          >
            <InputItem placeholder="Ingresa el nombre del doctor" />
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(EditarDoctor);
