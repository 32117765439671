import { PageHeader, Space, Popconfirm, message } from "antd";
import React, { Component } from "react";
import axios from 'axios';
import { TableItem, ButtonItem } from "../../../Components/Items";
import { connect } from "react-redux";
import { StopOutlined, EditOutlined, CheckCircleOutlined } from '@ant-design/icons';

class Prestaciones extends Component {
  state = {
    todasPrestaciones: [],
    loading: false
  }

  componentDidMount() {
    this.consultarPrestaciones();
  }

  consultarPrestaciones() {
    this.setState({loading: true});
    axios
      .post(
        "tiposPrestaciones/selectTodosTiposPrestaciones",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
          this.setState({loading: false});
          if (res.data.status === 404) {
              message.error("No se encontraron prestaciones.");
          } else {
            this.setState({
              todasPrestaciones: res.data.tiposPrestaciones.map(prestacion => {
                return {
                  ...prestacion,
                  accion: {
                    _id: prestacion._id,
                    estado: prestacion.estado
                  }
                }
              })
            });
          }
      });
  }

  agregarPrestacion() {
    this.props.history.push('/panel/agregarTipoPrestaciones');
  }

  editarPrestacion(id) {
    this.props.history.push('/panel/editarTipoPrestaciones', {id});
  }

  deshabilitarPrestacion(value) {
    axios
      .post(
        "tiposPrestaciones/deshabilitarTipoPrestacion",
        { tipoPrestacionId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Prestación deshabilitada");
        } else {
          message.error("Error al deshabilitar la prestación");
        }
        this.consultarPrestaciones();
      });
  }

  habilitarPrestacion(value) {
    axios
      .post(
        "tiposPrestaciones/habilitarTipoPrestacion",
        { tipoPrestacionId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Prestación habilitada");
        } else {
          message.error("Error al habilitar la prestación");
        }
        this.consultarPrestaciones();
      });
  }

  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      },
      {
        title: "Fecha de entrada en vigor",
        dataIndex: "fechaInicio",
        key: "fechaInicio",
        render: (text) => new Date(text).toLocaleDateString(),
        sorter: (a, b) => new Date(a.fechaInicio) - new Date(b.fechaInicio),
      },
      {
        title: "Acción",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.editarPrestacion(value._id)}
              style={{ color: "blue" }}
            />
            {value.estado === 1 ? (
              <Popconfirm
                title="¿Seguro que quieres deshabilitar esta prestación?"
                onConfirm={() => this.deshabilitarPrestacion(value._id)}
                okText="Sí"
                cancelText="No"
              >
                <CheckCircleOutlined style={{ color: "green" }} />
              </Popconfirm>
            ) : (
              <Popconfirm
                title="¿Seguro que quieres habilitar esta prestación?"
                onConfirm={() => this.habilitarPrestacion(value._id)}
                okText="Sí"
                cancelText="No"
              >
                <StopOutlined style={{ color: "red" }} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ];

    return (
      <>
        <PageHeader
          title="Prestaciones"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarPrestacion"
              onClick={this.agregarPrestacion.bind(this)}
            >
              Agregar Prestación
            </ButtonItem>
          }
        />
        <TableItem loading={this.state.loading} size="small" dataSource={this.state.todasPrestaciones} columns={columns} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Prestaciones);
