import { Collapse, Row, Col, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  dineroDisabledMask,
  ModalItem,
  ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";
import CancelarNotaDeCreditoVenta from "../CancelarNotaDeCreditoVenta/CancelarNotaDeCreditoVenta";

const { Panel } = Collapse;

class NotasDeCreditoVenta extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    notasHtml: <p>No se encontro ninguna nota de credito</p>,
    modalCancelarNotaDeCreditoVenta: false,
    modalCancelarNotaDeCreditoVentaId: null
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }

  verNotaDeCreditoVenta(id){
    this.props.history.push("/panel/verNotaDeCreditoVenta", {id})
  }

  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "notasDeCreditoVenta/notasVenta",
        { ventaId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) return;
        const html = (
          <Collapse style={{ marginBottom: 10 }}>
            {res.data.map((valueNota) => {
              return (
                <Panel
                  extra={
                    valueNota.estado !== 0 ? (
                      valueNota.moneda.abreviacion+' - '+dineroDisabledMask(valueNota.totalPagar)
                    ) : (
                      <Tag color="red">
                        Cancelada: {valueNota.moneda.abreviacion+' - '+dineroDisabledMask(valueNota.totalPagar)}
                      </Tag>
                    )
                  }
                  header={moment(valueNota.fechaNota).locale("es").format("ll")}
                  key={valueNota._id}
                >
                  <Row>
                    <Col span={12}>
                      <p>
                        Fecha:{" "}
                        {moment(valueNota.fechaNota).locale("es").format("ll")}
                      </p>
                      <p>
                        UUID:{" "}
                        {valueNota.dataFactura ? valueNota.dataFactura.uuid : "No tiene"}
                      </p>
                        <ButtonItem type="primary" onClick={() => this.verNotaDeCreditoVenta(valueNota._id)}>
                          Ver nota
                        </ButtonItem>
                    </Col>
                    <Col span={12}>
                      <p>Total: {valueNota.moneda.abreviacion+' - '+dineroDisabledMask(valueNota.totalPagar)}</p>
                      <p>Codigo: {valueNota.factura ? (valueNota.facturaSerie + ' - ' + valueNota.facturaNumero) : (valueNota.remisionSerie + ' - ' + valueNota.remisionNumero)}</p>
                      {valueNota.estado !== 0 && (
                        <ButtonItem type="primary" disabled danger onClick={() => {
                          this.setState({modalCancelarNotaDeCreditoVenta: true, modalCancelarNotaDeCreditoVentaId: valueNota._id})
                        }}>
                          Cancelar Nota
                        </ButtonItem>
                      )}
                    </Col>
                  </Row>
                </Panel>
              );
            })}
          </Collapse>
        );
        this.setState({ notasHtml: html });
      });
    this.setState({ loading: false });
  }
  cerrarNotasDeCreditoVenta() {
    this.props.cerrarNotasDeCreditoVenta();
  }
  cerrarCancelarNotaDeCreditoVenta() {
    this.setState({
      modalCancelarNotaDeCreditoVenta: false,
      modalCancelarNotaDeCreditoVentaId: null,
    });
    this.consultarTodo()
  }
  render() {
    return (
      <>
      <ModalItem
        title="Notas de Credito Venta"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarNotasDeCreditoVenta.bind(this)}
        paddingTop
      >
        {this.state.notasHtml}
      </ModalItem>
      <CancelarNotaDeCreditoVenta
          visible={this.state.modalCancelarNotaDeCreditoVenta}
          id={this.state.modalCancelarNotaDeCreditoVentaId}
          cerrarCancelarNotaDeCreditoVenta={this.cerrarCancelarNotaDeCreditoVenta.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(NotasDeCreditoVenta);
