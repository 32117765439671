import { PageHeader, message, Space, Popconfirm } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import { TableItem, ButtonItem, SegmentedItem} from '../../../Components/Items'
import { connect } from "react-redux";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";


class Impuestos extends Component {
  state = {
    todasImpuesto: [],
    loading: false,
    valor: 1
  }
  componentDidMount() {
    this.consultarImpuestos(this.state.valor);
  }

  consultarImpuestos(valor) {
    this.setState({loading: true})
    axios
    .post(
      "impuestos/selectTodosImpuestos",
      { empresaId: this.props.empresaId, valor: valor, },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
        this.setState({ todasImpuesto: [] });
      } else {
        this.setState({
          todasImpuesto: res.data.map(value => {
            return {...value, accion: { _id: value._id, estado: value.estado }}
          }),
        });
      }
    });
  }
  agregarImpuesto(){
    this.props.history.push('/panel/agregarImpuesto')
  }
  editarImpuesto(id){
    this.props.history.push('/panel/editarImpuesto', {id})
  }
  deshabilitarImpuesto(value) {
    axios
      .post(
        "impuestos/deshabilitarImpuesto",
        { impuestoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Impuesto deshabilitado");
        } else {
          message.error("Sucedio un error y no se pudo deshabilitar");
        }
        this.consultarImpuestos(this.state.valor);
      });
  }
  habilitarImpuesto(value) {
    axios
      .post(
        "impuestos/habilitarImpuesto",
        { impuestoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Impuesto habilitado");
        } else {
          message.error("Sucedio un error y no se pudo habilitar");
        }
        this.consultarImpuestos(this.state.valor);
      });
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarImpuesto &&
              <EditOutlined
                onClick={() => this.editarImpuesto(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarImpuesto &&
            <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este impuesto"
                  onConfirm={() => this.deshabilitarImpuesto(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este impuesto"
                  onConfirm={() => this.habilitarImpuesto(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
            }
          </Space>
        ),
      },
    ]
    return (
      <>
        <PageHeader
          title="Impuestos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
            <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarImpuestos(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
            {this.props.permisoAgregarImpuesto &&
            <ButtonItem
              type="primary"
              key="agregarImpuesto"
              onClick={this.agregarImpuesto.bind(this)}
            >
              Agregar Impuesto
            </ButtonItem>}
          </>
        }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasImpuesto} columns={columns} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarImpuesto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cde17f30e81b54183cfa6")?.activado,
    permisoEditarImpuesto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cde45f30e81b54183cfa7")?.activado,
    permisoDeshabilitarImpuesto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce810f30e81b54183cfb8")?.activado,
  };
};

export default connect(mapStateToProps)(Impuestos);