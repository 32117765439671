import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Divider,
  Select,
  Input,
  notification,
  Tabs
} from "antd";
import {
  dineroDisabledMask,
  Form,
  FormItem,
  SelectItem,
  NotificacionPolizas,
  DatePickerItem,
  InputItem,
  ButtonItem,
  SwitchItem,
  ErrorItem,
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import ElegirTipoDeCliente from "../../Clientes/ElegirTipoDeCliente/ElegirTipoDeCliente";
import facturaPdf from "../../../../Pdf/facturaPdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MandarCorreo from "../MandarCorreo/MandarCorreo";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

moment.locale("es");

const { Option } = Select;
const { TabPane } = Tabs

class GenerarFactura extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    datosVenta: undefined,
    todasClientes: null,
    objetoClientes: null,
    objetoUsosDeCFDI: null,
    todasUsosDeCFDI: null,
    modalElegirTipoDeCliente: false,
    modalElegirTipoDeClienteId: null,
    modalElegirTipoDeClienteNombre: null,
    modalCorreo: false,
    modalCorreoId: null,
    complementoDetallista: false,
    detallista: null,
    id: this.props.location.state.id,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "usodecfdi/selectUsosDeCFDI",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasUsosDeCFDI: res.data });
          this.setState({
            objetoUsosDeCFDI: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "clientes/selectClientesIguales",
        { empresaId: this.props.empresaId, ventaId: this.state.id },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasClientes: res.data });
          this.setState({
            objetoClientes: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "ventas/datosVentaPRecibirP",
        {
          empresaId: this.props.empresaId,
          ventaId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion de la venta");
        } else {
          this.setState({
            datosVenta: res.data[0],
            complementoDetallista: res.data[0].cliente.complementoDetallista,
            detallista: {
              orderIdentification: res.data[0].cliente.orderIdentification,
              referenceDateOrderIdentification: res.data[0].cliente.referenceDateOrderIdentification,
              additionalInformation: res.data[0].cliente.additionalInformation,
              deliveryNote: res.data[0].cliente.deliveryNote,
              referenceDateDeliveryNote: res.data[0].cliente.referenceDateDeliveryNote,
            }
          });
          this.formRef.current.setFieldsValue({
            usoDeCfdiId: res.data[0].cliente.usoDeCFDIID,
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "ventas/generarFactura",
        {
          ...values,
          empresaId: this.props.empresaId,
          ventaId: this.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha generado una factura de la venta");
          notification.success({
            description: "Haga click para descargar",
            message: "Factura Emitida",
            duration: 0,
            style: { cursor: "pointer" },
            onClick: () => {
              var xmltext = res.data.respuestaFactura.data.cfdi;

              const dataSat = res.data.respuestaFactura.dataSat
              const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'F') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Rfc + ' - ' + dataSat.Receptor.Nombre + ' )'

              var filename = nombreArchivo + ".xml";
              var pom = document.createElement("a");
              var bb = new Blob([xmltext], { type: "text/plain" });

              pom.setAttribute("href", window.URL.createObjectURL(bb));
              pom.setAttribute("download", filename);

              pom.dataset.downloadurl = [
                "text/plain",
                pom.download,
                pom.href,
              ].join(":");
              pom.draggable = true;
              pom.classList.add("dragout");

              pom.click();

              const pdf = facturaPdf(res.data);

              pdfMake.createPdf(pdf).download(nombreArchivo + ".pdf");
            },
          });
          NotificacionPolizas(res.data.polizasId)
          this.setState({ modalCorreo: true, modalCorreoId: res.data.ventaId })
        } else if (res.data.success === 1) {
          message.error("No se puede generar la factura");
          notification.warning({
            description: res.data.description,
            message: res.data.message,
            duration: 0,
          });
        } else {
          message.error("Hubo un error y no se genero la factura");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }
  agregarCliente() {
    this.props.history.push("/panel/agregarCliente");
  }
  onChangeCliente(valueId) {
    const cliente = this.state.todasClientes.find(
      (valueProv) => valueProv._id === valueId
    );
    if (!cliente.regimenFiscalId || !cliente.direcciones[0]?.coloniaId) {
      message.info('No se podra facturar a este cliente. Faltan datos')
    }
    if (!cliente.tipoClienteId) {
      const nombre = cliente.persona
        ? cliente.razonSocial
        : `${cliente.nombre} ${cliente.apellidoPaterno} ${cliente.apellidoMaterno}`;
      this.abrirElegirTipoDeCliente(cliente._id, nombre);
    }
    this.setState({
      complementoDetallista: cliente.complementoDetallista,
      detallista: {
        orderIdentification: cliente.orderIdentification,
        referenceDateOrderIdentification: cliente.referenceDateOrderIdentification,
        additionalInformation: cliente.additionalInformation,
        deliveryNote: cliente.deliveryNote,
        referenceDateDeliveryNote: cliente.referenceDateDeliveryNote,
      }
    })
    this.formRef.current.setFieldsValue({
      usoDeCfdiId: cliente.usoDeCFDIID,
    });
  }
  abrirElegirTipoDeCliente(id, nombre) {
    this.setState({
      modalElegirTipoDeCliente: true,
      modalElegirTipoDeClienteId: id,
      modalElegirTipoDeClienteNombre: nombre,
    });
  }
  cerrarElegirTipoDeCliente(elegido) {
    if (!elegido) {
      this.formRef.current.setFieldsValue({ clienteId: undefined });
    }
    this.consultarTodo();
    this.setState({
      modalElegirTipoDeCliente: false,
      modalElegirTipoDeClienteId: null,
      modalElegirTipoDeClienteNombre: null,
    });
  }
  cerrarMandarCorreo() {
    this.setState({
      modalCorreo: false,
      modalCorreoId: null,
    });
    this.formRef.current.resetFields();
    this.atras();
  }
  onChangeCambiarCliente(value) {
    if (!value) {
      this.setState({
        complementoDetallista: this.state.datosVenta.cliente.complementoDetallista,
        detallista: {
          orderIdentification: this.state.datosVenta.cliente.orderIdentification,
          referenceDateOrderIdentification: this.state.datosVenta.cliente.referenceDateOrderIdentification,
          additionalInformation: this.state.datosVenta.cliente.additionalInformation,
          deliveryNote: this.state.datosVenta.cliente.deliveryNote,
          referenceDateDeliveryNote: this.state.datosVenta.cliente.referenceDateDeliveryNote,
        }
      });
    } else {
      this.setState({
        complementoDetallista: false
      })
    }
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Generar Factura sobre Venta"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <h1>
              Fecha: {moment(this.state.datosVenta?.fechaVenta ? this.state.datosVenta?.fechaVenta : this.state.datosVenta?.createdAt).format("LL")}
            </h1>
          }
        />
        <div style={{ backgroundColor: "white", padding: 20, paddingTop: 0 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="generarFacturaventa"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Generar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Tabs defaultActiveKey={1} size="small">
                <TabPane tab="General" tabKey={1} key={1} forceRender>
                  <Row>
                    <Col span={6}>
                      <h1 style={{ margin: 0 }}>
                        Proyecto: {this.state.datosVenta?.proyecto.nombre}
                      </h1>
                      <p>
                        Descripcion: {this.state.datosVenta?.proyecto.descripcion}
                      </p>
                    </Col>
                    <Col span={6}>
                      <h1 style={{ margin: 0 }}>Dirección de envio</h1>
                      {this.state.datosVenta?.proyecto.calle ? (
                        <>
                          <p style={{ margin: 0 }}>
                            Calle: {this.state.datosVenta?.proyecto.calle}
                          </p>
                          <p style={{ margin: 0 }}>
                            Numero: {this.state.datosVenta?.proyecto.numeroCalle}
                          </p>
                        </>
                      ) : (
                        <>
                          <p style={{ margin: 0 }}>
                            Marca: {this.state.datosVenta?.proyecto.marca}
                          </p>
                          <p style={{ margin: 0 }}>
                            Modelo: {this.state.datosVenta?.proyecto.modelo}
                          </p>
                          <p style={{ margin: 0 }}>
                            Placas: {this.state.datosVenta?.proyecto.placas}
                          </p>
                        </>
                      )}
                    </Col>
                    <Col span={6}>
                      <h1 style={{ margin: 0 }}>
                        Cliente:{" "}
                        {this.state.datosVenta?.cliente.nombre
                          ? this.state.datosVenta?.cliente.nombre +
                          " " +
                          this.state.datosVenta?.cliente.apellidoPaterno +
                          " " +
                          this.state.datosVenta?.cliente.apellidoMaterno
                          : this.state.datosVenta?.cliente.razonSocial}
                      </h1>
                      <p style={{ margin: 0 }}>
                        Rfc: {this.state.datosVenta?.cliente.rfc}
                      </p>
                      <p style={{ margin: 0 }}>
                        Correo: {this.state.datosVenta?.cliente.correo}
                      </p>
                      <p style={{ margin: 0 }}>
                        Telefono: {this.state.datosVenta?.cliente.telefono}
                      </p>
                      <p style={{ margin: 0 }}>
                        Celular: {this.state.datosVenta?.cliente.celular}
                      </p>
                    </Col>
                    <Col span={6}>
                      <p style={{ margin: 0 }}>
                        Contacto: {this.state.datosVenta?.cliente.nombreContacto}
                      </p>
                      <p style={{ margin: 0 }}>
                        Tel. Contacto:{" "}
                        {this.state.datosVenta?.cliente.telefonoContacto}
                      </p>
                      <p style={{ margin: 0 }}>
                        Correo Contacto:{" "}
                        {this.state.datosVenta?.cliente.correoContacto}
                      </p>
                      <p style={{ margin: 0 }}>
                        Dias de crédito:{" "}
                        {this.state.datosVenta?.cliente.diasCredito}
                      </p>
                    </Col>
                  </Row>
                  <Divider style={{ marginBottom: 10, marginTop: 5 }} />
                  <Row>
                    <Col span={12}>
                      <FormItem
                        name="cambiarCliente"
                        label="Cambiar Cliente"
                        margin
                        valuePropName="checked"
                        initialValue={false}
                        specialLayout
                      >
                        <SwitchItem onChange={(value) => this.onChangeCambiarCliente(value)} />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const cambiarCliente = getFieldValue("cambiarCliente");
                          if (cambiarCliente) {
                            return (
                              <FormItem margin label="Cliente">
                                <Input.Group compact>
                                  <FormItem
                                    name="clienteFacturaId"
                                    noStyle
                                    required
                                  >
                                    <SelectItem
                                      onChange={this.onChangeCliente.bind(this)}
                                      placeholder="Cliente"
                                      width="80%"
                                    >
                                      {this.state.objetoClientes}
                                    </SelectItem>
                                  </FormItem>
                                  <ButtonItem
                                    icon={<PlusOutlined />}
                                    style={{ width: "20%" }}
                                    onClick={this.agregarCliente.bind(this)}
                                  />
                                </Input.Group>
                              </FormItem>
                            );
                          }
                        }}
                      </FormItem>
                      <FormItem
                        name="usoDeCfdiId"
                        label="Uso de CFDI"
                        margin
                        required
                      >
                        <SelectItem placeholder="Uso de CFDI">
                          {this.state.objetoUsosDeCFDI}
                        </SelectItem>
                      </FormItem>
                    </Col>
                  </Row>
                  <Divider style={{ marginBottom: 10, marginTop: 5 }} />
                  <Row>
                    <Col span={16}></Col>
                    <Col span={8}>
                      <h1 style={{ textAlign: "center", fontSize: 20 }}>Total Venta</h1>
                      {this.state.datosVenta && (
                        <table className="table">
                          <tbody>
                            <tr>
                              <th className="th-totales" style={{ width: "50%" }}>
                                Subtotal
                              </th>
                              <td
                                className="td-totales-right"
                                style={{ width: "50%" }}
                              >
                                {dineroDisabledMask(this.state.datosVenta.subtotal)}
                              </td>
                            </tr>
                          </tbody>
                          {this.state.datosVenta.arrayImpuestos.map((valueArray) => (
                            <tbody>
                              <tr key={valueArray.descripcionFactura}>
                                <th className="th-totales">
                                  {valueArray.descripcionFactura}
                                </th>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(valueArray.totalImpuestos)}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                          <tbody>
                            <tr>
                              <th className="th-totales">Total</th>
                              <td className="td-totales-right">
                                {dineroDisabledMask(this.state.datosVenta.total)}
                              </td>
                            </tr>
                          </tbody>
                          {this.state.datosVenta.arrayRetenciones.map(
                            (valueArrayRetenciones) => (
                              <tbody>
                                <tr key={valueArrayRetenciones.descripcionFactura}>
                                  <th className="th-totales">
                                    {valueArrayRetenciones.descripcionFactura}
                                  </th>
                                  <td className="td-totales-right">
                                    {dineroDisabledMask(
                                      valueArrayRetenciones.totalRetenciones
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            )
                          )}
                          <tbody>
                            <tr>
                              <th className="th-totales">Total Pagar</th>
                              <td className="td-totales-right">
                                {dineroDisabledMask(this.state.datosVenta.totalPagar)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                {(this.state.complementoDetallista && this.props.complementoDetallista) ?
                  <TabPane tab="Detallista" tabKey={2} key={2} forceRender>
                    {this.state?.detallista?.orderIdentification &&
                      <FormItem
                        name="orderIdentification"
                        label="OrderIdentification"
                        margin
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        required
                      >
                        <InputItem placeholder="OrderIdentification" />
                      </FormItem>
                    }
                    {this.state?.detallista?.referenceDateOrderIdentification &&
                      <FormItem
                        name="referenceDateOrderIdentification"
                        label="ReferenceDateOrderIdentification"
                        margin
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        required
                      >
                        <DatePickerItem placeholder="ReferenceDateOrderIdentification" />
                      </FormItem>
                    }
                    {this.state?.detallista?.additionalInformation &&
                      <FormItem
                        name="typeAdditionalInformation"
                        label="TypeAdditionalInformation"
                        margin
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        required
                      >
                        <SelectItem
                          placeholder="Type - AdditionalInformation"
                        >
                          <Option value="AAE">AAE - Cuenta predial</Option>
                          <Option value="CK">CK - Número de cheque</Option>
                          <Option value="ACE">ACE - Numero de documento(Remisión)</Option>
                          <Option value="ATZ">ATZ - Número de aprobación.</Option>
                          <Option value="AWR">AWR - Numero de documento que  se reemplaza</Option>
                          <Option value="ON">ON - Número de pedido (comprador)</Option>
                          <Option value="DQ">DQ - Folio de recibo de mercancías</Option>
                          <Option value="IV">IV - Número de Factura</Option>
                        </SelectItem>
                      </FormItem>
                    }
                    {this.state?.detallista?.additionalInformation &&
                      <FormItem
                        name="referenceIdentificationAdditionalInformation"
                        label="ReferenceIdentification"
                        margin
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        required
                      >
                        <InputItem placeholder="ReferenceIdentification - AdditionalInformation" />
                      </FormItem>
                    }
                    {this.state?.detallista?.deliveryNote &&
                      <FormItem
                        name="deliveryNote"
                        label="DeliveryNote"
                        margin
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        required
                      >
                        <InputItem placeholder="DeliveryNote" />
                      </FormItem>
                    }
                    {this.state?.detallista?.referenceDateDeliveryNote &&
                      <FormItem
                        name="referenceDateDeliveryNote"
                        label="ReferenceDateDeliveryNote"
                        margin
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        required
                      >
                        <DatePickerItem placeholder="ReferenceDateDeliveryNote" />
                      </FormItem>
                    }
                  </TabPane>
                  : null}
              </Tabs>
            </Form>
          </Spin>
        </div>
        <ElegirTipoDeCliente
          visible={this.state.modalElegirTipoDeCliente}
          id={this.state.modalElegirTipoDeClienteId}
          nombre={this.state.modalElegirTipoDeClienteNombre}
          cerrarElegirTipoDeCliente={this.cerrarElegirTipoDeCliente.bind(this)}
        />
        <MandarCorreo
          visible={this.state.modalCorreo}
          id={this.state.modalCorreoId}
          cerrarMandarCorreo={this.cerrarMandarCorreo.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    complementoDetallista: state.empresa.complementoDetallista,
  };
};

export default connect(mapStateToProps)(GenerarFactura);
