import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { PageHeader, Checkbox, message, Space, Popconfirm } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";

class TiposDeProveedores extends Component {
  state = {
    todasTiposDeProveedores: [],
    loading: false,
    valor: 1,
  }
  componentDidMount() {
    this.consultarTiposDeProveedores(this.state.valor);
  }

  consultarTiposDeProveedores(valor) {
    this.setState({loading: true})
    axios
    .post(
      "tiposdeproveedores/selectTodosTiposDeProveedores",
      { empresaId: this.props.empresaId, valor: valor },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
        this.setState({ todasTiposDeProveedores: [] });
      } else {
        this.setState({
          todasTiposDeProveedores: res.data.map((value) => {
            return {
              ...value,
              accion: { _id: value._id, estado: value.estado },
            };
          }),
        });
      }
    });
  }
  agregarTipoDeProveedor(){
    this.props.history.push('/panel/agregarTipoDeProveedor')
  }
  editarTipoDeProveedor(id){
    this.props.history.push('/panel/editarTipoDeProveedor', {id})
  }
  deshabilitarTipoDeProveedor(value) {
    axios
      .post(
        "tiposdeproveedores/deshabilitarTipoDeProveedor",
        { tipoDeProveedorId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de Proveedor deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarTiposDeProveedores(this.state.valor);
      });
  }
  habilitarTipoDeProveedor(value) {
    axios
      .post(
        "tiposdeproveedores/habilitarTipoDeProveedor",
        { tipoDeProveedorId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de Proveedor habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarTiposDeProveedores(this.state.valor);
      });
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
        ellipsis: true,
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
        ellipsis: true,
      },
      {
        title: "Generar Cuentas en Automatico",
        dataIndex: "generarCuentasAutomatico",
        key: "generarCuentasAutomatico",
        render: (value) => <Checkbox checked={value} />,
        align: "center",
        ellipsis: true,
      },
      {
        title: "Tipo de Poliza Diario",
        dataIndex: "tipoDePolizaDiario",
        key: "tipoDePolizaDiario",
        render: (value) => value.nombre+' - '+value.descripcion,
        ellipsis: true,
      },
      {
        title: "Tipo de Poliza Egreso",
        dataIndex: "tipoDePolizaEgreso",
        key: "tipoDePolizaEgreso",
        render: (value) => value.nombre+' - '+value.descripcion,
        ellipsis: true,
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        ellipsis: true,
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarTipoDeProveedor &&
              <EditOutlined
                onClick={() => this.editarTipoDeProveedor(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarTipoDeProveedor && 
            <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este tipo de proveedor"
                  onConfirm={() => this.deshabilitarTipoDeProveedor(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este tipo de proveedor"
                  onConfirm={() => this.habilitarTipoDeProveedor(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
            }
          </Space>
        ),
      },
    ];
    return (
      <>
        <PageHeader
          title="Tipos De Proveedores"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
             <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarTiposDeProveedores(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
            {this.props.permisoAgregarTipoDeProveedor &&
            <ButtonItem
              type="primary"
              key="agregarTipoDeProveedor"
              onClick={this.agregarTipoDeProveedor.bind(this)}
            >
              Agregar Tipo De Proveedor
            </ButtonItem>}
            </>
          }
        />
                        <TableItem
                        loading={this.state.loading}
          size="small"
          dataSource={this.state.todasTiposDeProveedores}
          columns={columns}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarTipoDeProveedor: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd7b8f30e81b54183cf94")?.activado,
    permisoEditarTipoDeProveedor: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd7e4f30e81b54183cf95")?.activado,
    permisoDeshabilitarTipoDeProveedor: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce4e8f30e81b54183cfb4")?.activado,
  };
};

export default connect(mapStateToProps)(TiposDeProveedores);