import { message, Space, notification, Select, Popconfirm } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
  ButtonItem,
  Form,
  FormItem,
  InputItem,
  SelectItem,
  ErrorItem,
} from "../../../../Components/Items";

const { Option } = Select;

class CancelarDevolucion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    complemento: false,
    pagarVenta: false
  };
  onFinishFailed(e) {
    ErrorItem(e);
  }
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "devoluciones/datosDevolucion",
        { devolucionId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.satatus === 404) return;
        this.setState({ pagarVenta: res.data.pagarVenta });
      });
    this.setState({ loading: false });
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "devoluciones/cancelarDevolucion",
        { ...values, devolucionId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Devlución cancelada");
          this.formRef.current.resetFields();
          this.cerrarCancelarDevolucion();
        } else if (res.data.success === 1) {
          notification.error({
            message: "Error",
            description: res.data.message,
            duration: null,
          });
        } else {
          message.error("Sucedio un error y no se pudo cancelar");
        }
        this.setState({ loading: false });
      });
  }
  cerrarCancelarDevolucion() {
    this.props.cerrarCancelarDevolucion();
  }
  render() {
    return (
      <ModalItem
        title="Cancelar Devolucion"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarCancelarDevolucion.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="cancelarDevolucion"
          layout
          width={700}
          onCancel={this.cerrarCancelarDevolucion.bind(this)}
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem
            label="Motivo"
            name="motivo"
            required
            pattern
            margin
            max={200}
          >
            <SelectItem placeholder="Motivo">
              <Option value="01">
                01 - Comprobante emitido con errores con relación.
              </Option>
              <Option value="02">
                02 - Comprobante emitido con errores sin relación.
              </Option>
              <Option value="03">03 - No se llevó a cabo la operación.</Option>
              <Option value="04">
                04 - Operación nominativa relacionada en una factura global.
              </Option>
            </SelectItem>
          </FormItem>
          <FormItem
            label="Folio Sustitucion"
            name="folioSustitucion"
            pattern
            margin
            max={200}
          >
            <InputItem placeholder="Folio de Sustitucion" />
          </FormItem>
          <p style={{textAlign: 'center', marginBottom: 10, paddingBottom: 10, marginTop: 10}}>
            {this.state.pagarVenta && "Se cancelara el pago a 1 venta"}
          </p>
          <p style={{textAlign: 'center', marginBottom: 0, paddingBottom: 0}}>
            {this.state.complemento
              ? "Se cancelara un complemento de pago."
              : ""}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            <Space>
              <Popconfirm
                title={ "Se cancelara la devolucion"}
                onConfirm={() => this.formRef.current.formRef.current.submit()}
                okText="Ok"
                cancelText="Cancelar"
              >
                <ButtonItem
                  type="primary"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Cancelar Devolución
                </ButtonItem>
              </Popconfirm>
              <ButtonItem
                type="primary"
                danger
                onClick={this.cerrarCancelarDevolucion.bind(this)}
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Regresar
              </ButtonItem>
            </Space>
          </div>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CancelarDevolucion);
