import { PageHeader, Popconfirm, Space, Tag, Tooltip } from "antd";
import axios from "axios";
import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { ButtonItem, SegmentedItem, TableItem, dineroDisabledMask } from "../../../Components/Items";
import { CloseCircleOutlined, SendOutlined } from "@ant-design/icons";
import CancelarVenta from "./CancelarVenta/CancelarVenta";
import EnviosVenta from "./EnviosVenta/EnviosVenta";

moment.locale("es");

class Ventas extends Component {
  state = {
    todasVentas: [],
    loading: false,
    modalCancelacion: false,
    modalCancelacionId: null,
    modalEnvios: false,
    modalEnviosId: null,
    valor: 5,
  };
  componentDidMount() {
    this.consultarVentas(this.state.valor);
  }

  consultarVentas(valor) {
    this.setState({ loading: true });
    axios
      .post(
        "ventas/selectVentas",
        { empresaId: this.props.empresaId , valor: valor},
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          this.setState({ todasVentas: [] });
        } else {
          this.setState({
            todasVentas: res.data.map((value) => {
              let numero = ''
              if (value.remision) {
                numero += (value.remisionSerie ? value.remisionSerie : 'R') + ' ' + value.remisionNumero
              }
              if (value.factura) {
                if (numero !== '') {
                  numero += ' '
                }
                numero += (value.facturaSerie ? value.facturaSerie : 'F') + ' ' + value.facturaNumero
              }
              return {
                ...value,
                fechaVenta: value.fechaFactura
                  ? value.fechaFactura
                  : value.fechaRemision,
                numero: numero,
                cliente: (value.cliente.persona ? value.cliente.razonSocial : (value.cliente.nombre + ' ' + value.cliente.apellidoPaterno + ' ' + value.cliente.apellidoMaterno)) + " - " + value.cliente.rfc,
                doctor: (this.props.equipoMedico && value.doctor) ? (value.doctor.nombre) : '', 
                paciente: (this.props.equipoMedico && value.paciente) ? (value.paciente.nombre) : '', 
                accion: {
                  _id: value._id,
                  dataFactura: !!value.dataFactura,
                  estado: value.estado,
                  estadoCosteo: value.estadoCosteo,
                  servicios: value.servicios,
                  estadoPago: value.estadoPago,
                  facturaGlobal: value.facturaGlobal,
                  factura: value.factura,
                  contado: value.contado,
                  remision: value.remision,
                  cuentasXC: value.cuentasXC,
                  envios: value.envios,
                  flete: value.flete,
                },
              };
            }),
          });
        }
      });
  }

  agregarVenta() {
    this.props.history.push("/panel/agregarVenta");
  }
  puntoDeVenta() {
    this.props.history.push("/panel/puntoDeVenta");
  }
  facturaGlobal() {
    this.props.history.push("/panel/facturaGlobal");
  }
  entregarInsumosVenta(id) {
    this.props.history.push("/panel/entregarInsumosVenta", { id });
  }
  generarEnvioVenta(id) {
    this.props.history.push("/panel/generarEnvioVenta", { id, certificada: this.props.certificada });
  }
  costearVenta(id) {
    this.props.history.push("/panel/costearVenta", { id });
  }
  generarFacturaVenta(id) {
    this.props.history.push("/panel/generarFacturaVenta", { id });
  }
  cancelarVenta(value) {
    this.setState({ modalCancelacion: true, modalCancelacionId: value });
  }
  cerrarCancelarVenta() {
    this.setState({
      modalCancelacion: false,
      modalCancelacionId: null,
    });
    this.consultarVentas(this.state.valor);
  }
  enviosVenta(value) {
    this.setState({ modalEnvios: true, modalEnviosId: value });
  }
  cerrarEnviosVenta() {
    this.setState({
      modalEnvios: false,
      modalEnviosId: null,
    });
    this.consultarVentas(this.state.valor);
  }
  render() {

    const agregarEquipoMedico = this.props.equipoMedico ? [
      {
        title: "Doctor",
        dataIndex: "doctor",
        key: "doctor",
        sorter: (a, b) => {
          if (a.doctor < b.doctor) {
            return -1;
          }
          if (a.doctor > b.doctor) {
            return 1;
          }
          return 0;
        },
        width: "8%",
        buscar: 'doctor',
        ellipsis: true,
      },
      {
        title: "Paciente",
        dataIndex: "paciente",
        key: "paciente",
        sorter: (a, b) => {
          if (a.paciente < b.paciente) {
            return -1;
          }
          if (a.paciente > b.paciente) {
            return 1;
          }
          return 0;
        },
        width: "8%",
        buscar: 'paciente',
        ellipsis: true,
      },
    ] : [
      {
        title: "Proyecto",
        dataIndex: "proyecto",
        key: "proyecto",
        sorter: (a, b) => {
          if (a.proyecto.nombre < b.proyecto.nombre) {
            return -1;
          }
          if (a.proyecto.nombre > b.proyecto.nombre) {
            return 1;
          }
          return 0;
        },
        render: (value) => value.nombre,
        width: "16%",
        ellipsis: true,
      },
    ]

    const columns = [
      {
        title: "F/R",
        dataIndex: "numero",
        key: "numero",
        buscar: 'numero',
        width: "7%",
      },
      ...agregarEquipoMedico,
      {
        title: "Cliente",
        dataIndex: "cliente",
        key: "cliente",
        sorter: (a, b) => {
          if (a.cliente < b.cliente) {
            return -1;
          }
          if (a.cliente > b.cliente) {
            return 1;
          }
          return 0;
        },
        width: "16%",
        buscar: 'cliente',
        ellipsis: true,
      },
      {
        title: "Fecha",
        dataIndex: "fechaVenta",
        key: "fechaVenta",
        sorter: (a, b) => {
          if (moment(a.fechaVenta) < moment(b.fechaVenta)) {
            return -1;
          }
          if (moment(a.fechaVenta) > moment(b.fechaVenta)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale("es").format("ll"),
        wdith: "13%",
        ellipsis: true,
        align: "center",
      },
      {
        title: "Subtotal",
        dataIndex: "subtotal",
        key: "subtotal",
        sorter: (a, b) => {
          if (a.subtotal < b.subtotal) {
            return -1;
          }
          if (a.subtotal > b.subtotal) {
            return 1;
          }
          return 0;
        },
        render: (value) => dineroDisabledMask(value),
        align: "right",
        buscar: "subtotal",
        width: "8%",
        ellipsis: true,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        sorter: (a, b) => {
          if (a.total < b.total) {
            return -1;
          }
          if (a.total > b.total) {
            return 1;
          }
          return 0;
        },
        render: (value) => dineroDisabledMask(value),
        buscar: "total",
        width: "8%",
        ellipsis: true,
        align: "right",
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (value) => {
          if (value === 0) {
            return <Tag color="red">Cancelada</Tag>;
          }
          if (value === 1) {
            return <Tag color="blue">Sin entregar</Tag>;
          }
          if (value === 2) {
            return <Tag color="orange">Entregado parcialmente</Tag>;
          }
          if (value === 3) {
            return <Tag color="green">Entregado</Tag>;
          }
        },
        width: "10%",
        ellipsis: true,
      },
      {
        title: "Acción",
        dataIndex: "accion",
        key: "accion",
        render: (value, index) => {
          if (value.estado === 0) return;

          let buttons = [];
          if (
            (value.estado === 1 || value.estado === 2) &&
            this.props.permisoEntregarInsumosVenta
          ) {
            buttons.push(
              <ButtonItem
                type="default"
                size="small"
                blanco
                onClick={() => this.entregarInsumosVenta(value._id)}
              >
                Entregar Insumos
              </ButtonItem>
            );
          }
          if (
            (value.estado === 1 || value.estado === 2) &&
            this.props.envios && !value.flete
          ) {
            buttons.push(
              <ButtonItem
                type="default"
                size="small"
                onClick={() => this.generarEnvioVenta(value._id)}
                verde
              >
                Generar Envio
              </ButtonItem>
            );
          }
          if (value.servicios && value.estadoCosteo === 0) {
            buttons.push(
              <ButtonItem
                type="default"
                size="small"
                onClick={() => this.costearVenta(value._id)}
                blanco
              >
                Costear Venta
              </ButtonItem>
            );
          }
          if (value.remision && !value.facturaGlobal && !value.factura && this.props.certificada && (value.estadoPago === 0 || value.contado === 1)) {
            buttons.push(
              <ButtonItem
                type="primary"
                size="small"
                onClick={() => this.generarFacturaVenta(value._id)}
              >
                Generar Factura
              </ButtonItem>
            );
          }
          if (value.estado !== 0 && (value.estadoPago === 0 || value.contado === 1) && (!value.cuentasXC || value.estadoPago === 0) && this.props.permisoCancelarVenta && (value.envios?.length <= 0 || !value.envios || value.estado === 1)) {
            buttons.push(
              <Popconfirm
                title={
                  this.props.certificada && value.factura
                    ? "Seguro que quieres cancelar esta venta, tambien se cancelara la factura"
                    : "Seguro que quieres cancelar esta venta"
                }
                onConfirm={() => this.cancelarVenta(value._id)}
                okText="Si"
                cancelText="No"
              >
                <CloseCircleOutlined style={{ color: "red" }} />
              </Popconfirm>
            );
          }
          if (value.envios?.length > 0) {
            buttons.push(
              <Tooltip title="Envios">
                <SendOutlined onClick={() => this.enviosVenta(value._id)} style={{ color: 'blue', cursor: 'pointer' }} />
              </Tooltip>
            )
          }
          return <Space direction="horizontal">{buttons}</Space>;
        },
        width: "22%",
      },
    ];
    return (
      <>
        <PageHeader
          title="Ventas"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
                  <SegmentedItem
                  options={[{label: 'Entregado', value: 1}, {label: 'Entregado Parcialmente', value: 2}, {label: 'Sin Entregar', value: 3},{label: 'Todos', value: 5}, {label: 'Cancelado', value: 4}]}
                  onChange={(value) => {
                    this.consultarVentas(value)
                    this.setState({ valor: value })
                  }}
                  value={this.state.valor}
                />
          }
            {this.props.permisoAgregarVenta && (
              <>
                {this.props.permisoPuntoVenta && 
                <ButtonItem
                  blanco
                  key="puntoDeVenta"
                  onClick={this.puntoDeVenta.bind(this)}
                >
                  Punto de Venta
                </ButtonItem>}
                {this.props.certificada && this.props.permisoFacturaGlobal ? (
                <ButtonItem
                  key="facturaGlobal"
                  naranja
                  onClick={this.facturaGlobal.bind(this)}
                >
                  Factura Global
                </ButtonItem>) : null
                }
                <ButtonItem
                  type="primary"
                  key="agregarVenta"
                  onClick={this.agregarVenta.bind(this)}
                >
                  Agregar Venta
                </ButtonItem>
              </>
            )}
            </>
          }
        />
        <TableItem
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.props.history.push("/panel/verVenta", { id: record._id });
              },
            };
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasVentas}
          columns={columns}
          pagination={false}
        />
        <CancelarVenta
          visible={this.state.modalCancelacion}
          id={this.state.modalCancelacionId}
          cerrarCancelarVenta={this.cerrarCancelarVenta.bind(this)}
        />
        <EnviosVenta
          history={this.props.history}
          visible={this.state.modalEnvios}
          id={this.state.modalEnviosId}
          cerrarEnviosVenta={this.cerrarEnviosVenta.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    certificada: state.empresa.certificada,
    envios: state.empresa.envios,
    equipoMedico: state.empresa.equipoMedico,
    permisoAgregarVenta:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ccb6bf30e81b54183cf70"
      )?.activado,
    permisoCancelarVenta:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472d37f4b2da7c85c1a8cff"
      )?.activado,
    permisoEntregarInsumosVenta:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ccbaff30e81b54183cf71"
      )?.activado,
      permisoPuntoVenta:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6750914f798d4e004368723f"
      )?.activado,
      permisoFacturaGlobal:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6750916e798d4e0043687240"
      )?.activado,
  };
};

export default connect(mapStateToProps)(Ventas);
