import {
  CheckCircleOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { PageHeader, Space, Popconfirm, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, dineroDisabledMask } from "../../../Components/Items";

class Indirectos extends Component {
  state = {
    idEditarInsumoCompuesto: null,
    todasIndirectos: [],
    loading: false
  };
  componentDidMount() {
    this.consultarIndirectos();
  }
  consultarIndirectos() {
    this.setState({ loading: true })
    axios
      .post(
        "indirectos/selectTodasIndirectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false })
        if (res.data.success === 2) {
          this.setState({
            todasIndirectos: res.data.indirectos
          });
        } else {
          message.info('No se pudo consultar información de los indirectos')
        }
      });
  }
  deshabilitarIndirecto(value) {
    axios
      .post(
        "indirectos/deshabilitarIndirecto",
        { indirectoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Indirecto deshabilitado");
        } else {
          message.error("Sucedio un error y no se pudo deshabilitar");
        }
        this.consultarIndirectos();
      });
  }
  habilitarIndirecto(value) {
    axios
      .post(
        "indirectos/habilitarIndirecto",
        { indirectoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Indirecto habilitado");
        } else {
          message.error("Sucedio un error y no se pudo habilitar");
        }
        this.consultarIndirectos();
      });
  }

  agregarIndirecto() {
    this.props.history.push("/panel/agregarIntangible");
  }
  editarIndirecto(id) {
    this.props.history.push("/panel/editarIntangible", { id });
  }

  render() {
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
        width: "5%",
      },
      {
        title: "Clave",
        dataIndex: "clave",
        key: "clave",
        sorter: (a, b) => {
          if (a.clave < b.clave) {
            return -1;
          }
          if (a.clave > b.clave) {
            return 1;
          }
          return 0;
        },
        buscar: "clave",
        width: "5%",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
        width: "30%",
      },
      {
        title: "Tipo de Insumo",
        dataIndex: "tipoDeInsumo",
        key: "tipoDeInsumo",
        sorter: (a, b) => {
          if (a.tipoDeInsumo < b.tipoDeInsumo) {
            return -1;
          }
          if (a.tipoDeInsumo > b.tipoDeInsumo) {
            return 1;
          }
          return 0;
        },
        buscar: "tipoDeInsumo",
        width: "16%",
      },
      {
        title: "Unidad",
        dataIndex: "unidad",
        key: "unidad",
        sorter: (a, b) => {
          if (a.unidad < b.unidad) {
            return -1;
          }
          if (a.unidad > b.unidad) {
            return 1;
          }
          return 0;
        },
        width: "6%",
      },
      {
        title: "Costo",
        dataIndex: "costo",
        key: "costo",
        sorter: (a, b) => {
          if (a.costo < b.costo) {
            return -1;
          }
          if (a.costo > b.costo) {
            return 1;
          }
          return 0;
        },
        render: (value, record) => {
          if(record.calculo){
            return record.porcentaje+' %'
          } else {
            return dineroDisabledMask(value)
          }
        },
        width: "6%",
        align: 'right'
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
              <EditOutlined
                onClick={() => this.editarIndirecto(value._id)}
                style={{ color: "blue" }}
              />
              <>
                {value.estado === 1 ? (
                  <Popconfirm
                    title="Seguro que quieres deshabilitar este indirecto"
                    onConfirm={() => this.deshabilitarIndirecto(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Seguro que quieres habilitar este indirecto"
                    onConfirm={() => this.habilitarIndirecto(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
          </Space>
        ),
        width: "12%",
      },
    ];
    return (
      <>
        <PageHeader
          title="Intangibles"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarIntangible"
              onClick={this.agregarIndirecto.bind(this)}
            >
              Agregar Intangible
            </ButtonItem>
          }
        />
        <TableItem
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasIndirectos}
          columns={columns}
          pagination={false}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Indirectos);
