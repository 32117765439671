import { Empty, PageHeader, Popconfirm, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";

moment.locale("es");

class ListaDeMateriales extends Component {
  state = {
    todasListaDeMateriales: [],
    loading: false,
    valor: 1,
  };
  componentDidMount() {
    this.consultarListaDeMateriales(this.state.valor);
  }
  consultarListaDeMateriales(valor) {
    this.setState({ loading: true });
    axios
      .post(
        "listasdemateriales/selectTodasListas",
        { empresaId: this.props.empresaId, valor: valor },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          this.setState({ todasListaDeMateriales: [] });
        } else {
          this.setState({
            todasListaDeMateriales: res.data.map(value => {
                return {...value, accion: { _id: value._id, estado: value.estado }}
              }),
          });
        }
      });
  }
  agregarListaDeMateriales() {
    this.props.history.push("/panel/agregarListaDeMateriales");
  }

  deshabilitarListaDeMateriales(value) {
    axios
      .post(
        "listasdemateriales/deshabilitarListaDeMateriales",
        { listaDeMaterialesId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Lista de Materiales deshabilitado");
        } else {
          message.error("Sucedio un error y no se pudo deshabilitar");
        }
        this.consultarListaDeMateriales(this.state.valor);
      });
  }
  habilitarListaDeMateriales(value) {
    axios
      .post(
        "listasdemateriales/habilitarListaDeMateriales",
        { listaDeMaterialesId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Lista de Materiales habilitado");
        } else {
          message.error("Sucedio un error y no se pudo habilitar");
        }
        this.consultarListaDeMateriales(this.state.valor);
      });
  }

  editarListaDeMateriales(id){
    this.props.history.push('/panel/editarListaDeMateriales', {id})
  }

  render() {
    const columns = [
        {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            sorter: (a, b) => {
              if (a.nombre < b.nombre) {
                return -1;
              }
              if (a.nombre > b.nombre) {
                return 1;
              }
              return 0;
            },
            buscar: "nombre",
          },
          {
            title: "Descripcion",
            dataIndex: "descripcion",
            key: "descripcion",
            sorter: (a, b) => {
              if (a.descripcion < b.descripcion) {
                return -1;
              }
              if (a.descripcion > b.descripcion) {
                return 1;
              }
              return 0;
            },
            buscar: "descripcion",
          },
      {
        title: "Insumo a Generar",
        dataIndex: "insumoAGenerar",
        key: "insumoAGenerar",
        sorter: (a, b) => {
          if (a.insumoAGenerar < b.insumoAGenerar) {
            return -1;
          }
          if (a.insumoAGenerar > b.insumoAGenerar) {
            return 1;
          }
          return 0;
        },
        buscar: "insumoAGenerar",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarListaDeMateriales &&
              <EditOutlined
                onClick={() => this.editarListaDeMateriales(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarListaDeMateriales && 
            
              <>
                {value.estado === 1 ? (
                  <Popconfirm
                    title="Seguro que quieres deshabilitar esta lista de materiales"
                    onConfirm={() => this.deshabilitarListaDeMateriales(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Seguro que quieres habilitar esta lista de materiales"
                    onConfirm={() => this.habilitarListaDeMateriales(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
            }
          </Space>
        ),
      },
    ];
    return (
      <>
        <PageHeader
          title="Lista De Materiales"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
            <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarListaDeMateriales(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
            {this.props.permisoAgregarListaDeMateriales &&
              <ButtonItem
                type="primary"
                key="agregarListaDeMateriales"
                onClick={this.agregarListaDeMateriales.bind(this)}
              >
                Agregar Lista De Materiales
              </ButtonItem>
            }
            </>
          }
        />
        <TableItem
            locale={{emptyText: (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={(<>
                <p>Las listas de materiales te permiten definir la lista de materia prima usada para fabricar el producto terminado;<br/> a través de una orden de producción.</p>
            </>)} />)}}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasListaDeMateriales}
          columns={columns}
          pagination={false}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarListaDeMateriales: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472df544b2da7c85c1a8d14")?.activado,
    permisoEditarListaDeMateriales: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472df844b2da7c85c1a8d15")?.activado,
    permisoDeshabilitarListaDeMateriales: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472dfb84b2da7c85c1a8d16")?.activado,
  };
};

export default connect(mapStateToProps)(ListaDeMateriales);
