import { Collapse, Row, Col, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  dineroDisabledMask,
  ModalItem,
  ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";
import CancelarPagoCajaChica from "../CancelarPagoCajaChica/CancelarPagoCajaChica";

const { Panel } = Collapse;

class PagosFacturaCajaChica extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pagosHtml: <p>No se encontro ningun pago</p>,
    modalCancelarPagoCajaChica: false,
    modalCancelarPagoCajaChicaId: null
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "pagos/pagosFacturaCajaChica",
        { facturaId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) return;
        const html = (
          <Collapse style={{ marginBottom: 10 }}>
            {res.data.map((valuePago) => {
              return (
                <Panel
                  extra={
                    valuePago.estado !== 0 ? (
                      valuePago.moneda.abreviacion+' - '+dineroDisabledMask(valuePago.monto)
                    ) : (
                      <Tag color="red">
                        Cancelada: {valuePago.moneda.abreviacion+' - '+dineroDisabledMask(valuePago.monto)}
                      </Tag>
                    )
                  }
                  header={moment(valuePago.fechaPago).locale("es").format("ll")}
                  key={valuePago._id}
                >
                  <Row>
                    <Col span={12}>
                      <p>
                        Dia del Pago:{" "}
                        {moment(valuePago.fechaPago).locale("es").format("ll")}
                      </p>
                      <p>
                        Complemento de Pago:{" "}
                        {valuePago.complemento ? "Si" : "No"}
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        Dia de registro:{" "}
                        {moment(valuePago.createdAt).locale("es").format("ll")}
                      </p>
                      <p>Monto: {valuePago.moneda.abreviacion+' - '+dineroDisabledMask(valuePago.monto)}</p>
                      {valuePago.estado !== 0 && !valuePago.contado && !valuePago.pagarFactura && (
                        <ButtonItem type="primary" danger onClick={() => {
                          this.setState({modalCancelarPagoCajaChica: true, modalCancelarPagoCajaChicaId: valuePago._id})
                        }}>
                          Cancelar Pago
                        </ButtonItem>
                      )}
                    </Col>
                  </Row>
                </Panel>
              );
            })}
          </Collapse>
        );
        this.setState({ pagosHtml: html });
      });
    this.setState({ loading: false });
  }
  cerrarPagosFacturaCajaChica() {
    this.props.cerrarPagosFacturaCajaChica();
  }
  cerrarCancelarPagoCajaChica() {
    this.setState({
      modalCancelarPagoCajaChica: false,
      modalCancelarPagoCajaChicaId: null,
    });
    this.consultarTodo()
  }
  render() {
    return (
      <>
      <ModalItem
        title="Pagos Factura"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarPagosFacturaCajaChica.bind(this)}
        paddingTop
      >
        {this.state.pagosHtml}
      </ModalItem>
      <CancelarPagoCajaChica
          visible={this.state.modalCancelarPagoCajaChica}
          id={this.state.modalCancelarPagoCajaChicaId}
          cerrarCancelarPagoCajaChica={this.cerrarCancelarPagoCajaChica.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(PagosFacturaCajaChica);