import {
    PageHeader,
    Popconfirm,
    Select,
    Space,
    Tag,
    message,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import { NotificacionPolizas, SelectItem, TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";
  import { CloseCircleOutlined } from "@ant-design/icons";
  import moment from "moment";
import "moment/locale/es";

moment.locale("es");
  
  const { Option } = Select;
  
  class ProductosAlCosto extends Component {
    state = {
      loading: false,
      objetoProyectos: [],
      todasProyectos: [],
      todasProductosAlCosto: [],
      proyecto: undefined,
      valor: 2,
    };
  
    async componentDidMount() {
      await this.consultarTodo(this.state.valor);
    }
  
    async consultarTodo(valor) {
      this.setState({ loading: true });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "productosAlCosto/selectTodasOrdenes",
          { empresaId: this.props.empresaId, valor: valor },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            this.setState({ todasProductosAlCosto: [] });
          } else {
            this.setState({
                todasProductosAlCosto: res.data.map((value) => {
                return {
                  ...value,
                  proyectoNombre: value.proyecto.nombre,
                  accion: { _id: value._id, estado: value.estado },
                };
              }),
            });
          }
          this.setState({ loading: false });
        });
      this.setState({ loading: false });
    }
  
    onChangeProyecto(value) {
      if (value === 0) {
        this.setState({ loading: true, proyecto: undefined });
        axios
          .post(
            "productosAlCosto/selectTodasOrdenes",
            { empresaId: this.props.empresaId },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            if (res.data.status === 404) {
              this.setState({ todasProductosAlCosto: [] });
            } else {
              this.setState({
                todasProductosAlCosto: res.data.map((value) => {
                  return {
                    ...value,
                    proyectoNombre: value.proyecto.nombre,
                    accion: { _id: value._id, estado: value.estado },
                  };
                }),
              });
            }
            this.setState({ loading: false });
          });
      } else {
        const proyecto = this.state.todasProyectos.find(
          (valueState) => valueState._id.toString() === value.toString()
        );
        this.setState({ loading: true, proyecto: proyecto.nombre });
        axios
          .post(
            "productosAlCosto/selectTodasOrdenes",
            { empresaId: this.props.empresaId, proyectoId: value },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            if (res.data.status === 404) {
              this.setState({ todasProductosAlCosto: [] });
            } else {
              this.setState({
                todasProductosAlCosto: res.data.map((value) => {
                  return {
                    ...value,
                    proyectoNombre: value.proyecto.nombre,
                    accion: { _id: value._id, estado: value.estado },
                  };
                }),
              });
            }
            this.setState({ loading: false });
          });
      }
    }
    abrirProductosAlCosto() {
      this.props.history.push("/panel/agregarOrdenProductosAlCosto");
    }
    confirmarProductosAlCosto(value) {
      axios
        .post(
          "productosAlCosto/confirmarOrdenProductosAlCosto",
          { ordenId: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
            this.setState({ loading: false });
            if (res.data.success === 2) {
              message.success(
                "Se mandaron los productos al costo"
              );
              NotificacionPolizas(res.data.polizasId)
            } else if (res.data.success === 1) {
              message.error(res.data.message ? res.data.message : "No hay suficiente inventario");
            } else {
              message.error("Hubo un error y el inventario no se redujo");
            }
          this.consultarTodo(this.state.valor);
        });
    }
    cancelarOrdenProductosAlCosto(value) {
      axios
        .post(
          "productosAlCosto/cancelarOrdenProductosAlCosto",
          { ordenId: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            message.success("Orden cancelada");
          } else {
            message.error("Sucedio un error y no se pudo cancelar");
          }
          this.consultarTodo(this.state.valor);
        });
    }
    render() {
      const columns = [
        {
          title: "Numero",
          dataIndex: "numero",
          key: "numero",
          sorter: (a, b) => {
            if (a.numero < b.numero) {
              return -1;
            }
            if (a.numero > b.numero) {
              return 1;
            }
            return 0;
          },
          buscar: "numero",
          width: "10%",
        },
        {
          title: "Fecha",
          dataIndex: "createdAt",
          key: "createdAt",
          sorter: (a, b) => {
            if (moment(a.createdAt) < moment(b.createdAt)) {
              return -1;
            }
            if (moment(a.createdAt) > moment(b.createdAt)) {
              return 1;
            }
            return 0;
          },
          render: (value) => moment(value).locale('es').format('L'),
          width: '10%',
        },
        {
          title: "Referencia",
          dataIndex: "referencia",
          key: "referencia",
          sorter: (a, b) => {
            if (a.referencia < b.referencia) {
              return -1;
            }
            if (a.referencia > b.referencia) {
              return 1;
            }
            return 0;
          },
          buscar: "referencia",
          width: "20%",
        },
        {
          title: "Proyecto",
          dataIndex: "proyectoNombre",
          key: "proyectoNombre",
          sorter: (a, b) => {
            if (a.proyectoNombre < b.proyectoNombre) {
              return -1;
            }
            if (a.proyectoNombre > b.proyectoNombre) {
              return 1;
            }
            return 0;
          },
          buscar: "proyectoNombre",
          width: "25%",
        },
        {
          title: "Estado",
          dataIndex: "estado",
          key: "estado",
          render: (value) => {
            if (value === 0) {
              return <Tag color="red">Cancelada</Tag>;
            }
            if (value === 1) {
              return <Tag color="blue">Por confirmar</Tag>;
            }
            if (value === 3) {
              return <Tag color="green">Agregada</Tag>;
            }
          },
          width: "15%",
          ellipsis: true,
        },
        {
          title: "Acción",
          dataIndex: "accion",
          key: "accion",
          render: (value, index) => {
            if (value.estado === 0) return;
            if (value.estado === 3) return;
  
            return (
              <Space direction="horizontal">
                {this.props.permisoConfirmarOrdenProductosAlCosto &&
                  <Popconfirm
                    title={
                      "Seguro que quieres confirmar esta orden de productos al costo"
                    }
                    onConfirm={() => this.confirmarProductosAlCosto(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <ButtonItem type="primary" size="small">
                      Confirmar
                    </ButtonItem>
                  </Popconfirm>
                }
                {this.props.permisoCancelarOrdenProductosAlCosto &&
                  <Popconfirm
                    title={
                      "Seguro que quieres cancelar esta orden de productos al costo"
                    }
                    onConfirm={() => this.cancelarOrdenProductosAlCosto(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Popconfirm>
                }
              </Space>
            );
          },
        },
      ];
      return (
        <>
          <PageHeader
            title={
              this.state.proyecto
                ? "Ordenes Productos Al Costo: " + this.state.proyecto
                : "Ordenes Productos Al Costo: Todos"
            }
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={
              <Space style={{ width: "100%", minWidth: 300 }}>
                {window.innerWidth > 1000 &&
                <SegmentedItem
                options={[{label: 'Agregada', value: 1}, {label: 'Por Confirmar', value: 2}, {label: 'Cancelada', value: 3}, {label: 'Todos', value: 4}]}
                onChange={(value) => {
                  this.consultarTodo(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
            }
                <SelectItem
                  placeholder="Proyecto"
                  width={200}
                  onChange={this.onChangeProyecto.bind(this)}
                  defaultValue={0}
                >
                  <Option value={0}>Todos</Option>
                  {this.state.objetoProyectos}
                </SelectItem>
                {this.props.permisoAgregarOrdenProductosAlCosto &&
                  <ButtonItem
                    type="primary"
                    key="agregarInventario"
                    onClick={this.abrirProductosAlCosto.bind(this)}
                  >
                    Productos Al Costo
                  </ButtonItem>
                }
              </Space>
            }
          />
          <TableItem
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: (event) => {
                  this.props.history.push("/panel/verOrdenProductosAlCosto", {
                    id: record._id,
                  });
                },
              };
            }}
            size="small"
            dataSource={this.state.todasProductosAlCosto}
            columns={columns}
            pagination={false}
            loading={this.state.loading}
          />
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
      permisoAgregarOrdenProductosAlCosto:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "647fc5549dc9c6543b161b8c"
      )?.activado,
      permisoConfirmarOrdenProductosAlCosto:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "647fc4e39dc9c6543b161b8a"
      )?.activado,
      permisoCancelarOrdenProductosAlCosto:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "647fc5049dc9c6543b161b8b"
      )?.activado,
    };
  };
  
  export default connect(mapStateToProps)(ProductosAlCosto);
  