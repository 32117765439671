import { Collapse, Row, Col, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  dineroDisabledMask,
  ModalItem,
  ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";
import CancelarDevolucion from "../CancelarDevolucion/CancelarDevolucion";

const { Panel } = Collapse;

class DevolucionesVenta extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    devolucionesHtml: <p>No se encontro ninguna devolución</p>,
    modalCancelarDevolucion: false,
    modalCancelarDevolucionId: null
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }

  verDevolucion(id){
    this.props.history.push("/panel/verDevolucion", {id})
  }

  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "devoluciones/devolucionesVenta",
        { ventaId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) return;
        const html = (
          <Collapse style={{ marginBottom: 10 }}>
            {res.data.map((valueDevolucion) => {
              return (
                <Panel
                  extra={
                    valueDevolucion.estado !== 0 ? (
                      valueDevolucion.moneda.abreviacion+' - '+dineroDisabledMask(valueDevolucion.totalPagar)
                    ) : (
                      <Tag color="red">
                        Cancelada: {valueDevolucion.moneda.abreviacion+' - '+dineroDisabledMask(valueDevolucion.totalPagar)}
                      </Tag>
                    )
                  }
                  header={moment(valueDevolucion.fechaDevolucion).locale("es").format("ll")}
                  key={valueDevolucion._id}
                >
                  <Row>
                    <Col span={12}>
                      <p>
                        Fecha:{" "}
                        {moment(valueDevolucion.fechaDevolucion).locale("es").format("ll")}
                      </p>
                      <p>
                        UUID:{" "}
                        {valueDevolucion.dataFactura ? valueDevolucion.dataFactura.uuid : "No tiene"}
                      </p>
                        <ButtonItem type="primary" onClick={() => this.verDevolucion(valueDevolucion._id)}>
                          Ver devolucion
                        </ButtonItem>
                    </Col>
                    <Col span={12}>
                      <p>Total: {valueDevolucion.moneda.abreviacion+' - '+dineroDisabledMask(valueDevolucion.totalPagar)}</p>
                      <p>Codigo: {valueDevolucion.factura ? (valueDevolucion.facturaSerie + ' - ' + valueDevolucion.facturaNumero) : (valueDevolucion.remisionSerie + ' - ' + valueDevolucion.remisionNumero)}</p>
                      {valueDevolucion.estado !== 0 && (
                        <ButtonItem type="primary" disabled danger onClick={() => {
                          this.setState({modalCancelarDevolucion: true, modalCancelarDevolucionId: valueDevolucion._id})
                        }}>
                          Cancelar Devolucion
                        </ButtonItem>
                      )}
                    </Col>
                  </Row>
                </Panel>
              );
            })}
          </Collapse>
        );
        this.setState({ devolucionesHtml: html });
      });
    this.setState({ loading: false });
  }
  cerrarDevolucionesVenta() {
    this.props.cerrarDevolucionesVenta();
  }
  cerrarCancelarDevolucion() {
    this.setState({
      modalCancelarDevolucion: false,
      modalCancelarDevolucionId: null,
    });
    this.consultarTodo()
  }
  render() {
    return (
      <>
      <ModalItem
        title="Devoluciones Venta"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarDevolucionesVenta.bind(this)}
        paddingTop
      >
        {this.state.devolucionesHtml}
      </ModalItem>
      <CancelarDevolucion
          visible={this.state.modalCancelarDevolucion}
          id={this.state.modalCancelarDevolucionId}
          cerrarCancelarDevolucion={this.cerrarCancelarDevolucion.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(DevolucionesVenta);
