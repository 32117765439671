import { PageHeader, Space, Popconfirm, message, Dropdown, Menu } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import { TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";
import { connect } from "react-redux";
import { StopOutlined, EditOutlined, CheckCircleOutlined, DownOutlined, PlusOutlined, ImportOutlined, ExportOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'

class Proveedores extends Component {
  state = {
    todasProveedores: [],
    loading: false,
    valor: 1,
  }
  componentDidMount() {
    this.consultarProveedores(this.state.valor);
  }

  consultarProveedores(valor) {
    this.setState({loading: true})
    axios
    .post(
      "proveedores/selectTodosProveedores",
      { empresaId: this.props.empresaId, valor: valor },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
        this.setState({ todasProveedores: [] });
      } else {
        this.setState({
          todasProveedores: res.data
        });
      }
    });
  }
  agregarProveedor(){
    this.props.history.push('/panel/agregarProveedor')
  }
  importarProveedores(){
    this.props.history.push('/panel/importarProveedores')
  }
  editarProveedor(id){
    this.props.history.push('/panel/editarProveedor', {id})
  }

  exportarProveedores() {
    this.setState({ loading: true });
    axios
      .post(
        "proveedores/exportarProveedores", 
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
          message.error("No se encontraron datos para exportar");
        } else {
          
          const datosProveedores = res.data.data.map(proveedor => ({
            "Código": proveedor.codigo,
            "Razón Social": proveedor.razonSocial,
            "Nombre Persona Física": proveedor.nombre,
            "Apellido Paterno": proveedor.apellidoPaterno,
            "Apellido Materno": proveedor.apellidoMaterno,
            "CURP": proveedor.curp,
            "RFC": proveedor.rfc,
            "Teléfono Empresa": proveedor.telefono,
            "Correo Empresa": proveedor.correo,
            "Cuenta Banco": proveedor.cuentaBanco,
            "Cuenta Clabe": proveedor.CuentaClabe,
            "Dias de Crédito": proveedor.diasCredito ? proveedor.diasCredito : "",
            "Limite de Crédito": proveedor.limiteCredito ? proveedor.limiteCredito : "",
            "Tipo de Tercero": (proveedor.tipoTercero === 1) ? "04 - Proveedor Nacional" : (proveedor.tipoTercero === 2) ? "05 - Proveedor Extranjero" : (proveedor.tipoTercero === 3) ? "15 - Proveedor Global" : "Tipo desconocido",
            "Tipo de operación": proveedor.TipoOperacion,
            "Código Postal": proveedor.cp,
            "Colonia": proveedor.coloniaText,
            "Calle": proveedor.calle,
            "Número": proveedor.numeroCalle,
            "Número Int": proveedor.numeroInterior,
            "Nombre Contacto": proveedor.nombreContacto,
            "Teléfono Contacto": proveedor.telefonoContacto,
            "Correo Contacto": proveedor.correoContacto,
            "Jefe del Contacto": proveedor.jefeContcto,
            "Persona de Pedidos": proveedor.personasPedidos,
            "Puesto del Jefe de Contacto": proveedor.puestoJefeContacto,
            "Observaciones": proveedor.observaciones,
            "Registro de Infonavit": proveedor.infonavit,
            "Registro Patronal": proveedor.registroPatronal,
            "Registro de la Cámara": proveedor.registroCamara,
          }));
          const ws = XLSX.utils.json_to_sheet(datosProveedores);
          const encabezados = Object.keys(datosProveedores[0]);  
          ws["!cols"] = encabezados.map(() => ({ wch: 20 }));
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Proveedores");
          XLSX.writeFile(wb, "Proveedores.xlsx");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        message.error("Ocurrió un error al generar el archivo");
      });
}

  deshabilitarProveedor(value) {
    axios
      .post(
        "proveedores/deshabilitarProveedor",
        { proveedorId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Proveedor deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarProveedores(this.state.valor);
      });
  }
  habilitarProveedor(value) {
    axios
      .post(
        "proveedores/habilitarProveedor",
        { proveedorId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Proveedor habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarProveedores(this.state.valor);
      });
  }
  handleMenuClick(e) {
    if(e.key === '1'){
      this.agregarProveedor()
    }
    if(e.key === '2'){
      this.importarProveedores()
    }
  }
  render() {
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
        ellipsis: true,
      },
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
        ellipsis: true,
      },
      {
        title: "Nombre Comercial",
        dataIndex: "nombreComercial",
        key: "nombreComercial",
        sorter: (a, b) => {
          if (a.nombreComercial < b.nombreComercial) {
            return -1;
          }
          if (a.nombreComercial > b.nombreComercial) {
            return 1;
          }
          return 0;
        },
        buscar: "nombreComercial",
        ellipsis: true,
      },
      {
        title: "RFC",
        dataIndex: "rfc",
        key: "rfc",
        sorter: (a, b) => {
          if (a.rfc < b.rfc) {
            return -1;
          }
          if (a.rfc > b.rfc) {
            return 1;
          }
          return 0;
        },
        buscar: "rfc",
        ellipsis: true,
      },
      {
        title: "Tipo de Proveedor",
        dataIndex: "tipoProveedor",
        key: "tipoProveedor",
        ellipsis: true,
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        ellipsis: true,
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarProveedor &&
              <EditOutlined
                onClick={() => this.editarProveedor(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarProveedor &&
            <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este proveedor"
                  onConfirm={() => this.deshabilitarProveedor(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este proveedor"
                  onConfirm={() => this.habilitarProveedor(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
            }
          </Space>
        ),
      },
    ];
    const menu = (
      <Menu onClick={this.handleMenuClick.bind(this)}>
        <Menu.Item key="1" icon={<PlusOutlined />}>
          Agregar Proveedor
        </Menu.Item>
        <Menu.Item key="2" icon={<ImportOutlined />}>
          Importar Proveedores
        </Menu.Item>
        <Menu.Item key="3" icon={<ExportOutlined />} onClick={this.exportarProveedores.bind(this)}>
          Exportar Proveedores
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        <PageHeader
          title="Proveedores"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
            <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarProveedores(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
            {this.props.permisoAgregarProveedor &&
            <Dropdown overlay={menu} trigger={['click']}>
            <ButtonItem type='primary'>
          Agregar <DownOutlined />
        </ButtonItem>
          </Dropdown>}
          </>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasProveedores} columns={columns} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarProveedor: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd70cf30e81b54183cf91")?.activado,
    permisoEditarProveedor: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd738f30e81b54183cf92")?.activado,
    permisoDeshabilitarProveedor: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce49ef30e81b54183cfb3")?.activado,
  };
};

export default connect(mapStateToProps)(Proveedores);
