import React, { Component } from "react";
import {
  PageHeader,
  Spin,
  Row,
  Col,
  Divider,
  Select,
  Form as FormInitial,
  message,
  Space
} from "antd";
import {
  dineroDisabledMask,
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  ButtonItem,
  InputItem,
  HeaderItem,
  BoxItem,
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { DollarOutlined } from "@ant-design/icons";
import PagosNotaDeCredito from "../PagosNotaDeCredito/PagosNotaDeCredito";

const { Option } = Select;

moment.locale("es");

class VerNotaDeCredito extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProveedores: null,
    todasProveedores: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoInsumos: (
      <Option disabled value={0} key={0}>
      Seleccione un proveedor primero
    </Option>
    ),
    todasInsumos: [],
    todasMonedas: null,
    objetoMonedas: null,
    impuestos: [],
    total: 0,
    totalPagar: 0,
    totalPagado: 0,
    subtotal: 0,
    ajusteSubtotal: 0,
    arrayImpuestos: [],
    arrayRetenciones: [],
    modalPagos: false,
    estado: 0,
    id: this.props.location.state.id,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "proveedores/selectProveedores",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProveedores: res.data });
          this.setState({
            objetoProveedores: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
      await axios
      .post("monedas/selectMonedas", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMonedas: res.data });
          this.setState({
            objetoMonedas: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviacion}
                </Option>
              );
            }),
          });
        }
      });

      let proveedorId

      await axios
      .post(
        "notasDeCreditoCompra/datosNota",
        { empresaId: this.props.empresaId, notaId: this.state.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion de la nota de credito");
        } else {
          this.formRef.current.setFieldsValue({
          ...res.data,
          fechaFactura: moment(res.data.fechaFactura),
          insumos: res.data.insumos.map(insumo => {
            const precioUnitarioConImpuestos = insumo.precioUnitario + (insumo.impuestos / insumo.cantidad)
            return {...insumo, precioUnitarioConImpuestos}
          })
          });
          proveedorId = res.data.proveedorId
          let newState = {estado: res.data.estado, total: res.data.total, totalPagar: res.data.totalPagar, totalPagado: res.data.totalPagado, subtotal: res.data.subtotal, arrayImpuestos: res.data.arrayImpuestos, arrayRetenciones: res.data.arrayRetenciones, ajusteSubtotal: res.data.ajusteSubtotal}
      
          this.setState(newState)
        }
      });

      await axios
      .post(
        "insumos/selectInsumosCompraYRelacionesProveedor",
        { empresaId: this.props.empresaId, proveedorId },
        {
          headers: this.props.headersToken,
        }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasInsumos: res.data });
            this.setState({
              objetoInsumos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });

      this.setState({ loading: false });
  }
  atras() {
    this.props.history.goBack();
  }
  cerrarPagosNotaDeCredito() {
    this.consultarTodo()
    this.setState({
      modalPagos: false,
    });
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Nota de Credito"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="verNotaDeCredito"
              layout
              bottom={
                <div style={{ display: "flex", justifyContent: "right" }}>
                <Space>
                  {this.state.totalPagado > 0 &&
                    <ButtonItem
                      type="primary"
                      icon={<DollarOutlined />}
                      style={{backgroundColor: 'green', borderColor: 'green'}}
                      onClick={() => this.setState({modalPagos: true})}
                    >
                      Pagos
                    </ButtonItem>
                  }
                  <ButtonItem
                    type="primary"
                    danger
                    onClick={this.atras.bind(this)}
                  >
                    Regresar
                  </ButtonItem>
                  </Space>
                  </div>
              }
            >
              <Row>
                <Col span={18}>
                  <HeaderItem>DATOS GENERALES</HeaderItem>
                    <BoxItem>
                      <Row>
                        <Col span={12}>
                          <FormItem
                            label="Proyecto"
                            name="proyectoId"
                            margin
                            required
                            disabled
                          >
                            <SelectItem placeholder="Proyecto" disabled>
                              {this.state.objetoProyectos}
                            </SelectItem>
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              let proyectoId = getFieldValue("proyectoId");
                              if (!proyectoId)
                                return <p>Seleccione un proyecto primero</p>;
                              let proyecto = this.state.todasProyectos.find(
                                (value) => value._id === proyectoId
                              );
                              return (
                                <>
                                  <Row>
                                    <Col span={12}>
                                      <h1 style={{ margin: 0 }}>
                                        Nombre: {proyecto.nombre}
                                      </h1>
                                      <p>Descripcion: {proyecto.descripcion}</p>
                                    </Col>
                                    <Col span={12}>
                                      <h1 style={{ margin: 0 }}>Dirección de envio</h1>
                                      {proyecto.calle ? (
                                        <>
                                          <p style={{ margin: 0 }}>
                                            Calle: {proyecto.calle}
                                          </p>
                                          <p style={{ margin: 0 }}>
                                            Numero: {proyecto.numeroCalle}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p style={{ margin: 0 }}>
                                            Marca: {proyecto.marca}
                                          </p>
                                          <p style={{ margin: 0 }}>
                                            Modelo: {proyecto.modelo}
                                          </p>
                                          <p style={{ margin: 0 }}>
                                            Placas: {proyecto.placas}
                                          </p>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                </>
                              );
                            }}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <FormItem
                            label="Proveedor"
                            name="proveedorId"
                            margin
                            required
                          >
                            <SelectItem placeholder="Proveedor" disabled>
                              {this.state.objetoProveedores}
                            </SelectItem>
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              let proveedorId = getFieldValue("proveedorId");
                              if (!proveedorId)
                                return <p>Seleccione un proveedor primero</p>;
                              let proveedor = this.state.todasProveedores.find(
                                (value) => value._id === proveedorId
                              );
                              return (
                                <>
                                  <Row>
                                    <Col span={12}>
                                      <h1 style={{ margin: 0 }}>
                                        Nombre:{" "}
                                        {proveedor.nombre
                                          ? proveedor.nombre +
                                            " " +
                                            proveedor.apellidoPaterno +
                                            " " +
                                            proveedor.apellidoMaterno
                                          : proveedor.razonSocial}
                                      </h1>
                                      <p style={{ margin: 0 }}>Rfc: {proveedor.rfc}</p>
                                      <p style={{ margin: 0 }}>
                                        Correo: {proveedor.correo}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        Telefono: {proveedor.telefono}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        Celular: {proveedor.celular}
                                      </p>
                                    </Col>
                                    <Col span={12}>
                                      <p style={{ margin: 0 }}>
                                        Contacto: {proveedor.nombreContacto}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        Tel. Contacto: {proveedor.telefonoContacto}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        Correo Contacto: {proveedor.correoContacto}
                                      </p>
                                      <p style={{ margin: 0 }}>
                                        Dias de crédito: {proveedor.diasCredito}
                                      </p>
                                    </Col>
                                  </Row>
                                </>
                              );
                            }}
                          </FormItem>
                        </Col>
                      </Row>
                    </BoxItem>
                </Col>
                <Col span={6}>
                  <HeaderItem>MONEDA</HeaderItem>
                  <BoxItem>
                    <FormItem
                      required
                      margin
                      name="monedaId"
                      label="Moneda"
                      labelCol={{ span: 8 }}
                      wrapperCol={{
                        span: 16,
                      }}
                      initialValue="60c7e4396465ad440c41c15a"
                    >
                      <SelectItem
                        disabled
                        onChange={(value) => {
                          if (value === "60c7e4396465ad440c41c15a") {
                            this.formRef.current.setFieldsValue({
                              tipoDeCambio: 1,
                            });
                          }
                        }}
                        placeholder="Moneda"
                      >
                        {this.state.objetoMonedas}
                      </SelectItem>
                    </FormItem>
                    <FormItem
                      required
                      number
                      margin
                      label="T. Cambio"
                      name="tipoDeCambio"
                      type="number"
                      initialValue={1}
                      labelCol={{ span: 8 }}
                      wrapperCol={{
                        span: 16,
                      }}
                    >
                      <InputNumberItem
                        disabled
                        dinero
                        placeholder="T. Cambio"
                      />
                    </FormItem>
                  </BoxItem>
                </Col>
              </Row>
              <HeaderItem>DATOS FACTURA</HeaderItem>
              <BoxItem>
                <Row>
                    <Col span={12}>
                      <FormItem
                        name="codigoFactura"
                        label="Codigo Factura"
                        margin
                        pattern
                      >
                        <InputItem disabled placeholder="Codigo Factura" />
                      </FormItem>
                      <FormItem
                        name="descripcion"
                        label="Descripcion"
                        margin
                        pattern
                      >
                        <InputItem disabled placeholder="Descripcion" />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem name="uuid" label="UUID" margin pattern required>
                        <InputItem disabled onChange={(e) => this.onChangeUUID(e.target.value)} placeholder="UUID" />
                      </FormItem>
                    <FormItem name="uuidRelacionado" label="UUID Relacionado" margin pattern required>
                        <InputItem disabled  placeholder="UUID Relacionado" />
                      </FormItem>
                      <FormItem
                      label="Fecha de N. C."
                      name="fechaFactura"
                      required
                      margin
                    >
                      <DatePickerItem
                      disabled
                        placeholder="Fecha de N. C."
                        allowClear={false}
                      />
                    </FormItem>
                    </Col>
                </Row>
              </BoxItem>
              <Divider
                style={{ marginBottom: 10, marginTop: 5 }}
                children="Insumos"
              />
              <FormInitial.List name="insumos">
                {(fields, { add, remove }) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "40%" }}>
                              Insumo
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Precio Unitario
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Precio Unitario con Impuestos
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Cantidad
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Subtotal
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Impuestos
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Total
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, arrayKey) => (
                          <tbody key={arrayKey}>
                            <tr>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "insumoId"]}
                                  fieldKey={[arrayKey, "insumoId"]}
                                  required
                                  noStyle
                                >
                                  <SelectItem
                                    sinBorde
                                    autoFocus
                                    style={{ width: "100%" }}
                                    placeholder="Insumo"
                                    disabled
                                  >
                                    {this.state.objetoInsumos}
                                  </SelectItem>
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "precioUnitario"]}
                                  fieldKey={[arrayKey, "precioUnitario"]}
                                  noStyle
                                  required
                                >
                                  <InputNumberItem
                                    sinBorde
                                    placeholder="Precio Unitario"
                                    disabled
                                    dinero
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  name={[name, "precioUnitarioConImpuestos"]}
                                  noStyle
                                  required
                                  shouldUpdate
                                >
                                  <InputNumberItem
                                    sinBorde
                                    disabled
                                    placeholder="Precio Unitario con Impuestos"
                                    dineroDisabled
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "cantidad"]}
                                  fieldKey={[arrayKey, "cantidad"]}
                                  required
                                  number
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    placeholder="Cantidad"
                                    disabled
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "subtotal"]}
                                  fieldKey={[arrayKey, "subtotal"]}
                                  required
                                  number
                                  noStyle
                                  shouldUpdate
                                >
                                  <InputNumberItem
                                    sinBorde
                                    disabled
                                    placeholder="Subtotal"
                                    dineroDisabled
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  name={[name, "impuestos"]}
                                  required
                                  number
                                  noStyle
                                >
                                  <InputNumberItem
                                    sinBorde
                                    dineroDisabled
                                    placeholder="Impuestos"
                                    disabled
                                  />
                                </FormItem>
                              </td>
                              <td className="td-border">
                                <FormItem
                                  {...restField}
                                  name={[name, "total"]}
                                  fieldKey={[arrayKey, "total"]}
                                  required
                                  number
                                  noStyle
                                  shouldUpdate
                                >
                                  <InputNumberItem
                                    sinBorde
                                    dineroDisabled
                                    disabled
                                    placeholder="Total"
                                  />
                                </FormItem>
                              </td>
                            </tr>
                          </tbody>
                        )
                      )}
                    </table>
                  </>
                )}
              </FormInitial.List>
              <Row>
                <Col span={10}></Col>
                <Col span={14}>
                        <table className="table">
                          <tbody>
                          <tr style={{marginBottom: 10}}>
                              <th
                                  className="td-totales-center"
                                  style={{ width: "50%" }}
                                >
                                </th>
                                <td
                                  className="td-totales-center"
                                  style={{ width: "20%" }}
                                >
                                  Ajuste
                                </td>
                                <td
                                  className="td-totales-center"
                                  style={{ width: "30%" }}
                                >
                                </td>
                              </tr>
                            <tr>
                              <th
                                className="th-totales"
                                style={{ width: "50%" }}
                              >
                                Subtotal
                              </th>
                              <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                >
                                  <InputNumberItem
                                    sinBorde
                                    dineroDisabled
                                    disabled
                                    value={this.state.ajusteSubtotal}
                                  />
                                </td>
                              <td
                                className="td-totales-right"
                                style={{ width: "50%" }}
                              >
                                {dineroDisabledMask(this.state.subtotal)}
                              </td>
                            </tr>
                          </tbody>
                          {this.state.arrayImpuestos.map((valueArray) => (
                            <tbody>
                              <tr key={valueArray.descripcionFactura}>
                                <th className="th-totales">
                                  {valueArray.descripcionFactura}
                                </th>
                                <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                >
                                  <InputNumberItem
                                    sinBorde
                                    dineroDisabled
                                    disabled
                                    value={valueArray.ajusteImpuesto}
                                  />
                                </td>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(
                                    valueArray.totalImpuestos
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                          <tbody>
                            <tr>
                              <th className="th-totales">Total</th>
                              <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                ></td>
                              <td className="td-totales-right">
                                {dineroDisabledMask(this.state.total)}
                              </td>
                            </tr>
                          </tbody>
                          {this.state.arrayRetenciones.map((value) => (
                            <tbody>
                              <tr>
                                <th className="th-totales">
                                  {value.descripcionFactura}
                                </th>
                                <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                >
                                  <InputNumberItem
                                    sinBorde
                                    dineroDisabled
                                    disabled
                                    value={value.ajusteRetencion}
                                  />
                                </td>
                                <td className="td-totales-right">
                                  {dineroDisabledMask(value.totalRetenciones)}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                          <tbody>
                            <tr>
                              <th className="th-totales">Total a pagar</th>
                              <td
                                  className="td-totales-right"
                                  style={{ width: "20%" }}
                                ></td>
                              <td className="td-totales-right">
                                {dineroDisabledMask(this.state.totalPagar)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
        <PagosNotaDeCredito
          visible={this.state.modalPagos}
          id={this.state.id}
          cerrarPagosNotaDeCredito={this.cerrarPagosNotaDeCredito.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(VerNotaDeCredito);
