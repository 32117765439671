import { PageHeader, Space, Popconfirm, message, Menu, Dropdown } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import { TableItem, ButtonItem, SegmentedItem, } from "../../../Components/Items";
import { connect } from "react-redux";
import { StopOutlined, EditOutlined, CheckCircleOutlined, PlusOutlined, ImportOutlined, DownOutlined, ExportOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'

class Clientes extends Component {
  state = {
    todasClientes: [],
    loading: false,
    valor: 1,
  }
  componentDidMount() {
    this.consultarClientes(this.state.valor);
  }

  consultarClientes(valor) {
    this.setState({loading: true})
    axios
    .post(
      "clientes/selectTodosClientes",
      { empresaId: this.props.empresaId, valor: valor },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
        this.setState({ todasClientes: [] });
      } else {
        this.setState({
          todasClientes: res.data
        });
      }
    });
  }
  agregarCliente(){
    this.props.history.push('/panel/agregarCliente')
  }
  importarClientes(){
    this.props.history.push('/panel/importarClientes')
  }
  exportarClientes() {
    this.setState({ loading: true });
  
    axios
      .post(
        "clientes/exportarClientes", 
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });

        if (res.data.status === 404) {
          message.error("No se encontraron datos para exportar");
        } else {
          // Preparar datos de clientes
          const datosClientes = res.data.data.map(cliente => ({
            "Código": cliente.codigo,
            "Razón Social": cliente.razonSocial,
            "Nombre Persona Física": cliente.nombre,
            "Apellido Paterno": cliente.apellidoPaterno,
            "Apellido Materno": cliente.apellidoMaterno,
            "CURP": cliente.curp,
            "RFC": cliente.rfc,
            "Regimen Fiscal": cliente.regimenFiscalText,

            "Teléfono Empresa": cliente.telefono,
            "Correo Empresa": cliente.correo,
            "Uso de CFDI": cliente.usoDeCFDIText,
            "Dias de Crédito": cliente.diasCredito,
            "Limite de Crédito": cliente.limiteCredito,
            "Descuento Max": cliente.limiteDescuento,
            "Nombre Contacto": cliente.nombreContacto,
            "Teléfono Contacto": cliente.telefonoContacto,
            "Correo Contacto": cliente.correoContacto,
            "Página Web": cliente.paginaWeb,
            "Código Postal": cliente.cp,
            "Colonia": cliente.coloniaText,
            "Calle": cliente.calle,
            "Número": cliente.numeroCalle,
            "Número Int": cliente.numeroInterior,
            "Observaciones": cliente.observaciones,
          }));

          // Crear la hoja de Excel
          const ws = XLSX.utils.json_to_sheet(datosClientes);
          
          // Aplicar estilos al encabezado
          const encabezados = Object.keys(datosClientes[0]);  
          
          ws["!cols"] = encabezados.map(() => ({ wch: 20 })); // Ajustar ancho de columnas


          // Crear el libro de Excel
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Clientes");

          // Exportar archivo Excel
          XLSX.writeFile(wb, "clientes.xlsx");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        message.error("Ocurrió un error al generar el archivo");
      });
}

  editarCliente(id){
    this.props.history.push('/panel/editarCliente', {id})
  }
  deshabilitarCliente(value) {
    axios
      .post(
        "clientes/deshabilitarCliente",
        { clienteId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Cliente deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarClientes(this.state.valor);
      });
  }
  habilitarCliente(value) {
    axios
      .post(
        "clientes/habilitarCliente",
        { clienteId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Cliente habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarClientes(this.state.valor);
      });
  }
  handleMenuClick(e) {
    if(e.key === '1'){
      this.agregarCliente()
    }
    if(e.key === '2'){
      this.importarClientes()
    }
  }
  render() {
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
      },
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Nombre Comercial",
        dataIndex: "nombreComercial",
        key: "nombreComercial",
        sorter: (a, b) => {
          if (a.nombreComercial < b.nombreComercial) {
            return -1;
          }
          if (a.nombreComercial > b.nombreComercial) {
            return 1;
          }
          return 0;
        },
        buscar: "nombreComercial",
        ellipsis: true,
      },
      {
        title: "RFC",
        dataIndex: "rfc",
        key: "rfc",
        sorter: (a, b) => {
          if (a.rfc < b.rfc) {
            return -1;
          }
          if (a.rfc > b.rfc) {
            return 1;
          }
          return 0;
        },
        buscar: "rfc",
      },
      {
        title: "Tipo de Cliente",
        dataIndex: "tipoCliente",
        key: "tipoCliente",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarCliente &&
              <EditOutlined
                onClick={() => this.editarCliente(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarCliente && 
            <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este cliente"
                  onConfirm={() => this.deshabilitarCliente(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este cliente"
                  onConfirm={() => this.habilitarCliente(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
            }
          </Space>
        ),
      },
    ];
        const menu = (
          <Menu onClick={this.handleMenuClick.bind(this)}>
            <Menu.Item key="1" icon={<PlusOutlined />}>
              Agregar Cliente
            </Menu.Item>
            <Menu.Item key="2" icon={<ImportOutlined />}>
              Importar Clientes
            </Menu.Item>
            <Menu.Item key="3" icon={<ExportOutlined />} onClick={this.exportarClientes.bind(this)}>
              Exportar Clientes
            </Menu.Item>

          </Menu>
        );
    return (
      <>
        <PageHeader
          title="Clientes"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarCliente &&
            <>
            {window.innerWidth > 1000 &&
            <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarClientes(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
            <Dropdown overlay={menu} trigger={['click']}>
            <ButtonItem type='primary'>
          Agregar <DownOutlined />
        </ButtonItem>
          </Dropdown>
            </>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasClientes} columns={columns} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarCliente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd44bf30e81b54183cf88")?.activado,
    permisoEditarCliente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd47cf30e81b54183cf89")?.activado,
    permisoDeshabilitarCliente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce419f30e81b54183cfb1")?.activado,
  };
};

export default connect(mapStateToProps)(Clientes);
