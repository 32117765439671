import { message, PageHeader, Space, Tag, Select, notification } from "antd";
import axios from "axios";
import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import { ButtonItem, DatePickerItem, Form, FormItem, ModalItem, SelectItem, TableItem, dineroDisabledMask } from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import facturaGlobalPdf from "../../../../Pdf/facturaGlobalPdf";
import MandarCorreoFacturaGlobal from "../../Ventas/MandarCorreoFacturaGlobal/MandarCorreoFacturaGlobal";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select

moment.locale("es");

class FacturaGlobalConsignacion extends Component {
  state = {
    todasVentas: [],
    loading: false,
    modalCancelacion: false,
    modalCancelacionId: null,
    modalEnvios: false,
    modalEnviosId: null,
    objetoProyectos: null,
    todasProyectos: [],
    objetoMesesSat: null,
    todasMesesSat: [],
    objetoPeriocidadesSat: null,
    todasPeriocidadesSat: [],
    ventasSeleccionadas: [],
    subtotal: 0,
    total: 0,
    modalDatosFacturaGlobal: 0,
    filtrosProyectos: [],
    modalVerFacturaGlobal: false,
    modalVerFacturaGlobalId: null
  };
  componentDidMount() {
    this.consultarDatosFacturaGlobal();
  }

  consultarDatosFacturaGlobal() {
    this.setState({ loading: true });
    axios
      .post(
        "consignaciones/selectDatosFacturaGlobalConsignacion",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          let filtrosProyectos = res.data.proyectos.map(valueProyecto => {
            return {
              text: valueProyecto.nombre,
              value: valueProyecto._id.toString()
            }
          })
          this.setState({
            objetoProyectos: res.data.proyectos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoMesesSat: res.data.mesesSat.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave+ ' - ' +value.Descripcion}
                </Option>
              );
            }),
            objetoPeriocidadesSat: res.data.periocidadesSat.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave+ ' - ' +value.Descripcion}
                </Option>
              );
            }),
            todasVentas: res.data.ventas.map((value) => {
              let numero = ''
              if (value.remision) {
                numero += (value.remisionSerie ? value.remisionSerie : 'R') + ' ' + value.remisionNumero
              }
              if (value.factura) {
                if (numero !== '') {
                  numero += ' '
                }
                numero += (value.facturaSerie ? value.facturaSerie : 'F') + ' ' + value.facturaNumero
              }
              return {
                ...value,
                fechaVenta: value.fechaFactura
                  ? value.fechaFactura
                  : value.fechaRemision,
                numero: numero,
                moneda: value.moneda.abreviacion,
                cliente: (value.cliente.persona ? value.cliente.razonSocial : (value.cliente.nombre + ' ' + value.cliente.apellidoPaterno + ' ' + value.cliente.apellidoMaterno)) + " - " + value.cliente.rfc,
              };
            }),
            todasProyectos: res.data.proyectos,
            todasMesesSat: res.data.mesesSat,
            todasPeriocidadesSat: res.data.periocidadesSat,
            filtrosProyectos
          });
        } else {
          message.error('Hubo un error y no se cargaron los datos')
        }
      });
  }

  atras() {
    this.props.history.goBack();
  }

  onSelectChange = (ventasSeleccionadas) => {

    let total = 0
    let subtotal = 0
    let monedaId
    let diferenteMoneda = false
    for (const valueVentaId of ventasSeleccionadas) {
      const venta = this.state.todasVentas.find(valueVentaTT => valueVentaTT._id.toString() === valueVentaId.toString())
      if (!monedaId) {
        monedaId = venta.monedaId.toString()
        total += venta.totalPagar
        subtotal += venta.subtotal
      } else {

        if (monedaId !== venta.monedaId.toString()) {
          diferenteMoneda = true
        } else {

          total += venta.totalPagar
          subtotal += venta.subtotal
        }

      }
    }

    if (diferenteMoneda) {
      message.warning('Las ventas necesitan ser de la misma moneda')
      total = 0
      subtotal = 0
    }

    this.setState({ ventasSeleccionadas, total, subtotal });
    if (ventasSeleccionadas.length === 1) {
      message.success(`Se ha seleccionado ${ventasSeleccionadas.length} venta`)
    } else if (ventasSeleccionadas.length > 1) {
      message.success(`Se han seleccionado ${ventasSeleccionadas.length} ventas`)
    }
  }

  abrirDatosFacturaGlobal() {
    if (this.state.ventasSeleccionadas.length > 0) {
      let monedaId
      let diferenteMoneda = false
      let modalDatosFacturaGlobal = false
      for (const valueVentaId of this.state.ventasSeleccionadas) {
        const venta = this.state.todasVentas.find(valueVentaTT => valueVentaTT._id.toString() === valueVentaId.toString())
        if (!monedaId) {
          monedaId = venta.monedaId.toString()
          modalDatosFacturaGlobal = true
        } else {

          if (monedaId !== venta.monedaId.toString()) {
            diferenteMoneda = true
            modalDatosFacturaGlobal = false
          } else {
            modalDatosFacturaGlobal = true
          }

        }
      }
      if (diferenteMoneda) {
        message.warning('Las ventas necesitan ser de la misma moneda')
      } else {
        this.setState({ modalDatosFacturaGlobal })
      }
    } else {
      message.warning('Seleccione al menos una venta para generar la factura global')
    }

  }

  cerrarDatosFacturaGlobal() {
    this.setState({ modalDatosFacturaGlobal: false })
  }

  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1] || e.errorFields[index].name[1] === 0) {
        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "ventas/agregarFacturaGlobal",
        {
          ...values,
          ventasIds: this.state.ventasSeleccionadas,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha registrado una factura global");
          if (res.data.respuestaFactura.status === "success") {
            notification.success({
              description: "Haga click para descargar",
              message: "Factura Emitida",
              duration: 0,
              style: { cursor: "pointer" },
              onClick: () => {
                var xmltext = res.data.respuestaFactura.data.cfdi;

                const dataSat = res.data.respuestaFactura.dataSat
                const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'F') + ' - ' + dataSat.Folio

                var filename = nombreArchivo + ".xml";
                var pom = document.createElement("a");
                var bb = new Blob([xmltext], { type: "text/plain" });

                pom.setAttribute("href", window.URL.createObjectURL(bb));
                pom.setAttribute("download", filename);

                pom.dataset.downloadurl = [
                  "text/plain",
                  pom.download,
                  pom.href,
                ].join(":");
                pom.draggable = true;
                pom.classList.add("dragout");

                pom.click();

                const pdf = facturaGlobalPdf(res.data);

                pdfMake.createPdf(pdf).download(nombreArchivo + '.pdf');
              },
            });
            this.setState({
              modalVerFacturaGlobal: true,
              modalVerFacturaGlobalId: res.data.facturaGlobalId,
            });
          }
        } else if (res.data.success === 1) {
          message.error(res.data.message ? res.data.message : "No se pudo registrar la factura global");
          notification.warning({
            description: res.data.description,
            message: res.data.message,
            duration: 0,
          });
        } else {
          message.error("Hubo un error y la factura global no se registro");
        }
      });
  }

  cerrarMandarCorreoFacturaGlobal() {
    this.setState({
      modalVerFacturaGlobal: false,
      modalVerFacturaGlobalId: null,
    });
    this.atras();
  }

  render() {
    const columns = [
      {
        title: "R",
        dataIndex: "numero",
        key: "numero",
        buscar: 'numero',
        width: "7%",
      },
      {
        title: "Proyecto",
        dataIndex: "proyecto",
        key: "proyecto",
        filters: this.state.filtrosProyectos,
        sorter: (a, b) => {
          if (a.proyecto.nombre < b.proyecto.nombre) {
            return -1;
          }
          if (a.proyecto.nombre > b.proyecto.nombre) {
            return 1;
          }
          return 0;
        },
        render: (value) => value.nombre,
        onFilter: (value, record) => record.proyectoId.toString().indexOf(value) === 0,
        width: "16%",
        ellipsis: true,
      },
      {
        title: "Cliente",
        dataIndex: "cliente",
        key: "cliente",
        sorter: (a, b) => {
          if (a.cliente < b.cliente) {
            return -1;
          }
          if (a.cliente > b.cliente) {
            return 1;
          }
          return 0;
        },
        width: "16%",
        buscar: 'cliente',
        ellipsis: true,
      },
      {
        title: "Moneda",
        dataIndex: "moneda",
        key: "moneda",
        sorter: (a, b) => {
          if (a.moneda < b.moneda) {
            return -1;
          }
          if (a.moneda > b.moneda) {
            return 1;
          }
          return 0;
        },
        width: "16%",
        buscar: 'moneda',
        ellipsis: true,
      },
      {
        title: "Fecha",
        dataIndex: "fechaVenta",
        key: "fechaVenta",
        sorter: (a, b) => {
          if (moment(a.fechaVenta) < moment(b.fechaVenta)) {
            return -1;
          }
          if (moment(a.fechaVenta) > moment(b.fechaVenta)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale("es").format("ll"),
        wdith: "13%",
        ellipsis: true,
        align: "center",
      },
      {
        title: "Subtotal",
        dataIndex: "subtotal",
        key: "subtotal",
        sorter: (a, b) => {
          if (a.subtotal < b.subtotal) {
            return -1;
          }
          if (a.subtotal > b.subtotal) {
            return 1;
          }
          return 0;
        },
        render: (value) => dineroDisabledMask(value),
        align: "right",
        buscar: "subtotal",
        width: "8%",
        ellipsis: true,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        sorter: (a, b) => {
          if (a.total < b.total) {
            return -1;
          }
          if (a.total > b.total) {
            return 1;
          }
          return 0;
        },
        render: (value) => dineroDisabledMask(value),
        buscar: "total",
        width: "8%",
        ellipsis: true,
        align: "right",
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (value) => {
          if (value === 0) {
            return <Tag color="red">Cancelada</Tag>;
          }
          if (value === 1) {
            return <Tag color="blue">Sin entregar</Tag>;
          }
          if (value === 2) {
            return <Tag color="orange">Entregado parcialmente</Tag>;
          }
          if (value === 3) {
            return <Tag color="green">Entregado</Tag>;
          }
        },
        width: "10%",
        ellipsis: true,
      },
    ];
    return (
      <>
        <PageHeader
          title={`Factura Global ${(this.state.subtotal !== 0 && this.state.total !== 0) ? (' - Subtotal: ' + dineroDisabledMask(this.state.subtotal) + '  Total: ' + dineroDisabledMask(this.state.total)) : ''}`}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="abrirDatosFacturaGlobal"
              onClick={this.abrirDatosFacturaGlobal.bind(this)}
            >
              Datos Factura Global
            </ButtonItem>
          }
        />
        <TableItem
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.props.history.push("/panel/verVenta", { id: record._id });
              },
            };
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasVentas}
          rowSelection={{
            selectedRowKeys: this.state.ventasSeleccionadas,
            onChange: this.onSelectChange.bind(this),
          }}
          columns={columns}
          pagination={false}
        />
        <ModalItem
          title="Datos Factura Global"
          visible={this.state.modalDatosFacturaGlobal}
          spinning={this.state.loading}
          onCancel={this.cerrarDatosFacturaGlobal.bind(this)}
          paddingTop
        >
          <Form
            ref={this.formRef}
            name="datosfacturaGlobal"
            layout
            width={700}
            onCancel={this.cerrarDatosFacturaGlobal.bind(this)}
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
            <FormItem
              label="Proyecto"
              name="proyectoId"
              margin
              required
            >
              <SelectItem
                placeholder="Proyecto"
              >
                {this.state.objetoProyectos}
              </SelectItem>
            </FormItem>
            <FormItem
              label="Periocidad"
              name="periocidadSatId"
              margin
              required
            >
              <SelectItem
                placeholder="Periocidad"
              >
                {this.state.objetoPeriocidadesSat}
              </SelectItem>
            </FormItem>
            <FormItem
              label="Año Periocidad"
              name="anoPeriocidad"
              required
              margin
              initialValue={moment()}
            >
              <DatePickerItem
                dateFormat='YYYY'
                placeholder="Año Periocidad"
                picker="year"
                allowClear={false}
              />
            </FormItem>
            <FormItem
              label="Mes"
              name="mesSatId"
              margin
              required
            >
              <SelectItem
                placeholder="Mes"
              >
                {this.state.objetoMesesSat}
              </SelectItem>
            </FormItem>
            <div style={{ display: "flex", justifyContent: "right", marginTop: 20, marginBottom: 10 }}>
              <Space>
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Agregar Factura Global
                </ButtonItem>
                <ButtonItem
                  type="primary"
                  danger
                  onClick={this.cerrarDatosFacturaGlobal.bind(this)}
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Cancelar
                </ButtonItem>
              </Space>
            </div>
          </Form>
        </ModalItem>
        <MandarCorreoFacturaGlobal
          visible={this.state.modalVerFacturaGlobal}
          id={this.state.modalVerFacturaGlobalId}
          history={this.props.history}
          cerrarMandarCorreoFacturaGlobal={this.cerrarMandarCorreoFacturaGlobal.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    certificada: state.empresa.certificada,
    envios: state.empresa.envios,
    permisoAgregarVenta:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ccb6bf30e81b54183cf70"
      )?.activado,
    permisoCancelarVenta:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472d37f4b2da7c85c1a8cff"
      )?.activado,
    permisoEntregarInsumosVenta:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615ccbaff30e81b54183cf71"
      )?.activado,
  };
};

export default connect(mapStateToProps)(FacturaGlobalConsignacion);
