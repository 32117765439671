import { PageHeader,  Space, Popconfirm, message } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import { TableItem, ButtonItem, SegmentedItem} from '../../../Components/Items'
import { connect } from "react-redux";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";


class Retenciones extends Component {
  state = {
    todasRetencion: [],
    loading: false,
    valor: 1,
  }
  componentDidMount() {
    this.consultarRetenciones(this.state.valor);
  }

  consultarRetenciones(valor) {
    this.setState({loading: true})
    axios
    .post(
      "retenciones/selectTodasRetenciones",
      { empresaId: this.props.empresaId, valor: valor, },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
        this.setState({ todasRetencion: [] });
      } else {
        this.setState({
          todasRetencion: res.data.map(value => {
            return {...value, accion: { _id: value._id, estado: value.estado }}
          }),
        });
      }
    });
  }
  agregarRetencion(){
    this.props.history.push('/panel/agregarRetencion')
  }
  editarRetencion(id){
    this.props.history.push('/panel/editarRetencion', {id})
  }
  deshabilitarRetencion(value) {
    axios
      .post(
        "retenciones/deshabilitarRetencion",
        { retencionId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Retencion deshabilitada");
        } else {
          message.error("Sucedio un error y no se pudo deshabilitar");
        }
        this.consultarRetenciones(this.state.valor);
      });
  }
  habilitarRetencion(value) {
    axios
      .post(
        "retenciones/habilitarRetencion",
        { retencionId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Retencion habilitada");
        } else {
          message.error("Sucedio un error y no se pudo habilitar");
        }
        this.consultarRetenciones(this.state.valor);
      });
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {value._id !== '613fef2a7ffb183b304924dc' ? 
            <>
              {this.props.permisoEditarRetencion &&
                <EditOutlined
                  onClick={() => this.editarRetencion(value._id)}
                  style={{ color: "blue" }}
                />
              }
              {this.props.permisoDeshabilitarRetencion &&
              <>
                {value.estado === 1 ? (
                  <Popconfirm
                    title="Seguro que quieres deshabilitar esta retencion"
                    onConfirm={() => this.deshabilitarRetencion(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Seguro que quieres habilitar esta retencion"
                    onConfirm={() => this.habilitarRetencion(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
              }
              </>
            : null}
          </Space>
        ),
      },
    ]
    return (
      <>
        <PageHeader
          title="Retenciones"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
            <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarRetenciones(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
            {this.props.permisoAgregarRetencion &&
            <ButtonItem
              type="primary"
              key="agregarRetencion"
              onClick={this.agregarRetencion.bind(this)}
            >
              Agregar Retencion
            </ButtonItem>}
            </>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasRetencion} columns={columns} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarRetencion: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cdedaf30e81b54183cfa9")?.activado,
    permisoEditarRetencion: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cdf00f30e81b54183cfaa")?.activado,
    permisoDeshabilitarRetencion: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce847f30e81b54183cfb9")?.activado,
  };
};

export default connect(mapStateToProps)(Retenciones);