import React, { Component } from "react";
import { Layout, Menu, message, notification } from "antd";
import { ClusterOutlined, CreditCardOutlined, LogoutOutlined, MenuOutlined, SettingFilled, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { logout } from "../../store/actions/user";
import { Switch, Route, NavLink } from "react-router-dom";
import logoHorizontal from "../../assets/Biwo_Horizontal.png";
import Empresas from "./Empresas/Empresas";
import Perfiles from "./Perfiles/Perfiles";
import Usuarios from "./Usuarios/Usuarios";
import Facturacion from "./Facturacion/Facturacion";
import ScrollToTopItem from "../../Components/ScrollToTopItem";
import Configuracion from "./Configuracion/Configuracion";
import { Drawer } from "antd5";

const { Header, Footer, Content, Sider } = Layout;
const { SubMenu } = Menu;

class PanelPrincipal extends Component {
  state = {
    loading: false,
    collapsed: true,
    openKeys: [],
    isMobile: window.innerWidth <= 1000,
  };

  componentDidMount() {
    if (this.props.history.location.pathname === '/panelPrincipal') {
      this.props.history.push(this.props.match.url + "/empresas");
    }
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  logout() {
    this.props.logout().then((response) => {
      if (response.success === 1) {
        this.props.history.push("/login");
        notification.info({
          message: 'Se ha cerrado la sesion'
        })
      } else {
        message.warning(response.err);
      }
    });
  }

  onOpenChange(keys) {
    const latestOpenKey = keys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] });
  }

  toggleDrawer = () => {
    this.setState((prevState) => ({ drawerVisible: !prevState.drawerVisible }));
  };

  render() {
    const current = this.props.history.location.pathname;

    const menuItems = (
      <>
        <Menu.Item
          key={this.props.match.url + "/empresas"}
          icon={<ClusterOutlined />}
        >
          <NavLink to={this.props.match.url + "/empresas"}>
            Empresas
          </NavLink>
        </Menu.Item>
        {this.props.tipo === 0 ?
          <>
            <Menu.Item
              key={this.props.match.url + "/perfiles"}
              icon={<UserOutlined />}
            >
              <NavLink to={this.props.match.url + "/perfiles"}>
                Perfiles
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={this.props.match.url + "/usuarios"}
              icon={<TeamOutlined />}
            >
              <NavLink to={this.props.match.url + "/usuarios"}>
                Usuarios
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={this.props.match.url + "/facturacion"}
              icon={<CreditCardOutlined />}
            >
              <NavLink to={this.props.match.url + "/facturacion"}>
                Facturacion
              </NavLink>
            </Menu.Item>
          </>
          : null}
        <Menu.Item
          key={this.props.match.url + "/configuracion"}
          icon={<SettingFilled />}
        >
          <NavLink to={this.props.match.url + "/configuracion"}>
            Configuracion
          </NavLink>
        </Menu.Item>
      </>
    )

    return (
      <Layout>
        <Header id="header-fixed">
          <Menu
            mode="horizontal"
            selectedKeys={current}
            style={{ height: "100%" }}
            id="menu-centered"
          >
            {this.state.isMobile &&
              <Menu.Item
                key={6}
                icon={<LogoutOutlined style={{ fontSize: 20 }} />}
                onClick={this.logout.bind(this)}
              />
            }
            <Menu.Item key={"/login"} id="menuitem-izquierda">
              <img src={logoHorizontal} alt="Logo" id="img-logo" />
            </Menu.Item>
            {this.state.isMobile ?
              <Menu.Item
                key={0}
                icon={<MenuOutlined style={{ fontSize: 20 }} />}
                onClick={this.toggleDrawer.bind(this)}
              />
              :
              <SubMenu
                key="1"
                id="menuitem"
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
                icon={<LogoutOutlined />}
              >
                <Menu.Item key="3" onClick={this.logout.bind(this)}>
                  Cerrar Sesion
                </Menu.Item>
              </SubMenu>
            }
          </Menu>
        </Header>
        <Layout>
          {this.state.isMobile ?
            <Drawer
              title="Menu"
              placement="left"
              onClose={this.toggleDrawer}
              open={this.state.drawerVisible}
              bodyStyle={{ padding: 0 }}
              width={300}
            >
              <Menu
                mode="inline"
                theme="light"
                openKeys={this.state.openKeys}
                selectedKeys={current}
                onOpenChange={this.onOpenChange.bind(this)}
              >
                {menuItems}
                <Menu.Item icon={<LogoutOutlined />} style={{ color: 'red' }} key="3" onClick={this.logout.bind(this)}>
                  Cerrar Sesion
                </Menu.Item>
              </Menu>
            </Drawer>
            :
            <Sider
              collapsible={window.innerWidth > 1000 ? true : false}
              collapsed={window.innerWidth > 1000 ? this.state.collapsed : true}
              onCollapse={window.innerWidth > 1000 ? this.onCollapse : () => { }}
              style={{ marginTop: "64px", minHeight: window.innerHeight - 64 }}
            >
              <Menu
                selectedKeys={current}
                mode="inline"
                theme="dark"
                defaultSelectedKeys={[this.props.match.url + "/empresas"]}
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange.bind(this)}
              >
                {menuItems}
              </Menu>
            </Sider>
          }
          <Layout>
            <Content id="content">
              <ScrollToTopItem />
              <Switch>
                <Route
                  path={this.props.match.url + "/empresas"}
                  component={Empresas}
                />
                <Route
                  path={this.props.match.url + "/perfiles"}
                  component={Perfiles}
                />
                <Route
                  path={this.props.match.url + "/usuarios"}
                  component={Usuarios}
                />
                <Route
                  path={this.props.match.url + "/facturacion"}
                  component={Facturacion}
                />
                <Route
                  path={this.props.match.url + "/configuracion"}
                  component={Configuracion}
                />
              </Switch>
            </Content>
            <Footer id="content-background" style={{ textAlign: "center" }}>
              Biwo ©2020
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioId: state.user.usuarioId,
    tipo: state.user.tipo,
    empresaId: state.empresa.id,
    rfc: state.empresa.rfc,
    nombre: state.empresa.nombre,
    logo: state.empresa.logo,
  };
};

export default connect(mapStateToProps, { logout })(PanelPrincipal);
