import { message, Select, Checkbox, Col, Row, Form as FormInitial, Tabs, Upload, } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FormItem, ModalItem, ButtonItem, InputItem, SelectItem, cuentaMask,
  BoxItem, HeaderItem, Form,
  ErrorItem,
  extraerDatosConstanciaFiscal,
  calcularSimilitud
} from "../../../../../Components/Items";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TabPane } = Tabs;

class AgregarCliente extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    tipoPersona: [],
    todasUsosDeCFDI: null,
    todasTipoDeCliente: null,
    todasCuentaContable: null,
    todasPaises: null,
    todasRegimenFiscal: null,
    todasVendedores: null,
    objetoRegimenFiscal: [],
    objetoUsosDeCFDI: [],
    objetoTipoDeCliente: [],
    objetoPaises: [],
    objetoCuentaContable: [],
    objetoVendedores: [],
    objetoColonias: [],
    objetoMunicipios: [],
    objetoEstados: [],
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.consultarTodo();
      this.setState({ visible: this.props.visible });
    }
  }

  async consultarTodo() {
    await this.setState({ loading: true });
    axios.post("clientes/selectDatosParaCliente", { empresaId: this.props.empresaId }, { headers: this.props.headersToken })
      .then((res) => {
        if (res.data.status !== 404) {
          this.setState({
            loading: false,
            todasUsosDeCFDI: res.data.usosDeCFDI,
            todasTipoDeCliente: res.data.tipoDeClientes,
            todasCuentaContable: res.data.cuentasContables,
            todasPaises: res.data.paises,
            todasRegimenFiscal: res.data.regimenesFiscales,
            todasVendedores: res.data.vendedores,
            objetoPaises: res.data.paises.map((value) => (
              <Option value={value._id} key={value._id}>{value.nombre + " - " + value.abreviatura}</Option>
            )),
            objetoEstados: res.data.estados.map((value) => (
              <Option value={value._id} key={value._id}>{value.nombre + " - " + value.abreviatura}</Option>
            )),
            objetoCuentaContable: res.data.cuentasContables.map((value) => (
              <Option value={value._id} key={value._id}>
                {cuentaMask(value.nivel1 + value.nivel2 + value.nivel3 + value.nivel4 + value.nivel5) + " - " + value.descripcion}
              </Option>
            )),
            objetoTipoDeCliente: res.data.tipoDeClientes.map((value) => (
              <Option value={value._id} key={value._id}>{value.nombre + " - " + value.descripcion}</Option>
            )),
            objetoUsosDeCFDI: res.data.usosDeCFDI.map((value) => (
              <Option value={value._id} key={value._id}>{value.descripcion}</Option>
            )),
            objetoRegimenFiscal: res.data.regimenesFiscales.map((value) => (
              <Option value={value._id} key={value._id}>{value.c_RegimenFiscal + " - " + value.descripcion}</Option>
            )),
            objetoVendedores: res.data.vendedores.map((value) => (
              <Option value={value._id} key={value._id}>
                {value.nombre + " " + value.apellidoPaterno + " " + value.apellidoMaterno}
              </Option>
            )),
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error("Error en la consulta:", error);
        this.setState({ loading: false });
      });
  }

  onChangeEstado(value, fieldKey, inicial) {
    if (!inicial) {
      let direcciones = this.formRef.current.getFieldValue("direcciones");
      if (typeof direcciones[fieldKey] === 'undefined') {
        direcciones[fieldKey] = {}
      }
      direcciones[fieldKey].municipioId = undefined;
      direcciones[fieldKey].coloniaId = undefined;
      this.formRef.current.setFieldsValue({ direcciones: direcciones });
      let objetoColonias = this.state.objetoColonias;
      objetoColonias[fieldKey] = null;
      this.setState({ objetoColonias: objetoColonias });
    }
    axios
      .post(
        "municipios/selectMunicipios",
        { estadoId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMunicipios: res.data });
          let objetoMunicipios = this.state.objetoMunicipios;
          objetoMunicipios[fieldKey] = res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre}
              </Option>
            );
          });
          this.setState({
            objetoMunicipios: objetoMunicipios,
          });
        }
      });
  }

  async onChangeRfc(e) {
    this.formRef.current.validateFields(['rfc']).then(() => {
      if (e.target.value) {
        if (e.target.value.length === 13) {
          //Persona Fisica
          this.formRef.current.setFieldsValue({ persona: false })
        } else if (e.target.value.length === 12) {
          //Persona Moral
          this.formRef.current.setFieldsValue({ persona: true })
        }
      }
    }).catch((err) => undefined)
  }

  onChangeMunicipio(value, fieldKey, inicial) {
    if (!inicial) {
      let direcciones = this.formRef.current.getFieldValue("direcciones");
      if (typeof direcciones[fieldKey] === 'undefined') {
        direcciones[fieldKey] = {}
      }
      direcciones[fieldKey].coloniaId = undefined;
      this.formRef.current.setFieldsValue({ direcciones: direcciones });
    }
    axios
      .post(
        "colonias/selectColonias",
        { municipioId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasColonias: res.data });
          let objetoColonias = this.state.objetoColonias;
          objetoColonias[fieldKey] = res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.cp + " - " + value.nombre}
              </Option>
            );
          });
          this.setState({
            objetoColonias: objetoColonias,
          });
        }
      });
  }


  async onSearchColonia(value, fieldKey, ponerColoniaId) {
    if (value.toString().length === 5) {
      await axios
        .post(
          "colonias/buscarCP",
          { cp: value },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
            message.info("No se encontro el codigo postal");
          } else {
            let direcciones = this.formRef.current.getFieldValue("direcciones");
            if (typeof direcciones[fieldKey] === 'undefined') {
              direcciones[fieldKey] = {}
            }
            if (ponerColoniaId && res.data._id) {
              direcciones[fieldKey].coloniaId = res.data._id
            }
            direcciones[fieldKey].paisId = res.data.paisId
            direcciones[fieldKey].estadoId = res.data.estadoId
            direcciones[fieldKey].municipioId = res.data.municipioId
            this.formRef.current.setFieldsValue({
              direcciones: direcciones,
            });
            this.onChangeEstado(res.data.estadoId, fieldKey, true);
            this.onChangeMunicipio(res.data.municipioId, fieldKey, true);
          }
        });
    }
  }
  async onSelectColoniaId(coloniaId, fieldKey) {
    const direcciones = this.formRef.current.getFieldValue('direcciones')
    if (typeof direcciones[fieldKey] === 'undefined') {
      direcciones[fieldKey] = {}
    }
    if (!direcciones[fieldKey].colonia) {
      const nombreColonia = this.state.todasColonias.find((todasColonia) => todasColonia._id.toString() === coloniaId.toString())?.nombre
      direcciones[fieldKey].colonia = nombreColonia
      await this.formRef.current.setFieldsValue({
        direcciones: direcciones
      })
    }
  }

  onFinishFailed = (errorInfo) => {
    ErrorItem(errorInfo)
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    axios.post("clientes/agregarCliente", { ...values, empresaId: this.props.empresaId }, { headers: this.props.headersToken })
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha registrado un cliente");
          this.formRef.current.resetFields();
          this.cerrarModal(res.data.codigo);
        } else if (res.data.success === 1) {
          message.warning(res.data.message ? res.data.message : "No se puede registrar el cliente");
        } else {
          message.error("Hubo un error y el cliente no se registró");
        }
      });
  };

  cerrarModal = (id) => {
    this.setState({ visible: false });
    this.props.cerrarModal(id);
    this.formRef.current.resetFields();
  };

  async onChangeArchivo(e) {
    this.setState({ loading: true });
    if (e.file.type === "application/pdf") {
      const datosConstancia = await extraerDatosConstanciaFiscal(e.file)
      if (datosConstancia.persona === 1) {


        if (datosConstancia.rfc && datosConstancia.nombre && datosConstancia.apellidoPaterno && datosConstancia.codigoPostal) {
          const findRegimen = this.state.todasRegimenFiscal.find(valueTRe => calcularSimilitud(valueTRe.descripcion, datosConstancia.regimenFiscal) > 65)
          this.onSearchColonia(datosConstancia.codigoPostal, 0, true)
          this.formRef.current.setFieldsValue({
            rfc: datosConstancia.rfc,
            curp: datosConstancia.curp,
            nombre: datosConstancia.nombre,
            regimenFiscalId: findRegimen ? findRegimen._id : undefined,
            apellidoPaterno: datosConstancia.apellidoPaterno,
            apellidoMaterno: datosConstancia.apellidoMaterno,
            nombreComercial: datosConstancia.nombreComercial,

            direcciones: [
              {
                calle: datosConstancia.calle,
                numeroCalle: datosConstancia.numeroExterior,
                numeroInterior: datosConstancia.numeroInterior,
                colonia: datosConstancia.colonia,
                codigoPostal: datosConstancia.codigoPostal,
              }
            ],
            persona: false
          })

        } else {
          message.error('No se pudo leer bien el archivo')
        }

      } else if (datosConstancia.persona === 2) {

        if (datosConstancia.rfc && datosConstancia.razonSocial && datosConstancia.codigoPostal) {
          this.onSearchColonia(datosConstancia.codigoPostal, 0, true)
          const findRegimen = this.state.todasRegimenFiscal.find(valueTRe => calcularSimilitud(valueTRe.descripcion, datosConstancia.regimenFiscal) > 65)
          this.formRef.current.setFieldsValue({
            razonSocial: datosConstancia.razonSocial,
            rfc: datosConstancia.rfc,
            regimenFiscalId: findRegimen ? findRegimen._id : undefined,
            curp: datosConstancia.curp,
            nombreComercial: datosConstancia.nombreComercial,

            direcciones: [
              {
                calle: datosConstancia.calle,
                numeroCalle: datosConstancia.numeroExterior,
                numeroInterior: datosConstancia.numeroInterior,
                colonia: datosConstancia.colonia,
                codigoPostal: datosConstancia.codigoPostal,
              }
            ],
            persona: true
          })
        } else {
          message.error('No se pudo leer bien el archivo')
        }

      } else {
        message.error('No se pudo leer bien el archivo')
      }
    } else {
      message.error("El archivo debe ser un archivo pdf");
    }
    this.setState({ loading: false });
  }

  onChangeTipoDeCliente(value) {
    const tipoDeCliente = this.state.todasTipoDeCliente.find(
      (valueTodos) => valueTodos._id.toString() === value.toString()
    );
    if (tipoDeCliente.generarCuentasAutomatico) {
      this.formRef.current.setFieldsValue({
        cuentaClienteId: "Se generara al guardarlo",
        cuentaAnticipoId: "Se generara al guardarlo",
        generarCuentaAnticipoId: false,
        cuentaFonGarId: "Se generara al guardarlo",
        generarCuentaFonGarId: false,
        cuentaDeudorId: "Se generara al guardarlo",
        generarCuentaDeudorId: false,
      });
    } else {
      this.formRef.current.setFieldsValue({
        cuentaClienteId: tipoDeCliente.cuentaClienteId,
        cuentaAnticipoId: tipoDeCliente.cuentaAnticipoId,
        generarCuentaAnticipoId: false,
        cuentaFonGarId: tipoDeCliente.cuentaFonGarId,
        generarCuentaFonGarId: false,
        cuentaDeudorId: tipoDeCliente.cuentaDeudorId,
        generarCuentaDeudorId: false,
      });
    }
  }


  onChangeRegimenFiscal(regimenFiscalId) {
    const retencionAgregar = this.state.todasRetenciones.find(todasRetenciones => todasRetenciones.regimenFiscalId.toString() === regimenFiscalId.toString())
    const retenciones = this.formRef.current.getFieldValue('retenciones')
    if (retencionAgregar) {
      if (retenciones) {
        const findRetencion = retenciones.find(todasRetencionesProv => todasRetencionesProv.retencionId.toString() === retencionAgregar._id.toString())
        if (!findRetencion) {
          this.formRef.current.setFieldsValue({
            retenciones: [...retenciones, { retencionId: retencionAgregar._id }]
          })
        }
      } else {
        this.formRef.current.setFieldsValue({
          retenciones: [{ retencionId: retencionAgregar._id }]
        })
      }
    }
  }

  render() {
    return (
      <ModalItem
        title="Agregar Cliente"
        visible={this.state.visible}
        spinning={this.state.loading}
        onCancel={() => this.cerrarModal()}
        footer={null}
        width={800}
      >
        <Form
          ref={this.formRef}
          name="modalAgregarCliente"
          layout
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <Tabs defaultActiveKey="1" tabBarExtraContent={
            <Upload
              showUploadList={{
                showRemoveIcon: false,
                showPreviewIcon: false,
              }}
              fileList={[]}
              customRequest={this.onChangeArchivo.bind(this)}
              accept=".pdf"
            >
              <ButtonItem blanco icon={<UploadOutlined />}>Leer Constancia</ButtonItem>
            </Upload>
          }>
            <TabPane tab="General" key="1">

              <FormItem
                name="persona"
                label="P. Moral"
                valuePropName="checked"
                initialValue={false}
                margin
              >
                <Checkbox />
              </FormItem>

              <FormItem
                label="RFC"
                name="rfc"
                required
                margin
                rfc
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <InputItem onChange={this.onChangeRfc.bind(this)} placeholder="RFC" />
              </FormItem>


              <FormItem shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  if (getFieldValue("persona")) {
                    return (
                      <FormItem
                        label="Razon Social"
                        margin
                        pattern
                        required
                        max={200}
                        name="razonSocial"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                      >
                        <InputItem placeholder="Razón Social" />
                      </FormItem>
                    );
                  }
                }}
              </FormItem>

              <Row>
                <Col span={12}>
                  <FormItem shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      if (!getFieldValue("persona")) {
                        return (
                          <FormItem
                            label="Nombre"
                            margin
                            pattern
                            required
                            name="nombre"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                          >
                            <InputItem
                              placeholder="Nombre persona física"
                              width="98%"
                            />
                          </FormItem>
                        );
                      }
                    }}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      if (!getFieldValue("persona")) {
                        return (
                          <FormItem
                            margin
                            pattern
                            name="apellidoPaterno"
                            labelCol={{ span: 0 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <InputItem
                              placeholder="Apelido Paterno"
                              width="98%"
                            />
                          </FormItem>
                        );
                      }
                    }}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      if (!getFieldValue("persona")) {
                        return (
                          <FormItem
                            margin
                            pattern
                            name="apellidoMaterno"
                            labelCol={{ span: 0 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <InputItem placeholder="Apellido Materno" />
                          </FormItem>
                        );
                      }
                    }}
                  </FormItem>
                </Col>
              </Row>



              <FormItem
                label="Nombre Comercial"
                margin
                pattern
                max={200}
                name="nombreComercial"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <InputItem placeholder="Nombre Comercial" />
              </FormItem>
              <FormItem
                label="Tipo Cliente"
                margin
                name="tipoClienteId"
                required
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <SelectItem
                  //width='120%'
                  placeholder="Tipo de Cliente"
                  onChange={this.onChangeTipoDeCliente.bind(this)}

                >
                  {this.state.objetoTipoDeCliente}
                </SelectItem>
              </FormItem>
              <FormItem
                name="vendedorId"
                fieldKey="vendedorId"
                label="Vendedor"
                margin
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <SelectItem placeholder="Vendedor">
                  {this.state.objetoVendedores}
                </SelectItem>
              </FormItem>
              <FormItem
                name="regimenFiscalId"
                label="R. Fiscal"
                margin
                tooltip={{ title: "Campo necesario para facturación" }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <SelectItem onChange={this.onChangeRegimenFiscal.bind(this)} placeholder="Regimen Fiscal">
                  {this.state.objetoRegimenFiscal}
                </SelectItem>
              </FormItem>
              <FormItem
                name="usoDeCFDIID"
                label="Uso de CFDI"
                margin
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <SelectItem placeholder="Uso de CFDI">
                  {this.state.objetoUsosDeCFDI}
                </SelectItem>
              </FormItem>
              <FormItem
                label="Correo"
                name="correo"
                pattern
                max={200}
                email
                margin
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <InputItem placeholder="Correo" />
              </FormItem>
            </TabPane>

            <TabPane tab="Direcciones" key="2">
              <FormInitial.List name="direcciones">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                      <>
                        {arrayKey === 0 && <HeaderItem>DIRECCION DE FACTURACION</HeaderItem>}
                        {arrayKey === 0 && <p style={{ textAlign: 'center' }}>Campos necesarios para facturación</p>}
                        <Row>
                          <Col span={24}>
                            <HeaderItem>DIRECCION</HeaderItem>
                            <BoxItem>
                              <Row>
                                <Col span={16}>
                                  <FormItem
                                    {...restField}
                                    name={[name, "calle"]}
                                    fieldKey={[fieldKey, "calle"]}
                                    label="Dirección"
                                    max={200}
                                    margin
                                    pattern
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 21 }}
                                  >
                                    <InputItem
                                      placeholder="Calle"
                                      width="98%"
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={4}>
                                  <FormItem
                                    {...restField}
                                    name={[name, "numeroCalle"]}
                                    fieldKey={[fieldKey, "numeroCalle"]}
                                    margin
                                    number
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <InputItem
                                      placeholder="Número"
                                      numeroCalle
                                      width="98%"
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={4}>
                                  <FormItem
                                    name={[name, "numeroInterior"]}
                                    fieldKey={[fieldKey, "numeroInterior"]}
                                    margin
                                    pattern
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <InputItem
                                      placeholder="Interior"
                                      width="98%"
                                    />
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={8}>
                                  <FormItem
                                    {...restField}
                                    name={[name, "coloniaId"]}
                                    fieldKey={[fieldKey, "coloniaId"]}
                                    label="C.P."
                                    margin
                                    required={arrayKey === 0}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                  >
                                    <SelectItem placeholder="Código Postal"
                                      onSearch={(coloniaId) => {
                                        this.onSearchColonia(coloniaId, arrayKey)
                                      }}
                                      onSelect={(coloniaId) => {
                                        this.onSelectColoniaId(coloniaId, arrayKey)
                                      }}
                                    >
                                      {!this.state.objetoColonias[
                                        fieldKey
                                      ] ? (
                                        <Option disabled>
                                          Eliga primero un municipio
                                        </Option>
                                      ) : (
                                        this.state.objetoColonias[fieldKey]
                                      )}
                                      <Option disabled>Busque por codigo postal</Option>
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                                <Col span={16}>
                                  <FormItem
                                    {...restField}
                                    name={[name, "colonia"]}
                                    fieldKey={[fieldKey, "colonia"]}
                                    label="Colonia"
                                    margin
                                    pattern
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 21 }}
                                  >
                                    <InputItem placeholder="Colonia" />
                                  </FormItem>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={8}>
                                  <FormItem
                                    {...restField}
                                    name={[name, "municipioId"]}
                                    fieldKey={[fieldKey, "municipioId"]}
                                    margin
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <SelectItem
                                      placeholder="Municipio"
                                      width="98%"
                                      onChange={(e) =>
                                        this.onChangeMunicipio(e, fieldKey)
                                      }
                                    >
                                      {!this.state.objetoMunicipios[
                                        fieldKey
                                      ] ? (
                                        <Option disabled>
                                          Eliga primero un estado
                                        </Option>
                                      ) : (
                                        this.state.objetoMunicipios[
                                        fieldKey
                                        ]
                                      )}
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                                <Col span={8}>
                                  <FormItem
                                    {...restField}
                                    name={[name, "estadoId"]}
                                    fieldKey={[fieldKey, "estadoId"]}
                                    margin
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <SelectItem
                                      placeholder="Estado"
                                      width="98%"
                                      onChange={(e) =>
                                        this.onChangeEstado(e, fieldKey)
                                      }
                                    >
                                      {this.state.objetoEstados}
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                                <Col span={8}>
                                  <FormItem
                                    {...restField}
                                    name={[name, "paisId"]}
                                    fieldKey={[fieldKey, "paisId"]}
                                    margin
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                  >
                                    <SelectItem
                                      placeholder="Pais"
                                      width="98%"
                                    >
                                      {this.state.objetoPaises}
                                    </SelectItem>
                                  </FormItem>
                                </Col>
                              </Row>
                            </BoxItem>
                            <FormItem noStyle>
                              <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                                <ButtonItem
                                  type="primary"
                                  danger
                                  onClick={() => remove(name)}
                                // block
                                // icon={<MinusCircleOutlined />}
                                >
                                  Eliminar Dirección
                                </ButtonItem>
                              </div>
                            </FormItem>
                          </Col>
                        </Row>
                      </>
                    ))}
                    <FormItem noStyle>
                      <ButtonItem
                        style={{ marginTop: 10, marginBottom: 10 }}
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Agregar Dirección
                      </ButtonItem>
                    </FormItem>
                  </>
                )}
              </FormInitial.List>

            </TabPane>
          </Tabs>

          <FormItem>
            <ButtonItem type="primary" htmlType="submit" loading={this.state.loading}>
              Guardar
            </ButtonItem>
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(AgregarCliente);
