import React, { Component } from "react";
import {
  Checkbox,
  Input,
  message,
  Select,
  PageHeader,
  Col,
  Row,
  Spin,
  Divider,
} from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  cuentaMask,
  ButtonItem,
  BoxItem,
  HeaderItem,
  InputItem,
  ErrorItem,
} from "../../../../Components/Items";
import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";
import AgregarTipoDePoliza from "../../TiposDePolizas/AgregarTipoDePoliza/AgregarTipoDePoliza";
import AgregarTipoDeFlujo from "../../TiposDeFlujos/AgregarTipoDeFlujo/AgregarTipoDeFlujo";

const { Option } = Select;

class AgregarTipoDeCliente extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasCuentaContable: null,
    objetoCuentaContable: null,
    todasCuentasContablesFinales: null,
    objetoCuentasContablesFinales: null,
    todasTiposDePolizas: null,
    objetoTiposDePolizasDiario: null,
    objetoTiposDePolizasIngreso: null,
    todasTiposDeFlujos: null,
    objetoTiposDeFlujos: null,
    modalAgregarCuentaContable: false,
    modalAgregarTipoDePoliza: false,
  };
  atras() {
    this.props.history.goBack();
  }
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "cuentascontables/selectCuentasContablesFinalesSinNivel5",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCuentaContable: res.data });
          this.setState({
            objetoCuentaContable: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "tiposdepolizas/selectTiposDePolizas",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasTiposDePolizas: res.data });
          this.setState({
            objetoTiposDePolizasDiario: res.data.map((value) => {
              if (value.tipoDePolizaId === 1 || value.tipoDePolizaId === 2) {
                return null;
              }
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.descripcion}
                </Option>
              );
            }),
          });
          this.setState({
            objetoTiposDePolizasIngreso: res.data.map((value) => {
              if (value.tipoDePolizaId !== 1) {
                return null;
              }
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "tiposdeflujos/selectTiposDeFlujos",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasTiposDeFlujos: res.data });
          this.setState({
            objetoTiposDeFlujos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }

  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "tiposdeclientes/agregarTipoDeCliente",
        { ...values, empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.id && res.data.success === 2) {
          message.success("Se agrego un tipo de cliente");
          this.formRef.current.resetFields();
          this.atras(res.data.id);
        } else {
          message.error("Sucedio un error y no se guardo");
        }
      });
  }

  abrirAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: true });
  }
  cerrarAgregarCuentaContable(codigo) {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarTodo().then(() => {
      if (typeof codigo === "string") {
        this.formRef.current.setFieldsValue({ cuentaContableId: codigo });
      }
    });
  }
  abrirAgregarTipoDePoliza() {
    this.setState({ modalAgregarTipoDePoliza: true });
  }
  cerrarAgregarTipoDePoliza(codigo) {
    this.setState({ modalAgregarTipoDePoliza: false });
    this.consultarTodo();
  }
  abrirAgregarTipoDeFlujo() {
    this.setState({ modalAgregarTipoDeFlujo: true });
  }
  cerrarAgregarTipoDeFlujo(codigo) {
    this.setState({ modalAgregarTipoDeFlujo: false });
    this.consultarTodo().then(() => {
      if (typeof codigo === "string") {
        this.formRef.current.setFieldsValue({ tipoDeFlujoId: codigo });
      }
    });
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Tipo de Cliente"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarTipoDeCliente"
              layout
              bottom={
                <ButtonItem
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Guardar Tipo de Cliente
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
              initialValues={{ cuentasDiario: [""], cuentasIngreso: [""] }}
            >
              <Row>
                <Col span={12}>
                  <FormItem
                    label="Nombre"
                    margin
                    pattern
                    required
                    max={20}
                    name="nombre"
                  >
                    <InputItem placeholder="Nombre" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Descripcion"
                    margin
                    pattern
                    required
                    max={200}
                    name="descripcion"
                  >
                    <InputItem placeholder="Descripcion" />
                  </FormItem>
                </Col>
              </Row>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <Row>
                <Col span={12}>
                  <HeaderItem>CUENTAS CONTABLES</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={12}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            return (
                              <FormItem
                                label="Consecutivo de cuentas"
                                margin
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                numberCero
                                required={getFieldValue(
                                  "generarCuentasAutomatico"
                                )}
                                name="consecutivoCuentas"
                              >
                                <InputNumberItem
                                  disabled={
                                    !getFieldValue("generarCuentasAutomatico")
                                  }
                                  placeholder="Consecutivo de cuentas"
                                />
                              </FormItem>
                            );
                          }}
                        </FormItem>
                        </Col>
                        <Col span={12}>
                        <FormItem
                          name="generarCuentasAutomatico"
                          label="Generar Cuentas en automático"
                          valuePropName="checked"
                          initialValue={false}
                          margin
                          labelCol={{ span: 18 }}
                          wrapperCol={{ span: 6 }}
                          >
                          <Checkbox />
                        </FormItem>
                          </Col>
                        </Row>
                        <FormItem label="Cuenta Cliente" 
                        margin 
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        shouldUpdate>
                          <Input.Group compact>
                            <FormItem name="cuentaClienteId" required noStyle>
                              <SelectItem
                                placeholder="Cuenta Cliente"
                                width="90%"
                              >
                                {this.state.objetoCuentaContable}
                              </SelectItem>
                            </FormItem>
                            <ButtonItem
                              icon={<PlusOutlined />}
                              style={{ width: "10%" }}
                              onClick={this.abrirAgregarCuentaContable.bind(
                                this
                              )}
                            />
                          </Input.Group>
                        </FormItem>
                    <FormItem label="Cuenta Anticipo" 
                    margin
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    shouldUpdate>
                      <Input.Group compact>
                        <FormItem name="cuentaAnticipoId" required noStyle>
                          <SelectItem placeholder="Cuenta Anticipo" width="90%">
                            {this.state.objetoCuentaContable}
                          </SelectItem>
                        </FormItem>
                        <ButtonItem
                          icon={<PlusOutlined />}
                          style={{ width: "10%" }}
                          onClick={this.abrirAgregarCuentaContable.bind(this)}
                        />
                      </Input.Group>
                    </FormItem>
                    <FormItem 
                    label="Cuenta Fondo Garantía" 
                    margin 
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    shouldUpdate>
                      <Input.Group compact>
                        <FormItem name="cuentaFonGarId" required noStyle>
                          <SelectItem
                            placeholder="Cuenta Fondo de Gararantia"
                            width="90%"
                          >
                            {this.state.objetoCuentaContable}
                          </SelectItem>
                        </FormItem>
                        <ButtonItem
                          icon={<PlusOutlined />}
                          style={{ width: "10%" }}
                          onClick={this.abrirAgregarCuentaContable.bind(this)}
                        />
                      </Input.Group>
                    </FormItem>
                    <FormItem label="Cuenta Acreedor" 
                    margin 
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    shouldUpdate>
                      <Input.Group compact>
                        <FormItem name="cuentaDeudorId" required noStyle>
                          <SelectItem placeholder="Cuenta Acreedor" width="90%">
                            {this.state.objetoCuentaContable}
                          </SelectItem>
                        </FormItem>
                        <ButtonItem
                          icon={<PlusOutlined />}
                          style={{ width: "10%" }}
                          onClick={this.abrirAgregarCuentaContable.bind(this)}
                        />
                      </Input.Group>
                    </FormItem>
                  </BoxItem>
                </Col>
                <Col span={12}>
                  <HeaderItem>POLIZAS</HeaderItem>
                  <BoxItem>
                    <FormItem
                      label="Tipo de Diario"
                      margin
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      tooltip="Elegir polizas de diario solamente"
                    >
                      <Input.Group compact>
                        <FormItem name="tipoDePolizaDiarioId" 
                        required 
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        noStyle>
                          <SelectItem
                            placeholder="Tipo de Poliza Diario"
                            width="90%"
                          >
                            {this.state.objetoTiposDePolizasDiario}
                          </SelectItem>
                        </FormItem>
                        <ButtonItem
                          icon={<PlusOutlined />}
                          style={{ width: "10%" }}
                          onClick={this.abrirAgregarTipoDePoliza.bind(this)}
                        />
                      </Input.Group>
                    </FormItem>
                    <FormItem
                      label="Tipo de Ingreso"
                      margin
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      tooltip="Elegir polizas de ingreso solamente"
                    >
                      <Input.Group compact>
                        <FormItem name="tipoDePolizaIngresoId" required noStyle>
                          <SelectItem
                            placeholder="Tipo de Poliza Ingreso"
                            width="90%"
                          >
                            {this.state.objetoTiposDePolizasIngreso}
                          </SelectItem>
                        </FormItem>
                        <ButtonItem
                          icon={<PlusOutlined />}
                          style={{ width: "10%" }}
                          onClick={this.abrirAgregarTipoDePoliza.bind(this)}
                        />
                      </Input.Group>
                    </FormItem>
                    <FormItem
                      label="Tipo de Flujo"
                      margin
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      tooltip="El tipo de flujo sirve para saber si se generara el diot y si es un ingreso"
                    >
                      <Input.Group compact>
                        <FormItem name="tipoDeFlujoId" required noStyle>
                          <SelectItem placeholder="Tipo de Flujo" width="90%">
                            {this.state.objetoTiposDeFlujos}
                          </SelectItem>
                        </FormItem>
                        <ButtonItem
                          icon={<PlusOutlined />}
                          style={{ width: "10%" }}
                          onClick={this.abrirAgregarTipoDeFlujo.bind(this)}
                        />
                      </Input.Group>
                    </FormItem>
                  </BoxItem>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
        <AgregarCuentaContable
          visible={this.state.modalAgregarCuentaContable}
          cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(
            this
          )}
        />
        <AgregarTipoDePoliza
          visible={this.state.modalAgregarTipoDePoliza}
          cerrarAgregarTipoDePoliza={this.cerrarAgregarTipoDePoliza.bind(this)}
        />
        <AgregarTipoDeFlujo
          visible={this.state.modalAgregarTipoDeFlujo}
          cerrarAgregarTipoDeFlujo={this.cerrarAgregarTipoDeFlujo.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarTipoDeCliente);
