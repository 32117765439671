import { message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
} from "../../../../Components/Items";

class AgregarPaciente extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
        this.consultarTodo();
    }
  }

  onFinishFailed(e) {
    ErrorItem(e);
  };

  consultarTodo() {
    this.setState({ loading: true });
    axios
      .post(
        "pacientes/selectDatosParaPaciente",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          this.formRef.current.setFieldsValue({codigo: res.data.nuevoCodigo})
        } else {
          message.error("No se encontro informacion");
        }
      });
  }

  onFinish(values) {
    if (values?.nombre) {
      this.setState({ loading: true });
      axios
        .post(
          "pacientes/agregarPaciente",
          {
            ...values,
            empresaId: this.props.empresaId,
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Paciente agregado exitosamente");
            this.cerrarAgregarPaciente();
          } else if (res.data.success === 1) {
            message.error("No se pudo agregar el paciente");
          } else {
            message.error("Sucedió un error y no se pudo agregar el paciente");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          message.error("Error al comunicarse con el servidor");
        });
    } else {
      message.info("Proporcione al menos el nombre del paciente");
    }
  }
  

  cerrarAgregarPaciente = () => {
    this.props.cerrarAgregarPaciente();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Agregar Paciente"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarAgregarPaciente.bind(this)}
      >
        <Form
          ref={this.formRef}
          name="modalAgregarPaciente"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
          
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem
            name="codigo"
            label="Número de Paciente"
            required
            margin
          >
            <InputItem disabled placeholder="Ingresa el número de paciente" />
          </FormItem>

          <FormItem
            name="nombre"
            label="Nombre del Paciente"
            required
            margin
          >
            <InputItem placeholder="Ingresa el nombre del paciente" />
          </FormItem>
                    <FormItem
                      name="cirugia"
                      label="Cirugia del Paciente"
                      margin
                    >
                      <InputItem placeholder="Ingresa la cirugia del paciente" />
                    </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(AgregarPaciente);
