import { Collapse, Row, Col, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  dineroDisabledMask,
  ModalItem,
  ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";
import CancelarPagoDevolucion from "../CancelarPagoDevolucion/CancelarPagoDevolucion";
// import CancelarPago from "../CancelarPago/CancelarPago";

const { Panel } = Collapse;

class PagosDevolucion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pagosHtml: <p>No se encontro ningun pago</p>,
    modalCancelarPagoVenta: false,
    modalCancelarPagoVentaId: null
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "pagos/pagosDevolucion",
        { devolucionId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) return;
        const html = (
          <Collapse style={{ marginBottom: 10 }}>
            {res.data.map((valuePago) => {
              return (
                <Panel
                  extra={
                    valuePago.estado !== 0 ? (
                      valuePago.moneda.abreviacion+' - '+dineroDisabledMask(valuePago.monto)
                    ) : (
                      <Tag color="red">
                        Cancelada: {valuePago.moneda.abreviacion+' - '+dineroDisabledMask(valuePago.monto)}
                      </Tag>
                    )
                  }
                  header={moment(valuePago.fechaPago).locale("es").format("ll")}
                  key={valuePago._id}
                >
                  <Row>
                    <Col span={12}>
                      <p>
                        Dia del Pago:{" "}
                        {moment(valuePago.fechaPago).locale("es").format("ll")}
                      </p>
                      <p>
                        Complemento de Pago:{" "}
                        {valuePago.complemento ? "Si" : "No"}
                      </p>
                      <p>
                        Nota de credito:{" "}
                        {valuePago.notaDeCreditoId ? "Si" : "No"}
                      </p>
                      <p>
                        Devolucion:{" "}
                        {valuePago.devolucionId ? "Si" : "No"}
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        Dia de registro:{" "}
                        {moment(valuePago.createdAt).locale("es").format("ll")}
                      </p>
                      <p>Monto: {valuePago.moneda.abreviacion+' - '+dineroDisabledMask(valuePago.monto)}</p>
                      {valuePago.devolverDinero &&
                        <p>
                          Compensación:{" "}
                          {valuePago.devolverDinero ? "Si" : "No"}
                        </p>
                      }
                      {valuePago.estado !== 0 && !valuePago.contado && !valuePago.pagarVenta && !valuePago.devolverDinero && (
                        <ButtonItem type="primary" danger onClick={() => {
                          this.setState({modalCancelarPagoVenta: true, modalCancelarPagoVentaId: valuePago._id})
                        }}>
                          Cancelar Pago
                        </ButtonItem>
                      )}
                    </Col>
                  </Row>
                </Panel>
              );
            })}
          </Collapse>
        );
        this.setState({ pagosHtml: html });
      });
    this.setState({ loading: false });
  }
  cerrarPagosDevolucion() {
    this.props.cerrarPagosDevolucion();
  }
  cerrarCancelarPagoDevolucion() {
    this.setState({
      modalCancelarPagoVenta: false,
      modalCancelarPagoVentaId: null,
    });
    this.consultarTodo()
  }

  render() {
    return (
      <>
      <ModalItem
        title="Pagos Devolución"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarPagosDevolucion.bind(this)}
        paddingTop
      >
        {this.state.pagosHtml}
      </ModalItem>
      <CancelarPagoDevolucion
          visible={this.state.modalCancelarPagoVenta}
          id={this.state.modalCancelarPagoVentaId}
          cerrarCancelarPagoDevolucion={this.cerrarCancelarPagoDevolucion.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(PagosDevolucion);
