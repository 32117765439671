import {
  Checkbox,
  PageHeader,
  Popconfirm,
  Select,
  Space,
  Tag,
  message,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ModalItem, NotificacionPolizas, SelectItem, TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";
import { CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const { Option } = Select;

class AgregarInventario extends Component {
  state = {
    loading: false,
    objetoProyectos: [],
    todasProyectos: [],
    todasAgregarInventario: [],
    proyecto: undefined,
    modalConfirmacion: false,
    modalConfirmacionId: null,
    valor: 2,
  };

  async componentDidMount() {
    await this.consultarTodo(this.state.valor);
  }

  async consultarTodo(valor) {
    this.setState({ loading: true });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "agregarInventario/selectTodasOrdenes",
        { empresaId: this.props.empresaId , valor: valor},
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          this.setState({ todasAgregarInventario: [] });
        } else {
          this.setState({
            todasAgregarInventario: res.data.map((value) => {
              return {
                ...value,
                proyectoNombre: value.proyecto.nombre,
                accion: { _id: value._id, estado: value.estado, contabilizar: value.contabilizar },
              };
            }),
          });
        }
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  onChangeProyecto(value) {
    if (value === 0) {
      this.setState({ loading: true, proyecto: undefined });
      axios
        .post(
          "agregarInventario/selectTodasOrdenes",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            this.setState({ todasAgregarInventario: [] });
          } else {
            this.setState({
              todasAgregarInventario: res.data.map((value) => {
                return {
                  ...value,
                  proyectoNombre: value.proyecto.nombre,
                  accion: { _id: value._id, estado: value.estado },
                };
              }),
            });
          }
          this.setState({ loading: false });
        });
    } else {
      const proyecto = this.state.todasProyectos.find(
        (valueState) => valueState._id.toString() === value.toString()
      );
      this.setState({ loading: true, proyecto: proyecto.nombre });
      axios
        .post(
          "agregarInventario/selectTodasOrdenes",
          { empresaId: this.props.empresaId, proyectoId: value },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            this.setState({ todasAgregarInventario: [] });
          } else {
            this.setState({
              todasAgregarInventario: res.data.map((value) => {
                return {
                  ...value,
                  proyectoNombre: value.proyecto.nombre,
                  accion: { _id: value._id, estado: value.estado, contabilizar: value.contabilizar },
                };
              }),
            });
          }
          this.setState({ loading: false });
        });
    }
  }
  abrirAgregarInventario() {
    this.props.history.push("/panel/agregarOrdenAgregarInventario");
  }
  confirmarAgregarInventario(value) {
    axios
      .post(
        "agregarInventario/confirmarOrdenAgregarInventario",
        { ordenId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Inventario agregado");
          NotificacionPolizas(res.data.polizasId)
        } else {
          message.error(res.data.message ? res.data.message : "No se puede registrar la orden");
        }
        this.consultarTodo(this.state.valor);
      });
  }
  cancelarOrdenAgregarInventario(value) {
    axios
      .post(
        "agregarInventario/cancelarOrdenAgregarInventario",
        { ordenId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Orden cancelada");
        } else {
          message.error("Sucedio un error y no se pudo cancelar");
        }
        this.consultarTodo(this.state.valor);
      });
  }
  render() {
    const columns = [
      {
        title: "Numero",
        dataIndex: "numero",
        key: "numero",
        sorter: (a, b) => {
          if (a.numero < b.numero) {
            return -1;
          }
          if (a.numero > b.numero) {
            return 1;
          }
          return 0;
        },
        buscar: "numero",
        width: "10%",
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => {
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return -1;
          }
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale('es').format('L'),
        width: '10%',
      },
      {
        title: "Referencia",
        dataIndex: "referencia",
        key: "referencia",
        sorter: (a, b) => {
          if (a.referencia < b.referencia) {
            return -1;
          }
          if (a.referencia > b.referencia) {
            return 1;
          }
          return 0;
        },
        buscar: "referencia",
        width: "20%",
      },
      {
        title: "Proyecto",
        dataIndex: "proyectoNombre",
        key: "proyectoNombre",
        sorter: (a, b) => {
          if (a.proyectoNombre < b.proyectoNombre) {
            return -1;
          }
          if (a.proyectoNombre > b.proyectoNombre) {
            return 1;
          }
          return 0;
        },
        buscar: "proyectoNombre",
        width: "25%",
      },
      {
        title: "Contabilizar",
        dataIndex: "contabilizar",
        key: "contabilizar",
        render: (value) => <Checkbox checked={value} />,
        width: "10%",
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (value) => {
          if (value === 0) {
            return <Tag color="red">Cancelada</Tag>;
          }
          if (value === 1) {
            return <Tag color="blue">Por confirmar</Tag>;
          }
          if (value === 3) {
            return <Tag color="green">Agregada</Tag>;
          }
        },
        width: "15%",
        ellipsis: true,
      },
      {
        title: "Acción",
        dataIndex: "accion",
        key: "accion",
        render: (value, index) => {
          if (value.estado === 0) return;
          if (value.estado === 3) return;

          return (
            <Space direction="horizontal">
              {this.props.permisoConfirmarOrdenAgregarInventario &&
                <Popconfirm
                  title={
                    "Seguro que quieres confirmar esta orden de agregar inventario"
                  }
                  onConfirm={() => {
                    if(value.contabilizar){
                      this.confirmarAgregarInventario(value._id, value.contabilizar)
                    }else{
                      this.setState({modalConfirmacion: true, modalConfirmacionId: value._id})
                    }
                  }}
                  okText="Si"
                  cancelText="No"
                >
                  <ButtonItem type="primary" size="small">
                    Confirmar
                  </ButtonItem>
                </Popconfirm>
              }
              {this.props.permisoCancelarOrdenAgregarInventario &&
                <Popconfirm
                  title={
                    "Seguro que quieres cancelar esta orden de agregar inventario"
                  }
                  onConfirm={() => this.cancelarOrdenAgregarInventario(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CloseCircleOutlined style={{ color: "red" }} />
                </Popconfirm>
              }
            </Space>
          );
        },
      },
    ];
    return (
      <>
        <PageHeader
          title={
            this.state.proyecto
              ? "Ordenes Agregar Inventario: " + this.state.proyecto
              : "Ordenes Agregar Inventario: Todos"
          }
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <Space style={{ width: "100%", minWidth: 300 }}>
              {window.innerWidth > 1000 &&
              <SegmentedItem
                options={[
                  {label: 'Agregada', value: 1}, 
                  {label: 'Por Confirmar', value: 2}, 
                  {label: 'Cancelada', value: 3}, 
                  {label: 'Todos', value: 4}
                ]}
                onChange={(value) => {
                  this.consultarTodo(value)
                  this.setState({valor: value})
                }}
                value={this.state.valor}
              />
          }
              <SelectItem
                placeholder="Proyecto"
                width={200}
                onChange={this.onChangeProyecto.bind(this)}
                defaultValue={0}
              >
                <Option value={0}>Todos</Option>
                {this.state.objetoProyectos}
              </SelectItem>
              {this.props.permisoAgregarOrdenAgregarInventario &&
                <ButtonItem
                  type="primary"
                  key="agregarInventario"
                  onClick={this.abrirAgregarInventario.bind(this)}
                >
                  Agregar Inventario
                </ButtonItem>
              }
            </Space>
          }
        />
        <TableItem
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.props.history.push("/panel/verOrdenAgregarInventario", {
                  id: record._id,
                });
              },
            };
          }}
          size="small"
          dataSource={this.state.todasAgregarInventario}
          columns={columns}
          pagination={false}
          loading={this.state.loading}
        />
                <ModalItem
          title="Confirmacion"
          visible={this.state.modalConfirmacion}
          onCancel={() => this.setState({ modalConfirmacion: false })}
          onOk={() => {
            this.setState({ modalConfirmacion: false });
            this.confirmarAgregarInventario(this.state.modalConfirmacionId);
          }}
          spinning={false}
          footer
        >
          <h1 style={{textAlign: 'center'}}>El inventario se ingresara sin costo</h1>
          <p style={{textAlign: 'center'}}>¿Seguro que quiere continuar?</p>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarOrdenAgregarInventario:
    state.user.tipo === 0 ||
    state.user.permisos.find(
      (valueTodos) =>
        valueTodos.permisoId.toString() === "647fc5c09dc9c6543b161b8d"
    )?.activado,
    permisoConfirmarOrdenAgregarInventario:
    state.user.tipo === 0 ||
    state.user.permisos.find(
      (valueTodos) =>
        valueTodos.permisoId.toString() === "647fc3bc9dc9c6543b161b87"
    )?.activado,
    permisoCancelarOrdenAgregarInventario:
    state.user.tipo === 0 ||
    state.user.permisos.find(
      (valueTodos) =>
        valueTodos.permisoId.toString() === "647fc4019dc9c6543b161b88"
    )?.activado,
  };
};

export default connect(mapStateToProps)(AgregarInventario);
