import React, { Component } from "react";
import { EditOutlined, EyeOutlined, MinusCircleOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { ErrorItem, Form, ModalItem, ButtonItem, TableItem, HeaderItem, FormItem, BoxItem, InputItem, SelectItem, InputNumberItem, SwitchItem, DatePickerItem, dineroDisabledMask, AutoCompleteItem } from "../../../../../Components/Items";
import axios from "axios";
import { Form as FormInitial, Col, Input, message, Popconfirm, Row, Select, Space, Tabs, Spin, notification } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import "moment/locale/es";
import AgregarUnidad from "../../../Insumos/AgregarInsumo/AgregarUnidad/AgregarUnidad";


const { Option } = Select
const { TabPane } = Tabs;



class TabEnvio extends Component {
    formRefGeneral = React.createRef()
    formRefUbicacion = React.createRef()
    formRefMercancia = React.createRef()
    state = {
        modalUbicacion: false,
        modalUbicacionIndex: undefined,
        modalMercancia: false,
        modalMercanciaIndex: undefined,
        todasPaises: [],
        objetoPaises: null,
        todasEstados: [],
        objetoEstados: null,
        todasColonias: [],
        objetoColonias: null,
        todasCodigoSat: [],
        buscarInfoCode: false,
        objetoCodigoSat: null,
        todasUnidades: [],
        todasMaterialesPeligrosos: [],
        todasEmbalajes: [],
        unidadPeso: null,
        todasSectoresCOF: [],
        todasFormasFarmaceuticas: [],
        todasCondicionesEspeciales: [],
        todasTiposMaterias: [],
        objetoUnidades: null,
        objetoMaterialesPeligrosos: null,
        objetoEmbalajes: null,
        objetoSectoresCOF: null,
        objetoFormasFarmaceuticas: null,
        objetoCondicionesEspeciales: null,
        objetoTiposMaterias: null,
        loadingGeneral: false,
        todasRemolques: [],
        todasChoferes: [],
        todasProyectosMoviles: [],
        todasUbicacionesPolos: [],
        objetoProyectosMoviles: null,
        objetoUbicacionesPolos: null,
        objetoChoferes: null,
        objetoRemolques: null,
        optionsUbicaciones: [],
        optionsMercancias: [],
        modalAgregarUnidad: false
    }

    componentDidMount() {
        this.consultarTodo()
    }


    async consultarTodo() {
        this.setState({ loadingUbicaciones: true, loadingMercancia: true, loadingGeneral: true })
        axios
            .post(
                "envios/datosEnvioFlete",
                { empresaId: this.props.empresaId, ventaId: this.state.id },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    this.setState({
                        loadingGeneral: false,
                        todasRemolques: res.data.remolques,
                        todasChoferes: res.data.choferes,
                        todasProyectosMoviles: res.data.proyectosMoviles,
                        todasUbicacionesPolos: res.data.ubicacionesPolos,
                        objetoProyectosMoviles: res.data.proyectosMoviles.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoUbicacionesPolos: res.data.ubicacionesPolos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + ' - ' + value.Descripcion}
                                </Option>
                            );
                        }),
                        objetoChoferes: res.data.choferes.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoRemolques: res.data.remolques.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.descripcion + ' - ' + value.placa}
                                </Option>
                            );
                        }),
                    });

                } else {
                    notification.warning({
                        message: 'No se puede generar el envio',
                        description: res.data.message
                    })
                }
            });
        await axios
            .post("paises/selectPaises", null, {
                headers: this.props.headersToken,
            })
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasPaises: res.data,
                        objetoPaises: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre + " - " + value.abreviatura}
                                </Option>
                            );
                        }),
                    });
                }
            });
        await axios
            .post("estados/selectEstados", null, {
                headers: this.props.headersToken,
            })
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasEstados: res.data,
                        objetoEstados: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre + " - " + value.abreviatura}
                                </Option>
                            );
                        }),
                    });
                }
            });
        await axios
            .post(
                "envios/datosMercanciaFlete",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    this.setState({
                        todasUnidades: res.data.unidades,
                        todasMaterialesPeligrosos: res.data.materialesPeligrosos,
                        todasEmbalajes: res.data.embalajes,
                        unidadPeso: res.data.unidadPeso,
                        todasSectoresCOF: res.data.sectoresCOF,
                        todasFormasFarmaceuticas: res.data.formasFarmaceuticas,
                        todasCondicionesEspeciales: res.data.condicionesEspeciales,
                        todasTiposMaterias: res.data.tiposMaterias,
                        objetoUnidades: res.data.unidades.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.unidadSat.c_ClaveUnidad + " - " + value.nombre}
                                </Option>
                            );
                        }),
                        objetoMaterialesPeligrosos: res.data.materialesPeligrosos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + " - " + value.Descripcion}
                                </Option>
                            );
                        }),
                        objetoEmbalajes: res.data.embalajes.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + " - " + value.Descripcion}
                                </Option>
                            );
                        }),
                        objetoSectoresCOF: res.data.sectoresCOF.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + " - " + value.Descripcion}
                                </Option>
                            );
                        }),
                        objetoFormasFarmaceuticas: res.data.formasFarmaceuticas.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + " - " + value.Descripcion}
                                </Option>
                            );
                        }),
                        objetoCondicionesEspeciales: res.data.condicionesEspeciales.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + " - " + value.Descripcion}
                                </Option>
                            );
                        }),
                        objetoTiposMaterias: res.data.tiposMaterias.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + " - " + value.Descripcion}
                                </Option>
                            );
                        }),
                    });
                } else {
                    message.info('No se cargaron bien los datos de las mercancias')
                }
            });
        this.setState({ loadingUbicaciones: false, loadingMercancia: false })
    }



    async modalUbicacion(index) {

        await this.setState({ modalUbicacion: true, modalUbicacionIndex: index, loadingUbicaciones: true })

        const clienteId = this.props.formRefGeneral.current.getFieldValue('clienteId')
        const cliente = this.props.todasClientes.find(valueTC => valueTC._id.toString() === clienteId)

        if (index || index === 0) {
            if (this.props.ubicacionesEnvio[index].estadoId) {
                await this.onChangeEstado(this.props.ubicacionesEnvio[index].estadoId, true);
            }
            if (this.props.ubicacionesEnvio[index].municipioId) {
                await this.onChangeMunicipio(this.props.ubicacionesEnvio[index].municipioId, true);
            }

            await this.formRefUbicacion.current.setFieldsValue({
                ...this.props.ubicacionesEnvio[index],
                fecha: moment(this.props.ubicacionesEnvio[index])
            })

        } else {

            await this.formRefUbicacion.current.setFieldsValue({
                rfcRD: cliente.rfc,
                nombreRD: cliente.persona ? cliente.razonSocial : (cliente.nombre + ' ' + cliente.apellidoPaterno + ' ' + cliente.apellidoMaterno)
            })
        }

        this.setState({
            loadingUbicaciones: false, optionsUbicaciones: cliente.direcciones ? cliente.direcciones.map(valueDirec => {
                return {
                    label: valueDirec.calle + (valueDirec.numeroCalle ? (' #' + valueDirec.numeroCalle) : '') + (valueDirec.numeroInterior ? (' - ' + valueDirec.numeroInterior) : ''),
                    value: valueDirec._id
                }
            }) : []
        })
    }


    onFinishFailed(e) {
        ErrorItem(e);
    }

    cerrarUbicacion() {
        this.setState({ modalUbicacion: false, modalUbicacionIndex: undefined, objetoColonias: null, todasColonia: [], objetoMunicipios: null, todasMunicipios: [] })
        this.formRefUbicacion.current.resetFields()
    }


    onFinishUbicacion(values) {

        if (this.state.modalUbicacionIndex || this.state.modalUbicacionIndex === 0) {

            let ubicacionesEnvio = [...this.props.ubicacionesEnvio]

            const colonia = this.state.todasColonias.find(valueTC => valueTC._id.toString() === values.coloniaId.toString())

            ubicacionesEnvio[this.state.modalUbicacionIndex] = {
                ...values,
                cp: colonia.cp
            }

            this.props.editarUbicacionesEnvio(ubicacionesEnvio)

        } else {

            const colonia = this.state.todasColonias.find(valueTC => valueTC._id.toString() === values.coloniaId.toString())


            let ubicacionesEnvio = [...this.props.ubicacionesEnvio]

            ubicacionesEnvio.push({
                ...values,
                cp: colonia.cp
            })

            this.props.editarUbicacionesEnvio(ubicacionesEnvio)
        }


        this.setState({ modalUbicacion: false, modalUbicacionIndex: undefined, objetoColonias: null, todasColonia: [], objetoMunicipios: null, todasMunicipios: [] })
        this.formRefUbicacion.current.resetFields()
    }

    onSearchColonia(value, ponerColoniaId) {
        if (value.toString().length === 5) {
            axios
                .post(
                    "colonias/buscarCP",
                    { cp: value },
                    {
                        headers: this.props.headersToken,
                    }
                )
                .then((res) => {
                    if (res.data.status === 404) {
                        message.info("No se encontro el codigo postal");
                    } else {
                        this.formRefUbicacion.current.setFieldsValue({
                            paisId: res.data.paisId,
                            estadoId: res.data.estadoId,
                            municipioId: res.data.municipioId,
                            coloniaId: ponerColoniaId && res.data._id ? res.data._id : undefined
                        });
                        this.onChangeEstado(res.data.estadoId, true);
                        this.onChangeMunicipio(res.data.municipioId, true);
                    }
                });
        }
    }

    async onSelectColoniaId(coloniaId) {
        const nombreColonia = this.state.todasColonias.find((todasColonia) => todasColonia._id.toString() === coloniaId.toString())?.nombre
        this.formRefUbicacion.current.setFieldsValue({
            colonia: nombreColonia
        })
    }


    async onChangeEstado(value, inicial) {
        if (!inicial) {
            this.formRefUbicacion.current.setFieldsValue({
                municipioId: undefined,
                coloniaId: undefined,
            });
            this.setState({ objetoColonias: null });
        }
        await axios
            .post(
                "municipios/selectMunicipios",
                { estadoId: value },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasMunicipios: res.data,
                        objetoMunicipios: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        })
                    });
                }
            });
    }


    async onChangeMunicipio(value, inicial) {
        if (!inicial) {
            this.formRefUbicacion.current.setFieldsValue({ coloniaId: undefined });
        }
        await axios
            .post(
                "colonias/selectColonias",
                { municipioId: value },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasColonias: res.data,
                        objetoColonias: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.cp + " - " + value.nombre}
                                </Option>
                            );
                        }),
                    });
                }
            });
    }

    async quitarUbicacion(index) {

        let ubicacionesEnvio = [...this.props.ubicacionesEnvio]

        ubicacionesEnvio.splice(index, 1)

        this.props.editarUbicacionesEnvio(ubicacionesEnvio)
    }




    async modalMercancia(index) {

        if (!this.props.ubicacionesEnvio || this.props.ubicacionesEnvio?.length < 2) return message.info('Agregar por lo menos dos ubicaciones')

        await this.setState({ modalMercancia: true, modalMercanciaIndex: index, loadingMercancia: true })

        const clienteId = this.props.formRefGeneral.current.getFieldValue('clienteId')
        const cliente = this.props.todasClientes.find(valueTC => valueTC._id.toString() === clienteId)

        if (index || index === 0) {

            await axios
                .post(
                    "codigoinsumos/buscarCodigoLista",
                    { empresaId: this.props.empresaId, subClaseId: this.props.mercanciasEnvio[index].codigoSatId },
                    { headers: this.props.headersToken, }
                )
                .then((res) => {
                    if (res.data.status === 404) {
                    } else {
                        this.setState({
                            todasCodigoSat: res.data,
                            buscarInfoCode: false,
                            objetoCodigoSat: res.data.map((value) => {
                                return (
                                    <Option value={value._id} key={value._id}>
                                        {value.c_ClaveProdServ + " - " + value.descripcion}
                                    </Option>

                                );
                            }),
                        });
                    }
                })

            await this.formRefMercancia.current.setFieldsValue(this.props.mercanciasEnvio[index])

        }

        this.setState({
            loadingMercancia: false, optionsMercancias: cliente.mercanciasEnvio ? cliente.mercanciasEnvio.map(valueMer => {
                return {
                    label: valueMer.descripcion + (valueMer.valorMercancia ? (' $' + valueMer.valorMercancia) : ''),
                    value: valueMer._id
                }
            }) : []
        })
    }


    cerrarMercancia() {
        this.formRefMercancia.current.resetFields()
        this.setState({ modalMercancia: false, modalMercanciaIndex: undefined })
    }


    onFinishMercancia(values) {

        if (!values.cantidades || values?.cantidades?.length < 0) return message.warning('Agregar cantidades')

        if (this.state.modalMercanciaIndex || this.state.modalMercanciaIndex === 0) {

            let mercanciasEnvio = [...this.props.mercanciasEnvio]

            const unidad = this.state.todasUnidades.find(valueTU => valueTU._id.toString() === values.unidadId.toString())
            const codigoSat = this.state.todasCodigoSat.find(valueTCS => valueTCS._id.toString() === values.codigoSatId.toString())

            let cantidad = 0

            for (const cantidadU of values.cantidades) {
                cantidad += cantidadU.cantidad
            }

            mercanciasEnvio[this.state.modalMercanciaIndex] = {
                ...values,
                unidad: unidad.nombre,
                codigoSat: (codigoSat.c_ClaveProdServ + " - " + codigoSat.descripcion),
                cantidad
            }

            this.props.editarMercanciasEnvio(mercanciasEnvio)

        } else {



            let mercanciasEnvio = [...this.props.mercanciasEnvio]

            const unidad = this.state.todasUnidades.find(valueTU => valueTU._id.toString() === values.unidadId.toString())
            const codigoSat = this.state.todasCodigoSat.find(valueTCS => valueTCS._id.toString() === values.codigoSatId.toString())

            let cantidad = 0

            for (const cantidadU of values.cantidades) {
                cantidad += cantidadU.cantidad
            }

            mercanciasEnvio.push({
                ...values,
                unidad: unidad.nombre,
                codigoSat: (codigoSat.c_ClaveProdServ + " - " + codigoSat.descripcion),
                cantidad
            })

            this.props.editarMercanciasEnvio(mercanciasEnvio)
        }

        this.setState({ modalMercancia: false, modalMercanciaIndex: undefined })
        this.formRefMercancia.current.resetFields()

    }

    abrirAgregarUnidad() {
        this.setState({ modalAgregarUnidad: true });
    }
    cerrarAgregarUnidad(codigo) {
        this.setState({ modalAgregarUnidad: false });
        this.consultarTodo().then(() => {
            if (typeof codigo === "string") {
                this.formRefMercancia.current.setFieldsValue({ unidadId: codigo });
            }
        });
    }

    onChangeCodigo(value) {          /*Fragmento que regresa la lista de busqueda del codigo*/
        this.setState({ buscarInfoCode: true })
        axios
            .post(
                "codigoinsumos/buscarCodigoLista",
                { empresaId: this.props.empresaId, codigo: value },
                { headers: this.props.headersToken, }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasCodigoSat: res.data,
                        buscarInfoCode: false,
                        objetoCodigoSat: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.c_ClaveProdServ + " - " + value.descripcion}
                                </Option>

                            );
                        }),
                    });
                }
            })
    }

    onChangeAutomovil(value) {
        const proyectoTraslado = this.state.todasProyectosMoviles.find(valueTA => valueTA._id.toString() === value.toString())

        if (proyectoTraslado.remolquesIds) {

            let remolquesIds = []

            for (const remolque of proyectoTraslado.remolquesIds) {
                const indexR = this.state.todasRemolques.find(valueTR => valueTR._id.toString() === remolque.toString())

                if (indexR !== -1) {
                    remolquesIds.push(remolque)
                }

                this.props.formRefGeneral.current.setFieldsValue({ remolquesIds })
            }


        } else {
            this.props.formRefGeneral.current.setFieldsValue({ remolquesIds: [] })
        }
    }

    async quitarMercancia(index) {

        let mercanciasEnvio = [...this.props.mercanciasEnvio]

        mercanciasEnvio.splice(index, 1)

        this.props.editarMercanciasEnvio(mercanciasEnvio)
    }

    async selectUbicacion(ubicacionId) {

        await this.setState({ loadingUbicaciones: true })


        const clienteId = this.props.formRefGeneral.current.getFieldValue('clienteId')
        const cliente = this.props.todasClientes.find(valueTC => valueTC._id.toString() === clienteId)
        const direccion = cliente.direcciones.find(valueTD => valueTD._id.toString() === ubicacionId.toString())

        if (direccion.estadoId) {
            await this.onChangeEstado(direccion.estadoId, true);
        }
        if (direccion.municipioId) {
            await this.onChangeMunicipio(direccion.municipioId, true);
        }

        await this.formRefUbicacion.current.setFieldsValue({
            paisId: direccion.paisId,
            estadoId: direccion.estadoId,
            municipioId: direccion.municipioId,
            coloniaId: direccion.coloniaId,
            colonia: direccion.colonia,
            calle: direccion.calle,
            numeroCalle: direccion.numeroCalle,
            numeroInterior: direccion.numeroInterior,
        })

        await this.setState({ loadingUbicaciones: false })


    }

    async selectMercancias(ubicacionId) {

        await this.setState({ loadingMercancia: true })


        const clienteId = this.props.formRefGeneral.current.getFieldValue('clienteId')
        const cliente = this.props.todasClientes.find(valueTC => valueTC._id.toString() === clienteId)
        const mercancia = cliente.mercanciasEnvio.find(valueTM => valueTM._id.toString() === ubicacionId.toString())

        await axios
            .post(
                "codigoinsumos/buscarCodigoLista",
                { empresaId: this.props.empresaId, subClaseId: mercancia.codigoSatId },
                { headers: this.props.headersToken, }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasCodigoSat: res.data,
                        buscarInfoCode: false,
                        objetoCodigoSat: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.c_ClaveProdServ + " - " + value.descripcion}
                                </Option>

                            );
                        }),
                    });
                }
            })

        await this.formRefMercancia.current.setFieldsValue({
            valorMercancia: mercancia.valorMercancia,
            unidadId: mercancia.unidadId,
            codigoSatId: mercancia.codigoSatId,
            descripcion: mercancia.descripcion,
            dimensiones: mercancia.dimensiones,
            descripcionMateria: mercancia.descripcionMateria,
            materialPeligroso: mercancia.materialPeligroso,
            materialPeligrosoId: mercancia.materialPeligrosoId,
            numCAS: mercancia.numCAS,
            pesoEnKGM: mercancia.pesoEnKGM,
            claveSTCC: mercancia.claveSTCC,
            tipoMateriaId: mercancia.tipoMateriaId,
            embalajeId: mercancia.embalajeId,
            descripcionEmbalaje: mercancia.descripcionEmbalaje,
            sectorCOFEPRIS: mercancia.sectorCOFEPRIS,
            sectorCOFEPRISId: mercancia.sectorCOFEPRISId,
            nombreIngredienteActivo: mercancia.nombreIngredienteActivo,
            nombreQuimico: mercancia.nombreQuimico,
            denominacionGenericaProd: mercancia.denominacionGenericaProd,
            denominacionDistintivaProd: mercancia.denominacionDistintivaProd,
            fabricante: mercancia.fabricante,
            formaFarmaceuticaId: mercancia.formaFarmaceuticaId,
            condicionEspecialId: mercancia.condicionEspecialId,
            registroSanitarioFolioAutorizacion: mercancia.registroSanitarioFolioAutorizacion,
            numRegSanPlagCOFEPRIS: mercancia.numRegSanPlagCOFEPRIS,
            datosFabricante: mercancia.datosFabricante,
            datosFormulador: mercancia.datosFormulador,
            datosMaquilador: mercancia.datosMaquilador,
            usoAutorizado: mercancia.usoAutorizado,
            pesoBruto: mercancia.pesoBruto,
            pesoNeto: mercancia.pesoNeto,
            pesoTara: mercancia.pesoTara,
        })

        await this.setState({ loadingMercancia: false })


    }

    render() {


        const columnsUbicaciones = [
            {
                title: "Calle",
                dataIndex: "calle",
                key: "calle",
                // sorter: (a, b) => {
                //     if (a.calle < b.calle) {
                //         return -1;
                //     }
                //     if (a.calle > b.calle) {
                //         return 1;
                //     }
                //     return 0;
                // },
                width: "30%",
                ellipsis: true,
            },
            {
                title: "Numero Ext.",
                dataIndex: "numeroCalle",
                key: "numeroCalle",
                sorter: (a, b) => {
                    if (a.numeroCalle < b.numeroCalle) {
                        return -1;
                    }
                    if (a.numeroCalle > b.numeroCalle) {
                        return 1;
                    }
                    return 0;
                },
                width: "10%",
                ellipsis: true,
            },
            {
                title: "Num. Int.",
                dataIndex: "numeroInterior",
                key: "numeroInterior",
                sorter: (a, b) => {
                    if (a.numeroInterior < b.numeroInterior) {
                        return -1;
                    }
                    if (a.numeroInterior > b.numeroInterior) {
                        return 1;
                    }
                    return 0;
                },
                width: "10%",
                ellipsis: true,
            },
            {
                title: "C.P.",
                dataIndex: "cp",
                key: "cp",
                sorter: (a, b) => {
                    if (a.cp < b.cp) {
                        return -1;
                    }
                    if (a.cp > b.cp) {
                        return 1;
                    }
                    return 0;
                },
                width: "10%",
                ellipsis: true,
            },
            {
                title: "Colonia",
                dataIndex: "colonia",
                key: "colonia",
                sorter: (a, b) => {
                    if (a.colonia < b.colonia) {
                        return -1;
                    }
                    if (a.colonia > b.colonia) {
                        return 1;
                    }
                    return 0;
                },
                width: "20%",
                ellipsis: true,
            },

            {
                title: "Accion",
                dataIndex: "accion",
                key: "accion",
                render: (value, record, index) => (
                    <Space size="middle">
                        {this.props.disabled ?
                            <EyeOutlined
                                onClick={() => this.modalUbicacion(index)}
                            />
                            :
                            <EditOutlined
                                onClick={() => this.modalUbicacion(index)}
                                style={{ color: "blue" }}
                            />
                        }
                        {!this.props.disabled &&
                            <Popconfirm
                                title="Seguro que quieres quitar esta ubicacion?"
                                onConfirm={() => this.quitarUbicacion(index)}
                                okText="Si"
                                cancelText="No"
                            >
                                <StopOutlined style={{ color: "red" }} />
                            </Popconfirm>
                        }
                    </Space>
                ),
                width: "20%",
                ellipsis: true,
            },
        ]


        const columnsMercancias = [
            {
                title: "Descripcion",
                dataIndex: "descripcion",
                key: "descripcion",
                // sorter: (a, b) => {
                //     if (a.descripcion < b.descripcion) {
                //         return -1;
                //     }
                //     if (a.descripcion > b.descripcion) {
                //         return 1;
                //     }
                //     return 0;
                // },
                width: "30%",
                ellipsis: true,
            },
            {
                title: "Unidad",
                dataIndex: "unidad",
                key: "unidad",
                sorter: (a, b) => {
                    if (a.unidad < b.unidad) {
                        return -1;
                    }
                    if (a.unidad > b.unidad) {
                        return 1;
                    }
                    return 0;
                },
                width: "10%",
                ellipsis: true,
            },
            {
                title: "Valor",
                dataIndex: "valorMercancia",
                key: "valorMercancia",
                sorter: (a, b) => {
                    if (a.valorMercancia < b.valorMercancia) {
                        return -1;
                    }
                    if (a.valorMercancia > b.valorMercancia) {
                        return 1;
                    }
                    return 0;
                },
                render: (value) => dineroDisabledMask(value),
                width: "10%",
                ellipsis: true,
            },
            {
                title: "Cantidad",
                dataIndex: "cantidad",
                key: "cantidad",
                sorter: (a, b) => {
                    if (a.cantidad < b.cantidad) {
                        return -1;
                    }
                    if (a.cantidad > b.cantidad) {
                        return 1;
                    }
                    return 0;
                },
                width: "10%",
                ellipsis: true,
            },
            {
                title: "Codigo Sat",
                dataIndex: "codigoSat",
                key: "codigoSat",
                sorter: (a, b) => {
                    if (a.codigoSat < b.codigoSat) {
                        return -1;
                    }
                    if (a.codigoSat > b.codigoSat) {
                        return 1;
                    }
                    return 0;
                },
                width: "20%",
                ellipsis: true,
            },

            {
                title: "Accion",
                dataIndex: "accion",
                key: "accion",
                render: (value, record, index) => (
                    <Space size="middle">
                        {this.props.disabled ?
                            <EyeOutlined
                                onClick={() => this.modalMercancia(index)}
                            />
                            :
                            <EditOutlined
                                onClick={() => this.modalMercancia(index)}
                                style={{ color: "blue" }}
                            />
                        }
                        {!this.props.disabled &&
                            <Popconfirm
                                title="Seguro que quieres quitar esta mercancia?"
                                onConfirm={() => this.quitarMercancia(index)}
                                okText="Si"
                                cancelText="No"
                            >
                                <StopOutlined style={{ color: "red" }} />
                            </Popconfirm>
                        }
                    </Space>
                ),
                width: "20%",
                ellipsis: true,
            },
        ]

        return (
            <Spin spinning={this.state.loadingGeneral}>

                <HeaderItem>GENERAL</HeaderItem>
                <BoxItem>

                    <Row>
                        <Col span={12}>
                            <FormItem label="Automovil Traslado" name="proyectoTransporteId" margin required>
                                <SelectItem disabled={this.props.disabled} onChange={this.onChangeAutomovil.bind(this)} placeholder="Automovil Traslado">
                                    {this.state.objetoProyectosMoviles}
                                </SelectItem>
                            </FormItem>
                            <FormItem label="Remolques" name="remolquesIds" margin>
                                <SelectItem disabled={this.props.disabled} placeholder="Remolques" mode="multiple" allowClear>
                                    {this.state.objetoRemolques}
                                </SelectItem>
                            </FormItem>
                            <FormItem label="Choferes" name="choferesIds" margin>
                                <SelectItem disabled={this.props.disabled} placeholder="Choferes" mode="multiple" allowClear>
                                    {this.state.objetoChoferes}
                                </SelectItem>
                            </FormItem>

                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="Transporte Internacional"
                                name="tranporteInt"
                                margin
                                initialValue={false}
                                valuePropName="checked"
                            >
                                <SwitchItem disabled />
                            </FormItem>
                            <FormItem
                                label="Registro ISTMO"
                                name="registroISTMO"
                                margin
                                initialValue={false}
                                valuePropName="checked"
                            >
                                <SwitchItem disabled={this.props.disabled} />
                            </FormItem>
                            <FormItem shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                    if (getFieldValue('registroISTMO')) {
                                        return <>
                                            <FormItem label="Ubicacion Polo Origen" name="ubicacionPoloOrigenId" margin required>
                                                <SelectItem disabled={this.props.disabled} placeholder="Ubicacion Polo Origen">
                                                    {this.state.objetoUbicacionesPolos}
                                                </SelectItem>
                                            </FormItem>
                                            <FormItem label="Ubicacion Polo Destino" name="ubicacionPoloDestinoId" margin required>
                                                <SelectItem disabled={this.props.disabled} placeholder="Ubicacion Polo Destino">
                                                    {this.state.objetoUbicacionesPolos}
                                                </SelectItem>
                                            </FormItem>
                                        </>
                                    }
                                }}
                            </FormItem>
                        </Col>
                    </Row>
                </BoxItem>


                <HeaderItem>UBICACIONES</HeaderItem>
                <BoxItem>

                    <ButtonItem
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            justifySelf: "left",
                            width: "auto",
                        }}
                        type="primary"
                        size="small"
                        onClick={() => this.modalUbicacion()}
                        block
                        icon={<PlusOutlined />}
                        disabled={this.props.disabled}
                    >
                        Agregar Ubicacion
                    </ButtonItem>

                    <TableItem
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (event) => {
                                    this.modalUbicacion(rowIndex)
                                },
                            };
                        }}
                        size="small"
                        dataSource={this.props.ubicacionesEnvio}
                        columns={columnsUbicaciones}
                        pagination={false}
                    />
                </BoxItem>


                <HeaderItem>MERCANCIAS</HeaderItem>
                <BoxItem>

                    <ButtonItem
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            justifySelf: "left",
                            width: "auto",
                        }}
                        type="primary"
                        size="small"
                        onClick={() => this.modalMercancia()}
                        block
                        icon={<PlusOutlined />}
                        disabled={this.props.disabled}
                    >
                        Agregar Mercancia
                    </ButtonItem>

                    <TableItem
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (event) => {
                                    this.modalMercancia(rowIndex)
                                },
                            };
                        }}
                        size="small"
                        dataSource={this.props.mercanciasEnvio}
                        columns={columnsMercancias}
                        pagination={false}
                    />

                </BoxItem>


                <ModalItem
                    title="Ubicacion"
                    visible={this.state.modalUbicacion}
                    spinning={this.state.loadingUbicaciones}
                    onCancel={this.cerrarUbicacion.bind(this)}
                    paddingTop
                    width={700}
                >
                    <Form
                        ref={this.formRefUbicacion}
                        name="agregarUbicacion"
                        layout
                        bottom={
                            <ButtonItem
                                type="primary"
                                htmlType="submit"
                                style={{ display: "block", margin: "0 0 0 auto" }}
                            >
                                Guardar
                            </ButtonItem>
                        }
                        onFinish={this.onFinishUbicacion.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Tabs>
                            <TabPane tab="Direccion" forceRender tabKey="1" key="1">
                                <HeaderItem>DIRECCION</HeaderItem>
                                <BoxItem>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                                name="tipoUbicacion"
                                                label="Tipo"
                                                margin
                                                required
                                                labelCol={{ span: 7 }}
                                                wrapperCol={{ span: 17 }}
                                            >
                                                <SelectItem disabled={this.props.disabled} placeholder="Tipo Ubicacion">
                                                    <Option key={1} value={1}>Origen</Option>
                                                    <Option key={2} value={2}>Destino</Option>
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label="Fecha de L/S"
                                                name="fecha"
                                                required
                                                margin
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 16 }}
                                                initialValue={moment().hour(12).minute(0)}
                                            >
                                                <DatePickerItem
                                                    disabled={this.props.disabled}
                                                    placeholder="Fecha de Llegada/Salida"
                                                    allowClear={false}
                                                    showTime
                                                    dateFormat='DD/MM/YYYY HH:mm'
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={16}>
                                            <FormItem
                                                name="calle"
                                                label="Dirección"
                                                max={200}
                                                margin
                                                pattern
                                                labelCol={{ span: 5 }}
                                                wrapperCol={{ span: 19 }}
                                            >
                                                <AutoCompleteItem
                                                    options={this.state.optionsUbicaciones}
                                                    placeholder="Escriba para buscar"
                                                    onSelect={this.selectUbicacion.bind(this)}
                                                    filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                                    disabled={this.props.disabled}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={4}>
                                            <FormItem
                                                name="numeroCalle"
                                                margin
                                                number
                                                labelCol={{ span: 0 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <InputItem
                                                    disabled={this.props.disabled}
                                                    placeholder="Número"
                                                    numeroCalle={true}
                                                    width="98%"
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={4}>
                                            <FormItem
                                                name="numeroInterior"
                                                margin
                                                pattern
                                                labelCol={{ span: 0 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <InputItem
                                                    disabled={this.props.disabled}
                                                    placeholder="Interior"
                                                    width="98%"
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <FormItem
                                                name="coloniaId"
                                                label="C.P."
                                                margin
                                                required
                                                labelCol={{ span: 10 }}
                                                wrapperCol={{ span: 14 }}
                                            >
                                                <SelectItem disabled={this.props.disabled} placeholder="Código Postal"
                                                    onSearch={(coloniaId) => {
                                                        this.onSearchColonia(coloniaId)
                                                    }}
                                                    onSelect={(coloniaId) => {
                                                        this.onSelectColoniaId(coloniaId)
                                                    }}
                                                >
                                                    {!this.state.objetoColonias ? (
                                                        <Option disabled key='municipio'>
                                                            Eliga primero un municipio
                                                        </Option>
                                                    ) : (
                                                        this.state.objetoColonias
                                                    )}
                                                    <Option disabled key='buscar'>Busque por codigo postal</Option>
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                        <Col span={16}>
                                            <FormItem
                                                name="colonia"
                                                label="Colonia"
                                                margin
                                                pattern
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                            >
                                                <InputItem disabled={this.props.disabled} placeholder="Colonia" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <FormItem
                                                name="municipioId"
                                                margin
                                                labelCol={{ span: 0 }}
                                                wrapperCol={{ span: 14, offset: 10 }}
                                            >
                                                <SelectItem
                                                    disabled={this.props.disabled}
                                                    placeholder="Municipio"
                                                    width="98%"
                                                    onChange={(e) =>
                                                        this.onChangeMunicipio(e)
                                                    }
                                                >
                                                    {!this.state.objetoMunicipios ? (
                                                        <Option disabled>
                                                            Eliga primero un estado
                                                        </Option>
                                                    ) : (
                                                        this.state.objetoMunicipios
                                                    )}
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                                name="estadoId"
                                                margin
                                                labelCol={{ span: 0 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <SelectItem
                                                    disabled={this.props.disabled}
                                                    placeholder="Estado"
                                                    width="98%"
                                                    onChange={(e) =>
                                                        this.onChangeEstado(e)
                                                    }
                                                >
                                                    {this.state.objetoEstados}
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                                name="paisId"
                                                margin
                                                labelCol={{ span: 0 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <SelectItem
                                                    disabled={this.props.disabled}
                                                    placeholder="Pais"
                                                    width="98%"
                                                >
                                                    {this.state.objetoPaises}
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={20}>
                                            <FormItem shouldUpdate noStyle>
                                                {({ getFieldValue }) => {
                                                    const tipoUbicacion = getFieldValue('tipoUbicacion')
                                                    if (tipoUbicacion === 2) {
                                                        return (
                                                            <FormItem
                                                                label='Distancia'
                                                                name='totalDistRecorrida'
                                                                number
                                                                margin
                                                                labelCol={{ span: 4 }}
                                                                wrapperCol={{ span: 20 }}
                                                                required
                                                            >
                                                                <InputNumberItem disabled={this.props.disabled} placeholder='Distancia Recorrida' />
                                                            </FormItem>
                                                        )
                                                    }
                                                }}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </BoxItem>
                            </TabPane>
                            <TabPane tab="Avanzado" forceRender tabKey="2" key="2">
                                <HeaderItem>AVANZADO</HeaderItem>
                                <BoxItem>
                                    <Row>
                                        <Col span={24}>
                                            <FormItem
                                                label="RFC R/D"
                                                name="rfcRD"
                                                required
                                                margin
                                                rfc
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                tooltip={{ title: "RFC (Remitente/Destinatario) : RFC de la persona propietaria del lugar" }}
                                            >
                                                <InputItem disabled={this.props.disabled} placeholder="RFC" />
                                            </FormItem>
                                            <FormItem
                                                label="Nombre R/D"
                                                name="nombreRD"
                                                margin
                                                pattern
                                                required
                                                max={200}
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                tooltip={{ title: "Nombre (Remitente/Destinatario) : Nombre de la persona propietaria del lugar" }}
                                            >
                                                <InputItem disabled={this.props.disabled} placeholder="Nombre" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </BoxItem>
                            </TabPane>
                        </Tabs>
                    </Form>
                </ModalItem>


                <ModalItem
                    title="Mercancia"
                    visible={this.state.modalMercancia}
                    spinning={this.state.loadingMercancia}
                    onCancel={this.cerrarMercancia.bind(this)}
                    paddingTop
                    width={800}
                >
                    <Form
                        ref={this.formRefMercancia}
                        name="agregarMercancia"
                        layout
                        bottom={
                            <ButtonItem
                                type="primary"
                                htmlType="submit"
                                style={{ display: "block", margin: "0 0 0 auto" }}
                            >
                                Guardar
                            </ButtonItem>
                        }
                        onFinish={this.onFinishMercancia.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Tabs>
                            <TabPane forceRender tab="Generales" tabKey="1" key="1">
                                <HeaderItem>GENERALES</HeaderItem>
                                <BoxItem>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                                label="Descripción"
                                                name="descripcion"
                                                required
                                                margin
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 16 }}
                                                pattern
                                            >
                                                <AutoCompleteItem
                                                    options={this.state.optionsMercancias}
                                                    placeholder="Escriba para buscar"
                                                    onSelect={this.selectMercancias.bind(this)}
                                                    filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                                    disabled={this.props.disabled}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label="Unidad"
                                                margin
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 16 }}
                                            >
                                                <Input.Group compact>
                                                    <FormItem name="unidadId" required noStyle>
                                                        <SelectItem disabled={this.props.disabled} placeholder="Unidad" width="70%">
                                                            {this.state.objetoUnidades}
                                                        </SelectItem>
                                                    </FormItem>
                                                    <ButtonItem
                                                        disabled={this.props.disabled}
                                                        icon={<PlusOutlined />}
                                                        style={{ width: "15%" }}
                                                        onClick={this.abrirAgregarUnidad.bind(this)}
                                                    />
                                                </Input.Group>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                                label="Valor"
                                                name="valorMercancia"
                                                margin
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 16 }}
                                                numberCero
                                            >
                                                <InputNumberItem
                                                    disabled={this.props.disabled}
                                                    placeholder="Valor Mercancia"
                                                    dinero
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label="Peso en KGM"
                                                name="pesoEnKGM"
                                                margin
                                                numberCero
                                            >
                                                <InputNumberItem disabled={this.props.disabled} placeholder="Peso en KGM" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <FormItem
                                                label="Codigo Sat"
                                                name="codigoSatId"
                                                required
                                                margin
                                                labelCol={{ span: 4 }}
                                                wrapperCol={{ span: 20 }}
                                            >
                                                <SelectItem
                                                    disabled={this.props.disabled}
                                                    mostrarCarga
                                                    loading={this.state.buscarInfoCode}
                                                    placeholder="Codigo Sat" onSearch={(value) => { this.onChangeCodigo(value) }
                                                    }>
                                                    <Option disabled>Escriba para buscar el codigo</Option>
                                                    {this.state.objetoCodigoSat}
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
                                        <ButtonItem
                                            disabled={this.props.disabled}
                                            style={{ width: "50%", marginTop: "10px" }}
                                            onClick={() => window.open("http://pys.sat.gob.mx/PyS/catPyS.aspx", "_blank")} // Abre en una nueva pestaña
                                        >Conoce tu codigo sat</ButtonItem>
                                    </Row>
                                </BoxItem>
                            </TabPane>
                            <TabPane forceRender tab='Cantidades' tabKey="3" key="3">
                                <HeaderItem>CANTIDADES</HeaderItem>
                                <BoxItem>
                                    <FormInitial.List
                                        name="cantidades"
                                    >
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ name }, arrayKey) => (
                                                    <>
                                                        <Row>
                                                            <Col span={12}>
                                                                <FormItem
                                                                    label="Ubicacion Origen"
                                                                    name={[name, "ubicacionOrigenIndex"]}
                                                                    fieldKey={[arrayKey, "ubicacionOrigenIndex"]}
                                                                    margin
                                                                    required
                                                                    labelCol={{ span: 10 }}
                                                                    wrapperCol={{ span: 12 }}
                                                                >
                                                                    <SelectItem disabled={this.props.disabled} placeholder='Ubicacion Origen'>
                                                                        {this.props.ubicacionesEnvio.map((valueUbicacion, indexUbicacion) => {
                                                                            return (
                                                                                <Option disabled={valueUbicacion.tipoUbicacion === 2} value={indexUbicacion} key={indexUbicacion}>
                                                                                    {'CP: ' + valueUbicacion.cp + (valueUbicacion.calle ? (" " + valueUbicacion.calle) : '') + (valueUbicacion.numeroCalle ? (' # ' + valueUbicacion.numeroCalle) : '')}
                                                                                </Option>
                                                                            )
                                                                        })}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </Col>
                                                            <Col span={12}>
                                                                <FormItem
                                                                    label="Ubicacion Destino"
                                                                    name={[name, "ubicacionDestinoIndex"]}
                                                                    fieldKey={[arrayKey, "ubicacionDestinoIndex"]}
                                                                    margin
                                                                    required
                                                                    labelCol={{ span: 10 }}
                                                                    wrapperCol={{ span: 12 }}
                                                                >
                                                                    <SelectItem disabled={this.props.disabled} placeholder='Ubicacion Destino'>
                                                                        {this.props.ubicacionesEnvio.map((valueUbicacion, indexUbicacion) => {
                                                                            return (
                                                                                <Option disabled={valueUbicacion.tipoUbicacion === 1} value={indexUbicacion} key={indexUbicacion}>
                                                                                    {'CP: ' + valueUbicacion.cp + (valueUbicacion.calle ? (" " + valueUbicacion.calle) : '') + (valueUbicacion.numeroCalle ? (' # ' + valueUbicacion.numeroCalle) : '')}
                                                                                </Option>
                                                                            )
                                                                        })}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={12}>
                                                                <FormItem
                                                                    label="Cantidad"
                                                                    name={[name, "cantidad"]}
                                                                    fieldKey={[arrayKey, "cantidad"]}
                                                                    required
                                                                    margin
                                                                    number
                                                                    labelCol={{ span: 10 }}
                                                                    wrapperCol={{ span: 12 }}
                                                                >
                                                                    <InputNumberItem
                                                                        disabled={this.props.disabled}
                                                                        placeholder="Cantidad"
                                                                    />
                                                                </FormItem>
                                                            </Col>
                                                            <Col span={12}>
                                                                <FormItem>
                                                                    <ButtonItem
                                                                        style={{
                                                                            float: "right",
                                                                            width: "auto",
                                                                        }}
                                                                        onClick={() => remove(name)}
                                                                        block
                                                                        rojo
                                                                        disabled={this.props.disabled}
                                                                        icon={<MinusCircleOutlined />}
                                                                    >
                                                                        Eliminar
                                                                    </ButtonItem>
                                                                </FormItem>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ))}
                                                <FormItem noStyle>
                                                    <ButtonItem
                                                        style={{ marginTop: 10, marginBottom: 10 }}
                                                        type="dashed"
                                                        onClick={() => {
                                                            const findIndexOrigen = this.props.ubicacionesEnvio.findIndex(valueTU => valueTU.tipoUbicacion === 1)
                                                            const findIndexDestino = this.props.ubicacionesEnvio.findIndex(valueTU => valueTU.tipoUbicacion === 2)
                                                            add({
                                                                ubicacionOrigenIndex: findIndexOrigen,
                                                                ubicacionDestinoIndex: findIndexDestino
                                                            })
                                                        }}
                                                        block
                                                        disabled={this.props.disabled}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Agregar Cantidad
                                                    </ButtonItem>
                                                </FormItem>
                                            </>
                                        )}
                                    </FormInitial.List>
                                </BoxItem>
                            </TabPane>
                            <TabPane forceRender tab="Avanzados" tabKey="2" key="2">
                                <HeaderItem>DATOS MERCANCIA</HeaderItem>
                                <BoxItem>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                                name="dimensiones"
                                                label="Dimensiones"
                                                margin
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 16 }}
                                                pattern
                                            >
                                                <InputItem disabled={this.props.disabled} placeholder="Ejemplo:  59/40/36plg" />
                                            </FormItem>
                                            <FormItem
                                                label="Descripcion Materia"
                                                name="descripcionMateria"
                                                margin
                                                pattern
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 16 }}
                                                tooltip={{ title: "Atributo opcional para expresar la clave de producto de la STCC (por sus siglas en inglés, Standard Transportation Commodity Code), cuando el medio de transporte utilizado para el traslado de los bienes y/o mercancías sea ferroviario." }}
                                            >
                                                <InputItem disabled={this.props.disabled} placeholder="Descripcion Materia" />
                                            </FormItem>
                                            <FormItem
                                                label="Material Peligroso"
                                                name="materialPeligroso"
                                                margin
                                                initialValue={false}
                                                valuePropName="checked"
                                            >
                                                <SwitchItem disabled={this.props.disabled} />
                                            </FormItem>
                                            <FormItem shouldUpdate noStyle>
                                                {({ getFieldValue }) => {
                                                    if (getFieldValue('materialPeligroso')) {
                                                        return (
                                                            <>
                                                                <FormItem label="Material Peligroso" name="materialPeligrosoId" margin required>
                                                                    <SelectItem disabled={this.props.disabled} placeholder="Material Peligroso" width="114%">
                                                                        {this.state.objetoMaterialesPeligrosos}
                                                                    </SelectItem>
                                                                </FormItem>
                                                                <FormItem
                                                                    name="numCAS"
                                                                    label="Numero CAS"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para expresar el número Chemical Abstracts Service (CAS) con el que se identifica el compuesto químico de la sustancia tóxica." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Numero CAS" />
                                                                </FormItem>
                                                            </>
                                                        )
                                                    }
                                                }}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label="Clave STCC"
                                                name="claveSTCC"
                                                margin
                                                numberCero
                                                tooltip={{ title: "Atributo opcional para expresar la clave de producto de la STCC (por sus siglas en inglés, Standard Transportation Commodity Code), cuando el medio de transporte utilizado para el traslado de los bienes y/o mercancías sea ferroviario." }}
                                            >
                                                <InputNumberItem disabled={this.props.disabled} placeholder="Clave STCC" />
                                            </FormItem>
                                            <FormItem label="Tipo Materia" name="tipoMateriaId" margin tooltip={{ title: "Atributo condicional para expresar el estado de la materia o producto al realizar una operación de comercio exterior a través de los distintos medios de transporte." }}>
                                                <SelectItem disabled={this.props.disabled} placeholder="Tipo Materia" width="100%">
                                                    {this.state.objetoTiposMaterias}
                                                </SelectItem>
                                            </FormItem>
                                            <FormItem shouldUpdate noStyle>
                                                {({ getFieldValue }) => {
                                                    if (getFieldValue('materialPeligroso')) {
                                                        return (
                                                            <>
                                                                <FormItem label="Embalaje" name="embalajeId" margin required>
                                                                    <SelectItem disabled={this.props.disabled} placeholder="Embalaje" width="100%">
                                                                        {this.state.objetoEmbalajes}
                                                                    </SelectItem>
                                                                </FormItem>
                                                                <FormItem
                                                                    name="descripcionEmbalaje"
                                                                    label="Descripcion Embalaje"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    required
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Ejemplo: Cajas de madera contrachapada" />
                                                                </FormItem>
                                                            </>
                                                        )
                                                    }
                                                }}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </BoxItem>
                                <HeaderItem>SECTOR COFEPRIS</HeaderItem>
                                <BoxItem>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                                label="Sector COFEPRIS"
                                                name="sectorCOFEPRIS"
                                                margin
                                                initialValue={false}
                                                valuePropName="checked"
                                            >
                                                <SwitchItem disabled={this.props.disabled} />
                                            </FormItem>
                                            <FormItem shouldUpdate noStyle>
                                                {({ getFieldValue }) => {
                                                    if (getFieldValue('sectorCOFEPRIS')) {
                                                        return (
                                                            <>
                                                                <FormItem label="Sector COFEPRIS" name="sectorCOFEPRISId" margin required>
                                                                    <SelectItem disabled={this.props.disabled} placeholder="Sector COFEPRIS" width="100%">
                                                                        {this.state.objetoSectoresCOF}
                                                                    </SelectItem>
                                                                </FormItem>
                                                                <FormItem
                                                                    name="nombreIngredienteActivo"
                                                                    label="Ingrediente Activo"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para expresar el nombre común del ingrediente activo de los precursores, químicos de uso dual, plaguicidas o fertilizantes que se trasladan a través de los distintos medios de transporte" }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Ingrediente Activo" />
                                                                </FormItem>
                                                                <FormItem
                                                                    name="nombreQuimico"
                                                                    label="Nombre Quimico"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para expresar el nombre de la sustancia activa de los precursores, químicos de uso dual o sustancias tóxicas que se traslada a través de los distintos medios de transporte." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Nombre Quimico" />
                                                                </FormItem>
                                                                <FormItem
                                                                    name="denominacionGenericaProd"
                                                                    label="Denominacion Generica"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para expresar el fármaco o la sustancia activa del medicamento, psicotrópico o estupefaciente que se traslada a través de los distintos medios de transporte." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Denominacion Generica" />
                                                                </FormItem>
                                                                <FormItem
                                                                    name="denominacionDistintivaProd"
                                                                    label="Denominacion Distintiva"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para expresar la marca con la que se comercializa el producto o nombre que le asigna el laboratorio o fabricante a sus especialidades farmacéuticas con el fin de distinguirlas de otras similares del medicamento, psicotrópico o estupefaciente que se traslada a través de los distintos medios de transporte." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Denominacion Distintiva" />
                                                                </FormItem>
                                                            </>
                                                        )
                                                    }
                                                }}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem shouldUpdate noStyle>
                                                {({ getFieldValue }) => {
                                                    if (getFieldValue('sectorCOFEPRIS')) {
                                                        return (
                                                            <>
                                                                <FormItem
                                                                    name="fabricante"
                                                                    label="Fabricante"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para expresar el nombre o razón social del establecimiento que realiza la fabricación o manufactura del medicamento, precursor, químico de uso dual, psicotrópico o estupefaciente que se traslada a través de los distintos medios de transporte." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Fabricante" />
                                                                </FormItem>
                                                                <FormItem label="Forma Farmaceutica" name="formaFarmaceuticaId" margin>
                                                                    <SelectItem disabled={this.props.disabled} placeholder="Forma Farmaceutica" width="100%">
                                                                        {this.state.objetoFormasFarmaceuticas}
                                                                    </SelectItem>
                                                                </FormItem>
                                                                <FormItem label="Condiciones Especiales" tooltip={{ title: "Atributo condicional para expresar la condición en la cual es necesario mantener el medicamento, precursor, químico de uso dual, psicotrópicos o estupefacientes durante el traslado y almacenamiento." }} name="condicionEspecialId" margin>
                                                                    <SelectItem disabled={this.props.disabled} placeholder="Condiciones Especiales" width="100%">
                                                                        {this.state.objetoCondicionesEspeciales}
                                                                    </SelectItem>
                                                                </FormItem>
                                                                <FormItem
                                                                    name="registroSanitarioFolioAutorizacion"
                                                                    label="Registro Sanitario"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para expresar el registro sanitario o folio de autorización con el que cuenta la empresa para el traslado del medicamento, psicotrópico o estupefaciente." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Registro Sanitario" />
                                                                </FormItem>
                                                                <FormItem
                                                                    name="numRegSanPlagCOFEPRIS"
                                                                    label="Numero Registro"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para expresar el número de registro sanitario para plaguicidas o fertilizantes cuya importación, comercialización y uso están permitidos en México, mismo que emite la Comisión Intersecretarial para el Control del Proceso y Uso de Plaguicidas, Fertilizantes y Sustancias Tóxicas (CICLOPLAFEST)." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Numero Registro" />
                                                                </FormItem>
                                                                <FormItem
                                                                    name="datosFabricante"
                                                                    label="Datos Fabricante"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para registrar el país y nombre o razón social de quien produce o fabrica el ingrediente activo del plaguicida o fertilizante." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Datos Fabricante" />
                                                                </FormItem>
                                                                <FormItem
                                                                    name="datosFormulador"
                                                                    label="Datos Formulador"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para registrar el país y nombre o razón social de quien formula el ingrediente activo del plaguicida o fertilizante." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Datos Formulador" />
                                                                </FormItem>
                                                                <FormItem
                                                                    name="datosMaquilador"
                                                                    label="Datos Maquilador"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para registrar el país y nombre o razón social de quien maquila el ingrediente activo del plaguicida o fertilizante." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Datos Maquilador" />
                                                                </FormItem>
                                                                <FormItem
                                                                    name="usoAutorizado"
                                                                    label="Uso Autorizado"
                                                                    margin
                                                                    labelCol={{ span: 8 }}
                                                                    wrapperCol={{ span: 16 }}
                                                                    pattern
                                                                    tooltip={{ title: "Atributo condicional para registrar el uso autorizado del plaguicida o fertilizante de acuerdo a la regulación del país." }}
                                                                >
                                                                    <InputItem disabled={this.props.disabled} placeholder="Uso Autorizado" />
                                                                </FormItem>
                                                            </>
                                                        )
                                                    }
                                                }}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </BoxItem>
                                <HeaderItem>DETALLE MERCANCIA</HeaderItem>
                                <BoxItem>
                                    {this.state.unidadPeso ?
                                        <p style={{ textAlign: 'center' }}>Unidad de peso: {this.state.unidadPeso.nombre} - {this.state.unidadPeso.unidadSat.c_ClaveUnidad}</p>
                                        : null}
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                                label="Peso Bruto"
                                                name="pesoBruto"
                                                margin
                                                numberCero
                                            >
                                                <InputNumberItem disabled={this.props.disabled} placeholder="Peso Bruto" />
                                            </FormItem>
                                            <FormItem
                                                label="Peso Neto"
                                                name="pesoNeto"
                                                margin
                                                numberCero
                                            >
                                                <InputNumberItem disabled={this.props.disabled} placeholder="Peso Neto" />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label="Peso Tara"
                                                name="pesoTara"
                                                margin
                                                numberCero
                                            >
                                                <InputNumberItem disabled={this.props.disabled} placeholder="Peso Tara" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </BoxItem>
                            </TabPane>
                        </Tabs>
                    </Form>
                </ModalItem>
                <AgregarUnidad
                    visible={this.state.modalAgregarUnidad}
                    cerrarAgregarUnidad={this.cerrarAgregarUnidad.bind(this)}
                />
            </Spin>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        usuarioId: state.user.usuarioId,
    };
};

export default connect(mapStateToProps)(TabEnvio);
