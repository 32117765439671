import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Select,
  Form as FormInitial,
  notification,
  Tabs,
} from "antd";
import {
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  NotificacionPolizas,
  HeaderItem,
  BoxItem,
  ButtonItem,
  SwitchItem,
  ErrorItem,
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import cartaPortePdf from "../../../../Pdf/cartaPortePdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MandarCorreoCartaPorte from "../MandarCorreoCartaPorte/MandarCorreoCartaPorte";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;
const { TabPane } = Tabs;

moment.locale("es");

class GenerarEnvio extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    datosVenta: undefined,
    todasInsumos: null,
    objetoInsumos: null,
    todasDireccionesCliente: null,
    objetoDireccionesCliente: null,
    todasProyectosFisicos: null,
    objetoProyectosFisicos: null,
    todasProyectosMoviles: null,
    objetoProyectosMoviles: null,
    todasProyectos: null,
    objetoProyectos: null,
    todasUbicacionesPolos: null,
    objetoUbicacionesPolos: null,
    todasChoferes: null,
    objetoChoferes: null,
    todasRemolques: null,
    objetoRemolques: null,
    id: this.props.location.state.id,
    modalVerCartaPorte: false,
    modalVerCartaPorteId: null,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "envios/datosEnvioVenta",
        { empresaId: this.props.empresaId, ventaId: this.state.id },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.setState({ todasRemolques: res.data.remolques, todasChoferes: res.data.choferes, todasProyectos: res.data.todosProyectos, todasDireccionesCliente: res.data.direccionesCliente, datosVenta: res.data.venta, todasInsumos: res.data.insumos, todasProyectosFisicos: res.data.proyectosFisicos, todasProyectosMoviles: res.data.proyectosMoviles, todasUbicacionesPolos: res.data.ubicacionesPolos });
          this.setState({
            objetoDireccionesCliente: res.data.direccionesCliente.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombreDireccion}
                </Option>
              );
            }),
            objetoInsumos: res.data.insumos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
            objetoProyectosFisicos: res.data.proyectosFisicos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoProyectosMoviles: res.data.proyectosMoviles.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoProyectos: res.data.todosProyectos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoUbicacionesPolos: res.data.ubicacionesPolos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave + ' - ' + value.Descripcion}
                </Option>
              );
            }),
            objetoChoferes: res.data.choferes.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
            objetoRemolques: res.data.remolques.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.descripcion + ' - ' + value.placa}
                </Option>
              );
            }),
          });
          this.formRef.current.setFieldsValue({
            insumos: res.data.venta.insumos.map(value => {
              return { ...value, precioUnitarioAjustado: (value.precioUnitario * (1 - (value.descuento / 100))) }
            }), proyectoOrigenId: res.data.venta.proyectoId
          });
          let arrayValue = this.formRef.current.getFieldValue("insumos");
          arrayValue.map((value, key) => {
            this.onChangeInsumoProyecto('', key)
            return null
          })

        } else {
          notification.warning({
            message: 'No se puede generar el envio',
            description: res.data.message
          })
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    let entregarTodos = 0;
    values.insumos = values.insumos.map(value => {
      let entregar = value.entregar ? value.entregar : 0
      entregarTodos += entregar
      return { ...value, entregar }
    })
    if (entregarTodos === 0) return message.info('Se neceitan cantidades para poder hacer esta operación')
    this.setState({ loading: true });
    axios
      .post(
        "envios/envioVenta",
        {
          ...values,
          empresaId: this.props.empresaId,
          ventaId: this.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false })
        if (res.data.success === 2) {
          message.success("Se ha registrado un envio");
          if (res.data.respuestaFactura.status === "success") {
            notification.success({
              description: "Haga click para descargar",
              message: "Carta Porte Emitida",
              duration: 0,
              style: { cursor: "pointer" },
              onClick: () => {
                var xmltext = res.data.respuestaFactura.data.cfdi;

                const dataSat = res.data.respuestaFactura.dataSat
                const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'CP') + ' - ' + dataSat.Folio

                var filename = nombreArchivo + ".xml";
                var pom = document.createElement("a");
                var bb = new Blob([xmltext], { type: "text/plain" });

                pom.setAttribute("href", window.URL.createObjectURL(bb));
                pom.setAttribute("download", filename);

                pom.dataset.downloadurl = [
                  "text/plain",
                  pom.download,
                  pom.href,
                ].join(":");
                pom.draggable = true;
                pom.classList.add("dragout");

                pom.click();

                const pdf = cartaPortePdf(res.data);

                pdfMake.createPdf(pdf).download(nombreArchivo + '.pdf');
              },
            });
            NotificacionPolizas(res.data.polizasId);
            this.setState({
              modalVerCartaPorte: true,
              modalVerCartaPorteId: res.data.envioId,
            });
          } else {
            message.success('Se ha generado un envio')
            NotificacionPolizas(res.data.polizasId);
            this.atras()
          }
        } else if (res.data.success === 1) {
          message.error("No se pudo registrar el envio");
          notification.warning({
            description: res.data.description,
            message: res.data.message,
            duration: 0,
          });
        } else {
          message.error("Hubo un error y el envio no se registro");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }
  async onChangeInsumoProyecto(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue("insumos");
    let proyectoId = arrayValue[key].proyectoId;
    const insumoId = arrayValue[key].insumoId;
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === insumoId
    );
    if (!insumo) return
    if (insumo.inventariable || insumo.insumoCompuesto) {
      await axios
        .post(
          "inventarios/inventarioInsumoProyecto",
          {
            proyectoId: proyectoId,
            insumoId: insumoId,
            noPrincipal: insumo.noPrincipal,
            insumoCompuesto: insumo.insumoCompuesto,
            empresaId: this.props.empresaId,
          },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          this.setState({ loading: false });
          arrayValue[key].cantidadProyecto = res.data.cantidad;
        });
    } else {
      arrayValue[key].cantidadProyecto = "No inventariable";
    }
    this.formRef.current.setFieldsValue({
      insumos: arrayValue,
    });
  }
  cerrarMandarCorreoCartaPorte() {
    this.setState({
      modalVerCartaPorte: false,
      modalVerCartaPorteId: null,
    });
    this.formRef.current.resetFields();
    this.atras();
  }

  onChangeAutomovil(value) {
    const proyectoTraslado = this.state.todasProyectosMoviles.find(valueTA => valueTA._id.toString() === value.toString())

    if (proyectoTraslado.remolquesIds) {

      let remolquesIds = []

      for (const remolque of proyectoTraslado.remolquesIds) {
        const indexR = this.state.todasRemolques.find(valueTR => valueTR._id.toString() === remolque.toString())

        if (indexR !== -1) {
          remolquesIds.push(remolque)
        }

        this.formRef.current.setFieldsValue({ remolquesIds })
      }


    } else {
      this.formRef.current.setFieldsValue({ remolquesIds: [] })
    }
  }

  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Generar Envio sobre Venta"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <h1>
              Fecha:{" "}
              {moment(this.state.datosVenta?.createdAt).format("LL")}
            </h1>
          }
        />
        <div style={{ backgroundColor: "white", paddingRight: 20, paddingLeft: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="generarEnvioVenta"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Generar Envio
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Tabs defaultActiveKey={1}>
                <TabPane tab="General" tabKey={1} key={1} forceRender>

                  <HeaderItem>GENERAL</HeaderItem>
                  <BoxItem>

                    <Row>
                      <Col span={12}>
                        <FormItem
                          label="Generar Carta Porte"
                          name="generarCarta"
                          margin
                          initialValue={this.props.location.state.certificada}
                          valuePropName="checked"
                        >
                          <SwitchItem disabled={!this.props.location.state.certificada} />
                        </FormItem>
                        <FormItem label="Proyecto Origen" name="proyectoOrigenId" margin required>
                          <SelectItem placeholder="Proyecto Origen">
                            {this.state.objetoProyectosFisicos}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Destino" name="direccionClienteDestinoId" margin required>
                          <SelectItem placeholder="Destino">
                            {this.state.objetoDireccionesCliente}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Automovil Traslado" name="proyectoTransporteId" margin required>
                          <SelectItem onChange={this.onChangeAutomovil.bind(this)} placeholder="Automovil Traslado">
                            {this.state.objetoProyectosMoviles}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Remolques" name="remolquesIds" margin>
                          <SelectItem placeholder="Remolques" mode="multiple" allowClear>
                            {this.state.objetoRemolques}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Choferes" name="choferesIds" margin>
                          <SelectItem placeholder="Choferes" mode="multiple" allowClear>
                            {this.state.objetoChoferes}
                          </SelectItem>
                        </FormItem>

                      </Col>
                      <Col span={12}>
                        <FormItem
                          label="Transporte Internacional"
                          name="tranporteInt"
                          margin
                          initialValue={false}
                          valuePropName="checked"
                        >
                          <SwitchItem disabled />
                        </FormItem>
                        <FormItem
                          label='Distancia Recorrida'
                          placeholder='Distancia Recorrida'
                          name='totalDistRecorrida'
                          number
                          margin
                        >
                          <InputNumberItem />
                        </FormItem>
                        <FormItem
                          label="Registro ISTMO"
                          name="registroISTMO"
                          margin
                          initialValue={false}
                          valuePropName="checked"
                        >
                          <SwitchItem />
                        </FormItem>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            if (getFieldValue('registroISTMO')) {
                              return <>
                                <FormItem label="Ubicacion Polo Origen" name="ubicacionPoloOrigenId" margin required>
                                  <SelectItem placeholder="Ubicacion Polo Origen">
                                    {this.state.objetoUbicacionesPolos}
                                  </SelectItem>
                                </FormItem>
                                <FormItem label="Ubicacion Polo Destino" name="ubicacionPoloDestinoId" margin required>
                                  <SelectItem placeholder="Ubicacion Polo Destino">
                                    {this.state.objetoUbicacionesPolos}
                                  </SelectItem>
                                </FormItem>
                              </>
                            }
                          }}
                        </FormItem>
                      </Col>
                    </Row>

                  </BoxItem>

                  <HeaderItem>FECHAS Y HORAS</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={12}>

                        <FormItem
                          label="Fecha de Salida"
                          name="fechaSalida"
                          required
                          margin
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={moment().hour(12).minute(0)}
                        >
                          <DatePickerItem
                            placeholder="Fecha de Salida"
                            allowClear={false}
                            showTime
                            dateFormat='DD/MM/YYYY HH:mm'
                          />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          label="Fecha de Llegada"
                          name="fechaLlegada"
                          required
                          margin
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={moment().hour(12).minute(0)}
                        >
                          <DatePickerItem
                            placeholder="Fecha de Llegada"
                            allowClear={false}
                            showTime
                            dateFormat='DD/MM/YYYY HH:mm'
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </BoxItem>

                </TabPane>
                <TabPane tab="Insumos" tabKey={2} key={2} forceRender>

                  <FormInitial.List name="insumos">
                    {(fields) => (
                      <>
                        <table className="table">
                          {fields.length > 0 ? (
                            <thead>
                              <tr>
                                <th className="th-border" style={{ width: "20%" }}>
                                  Insumo
                                </th>
                                <th className="th-border" style={{ width: "20%" }}>
                                  Proyecto
                                </th>
                                <th className="th-border" style={{ width: "10%" }}>
                                  Cantidad Proyecto
                                </th>
                                <th className="th-border" style={{ width: "16%" }}>
                                  Precio Unitario
                                </th>
                                <th className="th-border" style={{ width: "10%" }}>
                                  Cantidad Pedida
                                </th>
                                <th className="th-border" style={{ width: "10%" }}>
                                  Cantidad Entregada
                                </th>
                                <th className="th-border" style={{ width: "14%" }}>
                                  Cantidad a Enviar
                                </th>
                              </tr>
                            </thead>
                          ) : null}
                          {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                            <tbody key={fieldKey}>
                              <tr>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "insumoId"]}
                                    fieldKey={[fieldKey, "insumoId"]}
                                    required
                                    noStyle
                                  >
                                    <SelectItem
                                      sinBorde
                                      style={{ width: "100%" }}
                                      placeholder="Insumo"
                                      disabled
                                    >
                                      {this.state.objetoInsumos}
                                    </SelectItem>
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "proyectoId"]}
                                    fieldKey={[fieldKey, "proyectoId"]}
                                    required
                                    noStyle
                                  >
                                    <SelectItem
                                      sinBorde
                                      style={{ width: "100%" }}
                                      placeholder="Proyecto"
                                      onChange={(value) =>
                                        this.onChangeInsumoProyecto(
                                          value,
                                          arrayKey
                                        )
                                      }
                                    >
                                      {this.state.objetoProyectos}
                                    </SelectItem>
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "cantidadProyecto"]}
                                    required
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      disabled
                                      placeholder="Cantidad en Proyecto"
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "precioUnitarioAjustado"]}
                                    fieldKey={[fieldKey, "precioUnitarioAjustado"]}
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      placeholder="Precio Unitario"
                                      disabled
                                      dineroDisabled
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "cantidad"]}
                                    fieldKey={[fieldKey, "cantidad"]}
                                    required
                                    number
                                    noStyle
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      placeholder="Cantidad Vendida"
                                      disabled
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "entregado"]}
                                    fieldKey={[fieldKey, "entregado"]}
                                    required
                                    numberCero
                                    noStyle
                                    initialValue={0}
                                  >
                                    <InputNumberItem
                                      sinBorde
                                      placeholder="Cantidad Entregada"
                                      disabled
                                    />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem shouldUpdate noStyle>
                                    {({ getFieldValue }) => {
                                      let cantidad =
                                        this.state.datosVenta.insumos[
                                          fieldKey
                                        ].cantidad;
                                      let entregado =
                                        this.state.datosVenta.insumos[
                                          fieldKey
                                        ].entregado;
                                      let max;
                                      if (entregado) {
                                        max = cantidad - entregado;
                                      } else {
                                        max = cantidad;
                                      }
                                      const insumos = getFieldValue("insumos");
                                      const cantidadProyecto =
                                        insumos[arrayKey].cantidadProyecto;
                                      let maxProyecto = 0;
                                      if (
                                        cantidadProyecto === "No inventariable"
                                      ) {
                                        maxProyecto = Infinity;
                                      } else {
                                        maxProyecto = cantidadProyecto;
                                      }
                                      const maxDisabled = Number(Math.min(max, maxProyecto))
                                      return (
                                        <FormItem
                                          {...restField}
                                          name={[name, "entregar"]}
                                          fieldKey={[fieldKey, "entregar"]}
                                          number
                                          noStyle
                                          max={maxDisabled}
                                          type="number"
                                        >
                                          <InputNumberItem
                                            sinBorde
                                            placeholder={"Max: " + maxDisabled}
                                          />
                                        </FormItem>
                                      );
                                    }}
                                  </FormItem>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </>
                    )}
                  </FormInitial.List>
                </TabPane>
              </Tabs>
              {/* <Row>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Proyecto: {this.state.datosVenta?.proyecto.nombre}
                  </h1>
                  <p>
                    Descripcion:{" "}
                    {this.state.datosVenta?.proyecto.descripcion}
                  </p>
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>Dirección</h1>
                      <p style={{ margin: 0 }}>
                        Calle: {this.state.datosVenta?.proyecto.calle}
                      </p>
                      <p style={{ margin: 0 }}>
                        Numero:{" "}
                        {this.state.datosVenta?.proyecto.numeroCalle}
                      </p>
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Cliente:{" "}
                    {this.state.datosVenta?.cliente.nombre
                      ? this.state.datosVenta?.cliente.nombre +
                        " " +
                        this.state.datosVenta?.cliente
                          .apellidoPaterno +
                        " " +
                        this.state.datosVenta?.cliente.apellidoMaterno
                      : this.state.datosVenta?.cliente.razonSocial}
                  </h1>
                  <p style={{ margin: 0 }}>
                    Rfc: {this.state.datosVenta?.cliente.rfc}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo: {this.state.datosVenta?.cliente.correo}
                  </p>
                  <p style={{ margin: 0 }}>
                    Telefono:{" "}
                    {this.state.datosVenta?.cliente.telefono}
                  </p>
                  <p style={{ margin: 0 }}>
                    Celular: {this.state.datosVenta?.cliente.celular}
                  </p>
                </Col>
                <Col span={6}>
                  <p style={{ margin: 0 }}>
                    Contacto:{" "}
                    {this.state.datosVenta?.cliente.nombreContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Tel. Contacto:{" "}
                    {this.state.datosVenta?.cliente.telefonoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo Contacto:{" "}
                    {this.state.datosVenta?.cliente.correoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Dias de crédito:{" "}
                    {this.state.datosVenta?.cliente.diasCredito}
                  </p>
                </Col>
              </Row>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} /> */}
              {/* <Row>
                <Col span={8}></Col>
                <Col span={8}>
                <FormItem
                    label="Fecha de Entrega"
                    name="fechaEntrega"
                    required
                    margin
                    initialValue={moment()}
                  >
                    <DatePickerItem
                      placeholder="Fecha de Entrega"
                      allowClear={false}
                    />
                  </FormItem>
                </Col>
                <Col span={8}></Col>
              </Row> */}
            </Form>
          </Spin>
        </div>
        <MandarCorreoCartaPorte
          visible={this.state.modalVerCartaPorte}
          id={this.state.modalVerCartaPorteId}
          history={this.props.history}
          cerrarMandarCorreoCartaPorte={this.cerrarMandarCorreoCartaPorte.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    certificada: state.empresa.certificada,
  };
};

export default connect(mapStateToProps)(GenerarEnvio);
