import { Collapse, Row, Col, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  dineroDisabledMask,
  ModalItem,
  ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";
import CancelarPago from "../CancelarPago/CancelarPago";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MandarCorreoComplemento from "../../CuentasPorCobrar/MandarCorreoComplemento/MandarCorreoComplemento";
import MandarCorreoPago from "../../CuentasPorCobrar/MandarCorreoPago/MandarCorreoPago";
import CancelarPagoPdf from "../CancelarPago/CancelarPagoPdf/CancelarPagoPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Panel } = Collapse;

class PagosVenta extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pagosHtml: <p>No se encontro ningun pago</p>,
    modalCancelarPago: false,
    modalCancelarPagoId: null,
    modalVerComplemento: false,
    modalVerComplementoId: null,
    modalVerPago: false,
    modalVerPagoId: null,
    modalCancelarPagoPdf: false,
    modalCancelarPagoPdfId: null,
  };

  cerrarModalCancelarPdf() {
    this.setState({
      modalCancelarPagoPdf: false,
      modalcancelarPdfPagoId: null,
    });
  }


  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "pagos/pagosVenta",
        { ventaId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) return;
        const html = (
          <Collapse style={{ marginBottom: 10 }}>
            {res.data.map((valuePago) => {
              return (
                <Panel
                  extra={
                    valuePago.estado !== 0 ? (
                      valuePago.moneda.abreviacion + ' - ' + dineroDisabledMask(valuePago.monto)
                    ) : (
                      <Tag color="red">
                        Cancelada: {valuePago.moneda.abreviacion + ' - ' + dineroDisabledMask(valuePago.monto)}
                      </Tag>
                    )
                  }
                  header={moment(valuePago.fechaPago).locale("es").format("ll")}
                  key={valuePago._id}
                >
                  <Row>
                    <Col span={12}>
                      <p>
                        Dia del Pago:{" "}
                        {moment(valuePago.fechaPago).locale("es").format("ll")}
                      </p>
                      <p>
                        Complemento de Pago:{" "}
                        {valuePago.complemento ? "Si" : "No"}
                      </p>
                      <p>
                        Nota De Credito:{" "}
                        {valuePago.notaDeCreditoId ? "Si" : "No"}
                      </p>
                      <Row>
                        <Col span={12}>
                          {valuePago.complemento && (
                            <ButtonItem type="primary" onClick={() => {
                              this.setState({ modalVerComplemento: true, modalVerComplementoId: valuePago._id })
                            }}>
                              Ver Complemento
                            </ButtonItem>
                          )}
                        </Col>
                        <Col span={12}>
                          {valuePago.jsonPago && (
                            <ButtonItem type="primary" onClick={() => {
                              this.setState({ modalVerPago: true, modalVerPagoId: valuePago._id })
                            }}>
                              Ver Pago
                            </ButtonItem>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <p>
                        Dia de registro:{" "}
                        {moment(valuePago.createdAt).locale("es").format("ll")}
                      </p>
                      <p>Monto: {valuePago.moneda.abreviacion + ' - ' + dineroDisabledMask(valuePago.monto)}</p>
                      {valuePago.estado !== 0 && !valuePago.contado && !valuePago.pagarVenta && !valuePago.devolverDinero && (
                        <ButtonItem type="primary" danger onClick={() => {
                          this.setState({ modalCancelarPago: true, modalCancelarPagoId: valuePago._id })
                        }}>
                          Cancelar Pago
                        </ButtonItem>
                      )}
                      {valuePago.estado === 0 && (<ButtonItem type="primary" danger onClick={() => {
                        this.setState({ modalCancelarPagoPdf: true, modalCancelarPagoPdfId: valuePago._id })
                      }}>
                        Ver cancelacion
                      </ButtonItem>)}
                    </Col>
                  </Row>
                </Panel>
              );
            })}
          </Collapse>
        );
        this.setState({ pagosHtml: html });
      });
    this.setState({ loading: false });
  }
  cerrarPagosVenta() {
    this.props.cerrarPagosVenta();
  }
  cerrarCancelarPago(abrirPdf) {
    if(abrirPdf){
      this.setState({
        modalCancelarPago: false,
        modalCancelarPagoId: null,
        modalCancelarPagoPdf: true, 
        modalCancelarPagoPdfId: this.state.modalCancelarPagoId
      });
      this.consultarTodo()
    }else{
      this.setState({
        modalCancelarPago: false,
        modalCancelarPagoId: null,
      });
      this.consultarTodo()
    }
  }
  cerrarVerComplemento() {
    this.setState({
      modalVerComplemento: false,
      modalVerComplementoId: null,
    });
    this.consultarTodo()
  }
  cerrarMandarCorreoPago() {
    this.setState({
      modalVerPago: false,
      modalVerPagoId: null,
    });
    this.consultarTodo()
  }
  render() {
    return (
      <>
        <ModalItem
          title="Pagos Venta"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarPagosVenta.bind(this)}
          paddingTop
          zIndex={1}
        >
          {this.state.pagosHtml}
        </ModalItem>
        <CancelarPago
          visible={this.state.modalCancelarPago}
          id={this.state.modalCancelarPagoId}
          cerrarCancelarPago={this.cerrarCancelarPago.bind(this)}
        />
        <MandarCorreoComplemento
          visible={this.state.modalVerComplemento}
          id={this.state.modalVerComplementoId}
          cerrarMandarCorreoComplemento={this.cerrarVerComplemento.bind(this)}
        />
        <MandarCorreoPago
          visible={this.state.modalVerPago}
          id={this.state.modalVerPagoId}
          cerrarMandarCorreoPago={this.cerrarMandarCorreoPago.bind(this)}
        />
        <CancelarPagoPdf
          visible={this.state.modalCancelarPagoPdf}
          pagoId={this.state.modalCancelarPagoPdfId}
          onCancel={this.cerrarModalCancelarPdf.bind(this)}
          cerrarModalCancelarPdf={this.cerrarModalCancelarPdf.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(PagosVenta);
