import { message, Space, notification, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
  ButtonItem,
  Form,
  FormItem,
  InputItem,
  SelectItem,
  ErrorItem,
} from "../../../../Components/Items";
import ModalCancelarPdf from "./ModalCancelarPdf/ModalCancelarPdf";

const { Option } = Select;

class CancelarVenta extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    modalPdfVisible: false, 
    ventaId: null, 
  };

  onFinishFailed(e) {
    ErrorItem(e);
  }

  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "ventas/cancelarVenta",
        { ...values, ventaId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Venta cancelada");
        } else if (res.data.success === 1) {
          notification.error({
            message: "Error",
            description: res.data.message,
            duration: null,
          });
        } else {
          message.error("Sucedió un error y no se pudo cancelar");
        }
        this.formRef.current.resetFields();
        this.setState({ loading: false });
        this.mostrarModalPdf(); // Mostrar el modal de PDF
      });
  }

  mostrarModalPdf() {
    this.setState({
      modalPdfVisible: true, // Mostrar ModalCancelarPdf
      ventaId: this.props.id, // Almacenar el ID de la venta para el modal de PDF
    });
  }

  cerrarModalPdf = () => {
    this.setState({ modalPdfVisible: false }); // Cerrar ModalCancelarPdf
  };

  regresar = () => {
    this.props.cerrarCancelarVenta(); // Solo cerrar el modal principal
  };

  render() {
    return (
      <>
        <ModalItem
          title="Cancelar Venta"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.regresar} // Cerrar modal al presionar "Regresar"
          paddingTop
        >
          <Form
            ref={this.formRef}
            name="cancelarVenta"
            layout
            width={700}
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
            <FormItem
              label="Motivo"
              name="motivo"
              required
              pattern
              margin
              max={200}
            >
              <SelectItem placeholder="Motivo">
                <Option value="01">
                  01 - Comprobante emitido con errores con relación.
                </Option>
                <Option value="02">
                  02 - Comprobante emitido con errores sin relación.
                </Option>
                <Option value="03">03 - No se llevó a cabo la operación.</Option>
                <Option value="04">
                  04 - Operación nominativa relacionada en una factura global.
                </Option>
              </SelectItem>
            </FormItem>
            <FormItem
              label="Folio Sustitución"
              name="folioSustitucion"
              pattern
              margin
              max={200}
            >
              <InputItem placeholder="Folio de Sustitución" />
            </FormItem>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              <Space>
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Cancelar Venta
                </ButtonItem>
                <ButtonItem
                  type="primary"
                  danger
                  onClick={this.regresar} // Nueva función para cerrar el modal principal
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Regresar
                </ButtonItem>
              </Space>
            </div>
          </Form>
        </ModalItem>
        <ModalCancelarPdf
          visible={this.state.modalPdfVisible}
          ventaId={this.state.ventaId} // Pasar el ID como prop
          onCancel={this.cerrarModalPdf}
          cerrarModalPdf={this.cerrarModalPdf}
        />
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CancelarVenta);
