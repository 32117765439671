import { PageHeader, Popconfirm, Select, Space, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonItem, SelectItem, TableItem } from "../../../Components/Items";
import AgregarControlInventario from "./AgregarControlInventario/AgregarControlInventario";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EditarControlInventario from "./EditarControlInventario/EditarControlInventario";

const { Option } = Select;

class ControlInventario extends Component {
  state = {
    loading: false,
    objetoProyectos: [],
    todasProyectos: [],
    todasControlInventario: [],
    proyecto: undefined,
    proyectoId: undefined,
    modalAgregarControl: false,
    modalEditarControl: false,
    modalEditarControlId: false,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    axios
      .post(
        "controlInventario/selectControlInventario",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasControlInventario: res.data.map((value) => {
              return {
                ...value,
                codigo: value.insumo.codigo,
                descripcion: value.insumo.descripcion,
                accion: { _id: value._id, estado: value.estado },
              };
            }),
          });
        }
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  onChangeProyecto(value) {
    if (value === 0) {
      this.setState({ loading: true, proyecto: undefined, proyectoId: 0 });
      axios
        .post(
          "controlInventario/selectControlInventario",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            this.setState({ todasControlInventario: [] })
          } else {
            this.setState({
              todasControlInventario: res.data
            });
          }
          this.setState({ loading: false });
        });
    } else {
      const proyecto = this.state.todasProyectos.find(valueState => valueState._id.toString() === value.toString())
      this.setState({ loading: true, proyecto: proyecto.nombre, proyectoId: proyecto._id });
      axios
        .post(
          "controlInventario/selectControlInventario",
          { empresaId: this.props.empresaId, proyectoId: value },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
            this.setState({ todasControlInventario: [] })
          } else {
            this.setState({
              todasControlInventario: res.data
            });
          }
          this.setState({ loading: false });
        });

    }
  }
  abrirAgregarControlInventario() {
    this.setState({
      modalAgregarControl: true
    })
  }
  cerrarAgregarControlInventario() {
    this.setState({
      modalAgregarControl: false,
      proyectoId: 0,
      loading: true
    })
    this.onChangeProyecto(0)
  }
  abrirEditarControlInventario(id) {
    this.setState({
      modalEditarControl: true,
      modalEditarControlId: id
    })
  }
  cerrarEditarControlInventario() {
    this.setState({
      modalEditarControl: false,
      modalEditarControlId: undefined,
      proyectoId: 0,
      loading: true
    })
    this.onChangeProyecto(0)
  }
  eliminarControlInventario(value) {
    axios
      .post(
        "controlInventario/eliminarControl",
        { controlId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Control eliminado");
        } else {
          message.error("Sucedio un error y no se pudo eliminar");
        }
        this.onChangeProyecto(0);
      });
  }
  render() {
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
        width: "10%",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        defaultSortOrder: 'ascend',
        buscar: "descripcion",
        width: "35%",
      },
      {
        title: "Proyecto",
        dataIndex: "proyecto",
        key: "proyecto",
        sorter: (a, b) => {
          if (a.proyecto < b.proyecto) {
            return -1;
          }
          if (a.proyecto > b.proyecto) {
            return 1;
          }
          return 0;
        },
        buscar: "proyecto",
        width: "20%",
      },
      {
        title: "Mín.",
        dataIndex: "min",
        key: "min",
        sorter: (a, b) => {
          if (a.min < b.min) {
            return -1;
          }
          if (a.min > b.min) {
            return 1;
          }
          return 0;
        },
        width: "10%",
      },
      {
        title: "Máx.",
        dataIndex: "max",
        key: "max",
        sorter: (a, b) => {
          if (a.max < b.max) {
            return -1;
          }
          if (a.max > b.max) {
            return 1;
          }
          return 0;
        },
        width: "10%",
      },
      {
        title: "Unidad",
        dataIndex: "unidad",
        key: "unidad",
        render: (value) => value.nombre,
        width: "10%",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarControl && 
            <EditOutlined
              onClick={() => this.abrirEditarControlInventario(value._id)}
              style={{ color: "blue" }}
            />}
            {this.props.permisoEliminarControl &&
            <Popconfirm
              title="Seguro que quieres eliminar este control"
              onConfirm={() => this.eliminarControlInventario(value._id)}
              okText="Si"
              cancelText="No"
            >
              <DeleteOutlined style={{ color: "red" }} />
            </Popconfirm>}
          </Space>
        ),
        width: "10%",
      },
    ];
    return (
      <>
        <PageHeader
          title={this.state.proyecto ? "Control Inventario: " + this.state.proyecto : "Control Inventario: Todos"}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <Space style={{ width: "100%" }}>
              <SelectItem
                placeholder="Proyecto"
                width={200}
                onChange={this.onChangeProyecto.bind(this)}
                value={this.state.proyectoId}
                defaultValue={0}
              >
                <Option value={0}>Todos</Option>
                {this.state.objetoProyectos}
              </SelectItem>
              {this.props.permisoAgregarControl &&
              <ButtonItem
                type="primary"
                key="agregarControl"
                onClick={this.abrirAgregarControlInventario.bind(this)}
              >
                Agregar Control
              </ButtonItem>}
            </Space>
          }
        />
        <TableItem
          size="small"
          dataSource={this.state.todasControlInventario}
          columns={columns}
          pagination={false}
          loading={this.state.loading}
        //   onRow={(record, rowIndex) => {
        //     return {
        //       onDoubleClick: (event) => {
        //         this.props.history.push("/panel/inventarioInsumo", { id: record.insumo._id, proyectoId: this.state.proyectoId });
        //       },
        //     };
        //   }}
        />
        <AgregarControlInventario
          visible={this.state.modalAgregarControl}
          cerrarAgregarControlInventario={this.cerrarAgregarControlInventario.bind(
            this
          )}
        />
        <EditarControlInventario
          visible={this.state.modalEditarControl}
          id={this.state.modalEditarControlId}
          cerrarEditarControlInventario={this.cerrarEditarControlInventario.bind(
            this
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarControl: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6750906b798d4e004368723a")?.activado,
    //permisoCancelarControl: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6750909c798d4e004368723b")?.activado,
    permisoEliminarControl: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "675090b1798d4e004368723c")?.activado,
    permisoEditarControl: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "675090c7798d4e004368723d")?.activado,
    
  };
};

export default connect(mapStateToProps)(ControlInventario);
