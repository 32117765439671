import React, { Component } from "react";
import {
    Switch,
    Button,
    message,
    Spin,
    Select,
    Divider,
    Row,
    Col,
} from "antd";
import { cuentaMask, Form, FormItem, SelectItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";

const { Option } = Select

class ComplementosConfiguracion extends Component {
    formRef = React.createRef();

    state = {
        loading: false,
        todasUnidadesPeso: null,
        objetoUnidadesPeso: null,
        todasUniadesDeNegocioFinales: null,
        objetoUniadesDeNegocioFinales: null,
    }

    componentDidMount() {
        this.consultarConfiguracion()
    }
    onFinish(values) {
        axios
            .post(
                "empresas/editarConfiguracionComplementos",
                { ...values, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Se ha modificado la configuracion de la empresa");
                    this.consultarConfiguracion();
                } else if (res.data.success === 1) {
                    message.error("No se pudo modificar la configuracion");
                } else {
                    message.error("Hubo un error y no se modifico la configuracion");
                }
            });
    }
    async consultarConfiguracion() {
        this.setState({ loading: true })
        await axios
            .post(
                "empresas/selectConfiguracionComplementos",
                { empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.status === 404) {
                    message.error("Error no se encontro configuracion de esta empresa");
                } else {
                    this.formRef.current.setFieldsValue({
                        envios:
                            res.data.envios,
                        fletes:
                            res.data.fletes,
                        unidadPesoId:
                            res.data.unidadPesoId,
                        complementoDetallista:
                            res.data.complementoDetallista,
                        modulosMezcal:
                            res.data.modulosMezcal,
                        modulosConsignaciones:
                            res.data.modulosConsignaciones,
                        equipoMedico:
                            res.data.equipoMedico,
                        lotesCaducidades:
                            res.data.lotesCaducidades,
                        unidadDeNegocioConsignacionesId:
                            res.data.unidadDeNegocioConsignacionesId,
                    });
                }
            });
        await axios
            .post(
                "unidades/selectUnidadesPeso",
                { empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.status === 404) {
                    message.error("No se encotraron unidades de peso");
                } else {
                    this.setState({
                        todasUnidadesPeso: res.data,
                        objetoUnidadesPeso: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.unidadSat.c_ClaveUnidad + " - " + value.nombre}
                                </Option>
                            );
                        }),
                    })
                }
            });
        await axios
            .post(
                "unidadesdenegocio/selectUnidadesDeNegocioFinales",
                { empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({ todasUniadesDeNegocioFinales: res.data });
                    this.setState({
                        objetoUniadesDeNegocioFinales: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {cuentaMask(
                                        value.nivel1 + value.nivel2 + value.nivel3 + value.nivel4 + value.nivel5
                                    ) +
                                        " - " +
                                        value.nombre}
                                </Option>
                            );
                        }),
                    });
                }
            });
        this.setState({ loading: false })
    }
    onFinishFailed(e) {
        e.errorFields.map((value, index) => {
            if (e.errorFields[index].name[1]) {
                message.error(
                    "Error: " +
                    e.errorFields[index].errors[0] +
                    " en la fila " +
                    (e.errorFields[index].name[1] + 1)
                );
            } else {
                message.error("Error: Falta un campo");
            }
            return null;
        });
    }
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    name="complementosConfiguracion"
                    layout
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Divider style={{ margin: 0 }}>Envios</Divider>
                    <Row>
                        <Col span={4} />
                        <Col span={10}>
                            <FormItem
                                label="Activar Envios"
                                name="envios"
                                margin
                                initialValue={false}
                                valuePropName="checked"
                                tooltip='Al activar esta casilla se activan los envios en el apartado de las ventas, tambien proporciona la carta porte'
                            >
                                <Switch />
                            </FormItem>
                        </Col>
                        <Col span={10}>
                            <FormItem shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                    if (getFieldValue('envios')) {
                                        return (
                                            <FormItem
                                                label="Activar Fletes"
                                                name="fletes"
                                                margin
                                                initialValue={false}
                                                valuePropName="checked"
                                                tooltip='Al activar esta casilla se activan los envios realizados a terceros, proporciona insumos fleteados en las ventas, tambien proporciona la carta porte'
                                            >
                                                <Switch />
                                            </FormItem>
                                        )
                                    }
                                }}
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                            if (getFieldValue('envios')) {
                                return (
                                    <FormItem name="unidadPesoId" label="Unidad Peso" required margin>
                                        <SelectItem placeholder="Unidad Peso" width="60%">
                                            {this.state.objetoUnidadesPeso}
                                        </SelectItem>
                                    </FormItem>
                                )
                            }
                        }}
                    </FormItem>
                    <Divider style={{ margin: 0 }}>Equipo Medico</Divider>
                    <FormItem
                        label="Activar Equipo Medico"
                        name="equipoMedico"
                        margin
                        initialValue={false}
                        valuePropName="checked"
                        tooltip='Al activar esta casilla se activa el complemento de equipo medico para las ventas.'
                    >
                        <Switch />
                    </FormItem>
                    <Divider style={{ margin: 0 }}>Lotes y Caducidades</Divider>
                    <FormItem
                        label="Activar Lotes y Caducidades"
                        name="lotesCaducidades"
                        margin
                        initialValue={false}
                        valuePropName="checked"
                        tooltip='Al activar esta casilla se activan los lotes y caducidades en los productos.'
                    >
                        <Switch disabled />
                    </FormItem>
                    <Divider style={{ margin: 0 }}>Detallista</Divider>
                    <FormItem
                        label="Activar Complemento Detallista"
                        name="complementoDetallista"
                        margin
                        initialValue={false}
                        valuePropName="checked"
                        tooltip='Al activar esta casilla se activa el complemento detallista para las ventas. La informacion se proporciona en el cliente.'
                    >
                        <Switch />
                    </FormItem>
                    <Divider style={{ margin: 0 }}>Mezcal</Divider>
                    <FormItem
                        label="Activar Modulo Mezcal"
                        name="modulosMezcal"
                        margin
                        initialValue={false}
                        valuePropName="checked"
                        tooltip='Al activar esta casilla se activan los modulos del procesamiento del mezcal.'
                    >
                        <Switch />
                    </FormItem>
                    <Divider style={{ margin: 0 }}>Consignaciones</Divider>
                    <FormItem
                        label="Activar Consignaciones"
                        name="modulosConsignaciones"
                        margin
                        initialValue={false}
                        valuePropName="checked"
                        tooltip='Al activar esta casilla se activan los modulos de consignaciones.'
                    >
                        <Switch />
                    </FormItem>
                    <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                            const modulosConsignaciones = getFieldValue('modulosConsignaciones')
                            if (modulosConsignaciones) {
                                return (
                                    <FormItem
                                        label="Unidad de Negocio"
                                        margin
                                        name="unidadDeNegocioConsignacionesId"
                                        required
                                    >
                                        <SelectItem placeholder="Unidad de Negocio">
                                            {this.state.objetoUniadesDeNegocioFinales}
                                        </SelectItem>
                                    </FormItem>
                                )
                            }
                        }}
                    </FormItem>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                        Guardar Configuracion
                    </Button>
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(ComplementosConfiguracion);
