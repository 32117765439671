import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const requisicionPdf = (data) => {
    const empresa = data.empresa || {};
    const logoEmpresa = empresa.logo?.base64Completa || "";
    const logoBiwo = data.logoBiwo?.base64Completa || "";
    const dato = data.data || {};
    const usuario = data.usuario || {};
    const proyecto = data.proyecto || {};
    const insumos = data.insumos
    return{
     
            footer: { image: "logoBiwo", alignment: "center", width: 50 },
            content: [
              {
                 style: "tableExample",
                  table: {
                    widths: ["18%", "52%", "30%"],
                    layout: { defaultBorder: false },
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          image: "logo",
                          fit: [80, 80]
                        },
                        {
                          border: [false, false, false, false],
                          table: {
                            headerRows: 1,
                            widths: ["100%"],
                            body: [
                                [{ text: empresa.nombre || "Nombre Empresa", style: "tableHeader" }],
                                [
                                    {
                                        text: `R.F.C: ${empresa.rfc || "N/A"}`,
                                        style: "textoChico",
                                        alignment: "center",
                                    },
                                ],
                                [
                                    {
                                        text: empresa.calle ? `${empresa.calle} ${empresa.numeroCalle || ""}` : "Dirección N/A",
                                        style: "textoChico",
                                        alignment: "center",
                                    },
                                ],
                                [
                                    {
                                        text: empresa.colonia?.nombre
                                            ? `${empresa.colonia.nombre} C.P. ${empresa.colonia.cp || "N/A"}`
                                            : "Colonia N/A",
                                        style: "textoChico",
                                        alignment: "center",
                                    },
                                ],
                              [{ text: `REQUISICION NO° ${dato.numero || "N/A"}`, style: "tableHeader" }]
                            ]
                          },
                          layout: "noBorders"
                        },
                        {
                          border: [false, false, false, false],
                          table: {
                            headerRows: 1,
                            widths: ["100%"],
                            body: [
                              [
                                {
                                  text: "N° REQUISICION:",
                                  style: "subheader",
                                  alignment: "center"
                                }
                              ],
                              [
                                { text: dato.numero, style: "textoChico", alignment: "center" }
                              ],
                             
                              
                            ]
                          },
                          layout: "noBorders"
                        }
                      ]
                    ]
                  }
                },
              {
                style: "tableExample",
                table: {
                  widths: ["100%"],
                  layout: { defaultBorder: false },
                  body: [
                    [
                      {
                        table: {
                          widths: ["50%", "50%"],
                          layout: { defaultBorder: false },
                          body: [
                            [
                              { text: "Proyecto:", style: "subheader" },
                              { text: proyecto.nombre + " - " + proyecto.descripcion, style: "textoChico" },
                            ],
                            [
                              { text: "Fecha:", style: "subheader" },
                              { text: moment(dato.createdAt).format("DD [de] MMMM [de] YYYY") || "", style: "textoChico" },
                            ],
                            [
                              { text: "Fecha de Entrega:", style: "subheader" },
                              { text: moment(dato.fechaEntrega).format("DD [de] MMMM [de] YYYY") || "" || "", style: "textoChico" },
                            ],
                          ],
                        },
                        layout: "noBorders",
                      },
                    ],
                  ],
                },
              },
              {
                style: "tableExample",
                table: {
                  widths: ["10%", "10%", "10%", "20%", "50%"],
                  layout: { defaultBorder: true },
                  body: [
                    [
                      { text: "Cantidad", style: "tituloTabla" },
                      { text: "Clave Int.", style: "tituloTabla" },
                      { text: "Unidad", style: "tituloTabla" },
                      { text: "Clave SAT", style: "tituloTabla" },
                      { text: "Descripción", style: "tituloTabla" },
                    ],
                    ...insumos.map((insumo) => [
                        { text: insumo.cantidad || "", style: "textoTabla", alignment: "center" },
                        { text: insumo.claveInt || "", style: "textoTabla", alignment: "center" },
                        { text: insumo.unidad || "", style: "textoTabla", alignment: "center" },
                        { text: insumo.claveSat || "", style: "textoTabla", alignment: "center" },
                        { text: insumo.descripcion || "", style: "textoTabla", alignment: "center" },
                      ]),
                  ],
                },
              },
              
              [
                  {
                        style: 'tableExample', margin: [0,50, 0, 0],
                        table: {
                            widths: ['35%', '30%', '35%'],
                            layout: {
                            defaultBorder: false,
                          },
                            body: [
                                [
                                    {text: '', style: 'textoTabla', alignment: 'center'}, 
                                  {text: `Responsable: ${usuario.nombre} ${usuario.apellidoPaterno} ${usuario.apellidoMaterno}`, style: 'textoTabla', alignment: 'center', border: [false, true, false, false]}, 
                                  {text: '', style: 'textoTabla', alignment: 'center'}, 
              
                                ],
                            ]
                        },
                        layout: {
                        defaultBorder: false,
                      }
                  }		
              ],
              
            ],
            images: {
              logo: logoEmpresa,
              logoBiwo: logoBiwo 
            },
            styles: {
              header: { fontSize: 9, bold: true },
              subheader: { fontSize: 8, bold: true },
              tableExample: { margin: [0, 5, 0, 5] },
              tableHeader: { bold: true, fontSize: 12, alignment: "center" },
              textoChico: { fontSize: 8 },
              tituloTabla: {
                fontSize: 8,
                bold: true,
                fillColor: "#cccccc",
                alignment: "center",
              },
              textoTabla: { fontSize: 8 },
            },
            pageSize: "LETTER",
            pageMargins: [20, 20, 20, 20],
    };
    }
   


export default requisicionPdf;