import { message, Select, Checkbox, Row, Col, DatePicker } from "antd"; // Importa DatePicker
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputItem,
  ButtonItem,
  SelectItem,
} from "../../../../Components/Items"; // Componentes personalizados

const { Option } = Select;

class AgregarPeriodo extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    tiposPeriodos: null, // Estado para almacenar los tipos de periodos
    objetoTiposPeriodos: null,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.consultarTiposPeriodos();
      this.setState({ visible: this.props.visible });
    }
  }

  onFinishFailed = (e) => {
    message.error("Error en el formulario");
  };

  consultarTiposPeriodos = () => {
    this.setState({ loading: true });
    axios
      .post(
        "periodos/selectDatosParaPeriodo",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          if (res.data.tiposPeriodos && res.data.tiposPeriodos.length > 0) {
            this.setState({
              tiposPeriodos: res.data.tiposPeriodos,
              objetoTiposPeriodos: res.data.tiposPeriodos.map((value) => (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              )),
            });
            message.success("Tipos de periodos obtenidos exitosamente");
          } else {
            message.error("No se encontraron tipos de periodos");
          }
        } else {
          message.error("Error al obtener los tipos de periodos");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al consultar los tipos de periodos");
      });
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    axios
      .post(
        "periodos/agregarPeriodo",
        {
          ...values,
          empresaId: this.props.empresaId,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Periodo agregado exitosamente");
          this.cerrarModal();
        } else {
          message.error("Error al agregar el periodo");
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Error al comunicarse con el servidor");
      });
  };

  cerrarModal = () => {
    this.setState({ visible: false });
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Agregar Periodo"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={800}
      >
        <Form
          ref={this.formRef}
          name="modalAgregarPeriodo"
          layout="vertical"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
        >
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="tipoPeriodoId"
                label="Tipo de Periodo"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona un tipo de periodo" }]}
              >
                <SelectItem placeholder="Selecciona el tipo de periodo">
                  {this.state.objetoTiposPeriodos}
                </SelectItem>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="fechaInicio"
                label="Fecha de Inicio"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona la fecha de inicio" }]}
              >
                <DatePicker placeholder="Selecciona la fecha de inicio" style={{ width: '100%' }} />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="fechaFin"
                label="Fecha de Fin"
                required
                margin
                rules={[{ required: true, message: "Por favor selecciona la fecha de fin" }]}
              >
                <DatePicker placeholder="Selecciona la fecha de fin" style={{ width: '100%' }} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="ejercicio"
                label="Ejercicio"
                required
                margin
                rules={[{ required: true, message: "Por favor ingresa el ejercicio" }]}
              >
                <InputItem type="number" placeholder="Ingresa el ejercicio" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                name="mes"
                label="Mes"
                required
                margin
                rules={[{ required: true, message: "Por favor ingresa el mes" }]}
              >
                <InputItem type="number" placeholder="Ingresa el mes" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="diasDePago"
                label="Días de Pago"
                required
                margin
                rules={[{ required: true, message: "Por favor ingresa los días de pago" }]}
              >
                <InputItem type="number" placeholder="Ingresa los días de pago" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem name="inicioMes" valuePropName="checked" margin>
                <Checkbox>Inicio del Mes</Checkbox>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="finMes" valuePropName="checked" margin>
                <Checkbox>Fin del Mes</Checkbox>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem name="inicioBimestreIMSS" valuePropName="checked" margin>
                <Checkbox>Inicio del Bimestre IMSS</Checkbox>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="finBimestreIMSS" valuePropName="checked" margin>
                <Checkbox>Fin del Bimestre IMSS</Checkbox>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem name="inicioEjercicio" valuePropName="checked" margin>
                <Checkbox>Inicio del Ejercicio</Checkbox>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem name="finEjercicio" valuePropName="checked" margin>
                <Checkbox>Fin del Ejercicio</Checkbox>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(AgregarPeriodo);
