import { PageHeader, Space, Popconfirm, message } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import { TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";
import { connect } from "react-redux";
import { StopOutlined, EditOutlined, CheckCircleOutlined } from '@ant-design/icons'

class Empleados extends Component {
  state = {
    todasEmpleados: [],
    loading: false,
    valor: 1,
  }
  componentDidMount() {
    this.consultarEmpleados(this.state.valor);
  }

  consultarEmpleados(valor) {
    this.setState({loading: true})
    axios
    .post(
      "empleados/selectTodosEmpleados",
      { empresaId: this.props.empresaId, valor: valor, },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
        this.setState({ todasEmpleados: [] });
      } else {
        this.setState({
          todasEmpleados: res.data
        });
      }
    });
  }
  agregarEmpleado(){
    this.props.history.push('/panel/agregarEmpleado')
  }
  editarEmpleado(id){
    this.props.history.push('/panel/editarEmpleado', {id})
  }
  deshabilitarEmpleado(value) {
    axios
      .post(
        "empleados/deshabilitarEmpleado",
        { empleadoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Empleado deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarEmpleados(this.state.valor);
      });
  }
  habilitarEmpleado(value) {
    axios
      .post(
        "empleados/habilitarEmpleado",
        { empleadoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Emplados habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarEmpleados(this.state.valor);
      });
  }
  handleMenuClick(e) {
    if(e.key === '1'){
      this.agregarEmpleado()
    }
    if(e.key === '2'){
      this.importarEmpleados()
    }
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "RFC",
        dataIndex: "rfc",
        key: "rfc",
        sorter: (a, b) => {
          if (a.rfc < b.rfc) {
            return -1;
          }
          if (a.rfc > b.rfc) {
            return 1;
          }
          return 0;
        },
        buscar: "rfc",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
              {this.props.permisoEditarEmpleado &&
              <EditOutlined
                onClick={() => this.editarEmpleado(value._id)}
                style={{ color: "blue" }}
              />}
            <>
            {this.props.permisoDeshabilitarEmpleado &&
            <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este empleado"
                  onConfirm={() => this.deshabilitarEmpleado(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este empleado"
                  onConfirm={() => this.habilitarEmpleado(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>

              )}</>}
            </>
          </Space>
        ),
      },
    ];
    return (
      <>
        <PageHeader
          title="Empleados"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
            <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarEmpleados(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
            {this.props.permisoAgregarEmpleado &&
            <ButtonItem
            type="primary"
            key="agregarEmpleado"
            onClick={this.agregarEmpleado.bind(this)}
          >
            Agregar Empleado
          </ButtonItem>}
            </>

          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasEmpleados} columns={columns} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarEmpleado:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675096a4798d4e0043687255"
      )?.activado,
      permisoEditarEmpleado:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675096cb798d4e0043687256"
      )?.activado,
      permisoDeshabilitarEmpleado:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "675096f0798d4e0043687257"
      )?.activado,
  };
};

export default connect(mapStateToProps)(Empleados);
