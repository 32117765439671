import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { PageHeader,  Checkbox, Space, Popconfirm, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, SegmentedItem, } from "../../../Components/Items";

class TiposDeClientes extends Component {
  state = {
    todasTiposDeClientes: [],
    loading: false,
    valor: 1,
  }
  componentDidMount() {
    this.consultarTiposDeClientes(this.state.valor);
  }

  consultarTiposDeClientes(valor) {
    this.setState({loading: true})
    axios
    .post(
      "tiposdeclientes/selectTodosTiposDeClientes",
      { empresaId: this.props.empresaId, valor: valor },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
        this.setState({ todasTiposDeClientes: [] });
      } else {
        this.setState({
          todasTiposDeClientes: res.data.map((value) => {
            return {
              ...value,
              accion: { _id: value._id, estado: value.estado },
            };
          }),
        });
      }
    });
  }
  agregarTipoDeCliente(){
    this.props.history.push('/panel/agregarTipoDeCliente')
  }
  editarTipoDeCliente(id){
    this.props.history.push('/panel/editarTipoDeCliente', {id})
  }
  deshabilitarTipoDeCliente(value) {
    axios
      .post(
        "tiposdeclientes/deshabilitarTipoDeCliente",
        { tipoDeClienteId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de Cliente deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarTiposDeClientes(this.state.valor);
      });
  }
  habilitarTipoDeCliente(value) {
    axios
      .post(
        "tiposdeclientes/habilitarTipoDeCliente",
        { tipoDeClienteId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Tipo de Cliente habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarTiposDeClientes(this.state.valor);
      });
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
      },
      {
        title: "Generar Cuentas en Automatico",
        dataIndex: "generarCuentasAutomatico",
        key: "generarCuentasAutomatico",
        render: (value) => <Checkbox checked={value} />,
        align: "center",
      },
      {
        title: "Tipo de Poliza Diario",
        dataIndex: "tipoDePolizaDiario",
        key: "tipoDePolizaDiario",
        render: (value) => value.nombre+' - '+value.descripcion,
      },
      {
        title: "Tipo de Poliza Ingreso",
        dataIndex: "tipoDePolizaIngreso",
        key: "tipoDePolizaIngreso",
        render: (value) => value.nombre+' - '+value.descripcion,
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarTipoDeCliente &&
              <EditOutlined
                onClick={() => this.editarTipoDeCliente(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarTipoDeCliente &&
            <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este cliente"
                  onConfirm={() => this.deshabilitarTipoDeCliente(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este cliente"
                  onConfirm={() => this.habilitarTipoDeCliente(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
            }
          </Space>
        ),
      },
    ];
    return (
      <>
        <PageHeader
          title="Tipos De Clientes"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <>
            {window.innerWidth > 1000 &&
            <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarTiposDeClientes(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
          }
            {this.props.permisoAgregarTipoDeCliente &&
            <ButtonItem
              type="primary"
              key="agregarTipoDeCliente"
              onClick={this.agregarTipoDeCliente.bind(this)}
            >
              Agregar Tipo De Cliente
            </ButtonItem>}
            </>
          }
        />
        <TableItem
        loading={this.state.loading}
          size="small"
          dataSource={this.state.todasTiposDeClientes}
          columns={columns}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarTipoDeCliente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd509f30e81b54183cf8b")?.activado,
    permisoEditarTipoDeCliente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd538f30e81b54183cf8c")?.activado,
    permisoDeshabilitarTipoDeCliente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce451f30e81b54183cfb2")?.activado,
  };
};

export default connect(mapStateToProps)(TiposDeClientes);