import { Divider, message, Select, Tabs } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  SelectItem,
  InputNumberItem,
  cuentaMask,
  InputItem,
  ButtonItem,
  SwitchItem,
  ErrorItem,
} from "../../../../Components/Items";

const { Option } = Select;
const { TabPane } = Tabs;

class EditarProyecto extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasUniadesDeNegocioFinales: null,
    objetoUniadesDeNegocioFinales: null,
    todasPaises: null,
    objetoPaises: null,
    todasEstados: null,
    objetoEstados: null,
    todasMunicipios: null,
    objetoMunicipios: null,
    todasColonias: null,
    objetoColonias: null,
    todasConfiguracionesAutotransporte: null,
    objetoConfiguracionesAutotransporte: null,
    todasRemolques: null,
    objetoRemolques: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "unidadesdenegocio/selectUnidadesDeNegocioFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasUniadesDeNegocioFinales: res.data });
          this.setState({
            objetoUniadesDeNegocioFinales: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 + value.nivel2 + value.nivel3 + value.nivel4 + value.nivel5
                  ) +
                    " - " +
                    value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post("paises/selectPaises", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasPaises: res.data });
          this.setState({
            objetoPaises: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviatura}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post("estados/selectEstados", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasEstados: res.data });
          this.setState({
            objetoEstados: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviatura}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post("catalogosSAT/selectConfiguracionesAutotransporteYPermisos", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasConfiguracionesAutotransporte: res.data.configuraciones,
            todasPermisos: res.data.tipoPermisos,
            objetoConfiguracionesAutotransporte: res.data.configuraciones.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave + " - " + value.Descripcion}
                </Option>
              );
            }),
            objetoPermisos: res.data.tipoPermisos.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.Clave + ' - ' + value.Descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post("remolques/selectRemolques", { empresaId: this.props.empresaId }, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.success === 2) {
          this.setState({
            todasRemolques: res.data.remolques,
            objetoRemolques: res.data.remolques.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.descripcion + ' - ' + value.placa}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "proyectos/datosProyecto",
        { empresaId: this.props.empresaId, proyectoId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion del proyecto");
        } else {
          if (res.data.utilizado) {
            message.info('No se puede modificar toda la informacion ya que el proyecto ya fue utilizado')
          }
          if (res.data.estadoId) {
            this.onChangeEstado(res.data.estadoId)
          }
          if (res.data.municipioId) {
            this.onChangeMunicipio(res.data.municipioId)
          }
          this.formRef.current.setFieldsValue({
            ...res.data,
          })
          this.setState({
            utilizado: res.data.utilizado,
          })
        }
      });
    this.setState({ loading: false });
  }
  cerrarEditarProyecto(codigo) {
    this.props.cerrarEditarProyecto(codigo);
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "proyectos/editarProyecto",
        { ...values, empresaId: this.props.empresaId, proyectoId: this.props.id },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se edito un proyecto");
          this.formRef.current.resetFields();
          this.cerrarEditarProyecto(res.data.codigo);
        } else {
          message.error("Sucedio un error y no se guardo");
        }
      });
  }
  onChangeEstado(value) {
    this.formRef.current.setFieldsValue({
      municipioId: undefined,
      coloniaId: undefined,
    });
    this.setState({ objetoMunicipios: null, objetoColonias: null });
    axios
      .post(
        "municipios/selectMunicipios",
        { estadoId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMunicipios: res.data });
          this.setState({
            objetoMunicipios: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }
  onChangeMunicipio(value) {
    this.formRef.current.setFieldsValue({ coloniaId: undefined });
    this.setState({ objetoColonias: null });
    axios
      .post(
        "colonias/selectColonias",
        { municipioId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasColonias: res.data });
          this.setState({
            objetoColonias: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.cp + " - " + value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }
  render() {
    return (
      <ModalItem
        title="Editar Proyecto"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarEditarProyecto.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="agregarProyecto"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Guardar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="General" key="1" forceRender>
              <FormItem
                name="nombre"
                label="Nombre"
                required
                max={100}
                margin
                pattern
              >
                <InputItem placeholder="Nombre" />
              </FormItem>
              <FormItem
                name="descripcion"
                label="Descripción"
                margin
                pattern
                required
              >
                <InputItem placeholder="Descripción" />
              </FormItem>
              <FormItem
                label="Unidad de Negocio"
                margin
                name="unidadDeNegocioId"
                required
              >
                <SelectItem placeholder="Unidad de Negocio">
                  {this.state.objetoUniadesDeNegocioFinales}
                </SelectItem>
              </FormItem>
              <FormItem
                label="Tipo de Unidad"
                margin
                name="tipoDeUnidad"
                required
              >
                <SelectItem placeholder="Tipo de Unidad">
                  <Option value={1}>Fisico</Option>
                  <Option value={2}>Movil</Option>
                </SelectItem>
              </FormItem>
              <FormItem
                label="Numeracion Ventas"
                name="numeracion"
                tooltip={{ title: "Numeracion de ventas en este proyecto." }}
                margin
                initialValue={false}
                valuePropName="checked"
              >
                <SwitchItem />
              </FormItem>
              <FormItem shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const numeracion = getFieldValue('numeracion')
                  if (numeracion) {
                    return (
                      <>
                        <FormItem
                          name="serieFacturas"
                          label="Serie Facturas"
                          tooltip={{ title: "Serie que se mostrara en facturas." }}
                          required
                          margin
                          pattern
                        >
                          <InputItem placeholder="Serie que se mostrara en facturas" />
                        </FormItem>
                        <FormItem
                          name="folioFacturas"
                          label="Folio Facturas"
                          tooltip={{ title: "Folio de la siguiente factura." }}
                          type='number'
                          min={1}
                          required
                          number
                          margin
                        >
                          <InputNumberItem placeholder="Folio de la siguiente factura" />
                        </FormItem>
                        <FormItem
                          name="serieRemisiones"
                          label="Serie Remisiones"
                          tooltip={{ title: "Serie que se mostrara en remisiones." }}
                          required
                          margin
                          pattern
                        >
                          <InputItem placeholder="Serie que se mostrara en remisiones" />
                        </FormItem>
                        <FormItem
                          name="folioRemisiones"
                          label="Folio Remisiones"
                          type='number'
                          min={1}
                          tooltip={{ title: "Folio de la siguiente remision." }}
                          required
                          number
                          margin
                        >
                          <InputNumberItem placeholder="Folio de la siguiente remision" />
                        </FormItem>
                        <FormItem
                          name="serieCartaPorte"
                          label="Serie Carta Porte"
                          tooltip={{ title: "Serie que se mostrara en carta porte." }}
                          required
                          margin
                          pattern
                        >
                          <InputItem placeholder="Serie que se mostrara en carta porte" />
                        </FormItem>
                        <FormItem
                          name="folioCartaPorte"
                          label="Folio Carta Porte"
                          type='number'
                          min={1}
                          tooltip={{ title: "Folio de la siguiente carta porte." }}
                          required
                          number
                          margin
                        >
                          <InputNumberItem placeholder="Folio de la siguiente carta porte" />
                        </FormItem>
                        <FormItem
                          name="serieFacturaGlobal"
                          label="Serie Factura Global"
                          tooltip={{ title: "Serie que se mostrara en factura global." }}
                          required
                          margin
                          pattern
                        >
                          <InputItem placeholder="Serie que se mostrara en factura global" />
                        </FormItem>
                        <FormItem
                          name="folioFacturaGlobal"
                          label="Folio Factura Global"
                          type='number'
                          min={1}
                          tooltip={{ title: "Folio de la siguiente factura global." }}
                          required
                          number
                          margin
                        >
                          <InputNumberItem placeholder="Folio de la siguiente factura global" />
                        </FormItem>
                      </>
                    )
                  }
                }}
              </FormItem>
            </TabPane>
            <TabPane forceRender tab="Numeracion Compras" key="3">
              <FormItem
                label="Numeracion Compras"
                name="numeracionCompras"
                tooltip={{ title: "Numeracion de compras en este proyecto." }}
                margin
                initialValue={false}
                valuePropName="checked"
              >
                <SwitchItem />
              </FormItem>
              <FormItem shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const numeracionCompras = getFieldValue('numeracionCompras')
                  const numeracionOrdenesSemana = getFieldValue('numeracionOrdenesSemana')
                  if (numeracionCompras) {
                    return (
                      <>
                        <FormItem
                          name="serieOrdenesCompra"
                          label="Serie Ordenes"
                          tooltip={{ title: "Serie que se mostrara en ordenes de compra." }}
                          required
                          margin
                          pattern
                        >
                          <InputItem placeholder="Serie que se mostrara en ordenes de compra" />
                        </FormItem>
                        <FormItem
                          label="Numeracion Semana"
                          name="numeracionOrdenesSemana"
                          tooltip={{ title: "Numeracion de ordenes de compra por semana." }}
                          margin
                          initialValue={false}
                          valuePropName="checked"
                        >
                          <SwitchItem />
                        </FormItem>
                        {!numeracionOrdenesSemana &&
                          <FormItem
                            name="folioOrdenesCompra"
                            label="Folio Ordenes"
                            tooltip={{ title: "Folio de la siguiente orden de compra." }}
                            type='number'
                            min={1}
                            required
                            number
                            margin
                          >
                            <InputNumberItem placeholder="Folio de la siguiente orden de compra" />
                          </FormItem>
                        }
                      </>
                    )
                  }
                }}
              </FormItem>
            </TabPane>
            <TabPane tab="Información" key="2">
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  let tipo = getFieldValue("tipoDeUnidad");
                  if (tipo === 1) {
                    return (
                      <>
                        <FormItem label="Pais" name="paisId" margin>
                          <SelectItem placeholder="Pais">
                            {this.state.objetoPaises}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Estado" name="estadoId" margin>
                          <SelectItem
                            placeholder="Estado"
                            onChange={this.onChangeEstado.bind(this)}
                          >
                            {this.state.objetoEstados}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Municipio" name="municipioId" margin>
                          <SelectItem
                            placeholder="Municipio"
                            onChange={this.onChangeMunicipio.bind(this)}
                          >
                            {this.state.objetoMunicipios === null ? (
                              <Option disabled>Eliga primero un estado</Option>
                            ) : (
                              this.state.objetoMunicipios
                            )}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Colonia" name="coloniaId" margin>
                          <SelectItem placeholder="Colonia">
                            {this.state.objetoColonias === null ? (
                              <Option disabled>Eliga primero un estado</Option>
                            ) : (
                              this.state.objetoColonias
                            )}
                          </SelectItem>
                        </FormItem>
                        <FormItem
                          label="Calle"
                          name="calle"
                          max={200}
                          margin
                          pattern
                        >
                          <InputItem placeholder="Calle" />
                        </FormItem>
                        <FormItem
                          label="Numero"
                          name="numeroCalle"
                          margin
                          number
                        >
                          <InputNumberItem placeholder="Numero" numeroCalle />
                        </FormItem>
                        <FormItem label="Celular" name="celular" number margin>
                          <InputNumberItem placeholder="Celular" celular />
                        </FormItem>
                        <FormItem
                          label="Telefono"
                          name="telefono"
                          number
                          margin
                        >
                          <InputNumberItem placeholder="Telefono" celular />
                        </FormItem>
                      </>
                    );
                  } else if (tipo === 2) {
                    return (
                      <>
                        <FormItem
                          name="marca"
                          label="Marca"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Marca" />
                        </FormItem>
                        <FormItem
                          name="modelo"
                          label="Modelo"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Modelo" />
                        </FormItem>
                        <Divider style={{ marginBottom: 10, marginTop: 5 }} children="Carta Porte" />
                        <FormItem name="remolquesIds" label="Remolques" margin>
                          <SelectItem
                            mode="multiple"
                            placeholder="Remolques"
                          >
                            {this.state.objetoRemolques}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Configuracion V" name="configuracionAutotranporteId" margin>
                          <SelectItem
                            placeholder="Configuracion Vehicular"
                          >
                            {this.state.objetoConfiguracionesAutotransporte}
                          </SelectItem>
                        </FormItem>
                        <FormItem label="Tipo Permiso SCT" name="tipoPermisoId" margin>
                          <SelectItem placeholder="Tipo Permiso SCT">
                            {this.state.objetoPermisos}
                          </SelectItem>
                        </FormItem>
                        <FormItem
                          label='N. Permiso SCT'
                          name='permisoSCT'
                          pattern
                          margin
                        >
                          <InputItem placeholder='N. Permiso SCT' />
                        </FormItem>
                        <FormItem
                          label="Peso Bruto V"
                          name="pesoBrutoV"
                          margin
                          numberCero
                        >
                          <InputNumberItem placeholder="Peso Bruto Vehicular" />
                        </FormItem>
                        <FormItem
                          name="placas"
                          label="Placas"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Placas" />
                        </FormItem>
                        <FormItem
                          label="Año Modelo"
                          name="anoModelo"
                          margin
                          numberCero
                        >
                          <InputNumberItem placeholder="Año Modelo" />
                        </FormItem>
                        <FormItem
                          name="aseguradoraRespCivil"
                          label="Aseguradora Civil"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Aseguradora Responsabilidad Civil" />
                        </FormItem>
                        <FormItem
                          name="polizaRespCivil"
                          label="Poliza Civil"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Poliza Responsabilidad Civil" />
                        </FormItem>
                        <FormItem
                          name="aseguradoraMedAmbiente"
                          label="Aseguradora Ambiente"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Aseguradora Medio Ambiente" />
                        </FormItem>
                        <FormItem
                          name="polizaMedAmbiente"
                          label="Poliza Ambiente"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Poliza Medio Ambiente" />
                        </FormItem>
                        <FormItem
                          name="aseguradoraCarga"
                          label="Aseguradora Carga"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Aseguradora Carga" />
                        </FormItem>
                        <FormItem
                          name="polizaCarga"
                          label="Poliza Carga"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Poliza Carga" />
                        </FormItem>
                        <FormItem
                          name="primaSeguro"
                          label="Prima Seguro"
                          margin
                          pattern
                        >
                          <InputItem placeholder="Prima Seguro" />
                        </FormItem>
                      </>
                    );
                  } else {
                    return <p>Elija primero un tipo de unidad</p>
                  }
                }}
              </FormItem>
            </TabPane>
          </Tabs>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EditarProyecto);
