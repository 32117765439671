import moment from "moment";
import "moment/locale/es";
import { dineroDisabledMask } from "../Components/Items";
import xmlToJson from "../Components/xmlToJson";
moment.locale("es");

const cancelarVentaPdf = (data) => {
    const empresa = data.empresa || {};
    const logoEmpresa = empresa.logo?.base64Completa || "";
    const logoBiwo = data.logoBiwo?.base64Completa || "";
    const dato = data.data || {};
    const cliente = data.cliente || {};    

    if (data?.data?.remision === true){
        return {
            footer: { image: "logoBiwo", alignment: "center", width: 50 },
            content: [
                {
                    style: "tableExample",
                    table: {
                        widths: ["18%", "52%", "30%"],
                        layout: { defaultBorder: false },
                        body: [
                            [
                                {
                                    border: [false, false, false, false],
                                    image: "logo",
                                    fit: [80, 80],
                                },
                                {
                                    border: [false, false, false, false],
                                    table: {
                                        headerRows: 1,
                                        widths: ["100%"],
                                        body: [
                                            [{ text: empresa.nombre || "Nombre Empresa", style: "tableHeader" }],
                                            [
                                                {
                                                    text: `R.F.C: ${empresa.rfc || "N/A"}`,
                                                    style: "textoChico",
                                                    alignment: "center",
                                                },
                                            ],
                                            [
                                                {
                                                    text: empresa.calle ? `${empresa.calle} ${empresa.numeroCalle || ""}` : "Dirección N/A",
                                                    style: "textoChico",
                                                    alignment: "center",
                                                },
                                            ],
                                            [
                                                {
                                                    text: empresa.colonia?.nombre
                                                        ? `${empresa.colonia.nombre} C.P. ${empresa.colonia.cp || "N/A"}`
                                                        : "Colonia N/A",
                                                    style: "textoChico",
                                                    alignment: "center",
                                                },
                                            ],
                                            [{ text: "ACUSE DE CANCELACIÓN DE REMISIÓN", style: "tableHeader" }],
                                        ],
                                    },
                                    layout: "noBorders",
                                },
                                {
                                    border: [false, false, false, false],
                                    table: {
                                        headerRows: 1,
                                        widths: ["100%"],
                                        body: [
                                            [
                                                {
                                                    text: "Fecha:",
                                                    style: "subheader",
                                                    alignment: "center",
                                                },
                                            ],
                                            [
                                                {
                                                    text: dato?.cancelacion?.fechaCancelacion ? moment(dato.cancelacion.fechaCancelacion).format("DD [de] MMMM [de] YYYY") : " - ",
                                                    style: "textoChico",
                                                    alignment: "center",
                                                },
                                            ],
                                        ],
                                    },
                                    layout: "noBorders",
                                },
                            ],
                        ],
                    },
                },
                {
                    style: "tableExample",
                    table: {
                        widths: ["50%", "50%"],
                        layout: { defaultBorder: false },
                        body: [
                            [
                                {
                                    table: {
                                        widths: ["50%", "50%"],
                                        layout: { defaultBorder: false },
                                        body: [
                                            [
                                                { text: "Folio:", style: "subheader" },
                                                { text: dato.remisionSerie + " - " + dato.remisionNumero || "N/A", style: "textoChico" },
                                            ],
                                            [
                                                { text: "Clave del Receptor:", style: "subheader" },
                                                { text: cliente.clave || "N/A", style: "textoChico" },
                                            ],
                                            [
                                                { text: "RFC del Receptor:", style: "subheader" },
                                                { text: cliente.rfc || "N/A", style: "textoChico" },
                                            ],
                                        ],
                                    },
                                    layout: "noBorders",
                                },
                                {
                                    table: {
                                        widths: ["50%", "50%"],
                                        layout: { defaultBorder: false },
                                        body: [
                                            [
                                                { text: "Tipo de documento:", style: "subheader" },
                                                { text: "Remisión" || "N/A", style: "textoChico" },
                                            ],
                                            [
                                                { text: "Nombre del Receptor:", style: "subheader" },
                                                { text: cliente.nombre + " " + cliente.apellidoPaterno + " " + cliente.apellidoMaterno || "N/A", style: "textoChico" },
                                            ],
                                        ],
                                    },
                                    layout: "noBorders",
                                },
                            ],
                        ],
                    },
                },
                {
                    style: "tableExample",
                    table: {
                        widths: ["60%", "20%", "20%"],
                        layout: { defaultBorder: true },
                        body: [
                            [
                                { text: "Folio", style: "tituloTabla" },
                                { text: "Estado", style: "tituloTabla" },
                                { text: "Importe", style: "tituloTabla" },
                            ],
                            [
                                { text: dato.remisionSerie + " - " + dato.remisionNumero || "N/A", style: "textoTabla", alignment: "center"},
                                { text: "Cancelado" || "N/A", style: "textoTabla", alignment: "center" },
                                { text: dineroDisabledMask(dato.totalPagar || 0), style: "textoTabla", alignment: "center" },
                            ],
                        ],
                    },
                },
            ],
            images: {
                logo: logoEmpresa,
                logoBiwo: logoBiwo,
            },
            styles: {
                header: { fontSize: 9, bold: true },
                subheader: { fontSize: 8, bold: true },
                tableExample: { margin: [0, 5, 0, 5] },
                tableHeader: { bold: true, fontSize: 12, alignment: "center" },
                textoChico: { fontSize: 8 },
                tituloTabla: {
                    fontSize: 8,
                    bold: true,
                    fillColor: "#cccccc",
                    alignment: "center",
                },
                textoTabla: { fontSize: 8 },
            },
            pageSize: "LETTER",
            pageMargins: [20, 20, 20, 20],
        };


    }else if(data?.data?.factura === true){

        let xmlAcuse 
        const e = dato?.cancelacion?.acuse;
        xmlAcuse = new xmlToJson().parseFromString( e );
        
        const acuseFecha = xmlAcuse?.attributes;
        const Folios = xmlAcuse?.children.find(
            (valueXml) => valueXml.name === "Folios"
        );
        const UUID = Folios?.children.find(
            (valueXml) => valueXml.name === "UUID"
        );
        
        const EstatusUUID = Folios?.children.find(
            (valueXml) => valueXml.name === "EstatusUUID"
        );
    
        const Signature = xmlAcuse?.children.find(
            (valueXml) => valueXml.name === "Signature"
        )
        const SignatureValue = Signature.children.find(
            (valueXml) => valueXml.name === "SignatureValue"
        )
        

        return {
            footer: { image: "logoBiwo", alignment: "center", width: 50 },
            content: [
                {
                    style: "tableExample",
                    table: {
                        widths: ["18%", "52%", "30%"],
                        layout: { defaultBorder: false },
                        body: [
                            [
                                {
                                    border: [false, false, false, false],
                                    image: "logo",
                                    fit: [80, 80],
                                },
                                {
                                    border: [false, false, false, false],
                                    table: {
                                        headerRows: 1,
                                        widths: ["100%"],
                                        body: [
                                            [{ text: empresa.nombre || "Nombre Empresa", style: "tableHeader" }],
                                            [
                                                {
                                                    text: `R.F.C: ${empresa.rfc || "N/A"}`,
                                                    style: "textoChico",
                                                    alignment: "center",
                                                },
                                            ],
                                            [
                                                {
                                                    text: empresa.calle ? `${empresa.calle} ${empresa.numeroCalle || ""}` : "Dirección N/A",
                                                    style: "textoChico",
                                                    alignment: "center",
                                                },
                                            ],
                                            [
                                                {
                                                    text: empresa.colonia?.nombre
                                                        ? `${empresa.colonia.nombre} C.P. ${empresa.colonia.cp || "N/A"}`
                                                        : "Colonia N/A",
                                                    style: "textoChico",
                                                    alignment: "center",
                                                },
                                            ],
                                            [{ text: "ACUSE DE CANCELACIÓN DE CFDI", style: "tableHeader" }],
                                        ],
                                    },
                                    layout: "noBorders",
                                },
                                {
                                    border: [false, false, false, false],
                                    table: {
                                        headerRows: 1,
                                        widths: ["100%"],
                                        body: [
                                            [
                                                {
                                                    text: "Fecha:",
                                                    style: "subheader",
                                                    alignment: "center",
                                                },
                                            ],
                                            [
                                                {
                                                    text: dato?.cancelacion?.fechaCancelacion ? moment(dato.cancelacion.fechaCancelacion).format("DD [de] MMMM [de] YYYY") : " - ",
                                                    style: "textoChico",
                                                    alignment: "center",
                                                },
                                            ],
                                        ],
                                    },
                                    layout: "noBorders",
                                },
                            ],
                        ],
                    },
                },
                {
                    style: "tableExample",
                    table: {
                        widths: ["50%", "50%"],
                        layout: { defaultBorder: false },
                        body: [
                            [
                                {
                                    table: {
                                        widths: ["50%", "50%"],
                                        layout: { defaultBorder: false },
                                        body: [
                                            [
                                                { text: "Folio:", style: "subheader" },
                                                { text: dato.facturaSerie + " - " + dato.facturaNumero || "N/A", style: "textoChico" },
                                            ],
                                            [
                                                { text: "Clave del Receptor:", style: "subheader" },
                                                { text: cliente.clave || "N/A", style: "textoChico" },
                                            ],
                                            [
                                                { text: "RFC del Receptor:", style: "subheader" },
                                                { text: cliente.rfc || "N/A", style: "textoChico" },
                                            ],
                                            [
                                                { text: "Fecha y hora de solicitud de la cancelación:", style: "subheader" },
                                                { text: acuseFecha.Fecha || "N/A", style: "textoChico" },
                                            ],
                                        ],
                                    },
                                    layout: "noBorders",
                                },
                                {
                                    table: {
                                        widths: ["50%", "50%"],
                                        layout: { defaultBorder: false },
                                        body: [
                                            [
                                                { text: "Tipo de documento:", style: "subheader" },
                                                { text: "Factura - Ingreso" || "N/A", style: "textoChico" },
                                            ],
                                            [
                                                { text: "Nombre del Receptor:", style: "subheader" },
                                                { text: cliente.nombre + " " + cliente.apellidoPaterno + " " + cliente.apellidoMaterno || "N/A", style: "textoChico" },
                                            ],
                                        ],
                                    },
                                    layout: "noBorders",
                                },
                            ],
                        ],
                    },
                },
                {
                    style: "tableExample",
                    table: {
                        widths: ["60%", "20%", "20%"],
                        layout: { defaultBorder: true },
                        body: [
                            [
                                { text: "Folio Fiscal (UUID)", style: "tituloTabla" },
                                { text: "Estado CFDI", style: "tituloTabla" },
                                { text: "Importe", style: "tituloTabla" },
                            ],
                            [
                                { text:  UUID.value  || "N/A", style: "textoTabla", alignment: "center"},
                                { text: EstatusUUID.value || "N/A", style: "textoTabla", alignment: "center" },
                                { text: dineroDisabledMask(dato.totalPagar || 0), style: "textoTabla", alignment: "center" },
                            ],
                        ],
                    },
                },
                [
                    { text: "Sello digital del SAT:"}
                ],
                [
                    { text: SignatureValue.value  , style: "textoChico"}
                ],
            ],
            images: {
                logo: logoEmpresa,
                logoBiwo: logoBiwo,
            },
            styles: {
                header: { fontSize: 9, bold: true },
                subheader: { fontSize: 8, bold: true },
                tableExample: { margin: [0, 5, 0, 5] },
                tableHeader: { bold: true, fontSize: 12, alignment: "center" },
                textoChico: { fontSize: 8 },
                tituloTabla: {
                    fontSize: 8,
                    bold: true,
                    fillColor: "#cccccc",
                    alignment: "center",
                },
                textoTabla: { fontSize: 8 },
            },
            pageSize: "LETTER",
            pageMargins: [20, 20, 20, 20],
        }
    }

   
};

export default cancelarVentaPdf;
