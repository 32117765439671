import { message, Select, TimePicker } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  ButtonItem,
  InputItem,
  ErrorItem,
  SelectItem,
} from "../../../../Components/Items"; // Componentes personalizados

const { Option } = Select;

class AgregarTurno extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    visible: this.props.visible,
    todasJornadas: null,
    objetoTiposJornada: null,
    horas: null, // Estado para almacenar las horas calculadas
    minutosEntrada: null, // Almacenar la hora de entrada
    minutosSalida: null,  // Almacenar la hora de salida
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.consultarTiposJornada();
      this.setState({ visible: this.props.visible });
    }
  }

  onFinishFailed = (e) => {
    ErrorItem(e);
  };

  consultarTiposJornada = () => {
    this.setState({ loading: true });
    axios
      .post(
        "turnos/selectDatosParaTurno",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        
        if (res.data.success === 2) {
          if (res.data.tiposJornadas && res.data.tiposJornadas.length > 0) {
            this.setState({
              todasJornadas: res.data.tiposJornadas,
              objetoTiposJornada: res.data.tiposJornadas.map((value) => (
                <Option value={value._id} key={value._id}>
                  {value.c_TipoJornada + " - " + value.Descripcion}
                </Option>
              )),
            });
            message.success("Tipos de jornada obtenidos exitosamente");
          } else {
            message.error("No se encontraron tipos de jornada");
          }
        } else if (res.data.success === 1) {
          message.error("Error al obtener los tipos de jornada");
        } else {
          message.error("Error desconocido en la solicitud");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error al consultar los tipos de jornada");
      });
  };

  calcularHoras = (minutosEntrada, minutosSalida) => {
    const entradaEnMinutos = minutosEntrada.hours() * 60 + minutosEntrada.minutes();
    let salidaEnMinutos = minutosSalida.hours() * 60 + minutosSalida.minutes();

    if (salidaEnMinutos < entradaEnMinutos) {
      salidaEnMinutos += 1440; // Sumar 24 horas (1440 minutos)
    }

    const diferenciaEnMinutos = salidaEnMinutos - entradaEnMinutos;
    const horas = Math.floor(diferenciaEnMinutos / 60);
    const minutos = diferenciaEnMinutos % 60;

    return `${horas} horas ${minutos} minutos`; // Retorna el formato "X horas Y minutos"
  };

  // Actualiza las horas al cambiar la hora de entrada o salida
  onTimeChange = (time, field) => {
    // Actualizamos el estado dependiendo si es minutosEntrada o minutosSalida
    if (field === "minutosEntrada") {
      this.setState({ minutosEntrada: time }, this.actualizarHoras);
    } else {
      this.setState({ minutosSalida: time }, this.actualizarHoras);
    }
  };

  // Función que calcula las horas solo si ambas horas están seleccionadas
  actualizarHoras = () => {
    const { minutosEntrada, minutosSalida } = this.state;
    if (minutosEntrada && minutosSalida) {
      const horasCalculadas = this.calcularHoras(minutosEntrada, minutosSalida);
      this.setState({ horas: horasCalculadas }); // Actualiza el estado con las horas calculadas
    }
  };

  onFinish = (values) => {
    if (values?.nombre) {
      this.setState({ loading: true });

      const { minutosEntrada, minutosSalida, horas } = this.state;

      if (!minutosEntrada || !minutosSalida) {
        message.error("Por favor selecciona la hora de entrada y salida");
        return;
      }

      const minutosEntradaMinutos = minutosEntrada.hours() * 60 + minutosEntrada.minutes();
      const minutosSalidaMinutos = minutosSalida.hours() * 60 + minutosSalida.minutes();

      axios
        .post(
          "turnos/agregarTurno",
          {
            ...values,
            empresaId: this.props.empresaId,
            minutosEntrada: minutosEntradaMinutos,
            minutosSalida: minutosSalidaMinutos,
            horas: horas, // Añadir las horas calculadas
          },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Turno agregado exitosamente");
            this.cerrarModal();
          } else {
            message.error("Error al agregar el turno");
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          message.error("Error al comunicarse con el servidor");
        });
    } else {
      message.info("Proporcione al menos el nombre del turno");
    }
  };

  cerrarModal = () => {
    this.setState({ visible: false, horas: null, minutosEntrada: null, minutosSalida: null }); // Reiniciar los campos al cerrar el modal
    this.props.cerrarModal();
    if (this.formRef.current) {
      this.formRef.current.resetFields();
    }
  };

  render() {
    return (
      <ModalItem
        title="Agregar Turno"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarModal}
        width={800}
      >
        <Form
          ref={this.formRef}
          name="modalAgregarTurno"
          layout="vertical"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
              loading={this.state.loading}
            >
              Guardar
            </ButtonItem>
          }
        >
          <FormItem
            name="codigo"
            label="Código del Turno"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el código del turno" }]}
          >
            <InputItem placeholder="Ingresa el código del turno" />
          </FormItem>

          <FormItem
            name="nombre"
            label="Nombre del Turno"
            required
            margin
            rules={[{ required: true, message: "Por favor ingresa el nombre del turno" }]}
          >
            <InputItem placeholder="Ingresa el nombre del turno" />
          </FormItem>

          <FormItem
            name="minutosEntrada"
            label="Hora de Entrada"
            required
            margin
            rules={[{ required: true, message: "Por favor selecciona la hora de entrada" }]}
          >
            <TimePicker
              placeholder="Selecciona la hora de entrada"
              format="HH:mm"
              onChange={(time) => this.onTimeChange(time, "minutosEntrada")}
            />
          </FormItem>

          <FormItem
            name="minutosSalida"
            label="Hora de Salida"
            required
            margin
            rules={[{ required: true, message: "Por favor selecciona la hora de salida" }]}
          >
            <TimePicker
              placeholder="Selecciona la hora de salida"
              format="HH:mm"
              onChange={(time) => this.onTimeChange(time, "minutosSalida")}
            />
          </FormItem>

          {/* Campo para las horas calculadas (no editable) */}
          <FormItem
            label="Horas"
            margin
          >
            <InputItem value={this.state.horas} disabled placeholder="Horas calculadas automáticamente" />
          </FormItem>

          <FormItem
            name="tipoJornadaSatId"
            label="Tipo de Jornada"
            required
            margin
            rules={[{ required: true, message: "Por favor selecciona el tipo de jornada" }]}
          >
            <SelectItem placeholder="Selecciona el tipo de jornada">
              {this.state.objetoTiposJornada}
            </SelectItem>
          </FormItem>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => ({
  headersToken: state.user.headersToken,
  empresaId: state.empresa.id,
});

export default connect(mapStateToProps)(AgregarTurno);
