import {
    Col,
    Input,
    message,
    PageHeader,
    Row,
    Spin,
    Select
} from "antd";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
    Form,
    ButtonItem,
    ErrorItem,
    FormItem,
    InputItem,
    SelectItem,
    InputNumberItem,
    SwitchItem
} from "../../../../Components/Items";
import { PlusOutlined } from "@ant-design/icons";
import AgregarUnidad from "../../Insumos/AgregarInsumo/AgregarUnidad/AgregarUnidad";

const { Option } = Select

class EditarIndirecto extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        objetoUnidades: null,
        todasUnidades: [],
        todasTipoDeInsumo: [],
        objetoTipoDeInsumo: null,
        modalAgregarUnidad: false,
        id: this.props.location.state.id,
    };
    atras() {
        this.props.history.goBack();
    }
    componentDidMount() {
        this.consultarTodo();
    }

    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "indirectos/selectDatosParaIndirecto",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState(
                        {
                            todasUnidades: res.data.unidades,
                            todasTipoDeInsumo: res.data.tiposInsumos,
                            objetoUnidades: res.data.unidades.map((value) => {
                                return (
                                    <Option value={value._id} key={value._id}>
                                        {value.unidadSat.c_ClaveUnidad + " - " + value.nombre}
                                    </Option>
                                );
                            }),
                            objetoTipoDeInsumo: res.data.tiposInsumos.map((value) => {
                                return (
                                    <Option value={value._id} key={value._id}>
                                        {value.nombre}
                                    </Option>
                                );
                            }),
                        }
                    );
                    this.formRef.current.setFieldsValue({ codigo: res.data.nuevoCodigo })
                }
            });

        await axios
            .post(
                "indirectos/datosIndirecto",
                { empresaId: this.props.empresaId, indirectoId: this.state.id },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    this.formRef.current.setFieldsValue({
                        ...res.data.indirecto,
                    });
                } else {
                    message.error("Error no se encontro informacion del insumo");
                }
            });
        this.setState({ loading: false });
    }


    onFinishFailed(e) {
        ErrorItem(e);
    }



    onFinish(values) {
        this.setState({ loading: true });
        axios
            .post("indirectos/editarIndirecto",
                { ...values, empresaId: this.props.empresaId, indirectoId: this.state.id },
                {
                    headers: {
                        ...this.props.headersToken,
                    },
                })
            .then((res) => {
                this.setState({ loading: false });
                if (res.data.success === 2) {
                    message.success("Se ha registrado un indirecto");
                    this.formRef.current.resetFields();
                    this.atras(res.data._id);
                } else if (res.data.success === 1) {
                    message.error("No se puede editar el indirecto");
                } else {
                    message.error("Hubo un error y el indirecto no se edito");
                }
            });
    }

    abrirAgregarUnidad() {
        this.setState({ modalAgregarUnidad: true });
    }
    cerrarAgregarUnidad(codigo) {
        this.setState({ modalAgregarUnidad: false });
        this.consultarTodo().then(() => {
            if (typeof codigo === "string") {
                this.formRef.current.setFieldsValue({ unidadId: codigo });
            }
        });
    }

    render() {
        return (
            <>
                <PageHeader
                    onBack={this.atras.bind(this)}
                    title="Editar Intangible"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                />
                <div style={{ backgroundColor: "white", padding: 20 }}>
                    <Spin spinning={this.state.loading}>
                        <Form
                            ref={this.formRef}
                            name="editarIntangible"
                            layout
                            bottom={
                                <ButtonItem
                                    type="primary"
                                    htmlType="submit"
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Editar
                                </ButtonItem>
                            }
                            onFinish={this.onFinish.bind(this)}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Row>
                                <Col span={12}>
                                    <FormItem
                                        name="codigo"
                                        label="Código"
                                        margin
                                        pattern
                                    >
                                        <InputItem disabled placeholder="Código" />
                                    </FormItem>
                                    <FormItem
                                        label="Nombre"
                                        name="nombre"
                                        required
                                        margin
                                        pattern
                                    >
                                        <InputItem
                                            placeholder="Nombre"
                                        />
                                    </FormItem>
                                    <FormItem
                                        label="Descripción"
                                        name="descripcion"
                                        margin
                                        pattern
                                    >
                                        <InputItem
                                            placeholder="Descripción"
                                        />
                                    </FormItem>
                                    <FormItem label="Tipo de Ins." name="tipoDeInsumoId" margin>
                                        <SelectItem placeholder="Tipo de Insumo">
                                            {this.state.objetoTipoDeInsumo}
                                        </SelectItem>
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                        name="clave"
                                        label="Clave"
                                        margin
                                        pattern
                                    >
                                        <InputItem placeholder="Clave" />
                                    </FormItem>
                                    <FormItem
                                        label="Unidad"
                                        margin
                                    >
                                        <Input.Group compact>
                                            <FormItem name="unidadId" required noStyle>
                                                <SelectItem placeholder="Unidad" width="70%">
                                                    {this.state.objetoUnidades}
                                                </SelectItem>
                                            </FormItem>
                                            <ButtonItem
                                                icon={<PlusOutlined />}
                                                style={{ width: "15%" }}
                                                onClick={this.abrirAgregarUnidad.bind(this)}
                                            />
                                        </Input.Group>
                                    </FormItem>
                                    <FormItem
                                        label="Calculo"
                                        tooltip="Calcular el costo en base a otro tipo de insumo. Ej: 3% de mano de obra"
                                        name="calculo"
                                        margin
                                        initialValue={false}
                                        valuePropName="checked"
                                    >
                                        <SwitchItem />
                                    </FormItem>
                                    <FormItem noStyle shouldUpdate>
                                        {({ getFieldValue }) => {
                                            const calculo = getFieldValue('calculo')

                                            if (calculo) {
                                                return (
                                                    <>
                                                        <FormItem required label="Tipo Ins. Calc." name="tipoDeInsumoCalculoId" margin>
                                                            <SelectItem placeholder="Tipo de Insumo para Calculo">
                                                                {this.state.objetoTipoDeInsumo}
                                                            </SelectItem>
                                                        </FormItem>
                                                        <FormItem
                                                            label="Porcentaje"
                                                            name="porcentaje"
                                                            required
                                                            margin
                                                            numberCero
                                                        >
                                                            <InputNumberItem
                                                                porcentaje
                                                                placeholder="Porcentaje"
                                                            />
                                                        </FormItem>

                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <FormItem
                                                        label="Costo"
                                                        name="costo"
                                                        required
                                                        margin
                                                        numberCero
                                                    >
                                                        <InputNumberItem
                                                            placeholder="Costo"
                                                            dinero
                                                        />
                                                    </FormItem>
                                                )
                                            }
                                        }}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </div>
                <AgregarUnidad
                    visible={this.state.modalAgregarUnidad}
                    cerrarAgregarUnidad={this.cerrarAgregarUnidad.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(EditarIndirecto);
