import { message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputItem,
  ButtonItem,
  ErrorItem,
  InputNumberItem
} from "../../../../Components/Items";
import TextAreItem from "../../../../Components/TextAreaItem";

class EditarTipoDeInsumo extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    numeroSiguiente: 1
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  cerrarEditarTipoDeInsumo(codigo) {
    this.props.cerrarEditarTipoDeInsumo(codigo);
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "tiposDeInsumos/datosTipoDeInsumo",
        { empresaId: this.props.empresaId, tipoId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status !== 404) {
            this.formRef.current.setFieldsValue({...res.data})
        }else{
            message.warning('Hubo un error al cargar los datos')
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    ErrorItem(e);
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "tiposDeInsumos/editarTipoDeInsumo",
        { ...values, empresaId: this.props.empresaId, tipoId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha editado un tipo de insumo");
          this.formRef.current.resetFields();
          this.cerrarEditarTipoDeInsumo(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede editar el tipo de insumo");
        } else {
          message.error("Hubo un error y el tipo de insumo no se registro");
        }
      });
  }
  render() {
    return (
      <>
        <ModalItem
          title="Editar Tipo de Insumo"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarEditarTipoDeInsumo.bind(this)}
          paddingTop
        >
          <Form
            ref={this.formRef}
            name="editarTipoDeInsumo"
            layout
            bottom={
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Editar
              </ButtonItem>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
                        <FormItem
              name="numero"
              label="Numero"
              margin
              number
            >
              <InputNumberItem disabled placeholder="Numero" />
            </FormItem>
            <FormItem
              name="nombre"
              label="Nombre"
              required
              margin
              pattern
            >
              <InputItem placeholder="Nombre" />
            </FormItem>
            <FormItem name="descripcion" label="Descripción" margin pattern>
              <TextAreItem placeholder="Descripción" />
            </FormItem>
          </Form>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EditarTipoDeInsumo);
