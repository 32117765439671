import React, { useState } from 'react';

const IconStethoscope = ({ size = 16, stroke = 2, color = "currentColor", hoverColor = "#1890ff", marginRight = 8 }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={isHovered ? hoverColor : color}
            strokeWidth={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
                transition: "stroke 0.5s ease",
                marginRight: `${marginRight}px`, // Espacio a la derecha
                marginBottom: "-3px" // Ajuste de alineación vertical
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <path d="M6 4h-1a2 2 0 0 0 -2 2v3.5h0a5.5 5.5 0 0 0 11 0v-3.5a2 2 0 0 0 -2 -2h-1" />
            <path d="M8 15a6 6 0 1 0 12 0v-3" />
            <path d="M11 3v2" />
            <path d="M6 3v2" />
            <path d="M20 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
        </svg>
    );
};

export default IconStethoscope;
