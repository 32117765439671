import React, { Component } from "react";
import {
  Card,
  Layout,
  Spin,
  Input,
  Button,
  Menu,
  message,
  Row,
  Col,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import logoGrande from "../../assets/Biwo logo grande.png";
import logoHorizontal from "../../assets/Biwo_Horizontal.png";
import { NavLink } from "react-router-dom";
import { ButtonItem, ErrorItem, Form, FormItem } from "../../Components/Items";
import bienvenidaLogin from "../../assets/Bienvenida.png";
import axios from "axios";

const { Header, Footer, Content } = Layout;

class RecuperarContrasena extends Component {
    formRef = React.createRef();
  state = {
    loading: false,
  };

  componentDidMount() {
    //Viejo sitema de autenticacion
    // if (this.props.location?.state?.redirect) {
    //   notification.error({
    //     message: "Adventencia",
    //     description: "No se puede acceder a la pagina sin iniciar sesión",
    //   });
    // }
  }

  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "/recuperarContrasena",
        values,
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Cheque su correo para cambiar la contraseña");
          this.formRef.current.resetFields()
        } else if(res.data.success === 1){
            message.warning(res.data.message)
        } else{
          message.error("Hubo un error y no pudimos mandarle el correo, porfavor comuniquese con nosotros");
        }
      });
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  render() {
    const center = {
      style: {
        margin: "auto",
        display: "block",
        alignSelf: "center",
      },
    };
    const current = this.props.history.location.pathname;
    return (
      <Layout>
        <Header id="header-fixed">
          <Menu
            mode="horizontal"
            selectedKeys={current}
            style={{ height: "100%" }}
            id="menu-centered"
          >
            <Menu.Item key={"/login"} id='menuitem-izquierda'>
              <NavLink to={"/login"}>
                <img src={logoHorizontal} alt='Logo' id="img-logo" />
              </NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ marginTop: 64 }}>
        <Spin size="large" spinning={this.state.loading}>
            <Row style={{padding: '1rem'}}>
              <Col xs={0} sm={0} lg={12} xl={15}>
                <img src={bienvenidaLogin} id="img-center" alt="Biwo" style={{width: '100%', height: '100%'}} />
              </Col>
              <Col xs={24} sm={24} lg={12} xl={9} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Card id="login-card" bodyStyle={{ paddingTop: 0 }}>
              <img src={logoGrande} id="img-center" alt="Biwo" />
              <Form
              defaultBehavior
                name="recuperarContrasena"
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed}
                ref={this.formRef}
              >

                <FormItem name="usuario" pattern required max={200} min={5}>
                  <Input
                    placeholder="Correo o usuario"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
                </FormItem>
                <FormItem>
                  <ButtonItem htmlType="submit" type="primary" {...center}>
                    Recuperar Contraseña
                  </ButtonItem>
                </FormItem>
                <FormItem>
                  <Button type="link" {...center}>
                    <NavLink to="/login">Regresar al LogIn</NavLink>
                  </Button>
                </FormItem>
              </Form>
            </Card>
            </Col>
            </Row>
          </Spin>
        </Content>
        <Footer id="content-background" style={{ textAlign: "center" }}>
          Biwo ©2020
        </Footer>
      </Layout>
    );
  }
}

export default RecuperarContrasena;
