import { message } from "antd";

export function ErrorItem(e) {
  const errorMessages = e.errorFields.map((field) => {
    const errorMessage =
      field.errors && field.errors.length
        ? field.errors.join(", ")
        : "Error desconocido";

    const rowIndex = field.name[1];

    if (rowIndex !== undefined) {
      return `Fila ${rowIndex + 1}: ${errorMessage}`;
    } else {
      return `${errorMessage}`;
    }
  });

  if (errorMessages.length > 0) {
    message.warning(
      <div>
        <p>:Errores encontrados</p>
        <div>
          {errorMessages.map((msg, index) => (
            <div key={index}>{msg}</div>
          ))}
        </div>
      </div>,
      5
    );
  }
}

export default ErrorItem;
