import {
  FileTextOutlined,
  RiseOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { Card, Col, PageHeader, Row, Tabs, message } from "antd";
import React, { Component } from "react";
import HeaderItem from "../../../Components/HeaderItem";
import axios from "axios";
import { connect } from "react-redux";

const { TabPane } = Tabs;
const { Meta } = Card;

class CardReportes extends Component {
  async onClickStar(e) {
    if (this.props.favorito) {
      await axios
        .post(
          "reportes/eliminarReporteFavorito",
          { reporte: e },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            message.success("Eliminado de favorito");
          } else {
            message.error(
              "Sucedio un error y no se pudo eliminar de favoritos"
            );
          }
        });
    } else {
      await axios
        .post(
          "reportes/agregarReporteFavorito",
          { reporte: e },
          { headers: this.props.headersToken }
        )
        .then((res) => {

          if (res.data.success === 2) {
            message.success("Agregado a favorito");
          } else {
            message.error("Sucedio un error y no se pudo agregar a favoritos");
          }
        });
    }
  }

  render() {
    let clickstar = false;
    return (
      <Card
        hoverable
        style={{ borderColor: "#CDCDCD", cursor: "pointer" }}
        bodyStyle={{ backgroundColor: "#E3F9FF" }}
        onClick={() => {
          if (!clickstar) {
            this.props.onClick();
          }
        }}
      >
        <div style={{ display: "flex" }}>
          <Meta
            avatar={
              <FileTextOutlined
                style={{ color: "#00b5e2", fontSize: "20px" }}
              />
            }
            title={this.props.title}
          />
          {this.props.favorito ? (
            <StarFilled
              style={{ fontSize: "20px", marginLeft: "auto" }}
              onClick={async (e) => {
                clickstar = true;
                await this.onClickStar(this.props.title);
                this.props.onClickStar();
                clickstar = false;
              }}
            />
          ) : (
            <StarOutlined
              style={{ fontSize: "20px", marginLeft: "auto" }}
              onClick={async (e) => {
                clickstar = true;
                await this.onClickStar(this.props.title);
                this.props.onClickStar();
                clickstar = false;
              }}
            />
          )}
        </div>
      </Card>
    );
  }
}

class Reportes extends Component {
  state = {
    reportesFavoritos: [],
  };

  componentDidMount() {
    this.selectReportesFavoritos();
  }

  selectReportesFavoritos() {
    axios
      .post(
        "reportes/selectReportesFavoritos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          this.setState({
            reportesFavoritos: [],
          });
        } else {
          let nuevoArrayFavoritos = [];
          for (const valueFavorito of res.data) {
            if (
              this.props[
              "permisoAccesoReporte" + valueFavorito.replace(/\s+/g, "")
              ]
            ) {
              nuevoArrayFavoritos.push(valueFavorito);
            }
          }
          this.setState({
            reportesFavoritos: nuevoArrayFavoritos,
          });
        }
      });
  }

  displayFavoritos() {
    let arrayDeArrays = [];
    let arrayLength = this.state.reportesFavoritos.length;

    let cuenta = 0;

    while (cuenta !== arrayLength) {
      let cuentaMasTres = cuenta + 3;

      if (cuentaMasTres > arrayLength) {
        arrayDeArrays.push(
          this.state.reportesFavoritos.slice(cuenta, arrayLength)
        );
        cuenta = arrayLength;
      } else {
        arrayDeArrays.push(
          this.state.reportesFavoritos.slice(cuenta, cuentaMasTres)
        );
        cuenta = cuentaMasTres;
      }
    }

    const arrayFinal = arrayDeArrays.map((valueArray) => {
      return (
        <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
          <Col span={8}>
            {valueArray[0] ? (
              <CardReportes
                onClick={() =>
                  this.props.history.push(
                    "/panel/reporte" + valueArray[0].replace(/\s+/g, "")
                  )
                }
                onClickStar={() => this.selectReportesFavoritos()}
                favorito={this.state.reportesFavoritos.find(
                  (e) => e === valueArray[0]
                )}
                title={valueArray[0]}
              />
            ) : null}
          </Col>
          <Col span={8}>
            {valueArray[1] ? (
              <CardReportes
                onClick={() =>
                  this.props.history.push(
                    "/panel/reporte" + valueArray[1].replace(/\s+/g, "")
                  )
                }
                onClickStar={() => this.selectReportesFavoritos()}
                favorito={this.state.reportesFavoritos.find(
                  (e) => e === valueArray[1]
                )}
                title={valueArray[1]}
              />
            ) : null}
          </Col>
          <Col span={8}>
            {valueArray[2] ? (
              <CardReportes
                onClick={() =>
                  this.props.history.push(
                    "/panel/reporte" + valueArray[2].replace(/\s+/g, "")
                  )
                }
                onClickStar={() => this.selectReportesFavoritos()}
                favorito={this.state.reportesFavoritos.find(
                  (e) => e === valueArray[2]
                )}
                title={valueArray[2]}
              />
            ) : null}
          </Col>
        </Row>
      );
    });

    return arrayFinal;
  }

  render() {
    return (
      <>
        <PageHeader
          title="Reportes"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
          <Tabs defaultActiveKey="1">
            <TabPane tab={[<RiseOutlined />, "Todos"]} forceRender key="1">
              {this.props.permisoAccesoReporteVentasGenerales && (
                <>
                  <HeaderItem>Reportes de Ventas</HeaderItem>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteVentasGenerales && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteVentasGenerales"
                            )
                          }
                          onClickStar={() => this.selectReportesFavoritos()}
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Ventas Generales"
                          )}
                          title="Ventas Generales"
                        />
                      )}
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                  </Row>
                </>
              )}
              {this.props.permisoAccesoReporteComprasGenerales && (
                <>
                  <HeaderItem>Reportes de Compras</HeaderItem>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteComprasGenerales && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteComprasGenerales"
                            )
                          }
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Compras Generales"
                          )}
                          onClickStar={() => this.selectReportesFavoritos()}
                          title="Compras Generales"
                        />
                      )}
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                  </Row>
                </>
              )}
              {this.props.permisoAccesoReporteInventarioTotal ||
                this.props.permisoAccesoReporteCostoInventario ? (
                <>
                  <HeaderItem>Reportes de Inventario</HeaderItem>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteInventarioTotal && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteInventarioTotal"
                            )
                          }
                          onClickStar={() => this.selectReportesFavoritos()}
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Inventario Total"
                          )}
                          title="Inventario Total"
                        />
                      )}
                    </Col>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteCostoInventario && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteCostoInventario"
                            )
                          }
                          onClickStar={() => this.selectReportesFavoritos()}
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Costo Inventario"
                          )}
                          title="Costo Inventario"
                        />
                      )}
                    </Col>
                    <Col span={8}>

                     {this.props.permisoAccesoReporteCostoProduccion && <CardReportes
                        onClick={() =>
                          this.props.history.push(
                            "/panel/reporteCostoProduccion"
                          )
                        }
                        onClickStar={() => this.selectReportesFavoritos()}
                        favorito={this.state.reportesFavoritos.find(
                          (e) => e === "Costo Produccion"
                        )}
                        title="Costo Produccion"
                      />}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={8} >
                      {this.props.permisoAccesoReporteInventarioMaxYMin && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteInventarioMaxYMin"
                            )
                          }
                          onClickStar={() => this.selectReportesFavoritos()}
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Inventario Max Y Min"
                          )}
                          title="Inventario Max Y Min"
                        />
                      )}
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                  </Row>
                </>
              ) : null}
              {this.props.permisoAccesoReporteCuentasPorCobrar ||
                this.props.permisoAccesoReporteCuentasPorPagar ? (
                <>
                  <HeaderItem>Reportes Administrativos</HeaderItem>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteCuentasPorCobrar && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteCuentasPorCobrar"
                            )
                          }
                          onClickStar={() => this.selectReportesFavoritos()}
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Cuentas Por Cobrar"
                          )}
                          title="Cuentas Por Cobrar"
                        />
                      )}
                    </Col>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteCuentasPorPagar && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteCuentasPorPagar"
                            )
                          }
                          onClickStar={() => this.selectReportesFavoritos()}
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Cuentas Por Pagar"
                          )}
                          title="Cuentas Por Pagar"
                        />
                      )}
                    </Col>
                    <Col span={8}>
                      {this.props.permisoAccesoReportePagos &&
                      <CardReportes
                        onClick={() =>
                          this.props.history.push(
                            "/panel/reportePagos"
                          )
                        }
                        onClickStar={() => this.selectReportesFavoritos()}
                        favorito={this.state.reportesFavoritos.find(
                          (e) => e === "Pagos"
                        )}
                        title="Pagos"
                      />}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteIngresos &&
                      <CardReportes
                        onClick={() =>
                          this.props.history.push(
                            "/panel/reporteIngresos"
                          )
                        }
                        onClickStar={() => this.selectReportesFavoritos()}
                        favorito={this.state.reportesFavoritos.find(
                          (e) => e === "Ingresos"
                        )}
                        title="Ingresos"
                      />}
                    </Col>
                    <Col span={8}>
                      {this.props.permisoAccesoReportePresupuestos &&
                      <CardReportes
                        onClick={() =>
                          this.props.history.push(
                            "/panel/reportePresupuestos"
                          )
                        }
                        onClickStar={() => this.selectReportesFavoritos()}
                        favorito={this.state.reportesFavoritos.find(
                          (e) => e === "Presupuestos"
                        )}
                        title="Presupuestos"
                      />}
                    </Col>
                    <Col span={8}>

                      {/*Activar en siguiente actualizacion*/}

                      {this.props.permisoAccesoReporteReposicionCajaChica &&
                      <CardReportes
                        onClick={() =>
                          this.props.history.push(
                            "/panel/reporteReposicionCajaChica"
                          )
                        }
                        onClickStar={() => this.selectReportesFavoritos()}
                        favorito={this.state.reportesFavoritos.find(
                          (e) => e === "Reposicion Caja Chica"
                        )}
                        title="Reposicion Caja Chica"
                      />}
                    </Col>
                  </Row>
                </>
              ) : null}
              {this.props.permisoAccesoReporteEstadoDePosicionFinanciera ||
                this.props.permisoAccesoReporteEstadoDeResultados ||
                this.props.permisoAccesoReporteEstadoDeCuenta ||
                this.props.permisoAccesoReporteBalanzaDeComprabacion ||
                this.props.permisoAccesoReporteFlujoDeEfectivo ? (
                <>
                  <HeaderItem>Reportes Financieros</HeaderItem>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={8}>
                      {this.props
                        .permisoAccesoReporteEstadoDePosicionFinanciera && (
                          <CardReportes
                            onClick={() =>
                              this.props.history.push(
                                "/panel/reporteEstadoDePosicionFinanciera"
                              )
                            }
                            favorito={this.state.reportesFavoritos.find(
                              (e) => e === "Estado De Posicion Financiera"
                            )}
                            onClickStar={() => this.selectReportesFavoritos()}
                            title="Estado De Posicion Financiera"
                          />
                        )}
                    </Col>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteEstadoDeResultados && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteEstadoDeResultados"
                            )
                          }
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Estado De Resultados"
                          )}
                          onClickStar={() => this.selectReportesFavoritos()}
                          title="Estado De Resultados"
                        />
                      )}
                    </Col>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteEstadoDeCuenta && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteEstadoDeCuenta"
                            )
                          }
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Estado De Cuenta"
                          )}
                          onClickStar={() => this.selectReportesFavoritos()}
                          title="Estado De Cuenta"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteBalanzaDeComprabacion && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteBalanzaDeComprobacion"
                            )
                          }
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Balanza De Comprobacion"
                          )}
                          onClickStar={() => this.selectReportesFavoritos()}
                          title="Balanza De Comprobacion"
                        />
                      )}
                    </Col>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteFlujoDeEfectivo && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteFlujoDeEfectivo"
                            )
                          }
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Flujo De Efectivo"
                          )}
                          onClickStar={() => this.selectReportesFavoritos()}
                          title="Flujo De Efectivo"
                        />
                      )}
                    </Col>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteFlujoDeEfectivoPresupuestado && (
                        <CardReportes
                          onClick={() =>
                            this.props.history.push(
                              "/panel/reporteFlujoDeEfectivoPresupuestado"
                            )
                          }
                          favorito={this.state.reportesFavoritos.find(
                            (e) => e === "Flujo De Efectivo Presupuestado"
                          )}
                          onClickStar={() => this.selectReportesFavoritos()}
                          title="Flujo De Efectivo Presupuestado"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={8}>
                      {this.props.permisoAccesoReporteLibroDiario && 
                      <CardReportes
                        onClick={() =>
                          this.props.history.push(
                            "/panel/reporteLibroDiario"
                          )
                        }
                        favorito={this.state.reportesFavoritos.find(
                          (e) => e === "Libro Diario"
                        )}
                        onClickStar={() => this.selectReportesFavoritos()}
                        title="Libro Diario"
                      />}
                    </Col>
                  </Row>
                </>
              ) : null}
            </TabPane>
            <TabPane tab={[<StarFilled />, "Favoritos"]} key="2">
              {this.displayFavoritos()}
            </TabPane>
          </Tabs>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permisoAccesoReporteVentasGenerales:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ee6f6ebb47b9440df7b0"
      )?.activado,
    permisoAccesoReporteComprasGenerales:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef026ebb47b9440df7b1"
      )?.activado,
    permisoAccesoReporteInventarioTotal:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef186ebb47b9440df7b2"
      )?.activado,
    permisoAccesoReporteCostoInventario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef3a6ebb47b9440df7b3"
      )?.activado,
    permisoAccesoReporteCuentasPorCobrar:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef556ebb47b9440df7b4"
      )?.activado,
    permisoAccesoReporteCuentasPorPagar:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef796ebb47b9440df7b5"
      )?.activado,
    permisoAccesoReporteEstadoDePosicionFinanciera:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489ef8f6ebb47b9440df7b6"
      )?.activado,
    permisoAccesoReporteEstadoDeResultados:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489efb16ebb47b9440df7b7"
      )?.activado,
    permisoAccesoReporteEstadoDeCuenta:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489efd56ebb47b9440df7b8"
      )?.activado,
    permisoAccesoReporteBalanzaDeComprabacion:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489efeb6ebb47b9440df7b9"
      )?.activado,
    permisoAccesoReporteFlujoDeEfectivo:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6489f00b6ebb47b9440df7ba"
      )?.activado,
    permisoAccesoReporteFlujoDeEfectivoPresupuestado:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6494a5a018c09185602b4aaa"
      )?.activado,
    permisoAccesoReporteInventarioMaxYMin:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "67508da0798d4e0043687236"
      )?.activado,
      permisoAccesoReporteLibroDiario:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "67508c87798d4e0043687230"
      )?.activado,
      permisoAccesoReportePagos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "67508d11798d4e0043687231"
      )?.activado,
      permisoAccesoReporteIngresos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "67508d25798d4e0043687232"
      )?.activado,
      permisoAccesoReportePresupuestos:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "67508d38798d4e0043687233"
      )?.activado,
      permisoAccesoReporteReposicionCajaChica:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "67508d4f798d4e0043687234"
      )?.activado,
      permisoAccesoReporteCostoProduccion:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "67508d79798d4e0043687235"
      )?.activado,
  };
};

export default connect(mapStateToProps)(Reportes);
