import { PageHeader, Space, Popconfirm, message } from "antd";
import React, { Component } from "react";
import axios from 'axios';
import { TableItem, ButtonItem } from "../../../Components/Items";
import { connect } from "react-redux";
import { StopOutlined, EditOutlined, CheckCircleOutlined } from '@ant-design/icons';

class Conceptos extends Component {
  state = {
    todosConceptos: [],
    loading: false
  }

  componentDidMount() {
    this.consultarConceptos();
  }

  consultarConceptos() {
    this.setState({loading: true});
    axios
      .post(
        "conceptosNomina/selectTodosConceptos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
          this.setState({loading: false});
          if (res.data.status === 404) {
              message.error("No se encontraron conceptos.");
            } else {
          this.setState({
            todosConceptos: res.data.conceptos.map(valueConcepto => {
                return {
                    ...valueConcepto,
                    accion: {
                        _id: valueConcepto._id,
                        estado: valueConcepto.estado
                    }
                }
            })
          });
        }
      });
  }
  

  agregarConcepto() {
    this.props.history.push('/panel/agregarConcepto');
  }

  editarConcepto(id) {
    this.props.history.push('/panel/editarConcepto', {id});
  }

  deshabilitarConcepto(value) {
    axios
      .post(
        "conceptosNomina/deshabilitarConcepto",
        { conceptoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Concepto deshabilitado");
        } else {
          message.error("Error al deshabilitar el concepto");
        }
        this.consultarConceptos();
      });
  }

  habilitarConcepto(value) {
    axios
      .post(
        "conceptosNomina/habilitarConcepto",
        { conceptoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Concepto habilitado");
        } else {
          message.error("Error al habilitar el concepto");
        }
        this.consultarConceptos();
      });
  }

  render() {
    const columns = [
      {
        title: "Número",
        dataIndex: "numeroConcepto",
        key: "numeroConcepto",
        sorter: (a, b) => a.numeroConcepto - b.numeroConcepto,
      },
      {
        title: "Tipo",
        dataIndex: "tipo",
        key: "tipo",
        render: (text) => {
          switch(text) {
            case 0: return "Percepción";
            case 1: return "Deducción";
            case 2: return "Obligación";
            default: return "Desconocido";
          }
        },
        sorter: (a, b) => a.tipo - b.tipo,
      },
      {
        title: "Descripción",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => a.descripcion.localeCompare(b.descripcion),
      },
      {
        title: "Acción",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            <EditOutlined
              onClick={() => this.editarConcepto(value._id)}
              style={{ color: "blue" }}
            />
            {value.estado === 1 ? (
              <Popconfirm
                title="¿Seguro que quieres deshabilitar este concepto?"
                onConfirm={() => this.deshabilitarConcepto(value._id)}
                okText="Sí"
                cancelText="No"
              >
                <CheckCircleOutlined style={{ color: "green" }} />
              </Popconfirm>
            ) : (
              <Popconfirm
                title="¿Seguro que quieres habilitar este concepto?"
                onConfirm={() => this.habilitarConcepto(value._id)}
                okText="Sí"
                cancelText="No"
              >
                <StopOutlined style={{ color: "red" }} />
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ];

    return (
      <>
        <PageHeader
          title="Conceptos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <ButtonItem
              type="primary"
              key="agregarConcepto"
              onClick={this.agregarConcepto.bind(this)}
            >
              Agregar Concepto
            </ButtonItem>
          }
        />
        <TableItem loading={this.state.loading} size="small" dataSource={this.state.todosConceptos} columns={columns} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Conceptos);
