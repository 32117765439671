import {
    CheckCircleOutlined,
    EditOutlined,
    StopOutlined,
  } from "@ant-design/icons";
  import { PageHeader, Space, Popconfirm, message } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import { TableItem, ButtonItem, SegmentedItem } from "../../../Components/Items";
  
  class Servicios extends Component {
    state = {
      idEditarServicio: null,
      modalAgregarFamilia: false,
      todasServicios: [],
      loading: false,
      valor: 1,
    };
    componentDidMount() {
      this.consultarServicios(this.state.valor);
    }
    consultarServicios(valor) {
      this.setState({loading: true})
      axios
        .post(
          "servicios/selectTodosServicios",
          { empresaId: this.props.empresaId, valor: valor },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({loading: false})
          if (res.data.status === 404) {
            this.setState({ todasServicios: [] });  
          } else {
            this.setState({
              todasServicios: res.data
            });
          }
        });
    }
    deshabilitarServicio(value) {
      axios
        .post(
          "insumos/deshabilitarInsumo",
          { insumoId: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            message.success("Servicio deshabilitado");
          } else {
            message.error("Sucedio un eror y no se pudo deshabilitar");
          }
          this.consultarServicios(this.state.valor);
        });
    }
    habilitarServicio(value) {
      axios
        .post(
          "insumos/habilitarInsumo",
          { insumoId: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            message.success("Servicio habilitado");
          } else {
            message.error("Sucedio un eror y no se pudo habilitar");
          }
          this.consultarServicios(this.state.valor);
        });
    }
    agregarServicio() {
      this.props.history.push("/panel/agregarServicio");
    }
    editarServicio(id) {
      this.props.history.push("/panel/editarServicio", { id });
    }
    abrirAgregarFamilia() {
      this.setState({ modalAgregarFamilia: true });
    }
    cerrarAgregarFamilia() {
      this.setState({ modalAgregarFamilia: false });
      this.consultarServicios(this.state.valor);
    }
    render() {
      const columns = [
        {
          title: "Codigo",
          dataIndex: "codigo",
          key: "codigo",
          defaultSortOrder: 'ascend',
          sorter: (a, b) => {
            if (a.codigo < b.codigo) {
              return -1;
            }
            if (a.codigo > b.codigo) {
              return 1;
            }
            return 0;
          },
          buscar: "codigo",
          width: "10%",
        },
        {
          title: "Clave",
          dataIndex: "clave",
          key: "clave",
          sorter: (a, b) => {
            if (a.clave < b.clave) {
              return -1;
            }
            if (a.clave > b.clave) {
              return 1;
            }
            return 0;
          },
          buscar: "clave",
          width: "10%",
        },
        {
          title: "Descripcion",
          dataIndex: "descripcion",
          key: "descripcion",
          sorter: (a, b) => {
            if (a.descripcion < b.descripcion) {
              return -1;
            }
            if (a.descripcion > b.descripcion) {
              return 1;
            }
            return 0;
          },
          buscar: "descripcion",
          width: "36%",
        },
        {
          title: "Costo",
          dataIndex: "costo",
          key: "costo",
          sorter: (a, b) => {
            if (a.costo < b.costo) {
              return -1;
            }
            if (a.costo > b.costo) {
              return 1;
            }
            return 0;
          },
          render: (value) => "$ " + value.toFixed(2),
          width: "6%",
        },
        {
          title: "Unidad",
          dataIndex: "unidad",
          key: "unidad",
          sorter: (a, b) => {
            if (a.unidad < b.unidad) {
              return -1;
            }
            if (a.unidad > b.unidad) {
              return 1;
            }
            return 0;
          },
          width: "6%",
        },
        {
          title: "Familia",
          dataIndex: "familia",
          key: "familia",
          sorter: (a, b) => {
            if (a.familia < b.familia) {
              return -1;
            }
            if (a.familia > b.familia) {
              return 1;
            }
            return 0;
          },
          width: "21%",
        },
        {
          title: "Accion",
          dataIndex: "accion",
          key: "accion",
          render: (value) => (
            <Space size="middle">
              {this.props.permisoEditarServicio &&
              <EditOutlined
                onClick={() => this.editarServicio(value._id)}
                style={{ color: "blue" }}
              />
              }
              {this.props.permisoDeshabilitarServicio && 
                <>
                  {value.estado === 1 ? (
                    <Popconfirm
                      title="Seguro que quieres deshabilitar este servicio"
                      onConfirm={() => this.deshabilitarServicio(value._id)}
                      okText="Si"
                      cancelText="No"
                    >
                      <CheckCircleOutlined style={{ color: "green" }} />
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      title="Seguro que quieres habilitar este servicio"
                      onConfirm={() => this.habilitarServicio(value._id)}
                      okText="Si"
                      cancelText="No"
                    >
                      <StopOutlined style={{ color: "red" }} />
                    </Popconfirm>
                  )}
                </>
              }
            </Space>
          ),
          width: "12%",
        },
      ];
      return (
        <>
          <PageHeader
            title="Servicios"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={
              <>
              {window.innerWidth > 1000 &&
              <SegmentedItem
                options={[{label: 'Activo', value: 1}, {label: 'Deshabilitado', value: 2}, {label: 'Todos', value: 3}]}
                onChange={(value) => {
                  this.consultarServicios(value)
                  this.setState({ valor: value })
                }}
                value={this.state.valor}
              />
            }
              {this.props.permisoAgregarServicio ? 
              <ButtonItem
                type="primary"
                key="agregarServicio"
                onClick={this.agregarServicio.bind(this)}
              >
                Agregar Servicio
              </ButtonItem>
            : null}
            </>}
          />
          <TableItem
          loading={this.state.loading}
            size="small"
            dataSource={this.state.todasServicios}
            columns={columns}
            pagination={false}
          />
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      permisoAgregarServicio: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472d5754b2da7c85c1a8d02")?.activado,
      permisoEditarServicio: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472d5a84b2da7c85c1a8d03")?.activado,
      permisoDeshabilitarServicio: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472d5de4b2da7c85c1a8d04")?.activado,
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(Servicios);
  