import {
  Col,
  message,
  PageHeader,
  Row,
  Spin,
  Select,
  Space,
  Tabs,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { connect } from "react-redux";
import {
  SelectItem,
  Form,
  FormItem,
  cuentaMask,
  TableItem,
  dineroDisabledMask,
  RangePickerItem,
  ButtonItem,
  ErrorItem,
} from "../../../../Components/Items";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import * as XLSXStyle from "xlsx-js-style";
import moment from "moment";
import "moment/locale/es";
import reportePresupuestosPdf from "../../../../Pdf/reporteDePresupuestosPdf";

moment.locale("es");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;
const { TabPane } = Tabs;

class ReportePresupuestos extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pdf: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoUniadesDeNegocio: null,
    todasUniadesDeNegocio: null,
    objetoPresupuestos: null,
    todasPresupuestos: null,
    dataReporte: null,
    presupuestosEgresos: [],
    presupuestosIngresos: [],
    valuePeriocidad: 'month'
  };

  atras() {
    this.props.history.goBack();
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await axios
      .post(
        "presupuestos/selectPresupuestos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasPresupuestos: res.data });
          this.setState({
            objetoPresupuestos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + (value.descripcion ? (' - ' + value.descripcion) : '')}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "unidadesdenegocio/selectUnidadesDeNegocio",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasUniadesDeNegocio: res.data });
          this.setState({
            objetoUniadesDeNegocio: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                    value.nivel2 +
                    value.nivel3 +
                    value.nivel4 +
                    value.nivel5
                  ) +
                    " - " +
                    value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    this.consultarReportePresupuestos({
      periodo: [moment().subtract(1, "month"), moment()],
      periocidad: 'month'
    });
  }

  consultarReportePresupuestos(values) {
    this.setState({ loading: true });
    axios
      .post(
        "reportesAdministrativos/reportePresupuestos",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.setState({
            loading: false,
            dataReporte: res.data,
            presupuestosEgresos: res.data.presupuestosEgresos.map(valuePresupuesto => {
              return {
                ...valuePresupuesto,
                descripcion: valuePresupuesto.cuentaContable.descripcion,
                cuenta: cuentaMask(
                  valuePresupuesto.cuentaContable.nivel1 +
                  valuePresupuesto.cuentaContable.nivel2 +
                  valuePresupuesto.cuentaContable.nivel3 +
                  valuePresupuesto.cuentaContable.nivel4 +
                  valuePresupuesto.cuentaContable.nivel5
                )
              }
            }),
            presupuestosIngresos: res.data.presupuestosIngresos.map(valuePresupuesto => {
              return {
                ...valuePresupuesto,
                descripcion: valuePresupuesto.cuentaContable.descripcion,
                cuenta: cuentaMask(
                  valuePresupuesto.cuentaContable.nivel1 +
                  valuePresupuesto.cuentaContable.nivel2 +
                  valuePresupuesto.cuentaContable.nivel3 +
                  valuePresupuesto.cuentaContable.nivel4 +
                  valuePresupuesto.cuentaContable.nivel5
                )
              }
            })
          });
        } else if (res.data.success === 1) {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        } else {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        }
        this.setState({ loading: false });
      });
  }

  descargarExcel() {

    const dataReporte = this.state.dataReporte

    let mergesHojaIngresos = []
    let mergesHojaEgresos = []

    const allBorders = {
      top: { style: 'thin', color: { auto: 1 } },
      bottom: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
    }

    let lineasDescargarHojaIngresos = [];
    let lineasDescargarHojaEgresos = [];

    //Todo para ingresos

    lineasDescargarHojaIngresos.push([
      { v: "Ingresos", t: 's', s: { font: { bold: true, sz: "16" }, alignment: { horizontal: 'center' }, borders: allBorders } },
      {v: '', borders: allBorders},
      {v: '', borders: allBorders},
      {v: '', borders: allBorders},
      {v: '', borders: allBorders},
      {v: '', borders: allBorders},
    ])

    mergesHojaIngresos.push(XLSXStyle.utils.decode_range("A1:F1"))

    let proyectosFiltrados = []

    if (dataReporte.filtrarProyecto === 1) {
      proyectosFiltrados.push({
        v: "Proyectos:", t: "s", s: { font: { bold: true } }
      })
      dataReporte.proyectos.map((valueProyectos) => {
        proyectosFiltrados.push({
          v: valueProyectos.nombre, t: "s"
        })
        return null
      })
    } else if (dataReporte.filtrarProyecto === 2) {

      proyectosFiltrados.push({
        v: "Unidad de Negocio:", t: "s", s: { font: { bold: true } }
      })
      proyectosFiltrados.push({
        v: cuentaMask(
          dataReporte.unidadDeNegocio.nivel1 + dataReporte.unidadDeNegocio.nivel2 + dataReporte.unidadDeNegocio.nivel3 + dataReporte.unidadDeNegocio.nivel4 + dataReporte.unidadDeNegocio.nivel5
        ) +
          " - " +
          dataReporte.unidadDeNegocio.nombre, t: "s"
      })
    } else {
      proyectosFiltrados.push({
        v: "Proyectos:", t: "s", s: { font: { bold: true } },
      })
      proyectosFiltrados.push({
        v: "Todos", t: "s",
      })
    }

    lineasDescargarHojaIngresos.push([
      ...proyectosFiltrados
    ])

    let presupuestosFiltrados = []

    if(dataReporte.presupuestosFilter.length > 0){
      presupuestosFiltrados.push({
        v: "Presupuestos:", t: "s", s: { font: { bold: true } }
      })
      dataReporte.presupuestosFilter.map((valuePresupuestos) => {
        presupuestosFiltrados.push({
          v: valuePresupuestos.nombre, t: "s"
        })
        return null
      })
    }else{
      presupuestosFiltrados.push({
        v: "Presupuestos:", t: "s", s: { font: { bold: true } },
      })
      presupuestosFiltrados.push({
        v: "Todos", t: "s",
      })
    }

    lineasDescargarHojaIngresos.push([
      ...presupuestosFiltrados
    ])

    let datoPeriocidad = ''

    if (dataReporte.periocidad === 'date') {
      datoPeriocidad = 'Diario'
    } else if (dataReporte.periocidad === 'week') {
      datoPeriocidad = 'Semanal'
    } else if (dataReporte.periocidad === 'month') {
      datoPeriocidad = 'Mensual'
    } else if (dataReporte.periocidad === 'year') {
      datoPeriocidad = 'Anual'
    }

    lineasDescargarHojaIngresos.push([
      { v: "Fecha Inicial:", t: "s", s: { font: { bold: true } } },
      { v: dataReporte.fechaInicial, t: "d" },
      { v: "Fecha Final:", t: "s", s: { font: { bold: true } } },
      { v: dataReporte.fechaFinal, t: "d" },
      { v: "Periocidad:", t: "s", s: { font: { bold: true } } },
      { v: datoPeriocidad, t: "s" },
    ])

    lineasDescargarHojaIngresos.push([])

    let lineaPeriodos = [{}, {}, {}]

    let lineaEncabezados = []

    let lineasPresupuestos = []

    for (const [indexPresupuesto, valuePresupuesto] of dataReporte.presupuestosIngresos.entries()) {
      let lineaPresupuesto = []

      if (indexPresupuesto === 0) {
        lineaEncabezados.push(
          { v: 'Cuenta', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
          { v: 'Descripcion', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
          { v: 'Concepto', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } })
      }

      lineaPresupuesto.push({
        v: cuentaMask(
          valuePresupuesto.cuentaContable.nivel1 +
          valuePresupuesto.cuentaContable.nivel2 +
          valuePresupuesto.cuentaContable.nivel3 +
          valuePresupuesto.cuentaContable.nivel4 +
          valuePresupuesto.cuentaContable.nivel5
        ), t: 's'
      }, {
        v: valuePresupuesto.cuentaContable.descripcion, t: 's'
      }, {
        v: valuePresupuesto.concepto, t: 's'
      })


      for (const [indexPP, valuePP] of valuePresupuesto.periodos.entries()) {
        if (indexPresupuesto === 0) {
          lineaPeriodos.push(
            { v: valuePP.nombre.toUpperCase(), t: "s", s: { font: { bold: true, sz: '13' }, alignment: { horizontal: 'center' }, border: allBorders } },
            { v: '', t: "s", s: { font: { bold: true }, border: allBorders } },
            { v: '', t: "s", s: { font: { bold: true }, border: allBorders } }
          )
          const columnaInicial = XLSXStyle.utils.encode_col(3 + (indexPP * 3))
          const columnaFinal = XLSXStyle.utils.encode_col(5 + (indexPP * 3))
          mergesHojaIngresos.push(XLSXStyle.utils.decode_range(`${columnaInicial}6:${columnaFinal}6`))
          lineaEncabezados.push(
            { v: 'Programado', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
            { v: 'Saldo Contable', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
            { v: 'Por ejercer', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } })
        }

        lineaPresupuesto.push({
          v: valuePP.monto, t: 'n'
        }, {
          v: valuePP.saldo, t: 'n'
        }, {
          v: valuePP.porEjercer, t: 'n', s: { font: {color: (valuePP.porEjercer < 0) ? {rgb: 'FF0000'} : null}}
        })

      }

      lineaPresupuesto.push({
        v: valuePresupuesto.montoTotal, t: 'n'
      }, {
        v: valuePresupuesto.saldoTotal, t: 'n'
      }, {
        v: valuePresupuesto.porEjercerTotal, t: 'n', s: { font: {color: (valuePresupuesto.porEjercerTotal < 0) ? {rgb: 'FF0000'} : null}}
      })

      lineasPresupuestos.push(lineaPresupuesto)
    }

    lineaPeriodos.push(
      { v: 'Total', t: "s", s: { font: { bold: true, sz: '13' }, alignment: { horizontal: 'center' }, border: allBorders } },
      { v: '', t: "s", s: { font: { bold: true }, border: allBorders } },
      { v: '', t: "s", s: { font: { bold: true }, border: allBorders } }
    )


    const columnaInicial = XLSXStyle.utils.encode_col(lineaPeriodos.length - 3)
    const columnaFinal = XLSXStyle.utils.encode_col(lineaPeriodos.length - 1)
    mergesHojaIngresos.push(XLSXStyle.utils.decode_range(`${columnaInicial}6:${columnaFinal}6`))

    lineaEncabezados.push(
      { v: 'Programado', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
      { v: 'Saldo Contable', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
      { v: 'Por ejercer', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } })


    //Poner lineas en excel para egresos

    lineasDescargarHojaIngresos.push(lineaPeriodos, lineaEncabezados)

    for (const valueLineaPresupuesto of lineasPresupuestos) {
      lineasDescargarHojaIngresos.push(valueLineaPresupuesto)
    }




    //Todo para Egresos

    lineasDescargarHojaEgresos.push([
      { v: "Egresos", t: 's', s: { font: { bold: true, sz: "16" }, alignment: { horizontal: 'center' }, borders: allBorders } },
      {v: '', borders: allBorders},
      {v: '', borders: allBorders},
      {v: '', borders: allBorders},
      {v: '', borders: allBorders},
      {v: '', borders: allBorders},
    ])

    mergesHojaEgresos.push(XLSXStyle.utils.decode_range("A1:F1"))

    lineasDescargarHojaEgresos.push([
      ...proyectosFiltrados
    ])

    lineasDescargarHojaEgresos.push([
      ...presupuestosFiltrados
    ])

    lineasDescargarHojaEgresos.push([
      { v: "Fecha Inicial:", t: "s", s: { font: { bold: true } } },
      { v: dataReporte.fechaInicial, t: "d" },
      { v: "Fecha Final:", t: "s", s: { font: { bold: true } } },
      { v: dataReporte.fechaFinal, t: "d" },
      { v: "Periocidad:", t: "s", s: { font: { bold: true } } },
      { v: datoPeriocidad, t: "s" },
    ])

    lineasDescargarHojaEgresos.push([])

    let lineaPeriodosEgresos = [{}, {}, {}]

    let lineaEncabezadosEgresos = []

    let lineasPresupuestosEgresos = []

    for (const [indexPresupuesto, valuePresupuesto] of dataReporte.presupuestosEgresos.entries()) {
      let lineaPresupuesto = []

      if (indexPresupuesto === 0) {
        lineaEncabezadosEgresos.push(
          { v: 'Cuenta', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
          { v: 'Descripcion', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
          { v: 'Concepto', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } })
      }

      lineaPresupuesto.push({
        v: cuentaMask(
          valuePresupuesto.cuentaContable.nivel1 +
          valuePresupuesto.cuentaContable.nivel2 +
          valuePresupuesto.cuentaContable.nivel3 +
          valuePresupuesto.cuentaContable.nivel4 +
          valuePresupuesto.cuentaContable.nivel5
        ), t: 's'
      }, {
        v: valuePresupuesto.cuentaContable.descripcion, t: 's'
      }, {
        v: valuePresupuesto.concepto, t: 's'
      })


      for (const [indexPP, valuePP] of valuePresupuesto.periodos.entries()) {
        if (indexPresupuesto === 0) {
          lineaPeriodosEgresos.push(
            { v: valuePP.nombre.toUpperCase(), t: "s", s: { font: { bold: true, sz: '13' }, alignment: { horizontal: 'center' }, border: allBorders } },
            { v: '', t: "s", s: { font: { bold: true }, border: allBorders } },
            { v: '', t: "s", s: { font: { bold: true }, border: allBorders } }
          )
          const columnaInicial = XLSXStyle.utils.encode_col(3 + (indexPP * 3))
          const columnaFinal = XLSXStyle.utils.encode_col(5 + (indexPP * 3))
          mergesHojaEgresos.push(XLSXStyle.utils.decode_range(`${columnaInicial}6:${columnaFinal}6`))
          lineaEncabezadosEgresos.push(
            { v: 'Programado', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
            { v: 'Saldo Contable', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
            { v: 'Por ejercer', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } })
        }

        lineaPresupuesto.push({
          v: valuePP.monto, t: 'n'
        }, {
          v: valuePP.saldo, t: 'n'
        }, {
          v: valuePP.porEjercer, t: 'n', s: { font: {color: (valuePP.porEjercer < 0) ? {rgb: 'FF0000'} : null}}
        })

      }

      lineaPresupuesto.push({
        v: valuePresupuesto.montoTotal, t: 'n'
      }, {
        v: valuePresupuesto.saldoTotal, t: 'n'
      }, {
        v: valuePresupuesto.porEjercerTotal, t: 'n', s: { font: {color: (valuePresupuesto.porEjercerTotal < 0) ? {rgb: 'FF0000'} : null}}
      })

      lineasPresupuestosEgresos.push(lineaPresupuesto)
    }

    lineaPeriodosEgresos.push(
      { v: 'Total', t: "s", s: { font: { bold: true, sz: '13' }, alignment: { horizontal: 'center' }, border: allBorders } },
      { v: '', t: "s", s: { font: { bold: true }, border: allBorders } },
      { v: '', t: "s", s: { font: { bold: true }, border: allBorders } }
    )


    const columnaInicialEgresos = XLSXStyle.utils.encode_col(lineaPeriodosEgresos.length - 3)
    const columnaFinalEgresos = XLSXStyle.utils.encode_col(lineaPeriodosEgresos.length - 1)
    mergesHojaEgresos.push(XLSXStyle.utils.decode_range(`${columnaInicialEgresos}6:${columnaFinalEgresos}6`))

    lineaEncabezadosEgresos.push(
      { v: 'Programado', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
      { v: 'Saldo Contable', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } },
      { v: 'Por ejercer', t: "s", s: { font: { bold: true }, alignment: { horizontal: 'center' }, border: allBorders } })


    //Poner lineas en excel para egresos

    lineasDescargarHojaEgresos.push(lineaPeriodosEgresos, lineaEncabezadosEgresos)

    for (const valueLineaPresupuesto of lineasPresupuestosEgresos) {
      lineasDescargarHojaEgresos.push(valueLineaPresupuesto)
    }



    const worksheetIngresos = XLSXStyle.utils.aoa_to_sheet(lineasDescargarHojaIngresos);
    worksheetIngresos["!merges"] = mergesHojaIngresos
    const workbook = XLSXStyle.utils.book_new();
    XLSXStyle.utils.book_append_sheet(
      workbook,
      worksheetIngresos,
      "Presupuestos Ingresos"
    );

    const worksheetEgresos = XLSXStyle.utils.aoa_to_sheet(lineasDescargarHojaEgresos);
    worksheetEgresos["!merges"] = mergesHojaEgresos
    XLSXStyle.utils.book_append_sheet(
      workbook,
      worksheetEgresos,
      "Presupuestos Egresos"
    );

    //Examples style excel

    // let row = [
    //   { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
    //   { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
    //   { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: "E9E9E9" } } } },
    //   { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
    // ];

    // STEP 3: Create worksheet with rows; Add worksheet to workbook
    // const ws = XLSXStyle.utils.aoa_to_sheet([row]);
    // XLSXStyle.utils.book_append_sheet(workbook, ws, "readme demo");

    // ws["!merges"] = [
    //   XLSX.utils.decode_range("A1:B2"),
    //   XLSX.utils.decode_range("C1:C2"),
    //   XLSX.utils.decode_range("A3:B3")
    // ];
    // workbook.Sheets['Presupuestos']["A1"].s  = { font: { bold: true, color: { rgb: "FF0000" } } }

    // worksheet["A1:A10"].s  = { font: { bold: true, color: { rgb: "FF0000" } } }


    XLSXStyle.writeFile(
      workbook,
      `Presupuestos_${moment().locale("es").format("L")}.xlsx`
    );
  }

  descargarPdf() {
    pdfMake
      .createPdf(reportePresupuestosPdf(this.state.dataReporte))
      .download(
        `Presupuestos_${moment().locale("es").format("L")}`
      );
  }

  onFinishFailed(e) {
    ErrorItem(e);
  }

  render() {
    const columns = [
      {
        title: "Cuenta",
        dataIndex: "cuenta",
        key: "cuenta",
        sorter: (a, b) => {
          if (a.cuenta < b.cuenta) {
            return -1;
          }
          if (a.cuenta > b.cuenta) {
            return 1;
          }
          return 0;
        },
        buscar: "cuenta",
        onCell: (record) => {
          return {
            colSpan: record.colSpan === 5 ? 5 : 1,
          }
        },
        ellipsis: false,
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
        ellipsis: true,
      },
      {
        title: "Concepto",
        dataIndex: "concepto",
        key: "concepto",
        sorter: (a, b) => {
          if (a.concepto < b.concepto) {
            return -1;
          }
          if (a.concepto > b.concepto) {
            return 1;
          }
          return 0;
        },
        buscar: "concepto",
        ellipsis: true,
      },
      {
        title: "Programado",
        dataIndex: "montoTotal",
        key: "montoTotal",
        sorter: (a, b) => {
          if (a.montoTotal < b.montoTotal) {
            return -1;
          }
          if (a.montoTotal > b.montoTotal) {
            return 1;
          }
          return 0;
        },
        buscar: "montoTotal",
        render: (value) => isNaN(value) ? value : dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
      },
      {
        title: "Saldo Contable",
        dataIndex: "saldoTotal",
        key: "saldoTotal",
        sorter: (a, b) => {
          if (a.saldoTotal < b.saldoTotal) {
            return -1;
          }
          if (a.saldoTotal > b.saldoTotal) {
            return 1;
          }
          return 0;
        },
        buscar: "saldoTotal",
        render: (value) => isNaN(value) ? value : dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
      },
      {
        title: "Por Ejercer",
        dataIndex: "porEjercerTotal",
        key: "porEjercerTotal",
        sorter: (a, b) => {
          if (a.porEjercerTotal < b.porEjercerTotal) {
            return -1;
          }
          if (a.porEjercerTotal > b.porEjercerTotal) {
            return 1;
          }
          return 0;
        },
        buscar: "porEjercerTotal",
        render: (value) => isNaN(value) ? value : dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
      },
    ];
    return (
      <>
        <Form
          ref={this.formRef}
          name="rerpotePresupuestos"
          onFinish={this.consultarReportePresupuestos.bind(this)}
          onFinishFailed={this.onFinishFailed}
          inita
        >
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Reporte Presupuestos"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Space>
                  {this.state.dataReporte && (
                    <ButtonItem
                      blanco
                      onClick={() => this.descargarExcel()}
                      icon={<FileExcelOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar Excel
                    </ButtonItem>
                  )}
                  {this.state.dataReporte && (
                    <ButtonItem
                      blanco
                      onClick={() => this.descargarPdf()}
                      icon={<FilePdfOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar PDF
                    </ButtonItem>
                  )}
                  <ButtonItem
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Consultar
                  </ButtonItem>
                </Space>
              </div>
            }
          />
          <Spin spinning={this.state.loading}>
            <div
              style={{
                backgroundColor: "white",
                padding: 20,
                paddingTop: 5,
                marginBottom: 20,
              }}
            >
              <Tabs
                style={{ margin: 0 }}
                tabBarExtraContent={{
                  left: (
                    <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                      Filtros:
                    </h1>
                  ),
                }}
              >
                <TabPane tabKey="3" key="3" forceRender tab="Periodo">
                  <Row>
                    <Col span={8}>

                      <FormItem margin initialValue='month' name="periocidad" required label='Periocidad'>
                        <SelectItem
                          placeholder='Periocidad'
                          width='90%'
                          value={this.state.valuePeriocidad}
                          onChange={(e) => this.setState({ valuePeriocidad: e })}
                        >
                          <Option value="date">Diario</Option>
                          <Option value="week">Semanal</Option>
                          <Option value="month">Mensual</Option>
                          <Option value="year">Anual</Option>
                        </SelectItem>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem margin label='Periodo' initialValue={[moment().subtract(1, "month"), moment()]} name="periodo" required>
                        <RangePickerItem format={null} picker={this.state.valuePeriocidad} disabledDate={(current) => {
                          // Can not select days before today and today
                          return current > moment();
                        }} />
                      </FormItem>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabKey="2" key="2" forceRender tab="Presupuestos">
                  <FormItem name="presupuestos" noStyle margin>
                    <SelectItem
                      width="90%"
                      mode="multiple"
                      placeholder="Presupuestos"
                    >
                      {this.state.objetoPresupuestos}
                    </SelectItem>
                  </FormItem>
                </TabPane>
                <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                  <Row>
                    <Col span={8}>
                      <FormItem
                        name="filtrarProyecto"
                        required
                        noStyle
                        initialValue={0}
                      >
                        <SelectItem width="90%" placeholder="Filtrar Proyecto">
                          <Option default value={0}>
                            Niguno
                          </Option>
                          <Option value={1}>Proyectos</Option>
                          <Option value={2}>Unidades de negocio</Option>
                        </SelectItem>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const filtrarProyecto =
                            getFieldValue("filtrarProyecto");
                          if (filtrarProyecto === 1) {
                            return (
                              <FormItem required name="proyectos" noStyle>
                                <SelectItem
                                  width="90%"
                                  placeholder="Proyectos"
                                  mode="multiple"
                                >
                                  {this.state.objetoProyectos}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                          if (filtrarProyecto === 2) {
                            return (
                              <FormItem
                                required
                                name="unidadDeNegocioId"
                                noStyle
                              >
                                <SelectItem
                                  width="90%"
                                  placeholder="Unidades de Negocio"
                                >
                                  {this.state.objetoUniadesDeNegocio}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                        }}
                      </FormItem>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
            <h1 style={{ textAlign: "center" }}>Presupuestos Ingresos</h1>
            <TableItem
              columns={columns}
              dataSource={this.state.presupuestosIngresos}
              size="small"
              pagination={false}
            />
            <h1 style={{ textAlign: "center", marginTop: '10px' }}>Presupuestos Egresos</h1>
            <TableItem
              columns={columns}
              dataSource={this.state.presupuestosEgresos}
              size="small"
              pagination={false}
            />
          </Spin>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ReportePresupuestos);
