import { message, Space, notification, Select, Popconfirm } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ModalItem,
    ButtonItem,
    Form,
    FormItem,
    SelectItem,
    SwitchItem,
    NotificacionPolizas
} from "../../../../Components/Items";
import facturaPdf from "../../../../Pdf/facturaPdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MandarCorreo from "../../Ventas/MandarCorreo/MandarCorreo";
import remisionPdf from "../../../../Pdf/remisionPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select

class GenerarVenta extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        pdf: null,
        todasBancos: null,
        todasFormasDePago: null,
        objetoBancos: null,
        objetoFormasDePago: null,
        contado: 1,
        factura: 2,
        modalCorreo: false,
        modalCorreoId: null,
    };

    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible)
            this.consultarTodo();
    }

    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "cotizaciones/datosGenerarVenta",
                { cotizacionId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    this.setState({
                        todasFormasDePago: res.data.formasDePago,
                        todasBancos: res.data.bancosYCajas,
                        todasProyectos: res.data.proyectos,
                        todasUsosDeCFDI: res.data.usosDeCFDI,
                        objetoFormasDePago: res.data.formasDePago.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.descripcion}
                                </Option>
                            );
                        }),

                        objetoProyectos: res.data.proyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),

                        objetoBancos: res.data.bancosYCajas.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoUsosDeCFDI: res.data.usosDeCFDI.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.descripcion}
                                </Option>
                            );
                        }),
                        contado: res.data.contado,
                        factura: res.data.factura
                    })

                    if (res.data.contado !== 1) {
                        this.formRef.current.setFieldsValue({
                            formaDePagoId: '6125149afe07662acc100d6d'
                        })
                    } else {
                        this.formRef.current.setFieldsValue({
                            formaDePagoId: '6125149afe07662acc100d58'
                        })
                    }

                    this.formRef.current.setFieldsValue({ usoDeCfdiId: res.data.usoDeCfdiId, contado: res.data.contado, proyectoId: res.data.proyectoId })

                } else {
                    message.warning('No se cargaron bien los datos')
                }
            });
        this.setState({ loading: false });
    }

    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }
    onFinish(values) {
        this.setState({ loading: true });
        axios
            .post(
                "cotizaciones/generarVentaCotizacion",
                { ...values, cotizacionId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Se ha registrado una venta");
                    if (res.data.respuestaFactura.status === "success") {
                        notification.success({
                            description: "Haga click para descargar",
                            message: "Factura Emitida",
                            duration: 0,
                            style: { cursor: "pointer" },
                            onClick: () => {
                                var xmltext = res.data.respuestaFactura.data.cfdi;

                                const dataSat = res.data.respuestaFactura.dataSat
                                const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'F') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Rfc + ' - ' + dataSat.Receptor.Nombre + ' )'

                                var filename = nombreArchivo + ".xml";
                                var pom = document.createElement("a");
                                var bb = new Blob([xmltext], { type: "text/plain" });

                                pom.setAttribute("href", window.URL.createObjectURL(bb));
                                pom.setAttribute("download", filename);

                                pom.dataset.downloadurl = [
                                    "text/plain",
                                    pom.download,
                                    pom.href,
                                ].join(":");
                                pom.draggable = true;
                                pom.classList.add("dragout");

                                pom.click();

                                const pdf = facturaPdf(res.data);

                                pdfMake.createPdf(pdf).download(nombreArchivo + '.pdf');
                            },
                        });
                        NotificacionPolizas(res.data.polizasId);
                        this.setState({
                            modalCorreo: true,
                            modalCorreoId: res.data.ventaId,
                        });
                    } else {
                        notification.success({
                            description: "Haga click para descargar",
                            message: "Remision Emitida",
                            duration: 0,
                            style: { cursor: "pointer" },
                            onClick: () => {

                                const dataSat = res.data.respuestaFactura.dataSat
                                const nombreArchivo = (dataSat.Serie ? dataSat.Serie : 'R') + ' - ' + dataSat.Folio + ' ( ' + dataSat.Receptor.Nombre + ' )'

                                const pdf = remisionPdf(res.data);

                                pdfMake.createPdf(pdf).download(nombreArchivo+'.pdf');
                            },
                        });
                        NotificacionPolizas(res.data.polizasId);
                        this.setState({
                            modalCorreo: true,
                            modalCorreoId: res.data.ventaId,
                        });
                    }
                } else if (res.data.success === 1) {
                    message.error(res.data.message ? res.data.message : "No se pudo registrar la venta");
                    notification.warning({
                        description: res.data.description,
                        message: res.data.message,
                        duration: 0,
                    });
                } else {
                    message.error("Hubo un error y la venta no se registro");
                }
                this.setState({ loading: false });
            });
    }

    cerrarGenerarVentaDeContizacion() {
        this.props.cerrarGenerarVentaDeContizacion();
    }

    onChangeContado(value) {
        if (value !== 1) {
            this.formRef.current.setFieldsValue({
                formaDePagoId: '6125149afe07662acc100d6d'
            })
        } else {
            this.formRef.current.setFieldsValue({
                formaDePagoId: '6125149afe07662acc100d58'
            })
        }
    }

    cerrarMandarCorreo() {
        this.setState({
            modalCorreo: false,
            modalCorreoId: null,
        });
        this.formRef.current.resetFields();
        this.cerrarGenerarVentaDeContizacion()
    }

    render() {
        return (
            <>
                <ModalItem
                    title="Generar Venta"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarGenerarVentaDeContizacion.bind(this)}
                    paddingTop
                >
                    <Form
                        ref={this.formRef}
                        name="generarVenta"
                        layout
                        width={700}
                        onCancel={this.cerrarGenerarVentaDeContizacion.bind(this)}
                        onFinish={this.onFinish.bind(this)}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <FormItem
                            required
                            initialValue={2}
                            label='Tipo Venta'
                            name="factura"
                            margin
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                        >
                            <SelectItem
                                onChange={(value) => this.setState({ factura: value })}
                                placeholder='Tipo Venta'
                            >
                                <Option value={2}>Remisión</Option>
                                {this.props.permisoFacturarVentas &&
                                    this.props.certificada && (
                                        <Option value={1}>Factura</Option>
                                    )}
                            </SelectItem>
                        </FormItem>
                        <FormItem
                            required
                            name="contado"
                            label="Metodo pago"
                            margin
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                        >
                            <SelectItem
                                onChange={this.onChangeContado.bind(this)}
                            >
                                <Option value={1}>
                                    Contado - PUE : Pago en una sola
                                    exhibición
                                </Option>
                                <Option value={2}>
                                    Crédito sin enganche - PPD : Pago en
                                    parcialidades o diferido
                                </Option>
                                <Option value={3} disabled>
                                    Crédito con enganche - PPD : Pago en
                                    parcialidades o diferido
                                </Option>
                            </SelectItem>
                        </FormItem>
                        <FormItem noStyle shouldUpdate={(prev, curr) => prev.contado !== curr.contado}>
                            {({ getFieldValue }) => {
                                const contado = getFieldValue('contado')
                                return (
                                    <FormItem
                                        name="formaDePagoId"
                                        label="Forma Pago"
                                        initialValue="6125149afe07662acc100d58"
                                        margin
                                        required
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <SelectItem disabled={contado !== 1} placeholder="Forma de Pago">
                                            {this.state.objetoFormasDePago}
                                        </SelectItem>
                                    </FormItem>
                                )
                            }}
                        </FormItem>
                        <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                                const contado = getFieldValue("contado");
                                const cuentasXC = getFieldValue("cuentasXC");
                                if (contado === 1 || contado === 3) {
                                    return (
                                        <>
                                            <FormItem
                                                label="Cuentas XC"
                                                name="cuentasXC"
                                                margin
                                                initialValue={false}
                                                valuePropName="checked"
                                            >
                                                <SwitchItem />
                                            </FormItem>
                                            <FormItem
                                                required={!cuentasXC}
                                                margin
                                                name="bancoId"
                                                label="Banco"
                                            >
                                                <SelectItem placeholder="Banco" disabled={cuentasXC}>
                                                    {this.state.objetoBancos}
                                                </SelectItem>
                                            </FormItem>
                                        </>
                                    );
                                }
                            }}
                        </FormItem>
                        <FormItem shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                                const factura = getFieldValue('factura')
                                if (factura === 1) {
                                    return (
                                        <FormItem
                                            name="usoDeCfdiId"
                                            label="Uso de CFDI"
                                            margin
                                            required
                                            labelCol={{ span: 8 }}
                                            wrapperCol={{ span: 16 }}
                                        >
                                            <SelectItem placeholder="Uso de CFDI">
                                                {this.state.objetoUsosDeCFDI}
                                            </SelectItem>
                                        </FormItem>

                                    )
                                }
                            }}
                        </FormItem>
                        <FormItem
                            label="Proyecto"
                            name="proyectoId"
                            margin
                            required
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                        >
                            <SelectItem
                                placeholder="Proyecto"
                            >
                                {this.state.objetoProyectos}
                            </SelectItem>
                        </FormItem>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "right",
                                marginTop: 20,
                                marginBottom: 10,
                            }}
                        >
                            <Space>

                                <Popconfirm
                                    title={
                                        (this.state.factura === 1)
                                            ? "Se generara una factura"
                                            : "Se generara una remision"
                                    }
                                    onConfirm={() => this.formRef.current.formRef.current.submit()}
                                    okText="Ok"
                                    cancelText="Cancelar"
                                >
                                    <ButtonItem
                                        type="primary"
                                        style={{ display: "block", margin: "0 0 0 auto" }}
                                    >
                                        Generar Venta
                                    </ButtonItem>
                                </Popconfirm>

                                <Popconfirm>


                                </Popconfirm>
                                <ButtonItem
                                    type="primary"
                                    danger
                                    onClick={this.cerrarGenerarVentaDeContizacion.bind(this)}
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Regresar
                                </ButtonItem>
                            </Space>
                        </div>
                    </Form>
                </ModalItem>
                <MandarCorreo
                    visible={this.state.modalCorreo}
                    id={this.state.modalCorreoId}
                    cerrarMandarCorreo={this.cerrarMandarCorreo.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
        certificada: state.empresa.certificada,
        complementoDetallista: state.empresa.complementoDetallista,
        permisoFacturarVentas:
            state.user.tipo === 0 ||
            state.user.permisos.find(
                (valueTodos) =>
                    valueTodos.permisoId.toString() === "615ce36bf30e81b54183cfaf"
            )?.activado,
    };
};

export default connect(mapStateToProps)(GenerarVenta);
